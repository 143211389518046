<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2 class="banner-title">Hire Candidates on Contract or Part-Time in a Day</h2>
         
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area ptb-50">
  <div class="container">
   
    <div class="row align-items-center">
     

      <div class="col-lg-7 col-md-12">
        <div class="about-content">
          <div class="section-title">
            <h1 class="text-dark">Join Business Community of IT Companies to Hire Temp Resources</h1>
             <h2>Expand your Business & Reduce Sales Cost</h2>
          </div>
          <p >
            Reduce TAT (Turn Around Time) & save cost by getting access to 5000+ active bench employees from 4500+ IT companies.
          </p>

          <button><a  class="text-white" routerLink="/register"> Free SignUp</a> </button> 

          <button><a  class="text-white" routerLink="/request-demo"> Request Demo</a> </button> 
          <!-- <button> Request Demo</button> -->


        </div>
      </div>

      <div class="col-lg-5 col-md-12">

       

        
       <div class="connect-form">
        <form>
          <h5 class="text-dark">Hire Contract or Part-Time candidates in just a day</h5>
            <div>
              <label for="name">Enter Name:</label>
              <input type="text" id="name" name="name" placeholder="Your name" required>
            </div>
          
            <div>
              <label for="email">Enter Email:</label>
              <input type="email" id="email" name="email" placeholder="Your email" required>
            </div>
          
            <div>
              <label for="phone">Enter Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Your phone number" required>
            </div>
          
            <div>
              <label for="message">Type Message:</label>
              <textarea id="message" name="message" placeholder="Your message" required></textarea>
            </div>
          
            <div>
              <button type="submit">Submit</button>
            </div>
          </form>
          
       </div>
      </div>

    </div>
  </div>
</div>
<!-- End New -->



<!-- Work -->
<section class="work-area bg-light pt-50 ">
    <div class="container">
  
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div class="work-item bg-white">
            <h3 class="text-center">Reduce your Hiring Cost</h3>
            <p class="text-center">
              No more TA team is required to hire contract resources. Our Team
              will assist you to hire resources on Contract. Get dedicated
              recruiter
            </p>
          </div>
        </div>
  
        <div class="col-sm-6 col-lg-4">
          <div class="work-item bg-white">
            <h3 class="text-center">PAN India Contract Resources</h3>
            <p class="text-center">
              Get resources on Contract locally and hire onsite or offsite
              candidates at lowest rates. Get partners to outsource your project
              or job
            </p>
          </div>
        </div>
  
        <div class="col-sm-6 col-lg-4">
          <div class="work-item bg-white">
            <h3 class="text-center">No Hiring Commission</h3>
            <p class="text-center">
              You don’t need to pay any Hiring commission to on-board any resource
              on contract. Just connect & hire directly from IT Companies
            </p>
          </div>
        </div>
     </div>
    </div>
  </section>
  <!-- End Work -->


<!-- System -->
<section class="video-section  ptb-50">
    <!-- <div class="system-shape">
      <img src="assets/img/home-three/system1.png" alt="Shape" />
      <img src="assets/img/home-three/system2.png" alt="Shape" />
    </div> -->
  
    <div class="container">
        <div class="row">

            <div class="col-md-6">
                <div class="system-item">
                    <div class="section-title three">
                      <!-- <div class="sub-title-wrap">
                        <span class="sub-title">Quick Talent Marketplace</span>
                      </div> -->
                      <h2>Hire Contract Resources On-Demand</h2>
                    </div>
                    <p>Hiring a developer on contract was never so easy as ordering a Pizza. Hiring a developer on full-time is no more required as you can hire whenever you may need a developer on contract. Just connect with thousands of Bench employers and on-On-board a developers on contract in just 2 days.</p>
                    
                    <!-- <ul class="system-video">
                      <li class="left-btn">
                        <span>Watch Message</span>
                        <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=4nb5LA75_0Q"><i class="bx bx-play"></i></a>
                     </li>
                    </ul> -->
                  </div>
            </div>

            <div class="col-md-6">
                <div class="video-container">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/4nb5LA75_0Q"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
                    </iframe>
                  </div>
                  
            </div>
        </div>

     
    </div>
  </section>
  <!-- End System -->



  <!-- Work -->
<section class="talent-area bg-light ptb-50 ">
  <div class="container">

    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="work-item bg-white">
          <div class="img-port col-lg-4 offset-md-4"><img src="assets/img/first.png" alt="New" /></div>
          
          <h3 class="text-center">Post Job</h3>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="work-item bg-white">
          <div class="img-port col-lg-4 offset-md-4 "><img src="assets/img/second.png" alt="New" /></div>
          
          
          <h3 class="text-center">Connect with Bench Employer</h3>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="work-item bg-white">
          <div class="img-port col-lg-4 offset-md-4"> <img src="assets/img/third.png" alt="New" /></div>
          
         
          <h3 class="text-center">On-Board Temp Staff</h3>
        </div>
      </div>
   </div>
  </div>
</section>
<!-- End Work -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
