<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Bench Applications</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<div class="container my-5">
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="userid" class="col-md-12">
        <h3>{{ candidatedata?.name }} (RID : {{ candidatedata?.resourceId }})</h3>
      </div>
      <div class="card card_Border">
        <div class="card-body">
          <!-- Tabs -->
          <ul class="nav nav-tabs horizontalList" role="tablist">
            <li role="presentation" [ngClass]="{ active: tab[0] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(1, 0)">
                <i class="fa-solid fa-thumbs-up"></i>
                <span> Applied to ({{ total?.pending }})</span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[1] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(2, 1)">
                <i class="fa-solid fa-clipboard-check"></i>
                <span> Shortlisted ({{ total?.short }})</span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[2] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(3, 2)">
                <i class="fa-solid fa-circle-check"></i>
                <span> Hired ({{ total?.hired }})</span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[3] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(4, 3)">
                <i class="fa-solid fa-circle-xmark"></i>
                <span> Rejected ({{ total?.reject }})</span>
              </a>
            </li>
          </ul>
          <!-- tabContents -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active">
              <div class="row my-3">
                <!-- Searching/Filters -->
                <div *ngIf="candidateapp?.length > 0" class="col-lg-8 col-sm-9 pt-2 pb-3">
                  <form [formGroup]="searchForm" class="form-inline">
                    <div class="row form-group">
                      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-4">
                        <input type="text" formControlName="namesearch" placeholder="Search by name or email " class="form-control" id="email" />
                      </div>
                      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-4">
                        <input type="text" placeholder="Select range of date" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true }" formControlName="rangedate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" />
                      </div>
                      <div class="col-lg-4 col-sm-2">
                        <!--  -->
                        <button (click)="filtercandidate()" type="button" class="btn searchBtn">Search</button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- pagination -->
                <div *ngIf="candidateapp?.length > 0" class="col-lg-4 col-sm-3 pt-2 pb-3 text-end">
                  <span class="txt">Page {{ querydata?.page }} of {{ querydata?.totalpage }}</span
                  >&nbsp;
                  <button type="button" [disabled]="querydata?.page == 1" class="btn paginationBTN" (click)="getlistbypage('prev')">
                    <i class="fa fa-angle-left"></i></button
                  >&nbsp;
                  <button type="button" [disabled]="querydata?.page == querydata?.totalpage" class="btn paginationBTN" (click)="getlistbypage('next')">
                    <i class="fa fa-angle-right"></i></button
                  >&nbsp;
                </div>
              </div>

              <ul class="listService ps-0">
                <li *ngFor="let list of candidateapp; let i = index">
                  <div class="listWrpService featured-wrap ">
                    <div class="row">
                      <!-- *ngIf="userid=='' && loginType==3" -->
                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <a (click)="openCandidateDetails(list?.candidateId?._id)" class="title-head pointer text-dark">
                          <strong>{{ list?.candidateId?.name }}</strong>
                        </a>
                        <br />
                        <span class="badge bg-warning text-dark">My Bench</span>

                        RID :
                        <span class="text-info">{{ list?.candidateId?.resourceId }}</span>
                        <p class="para pt-2">
                          Key Skills :
                          <span *ngFor="let slist of list?.candidateId?.skill">
                            <span class="skillsBG">{{ slist }}</span>
                          </span>
                        </p>
                      </div>
                      <!-- *ngIf="userid=='' && loginType==3 && tab[0]" -->
                      <div class="col-lg-2 col-md-1 col-sm-1 col-xs-12 text-center">
                        Applied to
                        <br />
                        <i class="fa fa-long-arrow-right fa-2x text-info"></i>
                      </div>

                      <div *ngIf="userid == '' && loginType == 3 && tab[1]" class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        Shortlisted by
                        <br />
                        <i class="fa fa-long-arrow-right fa-2x text-info"></i>
                      </div>

                      <div *ngIf="userid" class="col-lg-2 col-md-2 col-sm-3 col-xs-3">
                        <div class="listImg">
                          <img src="assets/images/company.png" alt="" />
                        </div>
                      </div>

                      <!-- [ngClass]="{'col-lg-10 col-md-10 col-sm-9 col-xs-12':userid, 'col-lg-7 col-md-7 col-sm-7 col-xs-12': userid=='' && loginType==3,'col-lg-12 col-md-12 col-sm-12 col-xs-12': userid=='' && loginType==4}" -->

                      <div class="col-lg-7 col-md-7 col-sm-76 col-xs-12">
                        <div class="row">
                          <div class="col-lg-8 col-md-7">
                            <div class="title-head">
                              Job Title : <span class="text-info">{{ list?.jobId?.title }}</span>
                            </div>
                            <div class="c_name" *ngIf="list?.companyLoginId?.loginType == 3">
                              <span *ngIf="tab[0]">Applied at : </span>
                              <span *ngIf="tab[1]">Shortlisted By : </span>
                              <span class="text-info">{{ list?.companyLoginId?.cname }} </span>
                              <span class="badge bg-info text-dark"> Company</span>
                            </div>
                            <div class="c_name" *ngIf="list?.companyLoginId?.loginType == 4">
                              <span *ngIf="tab[0]">Applied at:</span>
                              <span *ngIf="tab[1]">Shortlisted By : </span>
                              <strong> {{ list?.companyLoginId?.name }} </strong>
                              <span class="badge bg-info text-dark"> Freelancer</span>
                            </div>
                            <p class="mb-0">
                              <i class="fa-solid fa-location-dot"></i> Location :
                              <span *ngFor="let loc of list?.jobId?.location">
                                <span class="text-info">{{ loc?.location }}</span
                                >,
                              </span>
                            </p>
                            <p class="mb-0">
                              <i class="fa-solid fa-calendar-days"></i>
                              Joining Date :
                              <span class="text-info">{{ list?.jobId?.tentativeStartDate | date: "MMM d, y" }}</span> to
                              <span class="text-info">{{ list?.jobId?.tentativeEndDate | date: "MMM d, y" }}</span>
                            </p>
                            <p class="para">
                              Key Skills :
                              <span *ngFor="let slist of list?.jobId?.skill">
                                <span class="skillsBG">{{ slist }}</span></span
                              >
                            </p>
                          </div>
                          <div class="col-lg-4 col-md-5 pe-0">
                            <div class="row">
                              <div class="col-lg-12 col-sm-12">
                                <div class="contact-details">
                                  <div>
                                    Rate : <strong> <i class="fa fa-inr"></i> {{ parseRate(list?.jobId?.ratemin) }}</strong> to <strong>{{ parseRate(list?.jobId?.ratemax) }}</strong> (Monthly)
                                  </div>
                                  <!-- <div *ngIf="currencytype == 'INR'">
                                    Rate : <strong> <i class="fa fa-inr"></i> {{ list?.jobId?.ratemin | currency: "Hourly" }}</strong> to <strong>{{ list?.jobId?.ratemax | currency: "Hourly" }}</strong> ({{ list?.jobId?.ratetype }})
                                  </div> -->
                                  <div>
                                    <span>
                                      Total Resources : <span class="text-info">{{ list?.jobId?.noofResources }}</span>
                                    </span>
                                    <br />
                                    <!-- <span>
                                      Rate : <span class="text-info">₹ {{ parseRate(list) }} (Monthly)</span>
                                    </span> -->
                                  </div>
                                  <div>
                                    Experience :
                                    <span class="text-info">{{ list?.jobId?.experiencemin }}</span> to
                                    <span class="text-info">{{ list?.jobId?.experiencemax }} Year </span>
                                  </div>
                                  <ng-container>
                                    <div *ngIf="list?.companyLoginId?.phone" class="contact-mobile"><i class="fa fa-phone" aria-hidden="true"></i> : {{ list?.companyLoginId?.phone }}</div>
                                    <div *ngIf="list?.companyLoginId?.email" class="contact-mobile">
                                      <i class="fa fa-envelope" aria-hidden="true"></i> :
                                      {{ list?.companyLoginId?.email }}
                                    </div>
                                  </ng-container>
                                  <div class="contact-email">
                                    <i class="fa-solid fa-calendar-days"></i> Applied :
                                    <span class="text-info">{{ list?.createdAt | date: "MMM d, y" }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12 text-end">
                        <div *ngIf="tab[0] == true">
                          <!-- <a type="button" class="btn btn-sm btn-primary" [routerLink]="['/business/commonsystem/chating']" [queryParams]="{ userid: list?.companyLoginId?._id }" target="_blank">Start Chat </a>&nbsp; -->

                          <a *ngIf="loginType == 3" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, candidateId: userid, name: candidatedata?.name, rid: candidatedata?.resourceId }" target="_blank" class="btn btn-sm btn-info">View Job </a>

                          <a *ngIf="loginType == 4" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, loginType: 4 }" target="_blank" class="btn btn-sm btn-info">View Job </a>
                        </div>

                        <div *ngIf="tab[1] == true">
                          <button *ngIf="loginType == 3 && list?.contactviewby == 0" class="btn btn-sm btn-info" (click)="viewshortcontact(list?._id, i)">View Contact</button>&nbsp;

                          <!-- <a class="btn btn-sm btn-primary" [routerLink]="['/business/commonsystem/chating']" [queryParams]="{ userid: list?.companyLoginId?._id }" target="_blank">Start Chat </a>&nbsp; -->

                          <a *ngIf="loginType == 3" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, candidateId: userid, name: candidatedata?.name, rid: candidatedata?.resourceId }" target="_blank" class="btn btn-sm btn-info">View Job </a>

                          <a *ngIf="loginType == 4" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, loginType: 4 }" target="_blank" class="btn btn-sm btn-info">View Job </a>
                        </div>
                        <div *ngIf="tab[2]">
                          <!-- <a *ngIf="loginType == 4" class="btn btn-sm btn-primary" [routerLink]="['/business/commonsystem/chating']" [queryParams]="{ userid: list?.companyLoginId?._id }" target="_blank">Start Chat </a>&nbsp; -->

                          <!-- <a *ngIf="loginType == 3" class="btn btn-sm btn-primary" [routerLink]="['/business/commonsystem/chating']" [queryParams]="{ userid: list?.companyLoginId?._id }" target="_blank">Start Chat </a>&nbsp; -->

                          <a *ngIf="loginType == 3" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, candidateId: userid, name: candidatedata?.name, rid: candidatedata?.resourceId }" target="_blank" class="btn btn-sm btn-info">View Job </a>
                          <a *ngIf="loginType == 4" [routerLink]="['/business/jobother/job-applied-candidate']" [queryParams]="{ jobid: list?.jobId?._id, loginType: 4 }" target="_blank" class="btn btn-sm btn-info">View Job </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li *ngIf="candidateapp?.length === 0 && shimmer == false">
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-3 text-center">
                      <img class="mt-4" src="/assets/img/data_not_found.png" width="150px" alt="not found jobs applicant" />
                      <p class="ps-2">Data Not Found!</p>
                    </div>
                  </div>
                </li>
                <li *ngIf="shimmer">
                  <div class="shimmer">
                    <div class="shine"></div>
                    <div class="shine"></div>
                    <div class="shine"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->

<!-- modal -->
<div *ngIf="isModalShownpackage" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden('upgrade')" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Please Update your Package</h3>
      </div>
      <div class="modal-footer">
        <a [routerLink]="['/business/package/plan']" [queryParams]="{ checkoutstt: '1' }" class="btn btn-blue"> Select Plan</a>
      </div>
    </div>
  </div>
</div>
