<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Candidates</h2>
          <ul>
            <li>
              <img src="assets/img/home-three/title-img-two.png" alt="Image" />
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>/</span>
            </li>
            <li>Candidates</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
  <div class="container">
    <form>
      <div class="row">
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="e.g UI/UX Design" />
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Age</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Category</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Location</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Languages</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Qualifications</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="form-group">
            <select>
              <option>Filter By Experiences</option>
              <option>Another option</option>
              <option>A option</option>
              <option>Potato</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <button type="submit" class="btn cmn-btn">
            Search By Filter
            <i class="bx bx-plus"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- End Filter -->

<!-- Showing -->
<div class="job-showing-area">
  <div class="container">
    <h4>Showing 1 - 8 of 11 results</h4>
    <div class="showing">
      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <div class="left">
            <div class="form-group">
              <select>
                <option>Newest</option>
                <option>Another option</option>
                <option>A option</option>
                <option>Potato</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="right">
            <ul>
              <li>
                <a href="#">
                  <i class="bx bx-dots-horizontal"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="bx bx-menu"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Showing -->

<!-- Candidate -->
<div class="candidate-area pb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
            <span>Web Developer</span>
            <ul class="experience">
              <li>Experience: <span>3-5 years</span></li>
              <li>Hour Rate: <span>$30</span></li>
              <li><i class="flaticon-send"></i> Chicago, Illinios</li>
            </ul>
            <ul>
              <li>Php</li>
              <li>jQuery</li>
              <li>WordPress</li>
              <li>CSS3</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate1.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
            <span>Senior UX/UI Designer</span>
            <ul class="experience">
              <li>Experience: <span>2-3 years</span></li>
              <li>Hour Rate: <span>$60</span></li>
              <li><i class="flaticon-send"></i> California</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate2.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3>
              <a routerLink="/candidate-details">Jesse R. Chung</a>
            </h3>
            <span>PHP Developer</span>
            <ul class="experience">
              <li>Experience: <span>1-3 years</span></li>
              <li>Hour Rate: <span>$50</span></li>
              <li><i class="flaticon-send"></i> Berlin</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate3.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Linnaea Ronald</a></h3>
            <span>Unity Developer</span>
            <ul class="experience">
              <li>Experience: <span>2-6 years</span></li>
              <li>Hour Rate: <span>$70</span></li>
              <li><i class="flaticon-send"></i> France</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate4.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Leo Tolstoy</a></h3>
            <span>Accounting/Finance</span>
            <ul class="experience">
              <li>Experience: <span>3-5 years</span></li>
              <li>Hour Rate: <span>$30</span></li>
              <li><i class="flaticon-send"></i> Taine, Paris</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate5.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3>
              <a routerLink="/candidate-details">Jack London</a>
            </h3>
            <span>UX/UI Designer</span>
            <ul class="experience">
              <li>Experience: <span>1-3 years</span></li>
              <li>Hour Rate: <span>$35</span></li>
              <li><i class="flaticon-send"></i> United State</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate6.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Charles Dickens</a></h3>
            <span>Education Training</span>
            <ul class="experience">
              <li>Experience: <span>3-5 years</span></li>
              <li>Hour Rate: <span>$30</span></li>
              <li><i class="flaticon-send"></i> Berlin</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate7.jpg" alt="Candidate" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="candidate-item two">
          <div class="left">
            <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
            <span>Senior UX/UI Designer</span>
            <ul class="experience">
              <li>Experience: <span>2-3 years</span></li>
              <li>Hour Rate: <span>$60</span></li>
              <li><i class="flaticon-send"></i> UK</li>
            </ul>
            <ul>
              <li>CSS</li>
              <li>Creative</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>HTML5</li>
            </ul>
            <div class="cmn-link">
              <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
            </div>
          </div>
          <img src="assets/img/candidate8.jpg" alt="Candidate" />
        </div>
      </div>
    </div>

    <div class="pagination-area">
      <ul>
        <li><a routerLink="/candidates">Prev</a></li>
        <li><a routerLink="/candidates">1</a></li>
        <li><a routerLink="/candidates">2</a></li>
        <li><a routerLink="/candidates">3</a></li>
        <li><a routerLink="/candidates">Next</a></li>
      </ul>
    </div>
  </div>
</div>
<!-- End Candidate -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
