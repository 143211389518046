import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-freelancer-register',
  templateUrl: './freelancer-register.component.html',
  styleUrls: ['./freelancer-register.component.scss']
})
export class FreelancerRegisterComponent {
  form: FormGroup;
  countryCodes = [
    { id: '+1', name: '+1 (USA)' },
    { id: '+44', name: '+44 (UK)' },
    { id: '+91', name: '+91 (India)' },
    { id: '+61', name: '+61 (Australia)' },
    { id: '+81', name: '+81 (Japan)' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      countryCode: ['+91'], // Default value for countryCode
      mobileNumber: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      password: [''],
      cityName: [''],
      resume: [''],
      skills: ['']
    });
  }

  // Define the onSubmit method
  onSubmit() {
    if (this.form.valid) {
      console.log('Form submitted:', this.form.value);
      // You can handle form submission logic here (e.g., sending form data to a server)
    } else {
      console.log('Form is invalid');
    }
  }
}
