import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  apiUrl: any = "https://www.onbenchmark.com/";
  baseurl = window.location.hostname.toLowerCase() == "localhost" ? "http://localhost:4500/" : "https://www.onbenchmark.com/";
  jsonheader: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
      this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
   }
   
  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`, 
    });
  }


  async create(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}api/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error(`Error occurred while creating ${tbl}:`, error);
      throw error;
    }
  }

  async update(ids: any, data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while updating profile:", error);
      throw error;
    }
  }

  async findTicket(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}api/${tbl}?filter=${data}`, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async commonUpload(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}auth/commonupload/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error(`Error occurred while creating ${tbl}:`, error);
      throw error;
    }
  }

  getbaseurl() {
    return this.baseurl;
  }







}
