<!-- Header -->
<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Forgot Password</h2>  
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ForgotPasswordForm -->
<div class="container-fluid ps-0">

  <div *ngIf="ifMsgSent === false" class="row mainBorder">
    <div class="col-lg-6 col-md-12 ">
      <div class="user-img">
        <img src="assets/img/forgot-pw.png" alt="User" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 align-self-center px-5">
      <div class="top">
        <h2 class="text-center">Forgot Password</h2> 

        <form [formGroup]="forgotPasswordForm">
          <div class="form-group">
            <input type="text" formControlName="email" placeholder="Enter your registered email*" class="form-control" id="email" [ngClass]="{'is-invalid': formForgotPassword.email.invalid && (formForgotPassword.email.touched || submitted), 'is-valid': formForgotPassword.email.valid && (formForgotPassword.email.touched || submitted)}" />
            
            <div *ngIf="formForgotPassword.email.invalid && (formForgotPassword.email.touched || submitted)">
              <small class="text-danger" *ngIf="formForgotPassword.email.errors['required']">Email is <b>required.</b></small>
              <small class="text-danger" *ngIf="formForgotPassword.email.errors['pattern']">Email should be <b>valid.</b> eg; abc&#64;domainName.com</small>
            </div>  

          </div>
        
          <div class="my-3 text-center">
            <button type="button" class="btn btn-info" id="forgotPasswordbtn" [disabled]="forgotPasswordForm.invalid"        (click)="onSubmit()">Submit</button>
          </div>
        </form>      
      </div>    
    </div>
  </div>
  <div *ngIf="ifMsgSent === true" class="row my-5 d-flex justify-content-center"> 
    <div class="col-lg-6 text-center columnBorder p-5 my-5">
      <i class="fa-solid fa-envelope fa-2x text-success"></i>
      <p>
        "An email has been sent. If the email address you entered is registered with onbenchmark.com, you will receive instructions on how to reset your password."
      </p>
    </div>
  </div>
</div>
<!-- End of ForgotPasswordForm -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->