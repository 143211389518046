<!-- Navbar -->
<div class="navbar-area fixed-top">
  <div class="mobile-nav">
    <a routerLink="/" class="logo"><img style="width: 150px" src="assets/img/logo.png" alt="Logo" /></a>
  </div>

  <div class="main-nav three">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" [routerLink]="!userLoggedIn ? '/' : '/dashboard'"><img style="width: 150px" src="assets/img/logo.png" alt="Logo" /></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <!-- <li *ngIf="!userLoggedIn" class="nav-item">
              <a class="navbar-brand" routerLink="/">Home</a>
            </li> -->

            <li *ngIf="!userLoggedIn" class="nav-item">
              <!-- <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Upload Resume </a> -->
              <a routerLink="/pricing-table" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pricing </a>
            </li>

            <!-- ==================Sudatt's Work ===================-->
            <li *ngIf="userLoggedIn" class="nav-item">
              <a routerLink="/dashboard" class="nav-link dropdown-toggle">Dashboard</a>
            </li>
            <!-- end of Sudatt's Work -->

            <li *ngIf="!userLoggedIn" class="nav-item">
              <!-- <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Upload Resume </a> -->
              <a routerLink="/hire-expert" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Find Expert </a>
            </li>
            <li *ngIf="!userLoggedIn" class="nav-item">
              <a routerLink="/hire-on-contract" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Hire on-Contract </a>
            </li>
            <li *ngIf="!userLoggedIn" class="nav-item">
              <a routerLink="/find-clients" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Find Clients </a>
            </li>

            <!-- ===================================================== -->

            <!-- ====================  Select Contry  - start ========================== -->

            <!-- ====================== Select Contry  - end  ========================= -->
            <!-- ==================================================== -->

            <!-- <li *ngIf="userLoggedIn" class="nav-item">
              <a routerLink="/support" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Support</a>
            </li> -->

            <!-- <li *ngIf="userLoggedIn" class="nav-item">
              <a routerLink="/search" [queryParams]="{ tab: 1, userType: 3 }" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> Search </a>
            </li> -->

            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                <img src="assets/img/ind-flag.png" class="flag" alt="Shape" />
                <!-- <i class="bx bx-chevron-down"></i> -->
              </a>

              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="https://www.onbenchmark.us/" class="nav-link">
                    <img src="assets/img/us-flag.png" class="flag" alt="Shape" /> 
                    USA</a
                  >
                </li>
              </ul>
            </li>
            <!-- Profile_dropdown -->

            <li *ngIf="userLoggedIn" class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle"> <i class="fa-solid fa-user me-1" style="margin-top: -10px"></i> {{ authService.userdata.data.fname }} <i class="bx bx-chevron-down"></i> </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/profile" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> My Profile </a> 
                </li>
                <li class="nav-item">
                  <a [routerLink]="['/pricing']" [queryParams]="{ checkoutstt: '1' }" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> Upgrade Plan </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/support" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> Support </a>
                </li>
                <li class="nav-item">
                  <a (click)="logout()" class="nav-link pointer" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> Logout </a>
                </li>
              </ul>
            </li>

            <!--
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="bx bx-chevron-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link dropdown-toggle">Users <i class="bx bx-chevron-down"></i></a>

                  <ul class="dropdown-menu">
                    <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Login</a></li>

                    <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Register</a></li>
                  </ul>
                </li>

                <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link dropdown-toggle">Employers <i class="bx bx-chevron-down"></i></a>

                  <ul class="dropdown-menu">
                    <li class="nav-item"><a routerLink="/employers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Employers</a></li>

                    <li class="nav-item"><a routerLink="/employer-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Employer Details</a></li>
                  </ul>
                </li>

                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Dashboard</a></li>

                <li class="nav-item"><a routerLink="/single-resume" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Single Resume</a></li>

                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Testimonials</a></li>

                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pricing</a></li>

                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">FAQ</a></li>

                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Coming Soon</a></li>

                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">404 Error</a></li>

                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Privacy Policy</a></li>

                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Terms & COnditions</a></li>
              </ul>
            </li> -->

            <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">About</a></li> -->

            <!-- <li class="nav-item">
              <span class="tooltip-span">Hot</span>

              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs <i class="bx bx-chevron-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/jobs" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Jobs</a></li>

                <li class="nav-item"><a routerLink="/favourite-jobs" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Favourite Jobs</a></li>

                <li class="nav-item"><a routerLink="/job-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Job Details</a></li>

                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Post A Job</a></li>
              </ul>
            </li> -->

            <!--    <li class="nav-item">
              <span class="tooltip-span two">New</span>

              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates <i class="bx bx-chevron-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/candidates" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Candidates</a></li>

                <li class="nav-item"><a routerLink="/candidate-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Candidate Details</a></li>

                <li class="nav-item"><a routerLink="/add-bench" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Add Bench</a></li>
              </ul>
            </li> -->

            <!-- <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="bx bx-chevron-down"></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Blog</a></li>

                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Blog Details</a></li>
              </ul>
            </li> -->

            <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Contact</a></li> -->
          </ul>

          <div class="side-nav three">
            <a *ngIf="!userLoggedIn" class="login-left" routerLink="/login">Login</a>

            <!-- <a *ngIf="userLoggedIn" class="profileLink" routerLink="/profile">
              <i style="margin-right: 10px" class="fa-solid fa-user"></i>
            </a>           -->
          </div>

          <div class="side-nav three signup">
            <a *ngIf="!userLoggedIn" class="signup-left" routerLink="/register">
              <!-- <i class="flaticon-enter"></i>  -->
              SignUp For Free</a
            >
            <!-- <a *ngIf="userLoggedIn" class="login-left" (click)="logout()"><i class="flaticon-exit"></i> Logout</a> -->

            <!-- <a class="job-right" routerLink="/post-a-job">Logout <i class="bx bx-plus"></i></a> -->
            <!-- <a class="job-right" routerLink="/post-a-job">Post A Job <i class="bx bx-plus"></i></a> -->
            <!-- <a *ngIf="!userLoggedIn" class="profileLink" routerLink="/profile">
            </a> -->

            <a *ngIf="!userLoggedIn" class="profileLink" routerLink="/profile">
              <!-- <i style="margin-right: 10px" class="fa-solid fa-user"></i> -->
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar -->
