import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from "./auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AddBenchService {

  apiUrl: any = "https://www.onbenchmark.com/";
  jsonheader: any;
  public globalprize = new Subject();

  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    private router: Router
  ) { 
    this.jsonheader = new HttpHeaders({ 'Content-Type': 'application/json' });
  } 


  changeprizeglobal(prize:any) {
    this.globalprize.next(prize);
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, 
    });
  }

  async FindbyId(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise() 
      .catch(error => {
        console.error('Error occurred while finding data by id:', error);
        throw error;
      });
  }

  fileupload(data: any, tbl: any): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.apiUrl}auth/Upload/${tbl}`, data, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  async getcurrency(): Promise<any> {
    return this.http.get(`${this.apiUrl}custom/getcurrency`).toPromise()
      .catch(error => {
        console.error('Error occurred while getting currency:', error);
        throw error;
      });
  } 

  async createBench(data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.post(`${this.apiUrl}api/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while creating bench:", error);
        throw error;
      });
  }

  async custumFind(data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.post(`${this.apiUrl}custom/mycandidate/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while getting candidate data:", error);
        throw error;
      });
  }

  async updateBench(ids: any, data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.put(`${this.apiUrl}company/update/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while updating bench:", error);
        throw error;
      });
  }

  async updateStatus(ids: any, data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while updating status:", error);
        throw error;
      });
  }

  async servicecommon(data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.post(`${this.apiUrl}custom/servicecommon/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while updating bench:", error);
        throw error;
      });
  }

  async getjobtype(data: any, tbl: any): Promise<any> {
    this.initialize();
    return this.http.post(`${this.apiUrl}custom/getjobtype/${tbl}`, data, {headers: this.authService.jsonheader }).toPromise()
      .catch((error) => {
        console.error("Error occurred while retrieving job type:", error);
        throw error;
      });
  }

  // =============== Bench_List =============
  async getMyBench(data: any): Promise<any> {
    // https://www.onbenchmark.com/custom/mycandidate/mycandidate
    return this.http.post(`${this.apiUrl}custom/mycandidate/mycandidate`, data, { headers: this.authService.jsonheader })
      .toPromise().catch((error) => {
        console.error("Error occurred while fetching bench type:", error);
        throw error;
      });
  }

  getCity(data: any): Promise<any> {
    return this.http.get(`${this.apiUrl}notoken/city?filter=${data}`).toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        throw error;
      });
  }

  // homegetSkill(data:any): Promise<any> {
  //   return this.http.get(this.baseurl + 'notoken/skill?filter=' + data).toPromise();
  // }

  homeGetSkill(data: any): Promise<any> {
    return this.http.get(`${this.apiUrl}notoken/skill?filter=${data}`).toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        throw error;
      });
  }



}
