import { Component, OnInit, Renderer2 } from "@angular/core";
import { AddBenchService } from "src/app/services/add-bench.service";
import { AuthService } from "src/app/services/auth.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormBuilder } from "@angular/forms";
import { PostJobService } from "../../../services/post-job.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-bench-resources",
  // standalone: true,
  // imports: [],
  templateUrl: "./hire-bench-resources.component.html",
  styleUrl: "./hire-bench-resources.component.scss",
})
export class HireBenchResourcesComponent implements OnInit {
  searchForm: any;
  searchFormmain: any;
  resources: any = [];

  search: any = {};
  // cls: any = "";
  benches: any = [];
  candidateresult: any = [];
  shimmer = true;
  pagebtn: any = { first: false, last: true, prev: true, next: true };
  currencytype: any = "";

  jobquerydata: any = {
    page: 1,
    totalpage: 0,
    limit: 25,
    total: 0,
    orderval: "createdAt",
    ordertype: "desc",
    serchval: {
      $and: [],
    },
    short: {
      updateDate: -1,
      createdAt: -1,
    },
  };

  // cPage: any = { totalItems: 0, currentPage: 1, pageSize: 10 };

  filter: any = {
    page: 1,
    totalpage: 0,
    limit: 50,
    total: 0,
    orderval: "createdAt",
    ordertype: "desc",
    serchval: { $and: [] },
    short: { updateDate: -1, createdAt: -1 },
  };

  // ========= Searching =======
  searchingForm: any;
  citylist: any = [];
  skillListdata: any = [];
  loderstt = false;
  cls = "";
  token: any = "";

  constructor(
    private addBenchSevice: AddBenchService,
    private loader: LoadingScreenService,
    private formBuild: FormBuilder,
    private postJobService: PostJobService,
    private toaster: ToastrService,
    private commonSevice: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.searchFormCreate();
    // this.searchmainFormCreate();
    this.searchingFormCreate();
  }

  ngOnInit(): void {
    if (localStorage.getItem("currency")) {
      this.currencytype = localStorage.getItem("currency");
    }
    this.addBenchSevice.globalprize.subscribe((res: any) => {
      this.currencytype = res.ctype;
    });

    this.loader.stopLoading();
    // this.getBenchList();
    // this.getCandidateList();
    // this.windowload();
    // this.getSkillList();
    this.windowrendor();

    this.authService.logoutHomeFromAll().subscribe((token) => {
      this.authService.getTempToken().subscribe((token) => {
        this.token = token.token;
        console.log("token ", this.token);
        this.getResources();
      });
    });

    this.route.queryParams.subscribe((params) => {
      console.log(params); // Log all query params
      let skills = params["skills"]; // Access specific query param
      let page = params["page"]; // Access specific query param
      let limit = params["limit"]; // Access specific query param
      let locations = params["locations"]; // Access specific query param
      this.search.skills = skills.split("-");
      this.search.page = page;
      this.search.limit = limit;
      this.search.location = locations;
      this.jobquerydata.page = page;

      console.log(locations);
    });

    this.getSkillList();
  }

  searchFilter() {
    console.log("skills ", this.search);
  }

  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.commonSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
        console.log("skillListdata: ", this.skillListdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  searchFormCreate() {
    this.searchForm = this.formBuild.group({
      name: [""],
      email: [""],
      gender: [""],
      mobile: [""],
      onsiteAvailability: [""],
      ratemin: [""],
      ratemax: [""],
      experiencemin: [""],
      experiencemax: [""],
      Availability: [""],
      skill: [""],
    });
  }

  // searchmainFormCreate() {
  //   this.searchFormmain = this.formBuild.group({
  //     name: [""],
  //     locationId: [""],
  //     currentlocation: [""],
  //     adminverified: [""],
  //   });
  // }

  getBenchList() {
    this.loader.startLoading();
    this.addBenchSevice.getMyBench(this.filter).then(
      (res: any) => {
        if (res.confirmation === "success") {
          this.loader.stopLoading();
          this.benches = res.data;
          console.log("benchListData: ", this.benches);
          // this.cPage.totalItems = res.total;
        }
      },
      (err) => {
        console.log(err);
        this.loader.stopLoading();
      },
    );
  }

  getResources() {
    this.jobquerydata.serchval = {
      $and: [],
    };
    let anySkillConditions = [];

    this.search.skills.forEach((s) => {
      anySkillConditions.push({
        skill: {
          $regex: s,
          $options: "i",
        },
      });
    });

    this.jobquerydata.serchval["$and"].push({
      $or: anySkillConditions,
    });

    if (this.search.workFrom && this.search.workFrom != "") {
      this.jobquerydata.serchval["$and"].push({
        onsiteAvailability: {
          $regex: this.search.workFrom,
          $options: "i",
        },
      });
    }

    if (this.search.rateMin && this.search.rateMax) {
      this.jobquerydata.serchval["$and"].push({
        $and: [
          {
            rate: {
              $lte: this.search.rateMax / (168 * 83.5),
            },
          },
          {
            rate: {
              $gte: this.search.rateMin / (168 * 83.5),
            },
          },
        ],
      });
    }

    if (this.search["experienceMax"] && this.search["experienceMin"]) {
      this.jobquerydata.serchval["$and"].push({
        $and: [
          {
            experience: {
              $lte: this.search["experienceMax"],
            },
          },
          {
            experience: {
              $gte: this.search["experienceMin"],
            },
          },
        ],
      });
    }

    this.postJobService
      .getHomeSearchedResources(this.jobquerydata, this.token)
      .then((resp) => {
        this.benches = resp.data;
        // console.log("resp ", this.jobs.data.data);
      });
  }

  getCandidateList() {
    console.log("I am in the function");
    this.candidateresult = [];
    let data = this.search;
    this.shimmer = true;
    this.addBenchSevice.custumFind(data, "mycandidate").then(
      (res: any) => {
        this.candidateresult = res.data;
        console.log("candidateDAta: ", this.candidateresult);
        this.shimmer = false;
        if (this.search.page == 1) {
          this.pagebtn.prev = false;
        }
        this.search.total = this.candidateresult.total;
        this.search.totalpage = Math.ceil(
          this.search.total / this.search.limit,
        );
      },
      (err) => {
        this.shimmer = false;
        console.log(err);
      },
    );
  }

  openCandidateDetails(bench: any) {
    this.router.navigateByUrl("/my-candidate-details?userId=" + bench._id);
  }

  searchfilter(val: any) {
    let side = this.searchForm.value;
    let main = this.searchFormmain.value;
    let data: any = { $and: [] };

    // if (this.sltskill.length > 0) {
    //   let skill:any = { $or: [] };
    //   this.sltskill.forEach((ele:any) => {
    //     skill.$or.push({ skill: { $regex: ele.name, $options: 'i' } });
    //   });
    //   data.$and.push(skill);
    // }

    if (side.gender) {
      data.$and.push({ gender: side.gender });
    }
    if (side.onsiteAvailability) {
      data.$and.push({ onsiteAvailability: side.onsiteAvailability });
    }
    if (side.Availability) {
      data.$and.push({ Availability: side.Availability });
    }
    if (side.experiencemin && side.experiencemax) {
      data.$and.push({
        experience: { $gte: side.experiencemin, $lte: side.experiencemax },
      });
    }
    if (side.ratemin && side.ratemax) {
      data.$and.push({ rate: { $gte: side.ratemin, $lte: side.ratemax } });
    }
    if (main.name) {
      let qury = {
        $or: [
          { name: { $regex: main.name, $options: "i" } },
          { resourceId: { $regex: main.name, $options: "i" } },
          { jobtype: { $regex: main.name, $options: "i" } },
        ],
      };
      data.$and.push(qury);
    }
    if (main.locationId) {
      data.$and.push({ locationId: main.locationId });
    }
    if (main.adminverified) {
      data.$and.push({ adminverified: main.adminverified });
    }
    if (data.$and.length > 0) {
      this.search.serchval = data;
    } else {
      this.search.serchval = { $and: [] };
    }
    this.getCandidateList();
  }

  getlistbypage(val: any) {
    if (val == "next") {
      this.pagebtn.prev = true;
      if (this.search.page < this.search.totalpage) {
        this.search.page = this.search.page + 1;
        this.getCandidateList();
      }
      if (this.search.page == this.search.totalpage) {
        this.pagebtn.next = false;
        this.getCandidateList();
      }
    }
    if (val == "prev") {
      if (this.search.page > 1) {
        this.pagebtn.next = true;
        this.search.page = this.search.page - 1;
        this.getCandidateList();
      }
    }
    if (val == "first") {
      this.search.page = 1;
      this.getCandidateList();
    }
  }

  // setvalue(val: any, id: any) {
  //   this.cls = "";
  //   this.searchFormmain.get("currentlocation").setValue(val);
  //   this.searchFormmain.get("locationId").setValue(id);
  // }

  changeStatus(bench: any, ids: any, val: any, inx: any) {
    let data: any = { status: val };
    if (val == false) {
      data.adminverified = 3;
    }
    this.loader.startLoading();
    this.addBenchSevice.updateStatus(ids, data, "mycandidate").then(
      (res: any) => {
        bench.status = val;
        this.candidateresult.data[inx].status = val;
        this.toaster.success("Candidate Status Changed Successfully");
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  getValue(val: any) {
    return val.replace("_", " ");
  }

  windowrendor() {
    this.renderer.listen("window", "click", (e: Event): any => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach((element) => {
        if (element == "salectchanged") {
          change1 += 1;
        }
        if (element == "salectchanged2") {
          change2 += 1;
        }
      });
    });
  }

  // openBenchDetails(bench) {
  //   this.router.navigateByUrl("/bench-details?jobId=" + bench._id);
  // }

  // ============== getCity ==================

  searchingFormCreate() {
    this.searchingForm = this.formBuild.group({
      title: [""],
      locationId: [""],
      currentlocation: [""],
      jobstatus: ["all"],
    });
  }

  get_city(val: any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = {
        $and: [
          { city_name: { $regex: val, $options: "i" } },
          { country_id: "5c976ba641e3f849a36370aa" },
        ],
      };
      this.addBenchSevice
        .getCity(JSON.stringify(filter))
        .then((res) => {
          console.log("CityList: ", res.data);
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
    }
  }

  setvalue(val: any, id: any) {
    this.cls = "";
    console.log("location", val);
    this.searchingForm.get("currentlocation").setValue(val);
    this.searchingForm.get("locationId").setValue(id);
  }

  // ==== customValidation =========
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 158 * 79.8).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }
}
