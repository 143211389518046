<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Expert  Sign Up</h2>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="freelancer-register pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                
                <!-- form - start-->

                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="freelancers-req">
                    <div class="row">

                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 req-free " >
                        <input id="firstName" formControlName="firstName" type="text" placeholder="Enter First Name" />
                      </div>
                    
                      <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-6 req-free">
                        <input id="lastName" formControlName="lastName" type="text" placeholder="Enter Last Name" />
                      </div>

                     
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="countryCode" style="display: flex;">
                          <ng-select
                            [items]="countryCodes"
                            bindLabel="name"
                            bindValue="id"
                            formControlName="countryCode"
                            placeholder="Select Country Code"
                            [searchable]="true">
                          </ng-select>
                
                          <input id="mobileNumber" formControlName="mobileNumber" type="text" placeholder="Enter mobile number"/>
                        </div>
                      </div>
                
                     
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input id="email" formControlName="email" type="email" placeholder="Enter Email" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                        <input id="password" formControlName="password" type="password" placeholder="Enter Password" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input id="cityName" formControlName="cityName" type="text" placeholder="Enter City Name" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input id="resume" formControlName="resume" type="file" placeholder="Upload Resume" />
                      </div>

                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input id="skills" formControlName="skills" type="text" placeholder="Enter Skills" />
                      </div>
                
                      <div class="checkbox-success">
                        <label for="agree" class="label">
                          <input id="agree" type="checkbox" name="agree">
                          &nbsp;I agree to
                          <a href="https://www.onbenchmark.com/auth/terms-condition" target="_blank">&nbsp;Terms & Conditions.</a>
                        </label>
                      </div>
                
                      <button class="submit-btn" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
                



                <!-- ============================================ -->

                <!-- <form >
                  <div class="freelancers-req">

                    <div class="row">

                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 req-free " >
                        <input id="firstName" formControlName="firstName" type="text" placeholder="Enter First Name" />
                      </div>
                    
                      <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-6 req-free">
                        <input id="lastName" formControlName="lastName" type="text" placeholder="Enter Last Name" />
                      </div>
                    

                        <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        
                          <div class="countryCode" style="display: flex;">
                            <ng-select
                              [items]="countryCodes"
                              bindLabel="name"
                              bindValue="id"
                              formControlName="countryCode"
                              placeholder="Select Country Code"
                              style="max-width: 120px; margin-right: 8px;"
                              [searchable]="true">
                            </ng-select>
                        
                            <input id="mobileNumber" formControlName="mobileNumber" type="text" placeholder="Enter mobile number" style="flex-grow: 1;" />
                          </div>
                        
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input id="email" formControlName="email" type="email" placeholder="Enter Email" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
                        <input id="password" formControlName="password" type="password" placeholder="Enter Password" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input id="cityName" formControlName="cityName" type="text" placeholder="Enter City Name" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input id="resume" formControlName="resume" type="file" placeholder="Upload Resume" />
                      </div>
                    
                      <div class="req-free col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input id="skills" formControlName="skills" type="text" placeholder="Enter Skills" />
                      </div>
  
  
                    </div>
  
                    <div class="checkbox-success">
                      <label for="agree" class="label">
                      <input id="agree" type="checkbox" name="agree">
                      
                        &nbsp;I agree to
                        <a href="https://www.onbenchmark.com/auth/terms-condition" target="_blank">&nbsp;Terms &amp; Conditions.</a>
                      </label>
                    </div>
                    
                      <button class="submit-btn" type="submit">Submit</button>
                      
                  </div>
                 
                  </form> -->

                <!-- form - end -->
                 
            </div>


            <div class="col-lg-6 col-md-6">
                <div class="about-content">
                    <div class="section-title">
                       
                        <h2 class="text-center">Why will you choose</h2>
                        <p class="sub-title-right">OnBenchMark is one of the best free job posting sites for hiring Contract resourcesand Experts . Reach the top talent!
                        </p>
                        <img src="assets/img/post-project.png" alt="New">
                    </div>
                    <!-- <p>An Open Platform for On-Demand Resourcing and Just-In-Hiring. One-stop solution for Contractual, Part-Time, Temporary Flexible Hiring Needs. Free Job Posting & Free Project Posting. Deploy Bench Resources / Bench Employees/ Unutilized Resources/ Idle Resources for onsite or Offsite projects.</p>
                    <p>Hire Experts, Gig Workers, Digital Nomads for contract hiring needs. Hire Virtual Assistants, Work from Home, Remote Working & Part-Time professionals without any commission and restriction to communicate with Clients or Experts.</p>
                    <p>Create Virtual Teams without having any liability for full-time employment. No bidding of projects or depositing of project cost, unlike other freelancing sites.</p> -->
                    <!-- <a class="cmn-btn" routerLink="/contact">Get Started<i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            
        </div>
    </div>
</div>
<!-- End New -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

