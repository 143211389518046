import { Component } from '@angular/core';

@Component({
  selector: 'app-hire-on-contract',
  // standalone: true,
  // imports: [],
  templateUrl: './hire-on-contract.component.html',
  styleUrl: './hire-on-contract.component.scss'
})
export class HireOnContractComponent {

}
