import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiUrl: any;
  jsonheader: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.apiUrl = this.authService.apiUrl;
    this.jsonheader = this.authService.jsonheader;
  }

  getDashboard(): Observable<any> { 
    return this.http
      .post(
        `${this.apiUrl}custom/companydashboard/dashboard`,
        {},
        {
          headers: this.authService.jsonheader,
        },
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  login(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/login`, credentials, {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      })
      .pipe(
        map((response: any) => {
          // Store token and role
          // if (response && response.token) {
          //   localStorage.setItem("token", response.token);
          //   localStorage.setItem("role", response.user.role);
          // }
          if (response && response.token) {
            localStorage.setItem("token", response.token);
            localStorage.setItem("token", response.token);
          }
          if (response && response.role) {
            localStorage.setItem("role", response.user.role);
          }
          return response;
        }),
      );
  }

  logout(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/logoutalldevice`, credentials, {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
}
