<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <!-- <h2 class="banner-title">Hire Contract Resources</h2> -->
          <h2 class="banner-title">Find  CTO, Architects & Senior Experts</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->


<!-- New -->
<div class="new-area ptb-50">
    <div class="container">
      
        <h3 class="text-center text-dark"> CTO, Architects & Software Experts  available on Part-Time or Consulting Basis</h3>
        <!-- ======================== -->
  
        <!-- <div class="banner-form-area">
           
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label><i class="bx bx-search"></i></label>
                    <input type="text" class="form-control" placeholder="Search" />
                  </div>
                </div>

              

                <div class="col-lg-6">
                  <div class="form-group two">
                    <label><i class="bx bx-location-plus"></i></label>
                    <input type="text" class="form-control" placeholder="Location" />
                  </div>
                </div>
              </div>

              <button type="submit" class="btn">Search Job <i class="bx bx-search"></i></button>
              
            </form>
          
</div> -->
        <!-- -========================== -->



        <!-- ====================================== -->
      <!-- start searching bar -->

       <!-- SearchingBar -->
       <div style="background: transparent; padding: 20px 10px" class="mt-2">
        <!-- <div class="row ps-0">
          <div class="job-list-right">
            <div class="job" style="text-align: left">
              <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': hireTalentStatus}"
                (click)="hireTalentSearch()"><i class="fa-solid fa-circle me-1"></i> Hire Talent
              </button>
              <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': findProjectsStatus}"
              (click)="findProjectsSearch()"><i class="fa-solid fa-circle me-1"></i> Find Projects</button>
            </div>
          </div>
        </div> -->
        <div class="row d-flex justify-content-start">

          <div class="col-lg-2"></div>

          <div class="col-lg-8 pt-3">

            <form>
            
              <div class="row searchBarBG pt-3 mt-0">
               
                <div class="col-lg-5 firstCol mb-3">
                  <div class="form-group">
                    <div class="input-group">
                      <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>

                      <ng-select [multiple]="true" [(ngModel)]="search.job" placeholder="Select Skill" style="width: calc(100% - 50px); border: none">
                        <ng-option *ngFor="let skill of skillListdata" value="skill.name">{{ skill.name }}</ng-option>
                      </ng-select>

                      <!-- <input type="text" [(ngModel)]="search.job" name="job" class="form-control" placeholder="Search Your Job" /> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="form-group two">
                    <div class="input-group">
                      <span class="input-group-text"><i class="fa-solid fa-location-dot"></i></span>

                      <ng-select [multiple]="false" [(ngModel)]="search.location" (input)="get_city($event)" placeholder="Select City" style="width: calc(100% - 50px); border: none">
                        <ng-option *ngFor="let city of citylist" value="city.city_name">{{ city.city_name }}</ng-option>
                      </ng-select>

                      <!-- <input type="text" [(ngModel)]="search.location" name="location" class="form-control" placeholder="Location" /> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-2" style="margin-top: -1px">
                  <button (click)="homeSearch()" type="submit" class="btn searchBTN">Search</button>
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-2"></div>
        </div>
      </div>
      <!-- end of SearchingBar -->

        <!-- end of SearchingBar -->

        <!-- ================================================ -->
      
  
      <div class="row align-items-center">
       
  
        <div class="col-lg-6 col-md-12">
          <div class="about-content">
            <div class="section-title">
             
              <!-- <span class="sub-title">Expand your Business & Reduce Sales Cost</span> -->
  
              
              <h2>Consult Expert <span> at Ease</span></h2>
              <h4>Getting Consulting from Experts in Just 2 Steps</h4>
            </div>
            <p >
              Hiring CTOs, Architects & Senior Software Experts  on Full-Time may cost very high. Get top-tier expertise without the commitment of full-time salaries with better flexibility, cost-efficiency, and access to strategic leadership, ensuring critical projects are handled with precision while maintaining agility in resource allocation & optimizing cost.
            </p>
  
            <!-- <h6>Let's talk +91 97189 28125    <span><a class="cmn-btn" routerLink="/contact"> Contact Us</a></span></h6> -->
            <h6><span><a class="cmn-btn" routerLink="/contact"> Contact Us</a></span></h6>
  <!-- <a class="cmn-btn" routerLink="/contact">Pricing<i class='bx bx-plus'></i></a> -->
          </div>
        </div>
  
        <div class="col-lg-6 col-md-12">
          <div class="new-img">
            <img src="assets/img//immediate-joiner.png" alt="New" />
            <!-- <img src="assets/img/about-shape.png" alt="New" /> -->
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <!-- End New -->
  


<!-- Work -->
<section class="work-area ptb-50 bg-light">
  <div class="container">
    <div class="section-title">
            <!-- <span class="text-center sub-title">Sell your Bench at Zero Commission</span> -->
            <h2 class="text-center">Sell your Bench at Zero Commission</h2>
        </div>

    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <i class="img-icon "><img src="assets/img/icon/customer-satisfaction.png"></i>
          <!-- <span>01</span> -->
          <h3>Connect & Deploy Bench</h3>
          <p class="txt-p">
            Get connected directly with global clients to deploy & bill your bench on contract or get projects.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <!-- <i class="flaticon-file"></i> -->
          <i class="img-icon "><img src="assets/img/icon/public-relation.png"></i>
          <!-- <span>02</span> -->
          <h3>Make Clients & Expand Business</h3>
          <p class="txt-p">
            No need to hire a sales team. Make relevant clients to deploy your bench through platform.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <!-- <i class="flaticon-comment"></i> -->
          <i class="img-icon "><img src="assets/img/icon/candidates.png"></i>
          <!-- <span>03</span> -->
          <h3>Get Immediate Joiner</h3>
          <p class="txt-p">
            Get immediate joiner on contract/ part-time from the biggest pool of real time active bench.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
                <div class="sell-bench">
                    <!-- <i class="flaticon-comment"></i> -->
                    <i class="img-icon "><img src="assets/img/icon/communication.png"></i>
                    <!-- <span>04</span> -->
                    <h3>Find Talent Your Way</h3>
                    <p class="txt-p">Connect directly with bench employers or freelancers to hire candidates on contract</p>
                </div>
            </div>

    
    </div>
  </div>
</section>
<!-- End Work -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
