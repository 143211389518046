<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Expert User Setting</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- expertUserSettingSection -->
<div class="counter-area two pt-100">
  <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" /></div>

  <!-- <div class="container">
    <div class="row">
       <div class="col-lg-12"></div>
    </div>
  </div> -->
  <!-- <div class="post-job-area ptb-100">
  <div class="container">
    <form [formGroup]="addJobForm">
      <div class="post-item"> -->

  <section class="content">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
              <div class="list-group">
                <a [ngClass]="tab[0]==true?'active':''" class="list-group-item pointer" style="padding: 10px 15px;" 
                (click)="tabopen(0)">
                  <i class="fa fa-user"></i>&nbsp;
                  <span>Profile</span>
                </a>
                <a [ngClass]="tab[1]==true?'active':''" class="list-group-item pointer" style="padding: 10px 15px;" 
                (click)="tabopen(1)">
                  <i class="fa fa-key"></i>
                  <span>
                    Password</span>
                </a>
                <a [ngClass]="tab[2]==true?'active':''" class="list-group-item pointer" style="padding: 10px 15px;" 
                (click)="tabopen(2)">
                  <i class="fa fa-envelope"></i>&nbsp;
                  <span>
                    Email Notification</span>
                </a>
              </div>
            </div>
            <div class="col-sm-9">
              <div *ngIf="tab[0]">
                <div class="card">
                  <div class="card-body px-4 formBG">
                    <form [formGroup]="candidateForm" class="box-body ">
                        <div class="">
                            <div>
                                <h4>Account </h4>
                            </div>
                            <hr>
                            <div class="form-group row mb-3">
                                <div class="col-sm-6">
                                <label class=" control-label">First Name* </label>
                                <input type="text" class="form-control" placeholder="Enter First Name" formControlName="fname" maxlength="100">
                                <small class="text-danger" *ngIf="candidateForm.get('fname').invalid && candidateForm.get('fname').touched">
                                    First Name is required.
                                </small>
                                </div>
                                <div class="col-sm-6">
                                <label class=" control-label">Last Name* </label>
                                <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lname" maxlength="100">
                                <small class="text-danger" *ngIf="this.candidateForm.get('lname').invalid && this.candidateForm.get('lname').touched">
                                    Last Name is required.
                                </small>
                                </div>
                            </div>
                            <div class="form-group row mb-3">
                                <div class="col-sm-6">
                                <label class=" control-label">Mobile* </label>
                                <input type="text" class="form-control" placeholder="Enter Mobile" formControlName="phone" maxlength="100">
                                <small class="text-danger" *ngIf="this.candidateForm.get('phone').invalid && this.candidateForm.get('phone').touched">
                                    Mobile is required.
                                </small>
                                </div>
                                <div class="col-sm-6">
                                <label class=" control-label">Timezone* </label>
            
                                <!-- <select class="form-control" formControlName="timezone">
                                    <option value="">Select Timezone </option>
                                    <option *ngFor="let list of zonelistdata" value="{{list?.timezone}}">
                                    {{list?.timezone}}
                                    </option>
                                </select> -->
            
                                <ng-select [multiple]="false" formControlName="timezone" placeholder="Select Timezone">
                                    <ng-option value="" [disabled]="true"> Select Timezone </ng-option>
                                    <ng-option *ngFor="let list of zonelistdata" [value]="list?.timezone">
                                        {{ list?.timezone }}
                                    </ng-option>
                                </ng-select>
            
            
                                <small class="text-danger" *ngIf="candidateForm.get('timezone').invalid && candidateForm.get('timezone').touched">
                                    Timezone is required.
                                </small>
                                </div>
                            </div>
                            <div class="form-group row mb-3">
                                <div class=" col-md-12">
                                <button type="button" class="btn btn-info" [disabled]="candidateForm.invalid" (click)="updateprofileFreelancer()">Update</button>
                                </div>
                            </div>
                        </div>
                       
                    </form>
                  </div>
                </div>
                <div class="card my-2">
                  <div class="card-body px-4 formBG">
                    <form [formGroup]="emailForm" class="box-body">
                      <div>
                        <h4>Account </h4>
                      </div>
                      <hr>
                      <div class="form-group row mb-3">
                       
                        <div *ngIf="profiledata?.changerequest==1" class="col-sm-12 resend">
                            <h4> Email change request pending</h4>
                            <p>
                                You have a pending request to change your email address to {{profiledata?.changeemail}}. To complete this request, 
                                please check your inbox at that email address to confirm ownership of that account.
                            </p>
                            <div>
                              <button type="button" class="btn btn-default-theme" (click)="resendmail('cancel')"> Cancel Request</button> &nbsp; &nbsp;
                              <button type="button" class="btn btn-theme" (click)="resendmail('resend')"> Resend  Email</button>
                            </div>
                            <br>
                        </div>
                        <div class="col-sm-12">
                          <label class=" control-label">Email* </label>
                          <div style="padding: 4px 0px;color: #479236;">
                              <i class="fa fa-envelope"></i>&nbsp;  {{feelancerdata?.email}}
                          </div>
                          <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" maxlength="100">
                          <small class="text-danger" *ngIf="emailForm.get('email').invalid && emailForm.get('email').touched">
                            Email is required.
                          </small>
                          <small class="text-danger" *ngIf="errorstt?.emailerror">
                            This email is already in use
                          </small>
                          <small class="text-danger" *ngIf="errorstt?.sent">
                              You send mail already , click on cancel and than send mail again.
                            </small>
                          
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class=" col-md-12">
                          <button type="button" class="btn btn-info" [disabled]="emailForm.invalid" (click)="updateemailFreelancer()">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="card my-2">
                  <div class="card-body px-4 formBG">
                    <form [formGroup]="contactForm" class="box-body">
                      <div>
                        <h4>contact </h4>
                      </div>
                      <hr>
                      <div class="form-group row mb-3">
                        <div class="col-sm-12">
                          <label class=" control-label">Address* </label>
                          <input type="text" class="form-control" placeholder="Enter Address" formControlName="address" maxlength="100">
                          <small class="text-danger" *ngIf="contactForm.get('address').invalid && contactForm.get('address').touched">
                            address is required.
                          </small>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class="col-sm-6">
                          <label class=" control-label">Zip /Postal Code* </label>
                          <input type="text" class="form-control" placeholder="Enter zipcode" formControlName="zipcode" maxlength="100">
                          <small class="text-danger" *ngIf="contactForm.get('zipcode').invalid && contactForm.get('zipcode').touched">
                            Zipcode is required.
                          </small>
                        </div>
                        <div class="col-sm-6">
                          <label class=" control-label">City* </label>
                          <input type="text" class="form-control" placeholder="Enter City" formControlName="location" maxlength="100">
                          <small class="text-danger" *ngIf="this.contactForm.get('location').invalid && this.contactForm.get('location').touched">
                            City is required.
                          </small>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class="col-sm-6">
                          <label class=" control-label">State* </label>
                          <input type="text" class="form-control" placeholder="Enter state" formControlName="state" maxlength="100">
                          <small class="text-danger" *ngIf="this.contactForm.get('state').invalid && this.contactForm.get('state').touched">
                            State is required.
                          </small>
                        </div>
                        <div class="col-sm-6">
                            <label class=" control-label">Country* </label>

                            <!-- <select class="form-control" formControlName="currentcountry" >
                                <option value="" >Select Country </option>
                                <option *ngFor="let list of listcountry" value="{{list?.country_name}}">  {{list?.country_name}} </option>
                            </select> -->

                            <ng-select [multiple]="false" formControlName="currentcountry" placeholder="Select Country">
                                <ng-option value="" [disabled]="true"> Select Country </ng-option>
                                <ng-option *ngFor="let list of listcountry" [value]="list?.country_name">
                                    {{ list?.country_name }}
                                </ng-option>
                            </ng-select>
                         
                        </div>
                      </div>
                      <hr>
                      <div class="form-group row mb-3">
                        <div class=" col-md-12">
                          <button type="button" class="btn btn-info" [disabled]="contactForm.invalid" (click)="updatecontactFreelancer()">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div *ngIf="tab[1]">
                <div class="card my-2">
                  <div class="card-body px-4 formBG">
                    <form [formGroup]="passwordForm" class="box-body">
                      <div>
                        <h4>Change Password </h4>
                      </div>
                      <hr>
                      <div class="form-group row mb-3">
                        <div class="col-sm-6">
                          <label class=" control-label">password* </label>
                          <input type="text" class="form-control" placeholder="Enter First Name" formControlName="password" maxlength="100">
                          <small class="text-danger" *ngIf="passwordForm.get('password').invalid && passwordForm.get('password').touched">
                            First Name is required.
                          </small>
                        </div>
                        <div class="col-sm-6">
                          <label class=" control-label">Confirm Password* </label>
                          <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="confirmpassword" maxlength="100">
                          <small class="text-danger" *ngIf="this.passwordForm.get('confirmpassword').invalid && this.passwordForm.get('confirmpassword').touched">
                            Last Name is required.
                          </small>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class=" col-md-12">
                          <button type="button" class="btn btn-info" [disabled]="candidateForm.invalid" (click)="updatepassword()">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div *ngIf="tab[2]">
                <div class="card my-2">
                  <div class="card-body px-4 formBG">
                    <form [formGroup]="notificationForm" class="box-body">
                      <div>
                        <h4>Email Notification </h4>
                      </div>
                      <hr>
                      <div class="form-group row mb-3">
                        <div class="col-sm-12">
                          <div class="form-check checkbox checkbox-success">
                            <input id="latestjob" class="form-check-input" type="checkbox" formControlName="latestjob" name="slltskill">
                            <label for="latestjob">
                              Email For Latest Jobs / Projects Posted
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-check checkbox checkbox-success">
                            <input id="marketing" class="form-check-input" type="checkbox" formControlName="marketing" name="slltskill">
                            <label for="marketing">
                              Marketing Email
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-check checkbox checkbox-success">
                            <input id="newsletter" class="form-check-input" type="checkbox" formControlName="newsletter" name="slltskill">
                            <label for="newsletter">
                              Monthly newsletter
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-check checkbox checkbox-success">
                            <input id="onbenchmarkdeal" class="form-check-input" type="checkbox" formControlName="onbenchmarkdeal" name="slltskill">
                            <label for="onbenchmarkdeal">
                              Onbenchmark Deals
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class=" col-md-12">
                          <button type="button" class="btn btn-info"  (click)="updatenotificationFreelancer()">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </section>





</div>
<!-- End expertUserSettingSection -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
