import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Router } from "express";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PostJobService {
  apiUrl: any = "https://www.onbenchmark.com/";
  jsonheader: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`,
    });
  }

  async getJobs(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/dashjobapplication/jobapplication`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getMyJobs(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/mycandidate/myjob`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getMyJobsForShortlist(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/getforshortlist/job`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async applyBenchJob(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}api/appliedcandidate`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getBenchContactDetails(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/checkshortlist/jobapplication`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  // https://www.onbenchmark.com/api/myjob/66b34b3ee98b3cffa8b40448

  // ==================

  // async customFind(data: any, tbl: any): Promise<any> {
  //   try {
  //     const response = await this.http.post(`${this.apiUrl}custom/mycandidate/${tbl}`, data, {
  //       headers: this.authService.jsonheader, }).toPromise();
  //     return response;
  //   } catch (error) {
  //     console.error("Error occurred while fetching data:", error);
  //     throw error;
  //   }
  // }

  async getSearchedJobs(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/searchjob/job`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getSearchedResources(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/searchcandidate/candidate`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getHomeSearchedResources(data: any, token: any): Promise<any> {
    let jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    return this.http
      .post(`${this.apiUrl}custom/searchcandidate/candidate`, data, {
        headers: jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async getMyJobDetails(jobId: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}api/job/${jobId}`, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job details:", error);
        throw error;
      });
  }

  async getJobDetails(jobId: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}api/job/${jobId}`, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job details:", error);
        throw error;
      });
  }

  async getMatchingJobs(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/matchingjob/job`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job details:", error);
        throw error;
      });
  }

  async getJobType(data: any, tbl: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/getjobtype/${tbl}`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching job type:", error);
        throw error;
      });
  }

  async FindById(data: any, tbl: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}api/${tbl}/${data}`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while finding data by id:", error);
        throw error;
      });
  }

  async createJob(data: any, tbl: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}api/${tbl}`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while creating job:", error);
        throw error;
      });
  }

  // async updateJob(ids: any, data: any, tbl: any): Promise<any> {
  //   return this.http.put(`${this.apiUrl}company/update/${tbl}/${ids}`, data, { headers: this.jsonheader }).toPromise()
  //     .catch((error) => {
  //       console.error("Error occurred while updating job:", error);
  //       throw error;
  //     });
  // }

  async updateJob(ids: any, data: any, tbl: string): Promise<any> {
    try {
      const response = await this.http
        .put(`${this.apiUrl}company/update/${tbl}/${ids}`, data, {
          headers: this.authService.jsonheader,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while updating job:", error);
      throw error;
    }
  }

  async updateJobStatus(ids: any, data: any, tbl: string): Promise<any> {
    try {
      const response = await this.http
        .put(`${this.apiUrl}api/${tbl}/${ids}`, data, {
          headers: this.authService.jsonheader,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while updating job status:", error);
      throw error;
    }
  }

  async ratePost(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http
        .post(`${this.apiUrl}custom/ratepost/${tbl}`, data, {
          headers: this.authService.jsonheader,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while getting rate data:", error);
      throw error;
    }
  }

  async getcurrency(): Promise<any> {
    return this.http
      .get(`${this.apiUrl}custom/getcurrency`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while getting currency:", error);
        throw error;
      });
  }

  homeGetSkill(data: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}notoken/skill?filter=${data}`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching skills:", error);
        throw error;
      });
  }

  // getcity(data:any): Promise<any> {
  //   return this.http.get(this.baseurl + 'notoken/city?filter=' + data).toPromise();
  // }

  getCity(data: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}notoken/city?filter=${data}`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        throw error;
      });
  }
}
