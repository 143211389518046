<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Frequently Asked Questions</h2>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- FAQ -->
<div class="faq-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How much is the registration fee at OnBenchMark.com for Freelancers?</a>
                            <p>Freelancer can register for free and does not require to pay any commission or registration fee at the time of getting job or project work. OnBenchMark is a zero commission freelancing website to get job and project work from global clients. Freelancers can connect directly with clients without any restriction and can share their contacts details to get repeat business unlike traditional top freelancing portals. Freelancers will get required freedom and autonomy to work at their terms and conditions.</p>
                        </li>
                        <li>
                            <a>Does OnBenchMark charges any commission if any freelancer gets a project?</a>
                            <p>No, OnBenchMark does not charge any commission even if you get a project or job. OBM is without Bidding, without Commission Online website to hire freelancers or get freelance work. Its a free website for Freelancers to get part-time, remote and freelance project and job work.</p>
                        </li>
                        <li>
                            <a>Is there any payment guarantee given by OnBenchMark from the Client?</a>
                            <p>No, OnBenchMark does not provide any guarantee for any payment. You have to deal with the Client directly and work at your terms and conditions.</p>
                        </li>
                        <li>
                            <a>Does OnBenchMark restrict client or freelancer to communicate with each other?</a>
                            <p>No, OBM does not restrict any of its registered users to communicate with its Clients or candidates nor it signs any agreement with any of the party for any such restriction in communication. Freelancers can be contacted directly and the Clients also can be contacted directly. But OBM also given an option to its Registered user to get contacted either by email, phone or Internal chat. If you chose Internal Chat then none of your contact details will be displayed it to anybody.</p>
                        </li>
                        <li>
                            <a>Is there any preferred mode of communication provided by OnBenchMark?</a>
                            <p>OnBenchMark does give access to the Internal Chat to communicate with Client or Freelancer and it also shares Email ID or Phone Number or both if the other party have chosen to share there contact details.</p>
                        </li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/faq-main.jpg" alt="FAQ">
                </div>
            </div>
        </div>

        <div class="faq-bottom">
            <h6>If you don't find your questions or need help  <a routerLink="/contact">Contact Us</a></h6>
        </div>
    </div>
</div>
<!-- End FAQ -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->