<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container mt-50">
        <div class="row align-items-end">
          <div class="col-lg-8 col-md-12">
            <div class="left">
              <h2>{{ candidate.name }}</h2>
              <ul>
                <li><i class="bx bx-box"></i> UX/UI Designer</li>
                <li>
                  <!-- <i class="bx bx-phone-call"></i>  -->
                  <i class="fa-solid fa-location-dot"></i>
                  <span class="text-light">{{ candidate?.currentlocation }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-12">
            <div class="right">
              <ul>
                <li>
                  <a>{{ candidate.jobtype }}</a>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div *ngIf="contactDetails.cname && contactDetails.cname != ''" class="row mb-3">
            <div class="col-lg-12 outerBorderBox">
              <div class="information widget-item">
                <h5 class="text-dark">Contact Information</h5>
                <div class="row">
                  <div class="col-lg-3">
                    <h6>Company</h6>
                    <span class="text-info">{{ contactDetails.cname }}</span>
                  </div>
                  <div class="col-lg-2">
                    <h6>Contact</h6>
                    <span class="text-info">{{ contactDetails.name }}</span>
                  </div>
                  <div class="col-lg-5">
                    <h6>Email</h6>
                    <span class="text-info">{{ contactDetails.email }}</span>
                  </div>
                  <div class="col-lg-2">
                    <h6>Phone</h6>
                    <span class="text-info">{{ contactDetails.phone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12" style="margin-bottom: 30px">
              <div class="details-inner">
                <!-- <h3>Rate</h3> -->
                <h3 class="mb-0">Rate</h3>
                <span>₹ {{ parseRate(candidate.rate) }} (Monthly)</span>
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12" style="margin-bottom: 30px">
              <div class="details-inner">
                <h3>Category</h3>
                <span>{{ candidate.jobtype }}</span>
              </div>
            </div> -->
            <div class="col-lg-6 col-md-12" style="margin-bottom: 30px">
              <div class="details-inner">
                <h3 class="mb-0">Experience</h3>
                <span>{{ candidate.experience }} Years</span>
              </div>
            </div>
            <div class="col-lg-6 col-md-12" style="margin-bottom: 30px">
              <div class="details-inner">
                <h3 class="mb-0">Availability</h3>
                <span>{{ candidate.Availability }}</span>
              </div>
            </div>
          </div>

          <div class="work bottom-item">
            <h3 class="mb-0">Skills</h3>
            <span *ngFor="let s of candidate.skill" class="span-one" style="margin: 2px 2px">{{ s }}</span>
          </div>

          <div class="work bottom-item">
            <h3 class="mb-0">Description</h3>

            <div [innerHTML]="candidate.description"></div>
          </div>

          <!-- <div class="portfolio">
            <h3>Resume</h3>

            <div [innerHTML]="candidate.dochtml[0]"></div>
          </div> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <!-- <div class="download widget-item">
            <a routerLink="/">Deactivate</a>
            <a routerLink="/">Edit</a>
            <a routerLink="/">Download CV</a>
          </div> -->

          <div class="information widget-item">
            <h3>My Jobs</h3>
            <!-- <div style="padding-bottom: 20px">
              <input type="text" [(ngModel)]="jobSearch" debounceTime="2000" placeholder="Name or Designation" (input)="searchJobs($event)" />
            </div> -->
            <ul class="checkbox-list">
              <li class="checkbox-item" *ngFor="let job of jobs.data">
                <label class="checkbox-label">
                  <input value="true" type="checkbox" class="checkbox-input" [(ngModel)]="job.checked" (change)="onCheckboxChange(job)" />
                  {{ job.title }}
                </label>

                <!-- <span style="font-size: 14px">{{ bench.designation }}</span> -->
              </li>
            </ul>
            <div class="job widget-item" style="border: none; padding-bottom: 0px; margin-bottom: 0px">
              <form>
                <!-- <button (click)="jobApply()" [disabled]="!canApply" type="submit" class="btn">Apply</button> -->
                <button (click)="jobApply()" [disabled]="!canApply" type="submit" class="btn">Short List</button>
              </form>
            </div>
          </div>

          <!-- <div class="information widget-item">
            <h3>Candidate Overview</h3>

            <ul>
              <li>
                <h4>Rate:</h4>
                <span>₹ {{ parseRate(candidate.rate) }} (Monthly)</span>
              </li>
              <li>
                <h4>Availability:</h4>
                <span>{{ candidate.Availability }}</span>
              </li>
              <li>
                <h4>Experience:</h4>
                <span>{{ candidate.experience }} Years</span>
              </li>
            </ul>
          </div> -->

          <!-- <div class="similar-candidates">
            <h4>Matching Jobs / Projects</h4>
          </div>

          <div class="candidate-item">
            <div class="left">
              <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
              <span>Web Developer</span>
              <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
              <ul>
                <li>Php</li>
                <li>jQuery</li>
                <li>WordPress</li>
                <li>CSS3</li>
                <li>HTML5</li>
              </ul>
              <div class="cmn-link">
                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
