<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2 class="banner-title">Request Demo</h2>
         
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area ptb-50">
  <div class="container">
  
    <div class="row align-items-center">

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
            <div class="section-title">
                <h5><span><img src="assets/img/mail.png"></span>&nbsp;
                    <span>Email Address:</span> &nbsp; info&#64;onbenchmark.com</h5>
                <!-- <h6> info&#64;onbenchmark.com</h6> -->
                <h5><span><img src="assets/img/call.png"></span>&nbsp;
                    <span>Phone:</span>&nbsp; +91 97189 28125</h5>
                <!-- <h6> </h6> -->
            </div>
      </div>
      </div>

      <div class="col-lg-6 col-md-12">
       <div class="connect-form">
        <form>
        
          <div class="row">
            <div class="col-lg-6">
                <input type="text" id="name" name="name" placeholder="Your name" required>
              </div>
            
              <div class="col-lg-6">
                <input type="tel" id="phone" name="phone" placeholder="Your phone number" required>
              </div>
            
              <div class="col-lg-12">
                <input type="email" id="email" name="email" placeholder="Your email" required>
              </div>
            
              <div class="col-lg-12">
                <textarea id="message" name="message" placeholder="Your message" required></textarea>
              </div>
            
              <div class="col-lg-12">
                <button type="submit">Submit</button>
              </div>
          </div>
            
          </form>
          
       </div>
      </div>

    </div>
  </div>

</div>

<!-- End New -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
