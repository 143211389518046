import { Component } from '@angular/core';

@Component({
  selector: 'app-find-clients',
  // standalone: true,
  // imports: [],
  templateUrl: './find-clients.component.html',
  styleUrl: './find-clients.component.scss'
})
export class FindClientsComponent {

}
