<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Post Job</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Post A Job -->
<div class="post-job-area ptb-50">
  <div class="container">
    <form [formGroup]="addJobForm">
      <div class="post-item">
        <!-- <div class="section-title">
          <h2>Post A Job/Project</h2>
        </div> -->
        <div class="row form-group">
          <div class="col-lg-6 col-md-12">
            <label>Title<span class="text-danger">*</span></label>
            <input type="text" placeholder="Enter Title" formControlName="title" maxlength="70" class="form-control" [ngClass]="{ 'is-invalid': formCreateJob.title.invalid && (formCreateJob.title.touched || submitted), 'is-valid': formCreateJob.title.valid }" />
            <div *ngIf="formCreateJob.title.invalid && (formCreateJob.title.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateJob.title.errors?.required">Title is <b>required.</b></small>
              <small class="text-danger" *ngIf="formCreateJob.title.errors?.minlength">Title must be of minimum <b>5 characters</b>.</small>
              <small class="text-danger" *ngIf="formCreateJob.title.errors?.maxlength">Title could be of maximum <b>70 characters</b>.</small>
            </div>
          </div>

          <div *ngIf="othercity == false" class="col-lg-6 col-md-12">
            <label>City Name<span class="text-danger">*</span> </label>
            <!--  [ngClass]="{'is-invalid': formCreateJob.location.invalid && (formCreateJob.location.touched || submitted), 'is-valid': formCreateJob.location.valid}" -->
            <input type="text" class="form-control" formControlName="location" placeholder="Search city name, Select city and country" (keyup)="get_city($any($event.target).value)" id="locationId" (change)="locationclear()" autocomplete="off" />

            <span *ngIf="loderstt" class="city_loader"><i class="fa fa-circle-o-notch fa-spin"></i></span>
            <div id="myDropdown" class="dropdownContent_city {{ cls }}" #dropdown>
              <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item?.country_id?.country_name, item._id)"> {{ item.city_name }}, {{ item?.state_id?.state_name }}, ({{ item?.country_id?.country_name }}) </a>
              <a (click)="othercityslt()"> Other City </a>
            </div>
            <div *ngIf="formCreateJob.location.invalid && (formCreateJob.location.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateJob.location.errors?.required"> Location is <b>required.</b> </small>
            </div>
          </div>

          <div *ngIf="othercity" class="col-lg-3 text-start">
            <label>City Name<span class="text-danger">*</span> </label>
            <input type="text" id="othercity" class="form-control inputFieldBorder" placeholder="Enter City Name" />
          </div>
          <div *ngIf="othercity" class="col-lg-3 text-start">
            <label>Country Name<span class="text-danger">*</span></label>
            <a href="javascript:void(0)" class="ps-3">
              <span class="badge bg-secondary" (click)="othercityslt()">Clear</span>
            </a>
            <select class="form-control inputFieldBorder" id="othercountry" (change)="otherslt($any($event.target).value, 2)">
              <option value="">Select Country *</option>
              <option *ngFor="let list of countrylist" value="{{ list?.country_name }}">{{ list?.country_name }}</option>
            </select>
          </div>
        </div>

        <!-- ================================================ -->
        <div class="row form-group">
          <div class="col-lg-12">
            <label>Required Skills<span class="text-danger">*</span></label>

            <!-- <ng-select [multiple]="true" placeholder="Select Skill" formControlName="skillsearch">
                <ng-option value="" [disabled]="true"> Select Skill </ng-option>
                <ng-option *ngFor="let list of skillListdata" [value]="list?.name">
                  {{ list?.name }}
                </ng-option>
              </ng-select> -->

            <!-- [ngClass]="{'is-invalid': formCreateJob.skillsearch.invalid && (formCreateJob.skillsearch.touched || submitted), 'is-valid': formCreateJob.skillsearch.valid}" -->

            <div>
              <span class="my-slt-li" *ngFor="let list of slist; let i = index">
                <span class="slt-name">{{ list }} </span>
                <span class="cls-close text-light" (click)="removeItem(i)">
                  <i class="bx bx-x-circle bxCloseIcon mb-0 pb-0"></i>
                </span>
              </span>
            </div>

            <input #menu type="text" class="form-control" placeholder="Enter comma separated one by one or select skills" formControlName="skillsearch" (keyup)="getsltSkills($any($event.target).value)" maxlength="40" />

            <div *ngIf="(formCreateJob.skillsearch.invalid && formCreateJob.skillsearch.touched) || (formCreateJob.skillsearch.invalid && submitted)">
              <small class="text-danger">Skill Search is <b>required.</b></small>
            </div>

            <span *ngIf="loderstt2" class="city_loader2">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            <div #menudrop id="myDropdown" class="dropdownContent_skills {{ cls1 }}">
              <a *ngFor="let item of templist" (click)="setskillvalue(item?.name)" class="salectchanged system">
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-12 col-md-12">
            <label>No. of Resources<span class="text-danger">*</span></label>
            <input type="number" class="form-control" placeholder="Enter No. of Resources" formControlName="noofResources" min="1" max="999" [ngClass]="{ 'is-invalid': formCreateJob.noofResources.invalid && (formCreateJob.noofResources.touched || submitted), 'is-valid': formCreateJob.noofResources.valid }" />
            <div *ngIf="formCreateJob.noofResources.invalid && (formCreateJob.noofResources.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateJob.noofResources.errors?.required"> No. of Resources is <b>required </b>and not more than 999. </small>
            </div>
          </div>
          <!-- <div class="col-lg-6 col-md-12">
            <label>Job Category<span class="text-danger">*</span></label>

            <ng-select [multiple]="false" formControlName="jobtype" placeholder="Select Job">
              <ng-option value="" [disabled]="true"> Select Job </ng-option>
              <ng-option *ngFor="let list of jobType" [value]="list?.name">
                {{ list?.name }}
              </ng-option>
            </ng-select>

            <div *ngIf="(formCreateJob.jobtype.invalid && formCreateJob.jobtype.touched) || (formCreateJob.jobtype.invalid && submitted)">
              <small class="text-danger">Job Type is <b>required.</b></small>
            </div>
          </div> -->
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-6 col-md-12">
            <label>Tentative Start Date<span class="text-danger">*</span></label>

            <input type="text" placeholder="Select Start Date" class="form-control" [minDate]="minDate" #dp="bsDatepicker" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" formControlName="tentativeStartDate" [ngClass]="{ 'is-invalid': (formCreateJob.tentativeStartDate.invalid && formCreateJob.tentativeStartDate.touched) || (formCreateJob.tentativeStartDate.invalid && submitted), 'is-valid': formCreateJob.tentativeStartDate.valid }" />

            <div *ngIf="(formCreateJob.tentativeStartDate.invalid && formCreateJob.tentativeStartDate.touched) || (formCreateJob.tentativeStartDate.invalid && submitted)">
              <small class="text-danger">Tentative Start Date is <b>required.</b></small>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <ng-template #popTemplate>
              <div [innerHtml]="info['workfrom']"></div>
            </ng-template>

            <label class="control-label"
              >Work From
              <i popovertitle="Work From" class="bx bxs-info-circle pointer mb-0 pb-0"></i>
              <span class="text-danger">*</span>
            </label>

            <!-- <select formControlName="workFrom" class="form-control">
              <option value="">Select</option>
              <option value="Offsite">Offsite</option>
              <option value="Onsite">Onsite</option>
              <option value="Any">Anywhere</option>
            </select> -->

            <ng-select [multiple]="false" formControlName="workFrom" placeholder="Select Option">
              <ng-option value="" [disabled]="true"> Select Option </ng-option>
              <!-- <ng-option value="Offsite">Offsite</ng-option> -->
              <ng-option value="Offsite">Remote</ng-option>
              <ng-option value="Onsite">Onsite</ng-option>
              <ng-option value="Any">Anywhere</ng-option>
            </ng-select>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-6 col-md-12">
            <label class="control-label">Experience Min (Years)<span class="text-danger">*</span></label>
            <input type="number" class="form-control" id="experiencemin" placeholder="Experience Min" min="0" formControlName="experiencemin" [ngClass]="{ 'is-invalid': formCreateJob.experiencemin.invalid && (formCreateJob.experiencemin.touched || submitted), 'is-valid': formCreateJob.experiencemin.valid }" />
            <div *ngIf="formCreateJob.experiencemin.invalid && (formCreateJob.experiencemin.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateJob.experiencemin.errors?.required"> Experience min is <b>required </b>and enter between 0 to 40. </small>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <label class="control-label">Experience Max (Years)<span class="text-danger">*</span></label>
            <input type="number" class="form-control" id="experiencemax" placeholder="Experience Max" min="0" formControlName="experiencemax" [ngClass]="{ 'is-invalid': formCreateJob.experiencemax.invalid && (formCreateJob.experiencemax.touched || submitted), 'is-valid': formCreateJob.experiencemax.valid }" />
            <div *ngIf="formCreateJob.experiencemax.invalid && (formCreateJob.experiencemax.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateJob.experiencemax.errors?.required"> Experience max is <b>required </b>and enter between 0 to 60. </small>
            </div>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-6 col-md-12">
            <label class="control-label">Mode Of Contact<span class="text-danger">*</span></label>

            <!-- <select formControlName="modeOfContact" class="form-control" style="width: 100%">
              <option value="">Select</option>
              <option value="1">Mobile</option>
              <option value="2">Email</option>
              <option value="4">Only Chat</option>
              <option value="3">All</option>
            </select> -->

            <ng-select [multiple]="false" formControlName="modeOfContact" placeholder="Select Option">
              <ng-option value="" [disabled]="true"> Select Option </ng-option>
              <ng-option value="1">Mobile</ng-option>
              <ng-option value="2">Email</ng-option>
              <ng-option value="4">Only Chat</ng-option>
              <ng-option value="3">All</ng-option>
            </ng-select>
          </div>
          <div class="col-lg-6 col-md-12">
            <label class="control-label">Duration<span class="text-danger">*</span></label>

            <!-- <select formControlName="duration" class="form-control" style="width: 100%">
              <option value="">Select</option>
              <option value="0-1">{{ "<" }} 1 Month</option>
              <option value="1-3">1-3 Month</option>
              <option value="3-6">3-6 Month</option>
              <option value="6-12">6-12 Month</option>
              <option value="Long-Term">Long Term</option>
            </select> -->

            <ng-select [multiple]="false" formControlName="duration" placeholder="Select Option">
              <ng-option value="" [disabled]="true"> Select Option </ng-option>
              <ng-option value="0-1">{{ "<" }} 1 Month</ng-option>
              <ng-option value="1-3">1-3 Month</ng-option>
              <ng-option value="3-6">3-6 Month</ng-option>
              <ng-option value="6-12">6-12 Month</ng-option>
              <ng-option value="Long-Term">Long Term</ng-option>
            </ng-select>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="control-label">Job Type<span class="text-danger">*</span></label>
              <div>
                <!-- <label class="form-check-label checkbox-inline">
                  <input type="checkbox" formControlName="contract" class="form-check-input" (change)="jobtypeslect(1)" />
                  <span class="ps-1">Contract</span>
                </label>

                <label class="form-check-label checkbox-inline ps-3">
                  <input type="checkbox" formControlName="freelance" class="form-check-input" (change)="jobtypeslect(2)" />
                  <span class="ps-1">Freelance/Part-Time</span>
                </label> -->

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" formControlName="contract" (change)="jobtypeslect(1)" />
                  <label class="form-check-label">Contract</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" formControlName="freelance" (change)="jobtypeslect(2)" />
                  <label class="form-check-label">Consultant</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ========================================= -->
        <div class="row">
          <div class="col-lg-12 text-start">
            <h4 class="rupees">Estimated Budget?</h4>
          </div>
          <hr />
        </div>
        <!-- ========================================= -->
        <div class="row form-group">
          <div class="col-lg-2">
            <label>Currency <span class="text-danger">*</span></label>
            <ng-select [searchable]="false" id="currency" formControlName="ratecurrency" name="ratecurrency">
              <ng-option value="in">₹</ng-option>
              <ng-option value="doller">$</ng-option>
            </ng-select>
          </div>

          <div class="col-lg-2">
            <label class="control-label">Rate Type <span class="text-danger">*</span></label>
            <ng-select [searchable]="false" id="currency" formControlName="sltratetype" (change)="changerate($any($event.target).value)">
              <ng-option value="Hourly">Hourly</ng-option>
              <ng-option value="Monthly">Monthly</ng-option>
            </ng-select>
          </div>

          <div class="col-lg-4" *ngIf="currency == 'in'">
            <label
              >Rate <small class="color-theme">({{ addJobForm.value.ratecurrency == "in" ? "INR" : "USD" }}/{{ addJobForm.value.sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="number" placeholder="Rates Min" min="1" formControlName="ratemin" id="inrminrate" class="form-control" />
              <!-- <div class="input-group-prepend">
                <div class="input-group-text form-control b_radiusL_zero">
                  <span (click)="checkexp('min')" class="drop-recom">
                    <i class="bx bxs-show bxIconSize"></i>
                  </span>
                </div>
              </div> -->
            </div>
            <small *ngIf="showtestmin" class="text-info">{{ showtestmin }}</small>
          </div>
          <!-- ======================================================== -->
          <div class="col-lg-4" *ngIf="currency == 'in'">
            <label
              >Rate <small class="color-theme">({{ addJobForm.value.ratecurrency == "in" ? "INR" : "USD" }}/{{ addJobForm.value.sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="number" class="form-control" placeholder="Rates Max" min="1" formControlName="ratemax" (change)="checkRate('max', $any($event.target).value)" id="inrmaxrate" />
              <!-- <div class="input-group-prepend">
                <div class="input-group-text form-control b_radiusL_zero">
                  <span (click)="checkexp('max')" class="drop-recom">
                    <i class="bx bxs-show bxIconSize"></i>
                  </span>
                </div>
              </div> -->
            </div>
            <!-- ===================================== -->
            <ng-container *ngIf="ratetype == 'Hourly'">
              <div *ngIf="(formCreateJob.ratemax.errors?.min && formCreateJob.ratemax.touched) || (formCreateJob.ratemax.errors?.max && formCreateJob.ratemax.touched)">
                <small class="text-danger" *ngIf="formCreateJob.ratemax.errors?.min && formCreateJob.ratemax.errors?.max">Rate max between Rs. <b>1 to 10000</b>.</small>
              </div>
            </ng-container>

            <!--  <ng-container *ngIf="ratetype == 'Hourly'">
                            <div *ngIf="formCreateJob.ratemax.touched">
                              <small class="text-danger" *ngIf="formCreateJob.ratemax.errors?.min || formCreateJob.ratemax.errors?.max">
                                Rate max must be between Rs. <b>1 to 10000</b>.
                              </small>
                            </div>
                        </ng-container> -->

            <ng-container *ngIf="ratetype == 'Monthly'">
              <!-- <small class="text-danger"
                              *ngIf="(formCreateJob.get('ratemax').errors?.min && formCreateJob.get('ratemax').touched) || (formCreateJob.get('ratemax').errors?.max && formCreateJob.get('ratemax').touched)">
                              *Rate max between Rs. 1 to 1680000.
                            </small> -->
            </ng-container>
            <!-- ===================================== -->
            <small *ngIf="showtestmax" class="text-info">{{ showtestmax }}</small>
          </div>

        </div>
        <!-- ================================== -->
        <!-- <div class="row form-group">
          <div class="col-lg-3" *ngIf="currency == 'in'">
            <label
              >Rate <small class="color-theme">({{ addJobForm.value.ratecurrency == "in" ? "INR" : "USD" }}/{{ addJobForm.value.sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="number" placeholder="Rates Min" min="1" formControlName="ratemin" id="inrminrate" class="form-control" />
           
            </div>
            <small *ngIf="showtestmin" class="text-info">{{ showtestmin }}</small>
          </div>
        
          <div class="col-lg-3" *ngIf="currency == 'in'">
            <label
              >Rate <small class="color-theme">({{ addJobForm.value.ratecurrency == "in" ? "INR" : "USD" }}/{{ addJobForm.value.sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="number" class="form-control" placeholder="Rates Max" min="1" formControlName="ratemax" (change)="checkRate('max', $any($event.target).value)" id="inrmaxrate" />
             
            </div>
          
            <ng-container *ngIf="ratetype == 'Hourly'">
              <div *ngIf="(formCreateJob.ratemax.errors?.min && formCreateJob.ratemax.touched) || (formCreateJob.ratemax.errors?.max && formCreateJob.ratemax.touched)">
                <small class="text-danger" *ngIf="formCreateJob.ratemax.errors?.min && formCreateJob.ratemax.errors?.max">Rate max between Rs. <b>1 to 10000</b>.</small>
              </div>
            </ng-container>

           

            <ng-container *ngIf="ratetype == 'Monthly'">
            
            </ng-container>
           
            <small *ngIf="showtestmax" class="text-info">{{ showtestmax }}</small>
          </div>
        </div> -->
        <hr />
        <!-- ===================================== -->
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="control-label">Job Description<span class="text-danger">*</span></label>
              <!-- <ckeditor [editor]="Editor" [config]="config" class="ck_height" (change)="getcountsummary($event)" formControlName="jobDescription" [ngClass]="{ 'is-invalid': formCreateJob.jobDescription.invalid && (formCreateJob.jobDescription.touched || submitted), 'is-valid': formCreateJob.jobDescription.valid }" > </ckeditor>             -->

              <!-- <div *ngIf="formCreateJob.jobDescription.invalid && (formCreateJob.jobDescription.touched || submitted)">
                <small class="text-danger" *ngIf="formCreateJob.jobDescription.errors?.required">Job Description is <b>required.</b></small>
                <small class="text-danger" *ngIf="formCreateJob.jobDescription.errors?.minlength">Job Description must be of minimum <b>50 characters</b>.</small>
                <small class="text-danger" *ngIf="formCreateJob.jobDescription.errors?.maxlength">Job Description could be of maximum <b>7000 characters</b>.</small>
              </div> -->

              <!-- <small class="text-danger"> Note: Characters not more than <b>7000</b> and not less than <b>50</b>. </small>&nbsp;<span><b>Left : </b> {{ cntchr }}</span> -->

              <ckeditor [editor]="Editor" [config]="config" class="ck_height" (change)="getcountsummary($event)" formControlName="jobDescription" [ngClass]="{ 'is-invalid': formCreateJob.jobDescription.invalid && (formCreateJob.jobDescription.touched || submitted), 'is-valid': formCreateJob.jobDescription.valid }"> </ckeditor>

              <small class="text-danger" *ngIf="cntchr > 6950">Job Description must be of minimum <b>50 characters</b>.</small>
              <br />
              <small class="text-warning">Note: Characters not more than <b>7000</b> and not less than <b>50</b>.</small>&nbsp;<span><b>Left: </b>{{ cntchr }}</span>
            </div>
          </div>
        </div>

        <div class="submit-btn">
          <div class="col-md-12">
            <!-- [disabled]="addJobForm.invalid" -->
            <button [disabled]="addJobForm.invalid" type="button" class="buttn" (click)="jobFormSubmit()">{{ btn }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- End Post A Job -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->

<!-- modal_for_ratesMin -->
<div *ngIf="isMinRatesModalShown" #staticModal="bs-modal" [config]="{ backdrop: 'static', show: true }" (onHidden)="onHidden_minRatesModal('min')" bsModal #autoShownModal="bs-modal" class="modal fade mt-5" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Rate Recommendation (Job Posting)</h5>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hide_minRatesModal('min')">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <row>
          <div class="col-lg-12">
            <ul class="drop-inner listStyle_none">
              <ng-container *ngIf="currency == 'in'">
                <li *ngFor="let p of per; let i = index" class="borderBottom">
                  <div class="inner-li" *ngIf="ratetype == 'Hourly'">
                    Hourly: <span (click)="setrate(p?.max * expmin * doller, 'min')">{{ p?.max * expmin * doller }} </span> - <span (click)="setrate(p?.min * expmin * doller, 'min')">{{ p?.min * expmin * doller }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                  <div class="inner-li" *ngIf="ratetype == 'Monthly'">
                    Monthly: <span (click)="setrate(p?.max * expmin * totalhour * doller, 'min')">{{ p?.max * expmin * totalhour * doller }}</span> - <span (click)="setrate(p?.min * expmin * totalhour * doller, 'min')">{{ p?.min * expmin * totalhour * doller }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                </li>
              </ng-container>

              <ng-container *ngIf="currency == 'doller'">
                <li *ngFor="let p of per; let i = index" class="borderBottom">
                  <div class="inner-li" *ngIf="ratetype == 'Hourly'">
                    Hourly: <span (click)="setrate(p?.max * expmin, 'min')">{{ p?.max * expmin }}</span> - <span (click)="setrate(p?.min * expmin, 'min')">{{ p?.min * expmin }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                  <div class="inner-li" *ngIf="ratetype == 'Monthly'">
                    Monthly: <span (click)="setrate(p?.max * expmin * totalhour, 'min')"> {{ p?.max * expmin * totalhour }}</span> - <span (click)="setrate(p?.min * expmin * totalhour, 'min')">{{ p?.min * expmin * totalhour }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </row>
      </div>
    </div>
  </div>
</div>

<!-- modal_for_ratesMax -->
<div *ngIf="isMaxRatesModalShown" #staticModal="bs-modal" [config]="{ backdrop: 'static', show: true }" (onHidden)="onHidden_maxRatesModal('max')" bsModal #autoShownModal="bs-modal" class="modal fade mt-5" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Rate Recommendation (Job Posting)</h5>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hide_maxRatesModal('max')">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <row>
          <div class="col-lg-12">
            <ul class="drop-inner listStyle_none">
              <ng-container *ngIf="currency == 'in'">
                <li *ngFor="let p of per; let i = index" class="borderBottom">
                  <div class="inner-li" *ngIf="ratetype == 'Hourly'">
                    Hourly: <span (click)="setrate(p?.max * expmax * doller, 'max')">{{ p?.max * expmax * doller }}</span> - <span (click)="setrate(p?.min * expmax * doller, 'max')">{{ p?.min * expmax * doller }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                  <div class="inner-li" *ngIf="ratetype == 'Monthly'">
                    Monthly: <span (click)="setrate(p?.max * expmax * totalhour * doller, 'max')">{{ p?.max * expmax * totalhour * doller }} </span> - <span (click)="setrate(p?.min * expmax * totalhour * doller, 'max')"> {{ p?.min * expmax * totalhour * doller }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="currency == 'doller'">
                <li *ngFor="let p of per; let i = index" class="borderBottom">
                  <div class="inner-li" *ngIf="ratetype == 'Hourly'">
                    Hourly: <span (click)="setrate(p?.max * expmax, 'max')"> {{ p?.max * expmax }} </span> - <span (click)="setrate(p?.min * expmax, 'max')">{{ p?.min * expmax }}</span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                  <div class="inner-li" *ngIf="ratetype == 'Monthly'">
                    Monthly: <span (click)="setrate(p?.max * expmax * totalhour, 'max')"> {{ p?.max * expmax * totalhour }}</span> - <span (click)="setrate(p?.min * expmax * totalhour, 'max')">{{ p?.min * expmax * totalhour }} </span> - {{ p?.per }}
                    % chance of getting resource
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </row>
      </div>
    </div>
  </div>
</div>
