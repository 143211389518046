<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Expert Profile</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->


<!-- expertProfile -->
<div class="job-area-list my-5">
    <div class="container ">
        <div class="row">
            <div class="col-md-8 grid-margin stretch-card">
              <div class="card">
                <div class="card-body" style="padding: 15px 15px;"> 
                  <div class="row p-3">
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3"> 
                      <img *ngIf="feelancerdata?.imageurl!=null" src="{{feelancerdata?.imageurl}}" class="img">
                      <img *ngIf="feelancerdata?.imageurl==null" src="assets/images/web-development.png" class="img ">
                      <div class="edit-image2 text-end" (click)="showModal('photo')">
                        <span  tooltip="Edit Image">
                          <i class="fa-solid fa-pen-to-square"></i>
                        </span>
                      </div>
                      <input style="display: none" type="file" (change)="onFileChangedImage($event)" #fileInput>
                      <div class="online text-center" *ngIf="feelancerdata?.status==true" style="padding: 5px 0px;">
                        <span><i class="fa fa-circle"></i>&nbsp; I'm Online! </span>
                      </div>
                      <div class="offline text-center" *ngIf="feelancerdata?.status==false" style="padding: 5px 0px;">
                        <span><i class="fa fa-circle-o"></i>&nbsp; I'm Offline! </span>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                      <div class="editbtn">
                        <button type="button" class="btn btn-sm btn-dark" (click)="openexperience('profile')"> Edit
                          Profile</button> &nbsp;
                        <!-- <button type="button" class="btn btn-sm bnt-theme" routerLink="/business/profileinfo/create-portfolio">  Change Theme</button> -->
                      </div>
                      <div class="profile-name">
                        {{feelancerdata?.name}}
                      </div>
                      <div class="profile-degination text-info">
                        {{feelancerdata?.designation}}
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <span class="head-prof"> Work From :</span> {{feelancerdata?.onsiteAvailability}}
                        </div>
                        <div class="col-sm-7">
                          <span class="head-prof"> Total Experience :</span> {{feelancerdata?.experience}} Year
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <span class="head-prof"> Availability:</span> {{feelancerdata?.Availability2}}
                        </div>
                        <div class="col-sm-7">
                          <span class="head-prof"> Rate :</span>$ {{feelancerdata?.rate}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <span class="head-prof">Gender : </span> {{feelancerdata?.gender}}
                        </div>
                        <div class="col-sm-7">
                          <span class="head-prof"> Category :</span> {{feelancerdata?.category}}
                        </div>
                        <div class="col-sm-12" *ngIf="feelancerdata?.Resumeurl">
                          <span class="head-prof"> Resume :</span>
                          <a href="{{feelancerdata?.Resumeurl}}" target="_blank" class="badge bg-warning ms-2"> View Resume</a>
                        </div>
                      </div>
                      <div class="profile-summery" style="padding: 10px 0px;" [innerHtml]="feelancerdata?.description">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <span> Projects </span>
                    <div class="editbtn2">
                      <!-- routerLink="/business/profileinfo/portfolio" -->
                      <button (click)="openAddProject()" type="button"  class="btn btn-sm btn-white">
                        Add Project
                      </button>
                    </div>
                  </div>
                  <div class="common-body">
                    <div *ngIf="feelancerdata?.projectList?.length==0" class="not-found-portfolio text-center">
                      <img src="../../../../assets/img/data_not_found.png" width="75" />
                      <div> No Project items have been added yet </div>
                    </div>
                    <div class="row">
                      <div *ngFor="let list of feelancerdata?.projectList;let i=index"
                        class="col-xs-6 col-sm-4 pointer mb-5" >

                        <div>
                          <span class="pointer" (click)="patchport(list)"> Edit </span> |
                          <span class="pointer" (click)="deleteport(list._id,i)"> Delete</span>
                        </div>

                        <!-- <div (click)="projectdetails(list)" class="portfolio-div2 text-center" [style.backgroundImage]="'url('+ list?.imageurl[0] +')'">
                          <div class="port-action"></div>
                          <div class="port-title">{{list?.title}}</div>
                        </div> -->
                       

                        <div class="portfolio-div2 text-center" [style.backgroundImage]="'url('+ list?.imageurl[0] +')'">
                          <div class="port-action">
                          </div>
                        </div>
                        <div class="port-title text-center">
                          {{list?.title}}
                        </div>






                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <span> Experience </span>
                    <div *ngIf=" feelancerdata?.experienceList?.length<10" class="editbtn2">
                      <button type="button" class="btn btn-sm btn-white" (click)="openexperience('experience')"> Add
                        Experience</button>
                    </div>
                  </div>
                  <div class="common-body">
                    <div class="row">
                      <div *ngFor="let list of feelancerdata?.experienceList;let i=index" class="col-sm-12">
                        <hr *ngIf="i!=0">
                        <div class="delete-btn">
                          <i class="fa fa-edit pointer" (click)="editpatchexperience(list)"></i> &nbsp;
                          <i class="fa fa-trash pointer" (click)="deleteex(list?._id,i)"></i>
                        </div>
                        <h4>{{list?.title}} </h4>
                        <b>{{list?.company}} </b>
                        <div> {{monthlist[(list?.startmonth-1)]}} {{list?.startyear}} -
                          <span *ngIf="list?.currently==0"> {{monthlist[(list?.endmonth-1)]}} {{list?.endyear}}</span>
                          <span *ngIf="list?.currently==1">Present </span>
                        </div>
                        <div class="summarytext" [innerHtml]="list?.summary"></div>
                      </div>
                    </div>
                    <div *ngIf="feelancerdata?.experienceList?.length==0">
                      No qualifications have been added.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <span> Education </span>
                    <div class="editbtn2">
                      <button type="button" class="btn btn-sm btn-white" (click)="openexperience('qualification')"> Add
                        Education</button>
                    </div>
                  </div>
                  <div class="common-body">
                    <div class="row">
                      <div *ngFor="let list of feelancerdata?.ugType;let i=index" class="col-sm-12">
                        <hr *ngIf="i!=0">
                        <div class="delete-btn">
                          <i class="fa fa-edit pointer" (click)="editpatchqualification(list,'ug')"></i> &nbsp;
                          <i class="fa fa-trash pointer" (click)="deletequalification(list?._id,i,'ug')"></i>
                        </div>
                        <h4>{{list?.ug}} </h4>
                        <div>{{list?.studyArea}} </div>
                        <div>{{list?.college}} </div>
                        <div> {{list?.startyear}} - {{list?.endyear}} </div>
                      </div>
                      <div *ngFor="let list of feelancerdata?.pgType;let i=index" class="col-sm-12">
                        <hr>
                        <div class="delete-btn">
                          <i class="fa fa-edit pointer" (click)="editpatchqualification(list,'pg')"></i> &nbsp;
                          <i class="fa fa-trash pointer" (click)="deletequalification(list?._id,i,'pg')"></i>
                        </div>
                        <h4>{{list?.pg}} </h4>
                        <div>{{list?.studyArea}} </div>
                        <div>{{list?.college}} </div>
                        <div> {{list?.startyear}} - {{list?.endyear}} </div>
                      </div>
                    </div>
                    <div *ngIf="feelancerdata?.ugType?.length==0 && feelancerdata?.pgType?.length==0">
                      No Education have been added.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <!-- <span> Publications / Achievement </span> -->
                    <span> Certifications </span>
                    <div class="editbtn2">
                      <button type="button" class="btn btn-sm btn-white" (click)="openexperience('publication')"> Add
                        Certificate</button>
                    </div>
                  </div>
                  <div class="common-body">
                    <div class="row">
                      <div *ngFor="let list of feelancerdata?.publicationList;let i=index" class="col-sm-12">
                        <hr *ngIf="i!=0">
                        <div class="delete-btn">
                          <i class="fa fa-edit pointer" (click)="editpatchpublication(list)"></i> &nbsp;
                          <i class="fa fa-trash pointer" (click)="deletepublication(list?._id,i)"></i>
                        </div>
                        <h4>{{list?.title}} </h4>
                        <div>{{list?.publisher}} </div>
                        <div class="summarytext" [innerHtml]="list?.summary"></div>
                      </div>
                    </div>
                    <div *ngIf="feelancerdata?.publicationList?.length==0">
                      No Certifications have been added.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 grid-margin stretch-card">
              <div class="card mb-2">
                <div class="profile-card">
                  <div class="profile-content">
                    <p class="profile-description">
                      <i class="fa fa-envelope" aria-hidden="true"></i> {{feelancerdata?.email}}
                    </p>
                    <p class="profile-description">
                      <i class="fa fa-phone" aria-hidden="true"></i> {{feelancerdata?.phone}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <span>Skills </span>
                  </div>
                  <div class="editbtn2">
                    <button class="btn btn-sm btn-white" type="button" (click)="openexperience('skill')">
                      Edit Skill
                    </button>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div style="padding: 5px 15px;">
                        <div class="mySltLi" *ngFor="let list of feelancerdata?.skill;let i=index">
                          <span class="slt-name">{{list}} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card my-2">
                <div class="card-body">
                  <div class="project-title">
                    <span>Other Banner Image </span>
                  </div>
                  <div class="editbtn2">
                    <button class="btn btn-sm btn-white" type="button" (click)="openexperience('bannerimg')">
                      Add
                    </button>
                  </div>
                  <div class="p-3">
                    <div *ngIf="bannerimage?.length>0">
                      <carousel>
                        <slide *ngFor="let list of bannerimage">
                          <img src="{{list}}" alt="first slide" style="display: block; width: 100%;">
                        </slide>
                      </carousel>
                    </div>
                    <div *ngIf="bannerimage?.length==0" class="text-center">
                        <img src="../../../../assets/img/data_not_found.png" width="75" />
                        <p>No banner image have been added.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
<!-- End expertProfile -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

<!-- modals -->

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden('photo')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Upload Photo</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal('photo')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pad-5">
          <div class="col-sm-12">
            <input #inputFile type="file" (change)="fileChangeEvent($event,'photo')" />
          </div>
        </div>
        <div class="row pad-5">
          <div class="col-sm-12" style="min-height: 100px;">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1/1" [resizeToWidth]="256"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event,'photo')" (imageLoaded)="imageLoaded('photo')"
              (cropperReady)="cropperReady($event,'photo')" (loadImageFailed)="loadImageFailed('photo')">
            </image-cropper>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-12">
            <button type="button" class="btn btn-danger me-2" (click)="cancelform('photo')">Cancel</button>
            <button type="submit" (click)="addimage('photo')" class="btn btn-info">
              <span *ngIf="loaderstt==false"> Add Image </span>
              <span *ngIf="loaderstt"> Please Wait...
                <img src="assets/images/loginloader.gif" /> 
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- profileModal -->
<div *ngIf="isModalprofile" [config]="{ show: true }" (onHidden)="onHidden('profile')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Profile</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('profile')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="candidateForm" class="box-body">
                <div class="form-group row mb-3">
                    <div class="col-sm-6">
                        <label class=" control-label">Your Designation* </label>
                        <input type="text" class="form-control" placeholder="Title" formControlName="designation" maxlength="100">
                        <small class="text-danger"
                        *ngIf="this.candidateForm.get('designation').invalid && this.candidateForm.get('designation').touched">
                        Designation is required and not more than 100 characters.
                        </small>
                    </div>
                    <div class="col-sm-6">
                        <label class="control-label">Rate
                        <small>(in USD/Hour)</small> * </label>
                        <input type="number" class="form-control" placeholder="Rates" min="0" formControlName="rate">
                        <div>
                        <small class="text-danger"
                            *ngIf="(candidateForm.get('rate').invalid && candidateForm.get('rate').touched) || (candidateForm.get('rate').invalid && candidateForm.get('rate').min) || (candidateForm.get('rate').invalid && candidateForm.get('rate').max)">
                            Please enter valid rate.
                        </small>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form-group row mb-3">
                    <div class="col-sm-4">
                        <label class="control-label">Work From * </label>
                        <select class="form-control" formControlName="onsiteAvailability">
                        <option value="">Select</option>
                        <option value="Onsite">Office</option>
                        <option value="Offsite">Home</option>
                        <option value="Any">Any Location</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label class=" control-label">Total Experience(In Year)* </label>
                        <input type="number" class="form-control" placeholder="Experience" min="0" formControlName="experience">
                    </div>
                    <div class="col-sm-4">
                        <label class=" control-label">Availability* </label>
                        <select class="form-control" formControlName="Availability">
                        <option value="">Select Availability </option>
                        <option value="Immediate">Immediate</option>
                        <option value="1_Week">1 Week</option>
                        <option value="2_Week">2 Week</option>
                        <option value="3_Week">3 Week</option>
                        <option value="1_Month">1 Month</option>
                        <option value="More_1_Month">More than 1 Month</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <label class="control-label">Hours Per Day * </label>
                        <select class="form-control" formControlName="hoursperday">
                        <option value="">Select Hours Per Day</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <label class="control-label">Start time</label>
                        <select class="form-control" formControlName="starttimeday">
                        <option value="">Select Start time</option>
                        <option *ngFor="let list of timedata" value="{{list}}">{{list}}</option>
                        </select>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <label class="control-label">Gender * </label>
                        <select class="form-control" formControlName="gender">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="form-group row mb-3">
                    <div class="col-sm-6">
                        <label class="control-label">Resume (.pdf,.doc and .docx)</label>
                        <input type="file" class="form-control" (change)="onFileSelect($event)">
                        <div *ngIf="candidateForm?.value?.Resumeurl" class="resume">
                            <a href="{{candidateForm?.value?.Resumeurl}}" target="_blank" class="badge bg-success mt-2">
                                {{candidateForm?.value?.originalimage}}
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="control-label">Category*</label>
                        <select formControlName="category" class="form-control" style="width: 100%">
                        <option value="">Select</option>
                        <option *ngFor="let list of jobType" value="{{list?.name}}">{{list?.name}}</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="form-group row mb-3">
                    <div class=" col-md-12">
                        <label class="control-label">Summary </label>

                        <ckeditor [editor]="Editor" [config]="configck" class="ck_height" (change) = "getcountsummary($event)" formControlName="description"> </ckeditor>

                        <small class="text-default"> <b> Note: </b> Characters not more than 4000 <b>Left : </b>
                        {{cntchr}}</small>
                    </div>
                </div>
                <div class="form-group row my-3">
                    <div class=" col-md-12 text-center">
                        <button type="button" class="btn btn-info" [disabled]="candidateForm.invalid"
                        (click)="updateFreelancer()">Update</button>&nbsp;
                        <button type="button" class="btn btn-danger" (click)="cancelform('profile')"> Cancel</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- addExperience -->
<div *ngIf="isModalexperience" [config]="{ show: true }" (onHidden)="onHidden('experience')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Experience</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('experience')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="experienceForm" class="box-body">
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class="control-label">Title* </label>
              <input type="text" class="form-control" placeholder="Enter your position or title" formControlName="title"
                maxlength="100">
              <small class="text-danger"
                *ngIf="experienceForm.get('title').invalid && experienceForm.get('title').touched">
                Title is required and not more than 100 characters.
              </small>
            </div>
            <div class="col-lg-6">
              <label class=" control-label">Company* </label>
              <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="company"
                maxlength="100">
              <small class="text-danger"
                *ngIf="experienceForm.get('company').invalid && experienceForm.get('company').touched">
                Company is required.
              </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-sm-12">
                  <label class="control-label">Start Date* </label>
                </div>
                <div class="col-lg-6">
                  <select class="form-control" formControlName="startmonth" (change)="checkdate()">
                    <option value="">Select Month</option>
                    <option *ngFor="let list of monthlist;let i=index" value="{{(i+1)}}">{{list}}</option>
                  </select>
                </div>
                <div class="col-lg-6">
                  <select class="form-control" formControlName="startyear" (change)="checkdate()">
                    <option value="">Select Year</option>
                    <option *ngFor="let list of yearlist" value="{{list}}">{{list}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-12">
                  <label class="control-label">End Date* </label>
                </div>
                <div class="col-lg-6">
                  <select class="form-control" formControlName="endmonth" (change)="checkdate()">
                    <option value="">Select Month</option>
                    <option *ngFor="let list of monthlist;let i=index" value="{{(i+1)}}">{{list}}</option>
                  </select>
                </div>
                <div class="col-lg-6">
                  <select class="form-control" formControlName="endyear" (change)="checkdate()">
                    <option value="">Select Year</option>
                    <option *ngFor="let list of yearlist" value="{{list}}">{{list}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class=" col-lg-12">
              
              <div class="form-check checkbox checkbox-success">
                <input id="currently" class="form-check-input" type="checkbox" formControlName="currently" (change)="currently()" name="slltskill">
                <label class="form-check-label" for="currently">
                  I'm currently working here
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class=" col-lg-12">
              <label class="control-label">Summary* </label>
              <ckeditor [editor]="Editor" [config]="configck" class="ck_height" formControlName="summary"> </ckeditor>
              <small class="text-danger"> *Characters not more than 4000 </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-12 text-center">
              <button type="button" class="btn btn-info" [disabled]="experienceForm.invalid"
                (click)="submitexperience()">{{btnstt}}</button>&nbsp;
              <button type="button" class="btn btn-danger" (click)="cancelform('experience')"> Cancel</button>          
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- addEducation -->
<div *ngIf="isModalqualification" [config]="{ show: true }" (onHidden)="onHidden('qualification')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Education</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('qualification')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="educationForm" class="box-body">
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class=" control-label">Education type* </label>
              <select class="form-control" formControlName="edutype">
                <option value=""> Select </option>
                <option value="ug"> High School </option>
                <option value="ug"> UG (Undergraduation) </option>
                <option value="ug"> Diploma </option>
                <option value="pg"> PG (Postgraduation) </option>
                <option value="pg"> PhD /Research </option>
              </select>
              <small class="text-danger"
                *ngIf="educationForm.get('edutype').invalid && educationForm.get('edutype').touched">
                Education type is required.
              </small>
            </div>
            <div class="col-lg-6">
              <label class=" control-label">Degree* </label>
              <input type="text" class="form-control" placeholder="eg; Bachelor's or B.tech" formControlName="degree"
                maxlength="100">
              <small class="text-danger"
                *ngIf="educationForm.get('degree').invalid && educationForm.get('degree').touched">
                Degree is required.
              </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class=" control-label">Study Area* </label>
              <input type="text" class="form-control" placeholder="eg; Computer Science" formControlName="studyArea"
                maxlength="100">
              <small class="text-danger"
                *ngIf="educationForm.get('studyArea').invalid && educationForm.get('studyArea').touched">
                Study Area is required.
              </small>
            </div>
            <div class="col-lg-6">
              <label class="control-label">College/University* </label>
              <input type="text" class="form-control" placeholder="Enter college Name" formControlName="college"
                maxlength="100">
              <small class="text-danger"
                *ngIf="educationForm.get('college').invalid && educationForm.get('college').touched">
                College/University is required.
              </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class="control-label">Start Year*</label>
              <select class="form-control" formControlName="startyear">
                <option value="">Select Year</option>
                <option *ngFor="let list of yearlist" value="{{list}}">{{list}}</option>
              </select>
            </div>
            <div class="col-lg-6">
              <label class="control-label">End Year*</label>
              <select class="form-control" formControlName="endyear">
                <option value="">Select Year</option>
                <option *ngFor="let list of yearlist" value="{{list}}">{{list}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-12 text-center">
              <button type="button" class="btn btn-info" [disabled]="educationForm.invalid"
                (click)="submitqualification()">{{btnstt}}</button>&nbsp;
              <button type="button" class="btn btn-danger" (click)="cancelform('qualification')"> Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- publicationModal -->
<div *ngIf="isModalpublication" [config]="{ show: true }" (onHidden)="onHidden('publication')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <!-- <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Publications /Achievement</h4> -->
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Certifications</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('publication')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="publicationForm" class="box-body">
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class=" control-label">Title* </label>
              <input type="text" class="form-control" placeholder="Enter Title*" formControlName="title"
                maxlength="100">
              <small class="text-danger"
                *ngIf="publicationForm.get('title').invalid && publicationForm.get('title').touched">
                Title is required and not more than 100 characters.
              </small>
            </div>
            <div class="col-lg-6">
              <label class=" control-label">Publisher* </label>
              <input type="text" class="form-control" placeholder="Enter publisher Name*" formControlName="publisher"
                maxlength="100">
              <small class="text-danger"
                *ngIf="publicationForm.get('publisher').invalid && publicationForm.get('publisher').touched">
                Publisher is required.
              </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class=" col-lg-12">
              <label class="control-label">Summary*</label>
              <ckeditor [editor]="Editor" [config]="configck" class="ck_height" formControlName="summary"></ckeditor>
              <small class="text-danger"> *Characters not more than 4000 </small>
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class=" col-lg-12 text-center">
              <button type="button" class="btn btn-info" [disabled]="publicationForm.invalid"
                (click)="submitpublication()">{{btnstt}}</button>&nbsp;
              <button type="button" class="btn btn-danger" (click)="cancelform('publication')"> Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- portfolioModal -->
<div *ngIf="isModalportfolio" [config]="{ show: true }" (onHidden)="onHidden('portfolio')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Project Details</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal('portfolio')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-4">
            <img src="{{projectinfo?.imageurl[0]}}" />
            <br>
            <a *ngIf="projectinfo?.website" href="{{projectinfo?.website}}" type="button" class="btn btn-green">
              Website</a>
          </div>
          <div class="col-sm-8">
            <h4> {{projectinfo?.title}}</h4>
            <p> Duration : {{projectinfo?.duration}} </p>
            <hr>
            <p> Role : </p>
            <p [innerHtml]="projectinfo?.role"> </p>
            <hr>
            <p> Description :</p>
            <p [innerHtml]="projectinfo?.description"> </p>
            <hr>
            <p> Skills :
              <span class="my-slt-li" *ngFor="let list of projectinfo?.skills;let i=index">
                <span class="slt-name">{{list}} </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- skillsModal -->
<div *ngIf="isModalskill" [config]="{ show: true }" (onHidden)="onHidden('skill')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Project Details</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('skill')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="skillForm" class="box-body">
          <label class="control-label"> Skills* </label>
          <div>
            <!-- <span class="my-slt-li" *ngFor="let list of slist;let i=index">
              <span class="slt-name">{{list}} </span>
              <span class="cls-close" (click)="removeItem(i);">X </span>
            </span> -->

            <span class="my-slt-li" *ngFor="let list of slist; let i = index">
              <span class="slt-name">{{ list }} </span>
              <span class="cls-close text-light" (click)="removeItem(i)">
                <i class="bx bx-x-circle bxCloseIcon mb-0 pb-0"></i>
              </span>
            </span>

          </div>
          <div class="keyskill" *ngIf="slist?.length>15">
            Add up to 15 skills. Remove skills by deleting tags.
          </div>
          <div *ngIf="slist?.length<=15">
            <small class="text-danger"
              *ngIf="skillForm.get('skillsearch').maxLength && skillForm.get('skillsearch').touched">
              *Characters not more than 100.
            </small>
            <br>
            <input #menu type="text" class="form-control" maxlength="100"
              placeholder="write in comma separated or Select Skills" formControlName="skillsearch"
              (keyup)="getsltSkills($any($event.target).value)">
            <span *ngIf="loderstt2" class="city_loader2">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            <div *ngIf="skilllist?.length" id="myDropdown" class="dropdownContent_skills {{cls2}}">
              <a *ngFor="let item of skilllist" (click)="setskill(item.name,item._id)">
                {{item.name}}
              </a>
            </div>
            <div #menudrop id="myDropdown" class="dropdownContent_skills {{cls1}}">
              <a *ngFor="let item of  templist" (click)="setskillvalue(item?.name)" class="salectchanged system">
                {{item.name}}
              </a>
            </div>
          </div>
          <br>
          <div class="form-group row">
            <div class=" col-md-12">
              <button type="button" class="btn btn-info" [disabled]="skillForm.invalid"
                (click)="updateskill()">Update</button>&nbsp;
              <button type="button" class="btn btn-danger" (click)="cancelform('skill')"> Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- bannerMOdal -->
<div *ngIf="isModalbanner" [config]="{ show: true }" (onHidden)="onHidden('bannerimg')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Upload Banner</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal('bannerimg')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pad-5">
          <div class="col-sm-12">
            <input #binputFile type="file" (change)="fileChangeEvent($event,'banner')" />
          </div>
        </div>
        <div class="row pad-5">
          <div class="col-sm-12" style="min-height: 100px;">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3/4" [resizeToWidth]="256"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event,'banner')" (imageLoaded)="imageLoaded('banner')"
              (cropperReady)="cropperReady($event,'banner')" (loadImageFailed)="loadImageFailed('banner')">
            </image-cropper>
          </div>
        </div>
      </div>
      <div *ngIf="otherimg" class="modal-footer">
        <div class="row">
          <div class="col-sm-12">
            <button type="button" class="btn btn-default-theme" (click)="cancelform('banner')">Cancel</button>
            <button type="submit" (click)="addimage('banner')" class="btn btn-theme">
              <span *ngIf="loaderstt"> Add Image </span>
              <span *ngIf="loaderstt"> Please Wait...
                <img src="assets/images/loginloader.gif" /> </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- addProjectModal -->
<div *ngIf="isModalProjectItem" [config]="{ show: true }" (onHidden)="onHidden('project')" bsModal
#autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-xl">
    <div class="modal-content p-2">
      <div class="modal-header modalHeader">
        <span *ngIf="btnstt === 'Save'">
          <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Add Project</h4>
        </span>
        <span *ngIf="btnstt === 'Update'">
          <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Update Project</h4>
        </span>

        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="cancelform('project')">
          <span aria-hidden="true" class="visually-hidden">
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="portfolioForm">
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class="control-label">Title* </label>
              <input type="text" class="form-control" formControlName="title" maxlength="100" />
            </div>
            <div class="col-lg-6">
              <label class="control-label">Duration in month </label>
              <input type="number" class="form-control" formControlName="duration" />
            </div>
          </div>
          <div class="form-group row mb-3">
            <div class="col-lg-6">
              <label class="control-label">Project Description* </label> 
              <div>               
                <!-- <ckeditor [config]="configck" formControlName="description"></ckeditor> -->
                <ckeditor [editor]="Editor" [config]="configck" class="ck_height" formControlName="description"> </ckeditor>
                <small class="text-info"> * characters not more than 2000 </small>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="control-label">Role and Responsibilities </label>
              <div>        
                <!-- <ckeditor [config]="configck" formControlName="role"></ckeditor> -->
                <ckeditor [editor]="Editor" [config]="configck" class="ck_height" formControlName="role"> </ckeditor>
                <small class="text-info"> * characters not more than 2000 </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="row mb-3">
                <div class="form-group col-lg-12 mb-3">
                  <label class="control-label">website </label>
                  <input type="text" class="form-control" formControlName="website" maxlength="100" placeholder="https://www.website.com" />
                </div>
                <div class="form-group col-lg-12 mb-3">
                  <label class="control-label">Add a video link </label>
                  <input type="text" class="form-control" formControlName="mediaUrl" maxlength="100" />
                </div>
                <div class="form-group col-lg-12 mb-3">
                  <label class="control-label">Skills* </label>
                  <div class="skill-div">
                    <div class="row ">
                      <div *ngFor="let item of feelancerdata?.skill;let i=index" class="col-lg-6">
                        
                        <div class="form-check checkbox checkbox-success pt-2">
                          <input id="agree_{{i}}skill" class="form-check-input slltskill" type="checkbox" name="slltskill" value="{{item}}">
                          <label for="agree_{{i}}skill">{{item}}</label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="control-label">Upload File* (Allowed formats: JPG, PNG, GIF. Maximum file size: 10MB) </label>
              <div class="uploader pointer">
                <input id="file-upload" type="file" name="fileUpload" accept="image/*" (change)="onFileChangedImage_project($event)" />
                <label for="file-upload" id="file-drag">
                  <img id="file-image" src="#" alt="Preview" class="hidden">
                  <div id="start">
                    <i class="fa fa-download" aria-hidden="true"></i>
                    <div>Select a file or drag here</div>
                    <div id="notimage" class="hidden">Please select an image</div>
                    <span id="file-upload-btn" class="btn btn-primary">Select a file</span>
                  </div>
                  <div id="response" class="hidden">
                    <div id="messages"></div>
                    <progress class="progress" id="file-progress" value="0">
                      <span>0</span>%
                    </progress>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div *ngFor="let img of imageList;let i=index" class="col-sm-4" style="margin-top: 5px;">
                  <div class="remove-btn">
                    <i class="fa fa-trash text-danger pointer" (click)="removeimg(i)"></i>
                  </div>
                  <div class="img-div">
                    <img src="{{img}}" height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row py-4">
            <div class="col-lg-12 text-center">
              <button type="button" class="btn btn-info" [disabled]="portfolioForm.invalid" (click)="submitPortfolio()">{{btnstt}}</button>&nbsp;
              <button type="button" class="btn btn-danger" (click)="addform()"> Cancel</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

