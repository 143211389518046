import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { ExcelService } from "src/app/services/excel.service";
import { JobApplicantsService } from "src/app/services/job-applicants.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { PostJobService } from "../../../services/post-job.service";
import * as $ from "jquery";

@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search.component.html",
  styleUrl: "./advanced-search.component.scss",
})
export class AdvancedSearchComponent implements OnInit {
  tab: any = [true, false, false, false];
  tabstt = 1;
  activeDropdown: any;

  pages: any = 0;
  currentPage: any = 1;

  pages_jobs: any = 0;
  currentPage_job: any = 1;

  jobquerydata: any = {
    page: 1,
    totalpage: 0,
    limit: 25,
    total: 0,
    orderval: "createdAt",
    ordertype: "desc",
    serchval: {
      $and: [],
    },
    short: {
      updateDate: -1,
      createdAt: -1,
    },
  };

  openstt = 1;
  jobid: any = "";
  shimmer = false;
  utype: any = "";
  authdata: any = {};
  skillListdata: any = [];
  jobdata: any = [];
  currencytype: any = "";
  templist: any = [];
  jobsAllSkill: any = [];
  jobsAnySkill: any = [];
  slist: any = [];
  cls1: any = [];
  cls2: any = [];
  search: any = {};
  advanceSearch: any = {};
  citylist: any = [];
  locationlist: any = [];
  jobs: any = [];
  resources: any = [];
  cls: any;
  loderstt: any = false;
  searched: any = false;

  constructor(
    private formBuild: FormBuilder,
    private commonSevice: CommonService,
    private jobApplicantsSevice: JobApplicantsService,
    private excelService: ExcelService,
    private toaster: ToastrService,
    private postJobService: PostJobService,
    private route: ActivatedRoute,
    private loader: LoadingScreenService,
    private auth: AuthService,
    public datepipe: DatePipe,
  ) {
    this.loader.stopLoading();
    this.route.queryParams.subscribe((params) => {
      this.tabstt = params["tab"];
      this.jobid = params["jobid"];
      this.getSkillList();
      this.getJobs(this.currentPage);
      this.getResources(this.currentPage_job);
      // this.getJobs();
      // if (this.jobid) {
      //   this.getJob();
      // }
      if (this.tabstt == 1) {
        this.getList(1, 0);
      } else if (this.tabstt == 2) {
        this.getList(2, 1);
      } else if (this.tabstt == 3) {
        this.getList(3, 2);
      } else {
        this.getList(4, 3);
      }
    });
  }

  // https://www.onbenchmark.com/custom/searchjob/job

  ngOnInit(): void {
    if (localStorage.getItem("currency")) {
      this.currencytype = localStorage.getItem("currency");
    }
    this.jobApplicantsSevice.globalprize.subscribe((res: any) => {
      this.currencytype = res.ctype;
    });
    if (this.auth.getloginstt()) {
      this.authdata = this.auth.getloginData();
    }
    this.auth.profiledata.subscribe((res: any) => {
      this.authdata = res;
    });
  }

  // ===========================================
  // getJobs() {
  //   this.postJobService.getSearchedJobs(this.jobquerydata).then((resp) => {
  //     this.jobs = resp.data;
  //     console.log("respData ", this.jobs);
  //   });
  // }

  getJobs(page: any) {
    this.postJobService.getSearchedJobs(this.jobquerydata).then((resp) => {
      this.jobs = resp.data;
      // console.log("respData ", this.jobs);
      this.pages = Math.ceil(resp.data.total / 10);
    });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.jobquerydata.page = this.currentPage;
      this.getJobs(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage = this.currentPage + 1;
      this.jobquerydata.page = this.currentPage;
      this.getJobs(this.currentPage);
    }
  }

  // ============================

  // getResources() {
  //   this.postJobService.getSearchedResources(this.jobquerydata).then((resp) => {
  //     this.resources = resp.data;
  //     console.log("respData==: ", this.resources.data);
  //   });
  // }

  getResources(page: any) {
    this.postJobService.getSearchedResources(this.jobquerydata).then((resp) => {
      this.resources = resp.data;
      console.log("CandidateRespData==: ", this.resources.data);
      this.pages_jobs = Math.ceil(resp.data.total / 10);
    });
  }

  // pages_jobs
  // currentPage_job

  previousPage_jobs() {
    if (this.currentPage_job > 1) {
      this.currentPage_job = this.currentPage_job - 1;
      this.jobquerydata.page = this.currentPage;
      this.getResources(this.currentPage_job);
    }
  }

  nextPage_jobs() {
    if (this.currentPage_job < this.pages) {
      this.currentPage_job = this.currentPage_job + 1;
      this.jobquerydata.page = this.currentPage_job;
      this.getResources(this.currentPage_job);
    }
  }

  // ==========================================

  getList(stt: any, inx: any) {
    this.tab = [false, false, false, false];
    this.tab[inx] = true;

    // console.log("this.openstt ", this.openstt);
    // console.log("stt ", stt);

    this.reSetJobsFilter();
    this.reSetHireOnConractFilters();

    this.jobquerydata.serchval = {
      $and: [],
    };

    if (this.openstt != stt) {
      this.searched = false;
    }
    this.openstt = stt;

    if (stt == 1) {
      this.advanceSearch.searchIn = "jobTitleKeySkills";
    }
    if (stt == 2) {
      this.advanceSearch.searchIn = "2";
    }

    // if (stt == 1) {
    //   // this.search.searchIn = "jobTitleKeySkills";
    // }
    // this.jobquerydata.page = 1;
    // this.jobquerydata.openstt = stt;
  }

  getJob() {
    this.loader.startLoading();
    this.jobApplicantsSevice.FindbyId(this.jobid, "job").then(
      (res: any) => {
        this.jobdata = res.data;
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  searchAdvance() {
    this.jobs = [];
    this.jobquerydata.serchval = {
      $and: [],
    };

    this.resources = [];
    this.jobs = [];

    this.searched = true;
    if (this.openstt == 1) {
      this.jobquerydata.serchval = {
        $and: [],
      };
      let anySkillConditions = [];

      if (this.jobsAnySkill.length > 0) {
        // jobDescription
        if (
          this.advanceSearch.searchIn == "any" ||
          this.advanceSearch.searchIn == "jobTitle" ||
          this.advanceSearch.searchIn == "jobTitleKeySkills"
        ) {
          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              title: {
                $regex: s,
                $options: "i",
              },
            });
          });
        }

        if (
          this.advanceSearch.searchIn == "any" ||
          this.advanceSearch.searchIn == "jobTitleKeySkills"
        ) {
          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              skill: {
                $regex: s,
                $options: "i",
              },
            });
          });
        }

        if (
          this.advanceSearch.searchIn == "any" ||
          this.advanceSearch.searchIn == "jobDescription"
        ) {
          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              jobDescription: {
                $regex: s,
                $options: "i",
              },
            });
          });
        }

        this.jobquerydata.serchval["$and"].push({
          $or: anySkillConditions,
        });
      }

      if (this.jobsAllSkill.length > 0) {
        let allSkillConditions = [];

        this.jobsAllSkill.forEach((s) => {
          allSkillConditions.push({
            skill: {
              $regex: s,
              $options: "i",
            },
          });
        });

        this.jobquerydata.serchval["$and"].push({
          $and: allSkillConditions,
        });
      }

      if (this.search.workFrom && this.search.workFrom != "") {
        this.jobquerydata.serchval["$and"].push({
          workFrom: {
            $regex: this.search.workFrom,
            $options: "i",
          },
        });
      }

      if (this.advanceSearch.location && this.search.location != "") {
        this.jobquerydata.serchval["$and"].push({
          "location.location": {
            $regex: this.advanceSearch.location,
            $options: "i",
          },
        });
      }

      if (this.search.rate && this.search.rate != "") {
        this.jobquerydata.serchval["$and"].push({
          $and: [
            {
              ratemin: {
                $lte: this.search.rate / (168 * 83.5),
              },
            },
            {
              ratemax: {
                $gte: this.search.rate / (168 * 83.5),
              },
            },
          ],
        });
      }

      if (this.search.experience && this.search.experience != "") {
        this.jobquerydata.serchval["$and"].push({
          $and: [
            {
              experiencemin: {
                $lte: this.search.experience,
              },
            },
            {
              experiencemax: {
                $gte: this.search.experience,
              },
            },
          ],
        });
      }

      // if (!this.search.rate || this.search.rate == "") {
      //   this.jobquerydata.serchval["$and"].splice(2, 1);
      // }
      // if (this.search.searchIn == "All") {
      //       }
      // if (this.search.searchIn == "All") {
      //       }

      this.getJobs(this.currentPage);
    }
    if (this.openstt == 2) {
      this.jobquerydata.serchval = {
        $and: [],
      };
      let anySkillConditions = [];

      if (this.jobsAnySkill.length > 0) {
        // jobDescription
        if (this.advanceSearch.searchIn == "1") {
          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              title: {
                $regex: s,
                $options: "i",
              },
            });
          });

          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              description: {
                $regex: s,
                $options: "i",
              },
            });
          });
        }

        if (this.advanceSearch.searchIn == "2") {
          this.jobsAnySkill.forEach((s) => {
            anySkillConditions.push({
              skill: {
                $regex: s,
                $options: "i",
              },
            });
          });
        }

        this.jobquerydata.serchval["$and"].push({
          $or: anySkillConditions,
        });
      }

      if (this.jobsAllSkill.length > 0) {
        let allSkillConditions = [];

        this.jobsAllSkill.forEach((s) => {
          allSkillConditions.push({
            skill: {
              $regex: s,
              $options: "i",
            },
          });
        });

        this.jobquerydata.serchval["$and"].push({
          $and: allSkillConditions,
        });
      }

      if (this.search.workFrom && this.search.workFrom != "") {
        this.jobquerydata.serchval["$and"].push({
          workFrom: {
            $regex: this.search.workFrom,
            $options: "i",
          },
        });
      }

      if (this.advanceSearch.location && this.search.location != "") {
        this.jobquerydata.serchval["$and"].push({
          "location.location": {
            $regex: this.advanceSearch.location,
            $options: "i",
          },
        });
      }

      // if (this.search.gender != "All") {
      //   this.jobquerydata.serchval["$and"].push({
      //     gender: this.search.gender,
      //   });
      // }

      if (this.search.deployment && this.search.deployment != "All") {
        this.jobquerydata.serchval["$and"].push({
          onsiteAvailability: this.search.deployment,
        });
      }

      if (this.search.Availability && this.search.Availability != "All") {
        this.jobquerydata.serchval["$and"].push({
          Availability: this.search.availability,
        });
      }

      if (this.search.rateMin && this.search.rateMax) {
        this.jobquerydata.serchval["$and"].push({
          $and: [
            {
              rate: {
                $lte: this.search.rateMax / (168 * 83.5),
              },
            },
            {
              rate: {
                $gte: this.search.rateMin / (168 * 83.5),
              },
            },
          ],
        });
      }

      if (this.search["experineceMax"] && this.search["experineceMin"]) {
        this.jobquerydata.serchval["$and"].push({
          $and: [
            {
              experience: {
                $lte: this.search["experineceMax"],
              },
            },
            {
              experience: {
                $gte: this.search["experineceMin"],
              },
            },
          ],
        });
      }

      // console.log(this.search["experineceMax"]);
      // console.log(this.jobquerydata);

      this.getResources(this.currentPage_job);
    }
  }

  parseAvailability(availability: any) {
    return availability.replace("_", " ");
  }

  getsltSkillsAll(val: any) {
    let srch = val.toLowerCase();
    this.templist = [];
    this.activeDropdown = "dropdown1";

    let no = val.search(",");
    if (no < 0) {
      this.cls1 = "displayblock";
      this.templist = this.skillListdata.filter(function (el: any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = "";
      }
    } else {
      if (this.jobsAllSkill.length < 15) {
        this.jobsAllSkill.push(val.replace(",", ""));
        this.search.allskill = "";

        this.cls1 = "";
        // this.addJobForm.get("skillsearch").setValue("");
      } else {
        this.cls1 = "";
        // this.addJobForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
  }

  getsltSkillsAny(val: any) {
    this.activeDropdown = "dropdown2";

    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls2 = "displayblock";
      this.templist = this.skillListdata.filter(function (el: any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls2 = "";
      }
    } else {
      if (this.jobsAnySkill.length < 15) {
        this.jobsAnySkill.push(val.replace(",", ""));
        this.cls2 = "";
        // this.addJobForm.get("skillsearch").setValue("");
      } else {
        this.cls2 = "";
        // this.addJobForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
    console.log("getsltSkillsAny ", this.templist);
  }

  // ====================== 17 ===============================
  //
  setskillvalueall(item: any) {
    console.log("setskillvalueall ", item);
    console.log(" this.search.allskill ", this.search.anyskill);

    if (this.jobsAllSkill.length < 15) {
      this.jobsAllSkill.push(item.trim());
      this.cls1 = "";
      // this.addJobForm.get("skillsearch").setValue("");
    } else {
      this.toaster.info("Select only 15 skill");
      this.cls1 = "";
      // this.addJobForm.get("skillsearch").setValue("");
    }
    this.search.allskill = "";
  }

  setskillvalueany(item: any) {
    console.log("setskillvalueany ", item);
    console.log(" this.search.anyskill ", this.search.anyskill);

    if (this.jobsAnySkill.length < 15) {
      this.jobsAnySkill.push(item.trim());
      this.cls2 = "";
      // this.addJobForm.get("skillsearch").setValue("");
    } else {
      this.toaster.info("Select only 15 skill");
      this.cls2 = "";
      // this.addJobForm.get("skillsearch").setValue("");
    }
    this.search.anyskill = "";
  }
  // ======================= 18 ==============================
  removeItem(inx: any) {
    this.slist.splice(inx, 1);
  }

  removeItemJobsAllSkill(index: any) {
    this.jobsAllSkill.splice(index, 1);
  }

  removeItemJobsAnySkill(index: any) {
    this.jobsAnySkill.splice(index, 1);
  }

  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.commonSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
        console.log("skillListdata: ", this.skillListdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  searchJobs() {}

  get_city(val: any) {
    if (val.length >= 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = { city_name: { $regex: "^" + val, $options: "i" } };
      this.commonSevice
        .getcity(JSON.stringify(filter))
        .then((res) => {
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
          console.log("cityName:==", this.citylist);
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
      this.locationlist = [];
    }
  }

  setvalue(val: any, cname: any, id: any) {
    this.cls = "";
    this.locationlist = [];
    this.locationlist.push({ locationId: id, location: val, country: cname });
    $("#locationId").val(val + " " + cname);
  }

  locationclear() {}

  othercityslt() {}

  // ==== customValidation =========
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 168 * 83.5).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  reSetJobsFilter(): void {
    this.search.workFrom = null;
    this.search.rate = "";
    this.search.experinece = "";
  }

  reSetHireOnConractFilters(): void {
    this.search.deployment = null;
    this.search.availability = null;
    this.search.rateMin = "";
    this.search.rateMax = "";
    this.search.experineceMin = "";
    this.search.experineceMax = "";
  }
}
