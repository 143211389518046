import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  template: `<re-captcha (resolved)="resolved($event)" siteKey="YOUR_SITE_KEY"></re-captcha>`,
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // custom validators
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  namePattern = "^[a-zA-Z ]+$";

  captchaResponse: string | null = null;

  addContact: any;
  addContactUsForm: any;
  submitted: boolean = false;


  constructor(
    private formBuild: FormBuilder, 
    private commonSevice: CommonService,
    private toastrService: ToastrService, 
    private route: ActivatedRoute,    
    private loader: LoadingScreenService, 
    private router: Router  
  ) { 
    this.createContactUsForm();
  } 

  ngOnInit(): void {
  }

  createContactUsForm() {
    this.addContactUsForm = this.formBuild.group({
      "name": ["", Validators.compose([Validators.required, Validators.pattern(this.namePattern), ]), ],
      "phone": ["", Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), ]),], 
      "email": ["", Validators.compose([Validators.required, Validators.email, Validators.pattern(this.emailPattern)])],
      "description": ['', [Validators.required, Validators.maxLength(100)]],
      "captcha": ['', Validators.required]
    });
  }

  get formCreateContactUs() {
    return this.addContactUsForm.controls;
  }

  onSubmit(): any {
    this.submitted = true; 
    for (const controlName of Object.keys(this.addContactUsForm.controls)) {
      const control = this.addContactUsForm.get(controlName);
    
      if (control.invalid) {
        console.log(`Control ${controlName} is not valid.`);
      }
    }

    console.log("contactFormData:", this.addContactUsForm.value);

    if (this.addContactUsForm.valid) {
      let clientData:any = this.addContactUsForm.value;
      // if (!this.id) {

        this.loader.startLoading();
        this.commonSevice.contactUsForm(clientData).then((response: any) => {
          this.loader.stopLoading();

          if (response.confirmation.toLowerCase() === "success") {
              this.loader.stopLoading();
              console.log("addResponse:", response);
             
              this.toastrService.success("We will contact soon!!");
              // this.route.navigate([""]);
            } else {
              this.toastrService.error("Failed to add message!");
            }
            
          },
          (error: any) => {
           
            this.toastrService.error("Failed to add message!"); 
          }
        );
      // } else {

     
      // }
    } else {
      console.log("Form data invalid");
     
    }
  }



  resolvedCaptcha(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    this.addContactUsForm.controls['captcha'].setValue(this.captchaResponse);
  }

  // function of customValidation
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // end of customValidationFunction

}
