<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Job Applicants</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<div class="container my-5">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card_Border">
        <div class="card-body">
          <!-- Tabs -->
          <ul class="nav nav-tabs horizontalList" role="tablist">
            <li class="" role="presentation" [ngClass]="{ active: tab[0] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(1, 0)">
                <i class="fa-solid fa-thumbs-up"></i>
                <!-- <i class='bx bxs-like bxIconSize'></i> -->
                <span> Applied ({{ total?.pending }}) </span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[1] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(2, 1)">
                <!-- <i class='bx bx-list-check bxIconSize'></i> -->
                <i class="fa-solid fa-clipboard-check"></i>
                <span> Shortlisted ({{ total?.short }}) </span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[2] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(3, 2)">
                <!-- <i class='bx bxs-check-circle bxIconSize'></i> -->
                <i class="fa-solid fa-circle-check"></i>
                <span> Hired ({{ total?.hired }}) </span>
              </a>
            </li>
            <li role="presentation" [ngClass]="{ active: tab[3] }">
              <a href="javascript:void(0)" class="py-2" (click)="getList(4, 3)">
                <!-- <i class='bx bxs-x-circle bxIconSize' ></i> -->
                <i class="fa-solid fa-circle-xmark"></i>
                <span class="pb-3 mb-3"> Rejected ({{ total?.reject }}) </span>
              </a>
            </li>
          </ul>

          <!-- tabContents -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active">
              <div *ngIf="jobapp?.length > 0" class="row my-3">
                <!-- Searching/Filters -->
                <div class="col-lg-8 col-sm-10 pt-2">
                  <form [formGroup]="searchForm" class="form-inline" action="/action_page.php">
                    <div class="row form-group">
                      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-4">
                        <input type="text" formControlName="namesearch" placeholder="Search by name or email " class="form-control" id="email" />
                      </div>
                      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-4">
                        <input type="text" placeholder="Select range of date" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true }" formControlName="rangedate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" />
                      </div>
                      <div class="col-sm-4 col-lg-4">
                        <button type="button" class="btn searchBtn me-4" (click)="filterjob()">Search</button>
                        <!-- *ngIf="openstt==2 || openstt ==3" -->
                        <!-- <button *ngIf="openstt == 2 || openstt == 3" type="button" class="btn btn-info" (click)="exportexcel()"><i class="bx bxs-file-export"></i> Export Excel</button> -->
                      </div>
                    </div>
                  </form>
                </div>
                <!-- pagination -->
                <div *ngIf="jobapp?.length > 0" class="col-lg-4 col-sm-2 pt-2 text-end">
                  <span class="txt">Page {{ querydata?.page }} of {{ jobapp?.length }}</span
                  >&nbsp;
                  <button type="button" [disabled]="querydata?.page == 1" class="btn paginationBTN" (click)="getlistbypage('prev')">
                    <i class="fa fa-angle-left"></i></button
                  >&nbsp;
                  <button type="button" [disabled]="querydata?.page == querydata?.totalpage" class="btn paginationBTN" (click)="getlistbypage('next')">
                    <i class="fa fa-angle-right"></i></button
                  >&nbsp;
                </div>
              </div>

              <ul class="listService ps-0">
                <li *ngFor="let list of jobapp; let i = index">
                  <div class="listWrpService featured-wrap">
                    <div class="row">
                      <div *ngIf="jobid == ''" class="col-lg-3 col-sm-3">
                        <!-- [routerLink]="['/business/jobother/my-job-view']" [queryParams]="{jobid:list?.jobId?._id}"  target="_blank" -->

                        <!-- [routerLink]="['/job-applicants']" [queryParams]="{ jobid: list.jobId?._id, tab: 1, userType: 3 }" -->

                        <!-- (click)="openJobDetails(list)" -->

                        <a  href="javascript:void(0)" class="title-head pointer text-dark" >
                          <strong>{{ list?.jobId?.title }}</strong> 
                        </a>

                        <br />

                        <span class="badge bg-warning text-dark mb-1">My Job</span>
                        <br />

                        <span class="mt-1">
                          JID : <span class="text-info">{{ list?.jobId?.resourceId }}</span>
                        </span>

                        <p class="para">
                          Required Skills :
                          <span *ngFor="let slist of list?.jobId?.skill">
                            <span *ngIf="i < 8">
                              <span class="skillsBG">{{ slist }}</span>
                            </span>
                          </span>
                        </p>
                      </div>

                      <div *ngIf="jobid == ''" class="col-lg-1 col-md-1 col-sm-1 col-xs-12 hidden-xs">
                        <i class="fa fa-long-arrow-right fa-2x text-info"></i>
                      </div>

                      <div *ngIf="jobid" class="col-lg-2 col-md-2 col-sm-3 col-xs-3">
                        <div *ngIf="list?.userType == 1" class="listImg">
                          <img *ngIf="list?.companyId?.logoImg != null; else other_content" src="{{ list?.companyId?.logoImg }}" alt="" />
                          <ng-template #other_content>
                            <span class="cname"> {{ list?.company?.cname }} </span>
                          </ng-template>
                        </div>
                        <div *ngIf="list?.userType == 2" class="listImg">
                          <span class="cname"> {{ "Freelancer" }} </span>
                        </div>
                      </div>

                      <div [ngClass]="{ 'col-lg-10 col-md-10 col-sm-9 col-xs-12': jobid, 'col-lg-8 col-md-8 col-sm-8 col-xs-12': jobid == '' }" class="">
                        <div class="row">
                          <div class="col-lg-7 col-sm-7">
                            <!-- [routerLink]="['/business/commonsystem/short-candidate']"
                                          [queryParams]="{ jobTitle : jobdata?.title, companyId : list?.company?._id ,candidateId:list?.candidateId?._id , jobId : jobid , userType : list?.userType ,  appId : list?._id }" -->
                            <div *ngIf="list?.userType == 1" class="title-head pointer">
                              RID : <span class="text-info">{{ list?.candidateId?.resourceId }}</span>
                            </div>
                            <div *ngIf="list?.userType == 1" class="c_name">
                              Company Name : <span class="text-info">{{ list?.company?.cname }} </span>
                              <span class="badge bg-info text-dark"> Business</span>
                            </div>

                            <!-- [routerLink]="['/business/commonsystem/short-candidate']"
                                          [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}"
                                          target="_blank" -->
                            <a href="javascript:void(0)" *ngIf="list?.userType == 2" class="title-head pointer">
                              {{ list?.candidateId?.name }}
                              <br />
                              <span class="badge bg-info text-dark">Freelancer</span>
                            </a>

                            <div *ngIf="list?.rate > 0">
                              <i class="fa fa-star rate-color"></i> Rate You : <b> {{ list?.rate }}</b>
                            </div>
                            <p class="mb-0">
                              <i class="fa-solid fa-location-dot"></i>
                              Location :
                              <span>
                                <span class="text-info">{{ list?.candidateId?.currentlocation }}</span>
                              </span>
                            </p>
                            <p class="mb-0">
                              Gender : <span class="text-info">{{ list?.candidateId?.gender }}</span>
                            </p>
                            <p class="mb-0">
                              Experience : <span class="text-info">{{ list?.candidateId?.experience }} Year</span>
                            </p>
                            <p class="para">
                              Key Skills :
                              <span *ngFor="let slist of list?.candidateId?.skill; let i = index">
                                <span *ngIf="i < 8">
                                  <span class="skillsBG">{{ slist }}</span>
                                </span>
                              </span>
                            </p>
                          </div>
                          <div class="col-lg-5 col-md-5">
                            <div class="row">
                              <div class="col-lg-12 col-sm-12">
                                <div class="contact-details">
                                  <div *ngIf="currencytype === 'USD'">Rate : <i class="fa fa-dollar"> </i> {{ list?.candidateId?.rate | currency: "Hourly" }} ({{ list?.candidateId?.ratetype }})</div>
                                  <div *ngIf="currencytype === 'INR'">Rate : <i class="fa fa-inr"> </i> {{ list?.candidateId?.rate | currency: "Hourly" }} ({{ list?.candidateId?.ratetype }})</div>
                                  <div *ngIf="list?.userType == 1 && (openstt == 2 || openstt == 3) && list?.upcheck?.package == 'live'">
                                    <div class="contact-mobile" *ngIf="list?.upcheck?.packageType == 3"><i class="fa fa-phone" aria-hidden="true"></i> : {{ list?.company?.mobile }}</div>
                                    <div class="contact-mobile" *ngIf="list?.upcheck?.packageType == 2">
                                      <i class="fa fa-phone" aria-hidden="true"></i> :

                                      <!-- [routerLink]="['/business/package/plan']" [queryParams]="{checkoutstt: '1'}" -->
                                      <a href="javascript:void(0)" class="btn btn-info btn-sm"> Upgrade Package</a>
                                    </div>
                                    <div class="contact-mobile"><i class="fa fa-envelope" aria-hidden="true"></i> : {{ list?.company?.email }}</div>
                                  </div>
                                  <div *ngIf="list?.userType == 2 && (openstt == 2 || openstt == 3) && list?.upcheck?.package == 'live'">
                                    <div class="contact-mobile"><i class="fa fa-phone" aria-hidden="true"></i> : {{ list?.candidateId?.phone }}</div>

                                    <div class="contact-mobile" *ngIf="list?.upcheck?.package == 'live'"><i class="fa fa-envelope" aria-hidden="true"></i> : {{ list?.candidateId?.email }}</div>
                                  </div>
                                  <div class="contact-email ps-2">
                                    <i class="fa-solid fa-calendar-days"></i>
                                    <span>
                                      Applied : <span class="text-info">{{ list?.createdAt | date: "MMM d, y" }}</span> </span
                                    ><br />
                                    <span>
                                      Rate : <span class="text-info">₹ {{ parseRate(list) }} (Monthly)</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12 text-end"> 
                        <div *ngIf="tab[0]">
                          <button *ngIf="list?.userType == 2" type="button" class="btn btn-sm btn-info" (click)="updateStatus(list?._id, 2, i, 'cv')" tooltip="Click to Shortlist" placement="top">Shortlist to View Contact</button>

                          <button *ngIf="list?.userType == 1" type="button" class="btn btn-sm btn-info" (click)="updateStatus(list?._id, 2, i, 'benchcv')" tooltip="Click to Shortlist" placement="top">Shortlist to View Contact
                          </button>&nbsp; 
                          
                          <button type="button" class="btn btn-sm btn-danger" (click)="updateStatus(list?._id, 4, i, '')" tooltip="Click to Reject" placement="top">Reject
                            
                          </button>&nbsp;

                          <!-- [routerLink]="['/business/commonsystem/short-candidate']"
                                        [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" target="_blank"  -->
                          <a [routerLink]="['/shortlisted-resources']"
                          [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" href="javascript:void(0)" *ngIf="openstt == 2 || openstt == 3" type="button" class="btn btn-sm btn-info">View Profile</a>&nbsp;

                          <!-- [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.candidateId?.loginId,utype:'freelancer'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 2 && (openstt == 2 || openstt == 3)" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a> -->

                          <!-- [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.company?._id,utype:'company'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 1 && (openstt == 2 || openstt == 3)" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat</a>&nbsp; -->
                        </div>

                        <div *ngIf="tab[1]">
                          <button type="button" class="btn btn-sm btn-success" (click)="updateStatus(list?._id, 3, i, '')" tooltip="Click to Hired, Earn OBM coin" placement="top">Hire</button>&nbsp; <button type="button" class="btn btn-sm btn-danger" (click)="updateStatus(list?._id, 4, i, '')" tooltip="Click to Reject" placement="top">Reject</button>&nbsp;

                          <!--  [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.candidateId?.loginId,utype:'freelancer'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 2" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a> -->

                          <!-- [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.company?._id,utype:'company'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 1" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat</a>&nbsp; -->

                          <!-- [routerLink]="['/business/commonsystem/short-candidate']"
                                        [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" target="_blank" -->
                          <a [routerLink]="['/shortlisted-resources']"
                          [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" href="javascript:void(0)" *ngIf="openstt == 2 || openstt == 3" href="javascript:void(0)" type="button" class="btn btn-sm btn-info" tooltip="Click to View Profile" placement="top">View Profile</a>
                        </div>

                        <div *ngIf="tab[2]">
                          <!-- href="/rate-us?jobappId={{list?._id}}" -->
                          <a href="javascript:void(0)" type="button" class="btn btn-sm btn-primary" target="_blank" tooltip="Click to Rate candidate" placement="top"> <i class="fa fa-star"></i> Rate me</a>&nbsp;

                          <!--  [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.candidateId?.loginId,utype:'freelancer'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 2" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a> -->

                          <!--  [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.company?._id,utype:'company'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 1" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a>&nbsp; -->

                          <!-- [routerLink]="['/business/commonsystem/short-candidate']"
                                        [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" target="_blank" -->
                          <a [routerLink]="['/shortlisted-resources']"
                          [queryParams]="{jobTitle:jobdata?.title,companyId:list?.company?._id,candidateId:list?.candidateId?._id,jobId:jobid,userType:list?.userType,appId:list?._id}" href="javascript:void(0)" *ngIf="openstt == 2 || openstt == 3" href="javascript:void(0)" class="btn btn-sm btn-info" tooltip="Click to View Profile" placement="top">View Profile</a>
                        </div>
                        <div *ngIf="tab[3]">
                          <!-- [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.candidateId?.loginId,utype:'freelancer'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 2" type="button" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a> -->

                          <!-- [routerLink]="['/business/commonsystem/chating']"
                                        [queryParams]="{userid:list?.company?._id,utype:'company'}" target="_blank" -->
                          <!-- <a href="javascript:void(0)" *ngIf="list?.userType == 1" type="button" class="btn btn-sm btn-primary" tooltip="Click to Start Chat" placement="top">Start Chat </a>&nbsp; -->
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li *ngIf="jobapp?.length === 0 && shimmer == false">
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-3 text-center">
                      <img class="mt-4" src="/assets/img/data_not_found.png" width="150px" alt="not found jobs applicant" />
                      <p class="ps-2">Data Not Found!</p>
                    </div>
                  </div>
                </li>
                <li *ngIf="shimmer">
                  <div class="shimmer">
                    <div class="shine"></div>
                    <div class="shine"></div>
                    <div class="shine"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

<!-- modals -->

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden('comment')" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">{{ rateobj.name }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal('comment')">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <span *ngFor="let list of ratearr; let i = index">
              <i *ngIf="list == true" class="fa fa-star fa-2x rate-color" (click)="rateUs(i, 1, i + 1)"></i>
              <i *ngIf="list == false" class="fa fa-star-o fa-2x rate-color" (click)="rateUs(i, 0, i + 1)"></i>
            </span>
          </div>
          <div class="col-sm-12" style="margin-top: 10px">
            <label> Comment</label>
            <textarea rows="3" class="form-control" [(ngModel)]="description"> </textarea>
          </div>
          <div *ngIf="ratestt" class="col-sm-12 text-end" style="margin-top: 10px">
            <button type="button" class="btn btn-sm btn-default" (click)="resetrate()">Clear</button>&nbsp;
            <button type="button" class="btn btn-sm btn-primary" (click)="addRate()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalShownpackage" [config]="{ show: true }" (onHidden)="onHidden('upgrade')" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Please Update your Package</h3>
        <div *ngIf="utype == 'cv'">CV Access balance is zero.</div>
        <div *ngIf="utype == 'benchcv'">Company contact view balance is zero.</div>
      </div>
      <div class="modal-footer">
        <!-- [routerLink]="['/business/package/plan']" [queryParams]="{checkoutstt: '1'}" -->
        <a href="javascript:void(0)" class="btn btn-blue"> Select Plan</a>
      </div>
    </div>
  </div>
</div>
