<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>My Account</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- <div class="post-job-area ptb-100"></div> -->
<div class="container my-5">

  <!-- PlanSection -->
  <div class="row mt-3 mx-5 mb-5 planSectionBorder py-3">
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
      <h5 class="text-gray">Expire On:
        <span class="date-font"> {{packagedata?.endDate | date: 'MMM d, y'}} </span>
      </h5>
      <!-- need to add a condition to check whether plan is expired or not -->
    </div>
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
      <h5 class="text-gray"> Plan Type :
        <span *ngIf="packagedata?.planType==1" class="badge bg-warning plan-font">
          Basic
        </span>
        <span *ngIf="packagedata?.planType==2" class="badge bg-info plan-font">
          Essential
        </span>
        <span *ngIf="packagedata?.planType==3" class="badge bgGreen plan-font">
          Premium
        </span>
        <span *ngIf="packagedata?.planType==4" class="badge bg-success plan-font">
          Customise
        </span>
        <span *ngIf="packagedata?.planType==5" class="badge bg-success plan-font"> 
          Enterprise
        </span>       
      </h5>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
      <button [routerLink]="['/pricing']" [queryParams]="{checkoutstt: '1'}" type="button" class="btn btn-info text-light">
         <strong>Upgrade Plan</strong> 
      </button>
    </div>
  </div>
 
  <!-- end of Plans -->

  <!-- ProfileSection -->
  <div class="row">
    <div class="col-md-3">
      <div class="card card-primary card-outline">
        <div class="card-body">
          <ul class="list-group list-group-unbordered">
            <li [ngClass]="tablist[0] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(1)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-clipboard fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Basic Info </span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[1] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(2)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-handshake-angle fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Services & Expertise</span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[2] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(3)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-image-portrait fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Portfolio</span>
                </div>
              </div>
            </li>
            <!-- <li [ngClass]="tablist[3] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(4)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-check-double fa-2x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class=""> Reviews</span>
                </div>
              </div>
            </li> -->
            <li [ngClass]="tablist[7] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="openPlansTab(8)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-people-arrows fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Manage Quota</span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[8] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="openPlansTab(9)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-file-invoice fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Invoice History</span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[4] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(5)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-clipboard-check fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Company Documents</span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[5] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(6)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-bell fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Notifications</span>
                </div>
              </div>
            </li>
            <li [ngClass]="tablist[6] ? 'background-pro' : ''" class="list-group-item profile-head pointer" (click)="opentab(7)">
              <div class="row">
                <div class="col-lg-2">
                  <i class="fa-solid fa-vault fa-1x"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font"> Change Password</span>
                </div>
              </div>
            </li>
            <!-- need to add resetLogin API inside this too -->
            <li (click)="logout()" class="list-group-item profile-head pointer bgMuted" >
              <div class="row ">
                <div class="col-lg-2">
                  <i class="fa-solid fa-power-off fa-1x text-danger"></i>
                </div>
                <div class="col-lg-10 pt-1">
                  <span class="tab-font text-danger"> Logout</span> 
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Tab_Content -->
    <div class="col-md-9">
      <div class="tab-content">
        <!-- Basic Information -->

        <div [ngClass]="{ active: tablist[0] == true }" class="tab-pane" id="settings">
          <div class="card">
            <div class="card-header block-heading">
              <div class="row text-center pt-3">
                <!-- <div class="col-lg-12 headingColor">
                                    <i class="fa-solid fa-clipboard fa-3x"></i>
                                    <span class="headingSize ps-2"> Basic Information </span>
                                </div> -->
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Basic Information</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <form [formGroup]="profileForm" class="form-horizontal">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="row form-group mb-3">
                      <div class="col-lg-12 mb-3">
                        <label>Email*</label>
                        <div class="name-text">{{ data?.email }}</div>
                      </div>
                      <div class="col-lg-12">
                        <label>Name, Company Name*</label>
                        <div class="name-text">{{ data?.name }} , {{ data?.cname }}</div>
                      </div>
                    </div>
                    <div class="row form-group mb-3">
                      <div class="col-lg-12">
                        <label>Website*</label>
                        <input type="text" class="form-control" placeholder="https://www.company.com/" formControlName="website" />
                      </div>
                    </div>
                    <div class="row form-group mb-3">
                      <div class="col-lg-6">
                        <label>Total Employee*</label>
                        <input type="text" class="form-control" placeholder="Size" formControlName="size" />
                      </div>
                      <div class="col-lg-6">
                        <label>Location (City Name)*</label>
                        <input type="text" class="form-control" placeholder="Location*" formControlName="location" />
                      </div>
                    </div>
                    <div class="row form-group mb-3">
                      <div class="col-lg-6">
                        <label>GST No</label>
                        <input type="text" class="form-control" placeholder="GST No" formControlName="gst" />
                      </div>
                      <div class="col-lg-6">
                        <label>Mode Of Contact*</label>
                        <ng-select [multiple]="false" formControlName="modeOfContact" placeholder="Select mode of contact">
                          <ng-option value="" [disabled]="true"> Select</ng-option>
                          <ng-option value="1">Mobile</ng-option>
                          <ng-option value="2">Email</ng-option>
                          <ng-option value="4">Only Chat</ng-option>
                          <ng-option value="3">All</ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <div class="row form-group mb-3">
                      <div class="col-lg-6">
                        <label>Phone*</label>
                        <input type="text" class="form-control" placeholder="phone*" maxlength="12" formControlName="phone" (keypress)="numberOnly($event)" />
                      </div>
                      <ng-container class="col-lg-6" formArrayName="altphone">
                        <div class="col-lg-6" *ngFor="let item of sellingPrice.controls; let priceIndex = index; let last = last" [formGroupName]="priceIndex">
                          <div [ngClass]="priceIndex > 0 ? 'padding-top-15' : ''">
                            <label class="label-color"
                              >Alt Phone No.<span *ngIf="priceIndex > 0"> {{ priceIndex + 1 }}</span></label
                            >
                            <input type="text" class="form-control" placeholder="Alt Phone No" formControlName="altphoneadd" (keypress)="numberOnly($event)" />
                            <div *ngIf="priceIndex == 0" class="image-plus" (click)="addItem()">
                              <!-- <img src="assets/images/icon/plus.png" /> -->
                            </div>
                            <div *ngIf="priceIndex > 0" class="trash" (click)="removeItem(priceIndex)">
                              <!-- <img src="assets/images/icon/trash.png" width="25" /> -->
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row form-group mb-3">
                      <div class="col-lg-12">
                        <label>Write about your Company</label>
                        <ckeditor [editor]="Editor" [config]="config" class="ck_height" formControlName="aboutagency"> </ckeditor>
                      </div>
                    </div>
                    <div class="row form-group mb-3 mt-5">
                      <div class="offset-lg-12 col-lg-12 text-end">
                        <button type="submit" class="btn btn-info" (click)="updateProfile('profile', 0, false)" [disabled]="!profileForm.valid">Save</button>&nbsp;
                        <button type="submit" class="btn btn-info" (click)="updateProfile('profile', 2, true)" [disabled]="!profileForm.valid">Save and Next</button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="upload-box text-center">
                      <div class="ngx-file-drop__content">
                        <input type="file" #getfileclick class="ngx-file-drop__file-input ng-star-inserted" accept=".png,.jpg,.jpeg" (change)="uploadfiles($event, 'logo')" />
                        <h4 class="ng-star-inserted">Company Logo</h4>
                        <label *ngIf="logourl == null" class="cursor-pointer ng-star-inserted" (click)="getfile('logo')">
                          <!-- <img alt="Attach" src="assets/images/icon/uploading.png" width="60" /> -->
                          <i class="fa-solid fa-cloud-arrow-up fa-2x"></i>
                        </label>
                        <label *ngIf="logourl" class="cursor-pointer ng-star-inserted">
                          <!-- <img alt="Attach" class="close-btn" src="assets/images/icon/close.png"
                                                (click)="resetimg('logo')" /> -->
                          <div class="text-end">
                            <i (click)="resetimg('logo')" class="fa-regular fa-circle-xmark text-danger"></i>
                            <img alt="Attach" src="{{ logourl }}" width="100%" />
                          </div>
                        </label>
                        <p *ngIf="logourl == null" class="ng-star-inserted"><label class="browse-label" (click)="getfile('logo')">Browse</label> and upload your logo.</p>
                        <p *ngIf="logourl == null" class="ng-star-inserted">Files support <b>images</b> (.jpg, .jpeg, .png), Size of <b>logo</b> can be up to<b> 1 MB</b>.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Services & Expertise -->
        <div class="tab-pane" [ngClass]="{ active: tablist[1] == true }">
          <div class="card">
            <div class="card-header block-heading">
             
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Services & Expertise</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <form [formGroup]="ServicesForm" class="form-horizontal">
                <div class="row form-group mb-3">
                  <div class="col-lg-6">
                    <label>Industry*</label>
                    <ng-select [multiple]="false" formControlName="companyType" placeholder="Select Industry">
                      <ng-option value="" [disabled]="true"> Select Industry </ng-option>
                      <ng-option *ngFor="let list of jobType" [value]="list?.name">
                        {{ list?.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-lg-6">
                    <label>Experience in this Industry (Years)*</label>
                    <input type="text" class="form-control" placeholder="Enter the experience in this industry" formControlName="experienced" (keypress)="numberOnly($event)" maxlength="2" />
                  </div>
                </div>
                <div class="row form-group mb-3">
                  <!-- <div class="col-lg-6">
                    <label>Language preference?*</label>
                    <div>
                      <span class="my-slt-li" *ngFor="let list of sltlanguagelist; let i = index">
                        <span class="slt-name">{{ list }} </span>
                        <span class="cls-close text-light" (click)="removearr(i, 1)">
                          <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                        </span>
                      </span>
                    </div>
                    <ng-select [multiple]="true" (change)="setvaluearr($any($event.target).value, 1)" placeholder="Select Language">
                      <ng-option value="" [disabled]="true"> Select Language </ng-option>
                      <ng-option *ngFor="let list of languagelist; let i = index">
                        {{ list }}
                      </ng-option>
                    </ng-select>

                   
                    <div>
                      <span class="my-slt-li" *ngFor="let list of sltlanguagelist;let i=index">
                        <span class="slt-name">{{list}} </span>
                        <span class="cls-close" (click)="removearr(i,1);">X </span>
                      </span>
                    </div>
                    <select class="form-control" (change)="setvaluearr($any($event.target).value,1)">
                      <option value=""> Select Language</option>
                      <option *ngFor="let list of languagelist;let i=index">
                        {{list}}
                      </option>
                    </select>
                  </div> -->
                  <div class="col-lg-6">
                    <label>Working Hours*</label>
                    <div class="row">
                      <div class="col-lg-6">
                        <ng-select [multiple]="false" formControlName="workinghours" placeholder="Select Time">
                          <ng-option value="" [disabled]="true"> Select Time </ng-option>
                          <ng-option *ngFor="let list of timelist" value="{{ list }}">
                            {{ list }}
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="col-lg-6">
                        <ng-select [multiple]="false" formControlName="workinghourslast" placeholder="Select Time">
                          <ng-option value="" [disabled]="true"> Select Time </ng-option>
                          <ng-option *ngFor="let list of timelist" value="{{ list }}">
                            {{ list }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row form-group mb-3">
                  <div class="col-lg-12">
                    <label>Services*</label>

                     <div>
                      <span class="my-slt-li" *ngFor="let list of sltservicelist; let i = index">
                        <span class="slt-name">{{ list }} </span>
                        <span class="cls-close" (click)="removearr(i, 2)">X </span>
                        <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                      </span>
                    </div>

                    <ng-select [multiple]="true" formControlName="solutionProvideService" (ngModelChange)="setvaluearr($any($event.target).value, 2)" [placeholder]="'Select Service'">
                      <ng-option  [value]="null" disabled> Select Service </ng-option>
                      <ng-option *ngFor="let list of servicelist; let i = index" [value]="list">
                        {{ list }}
                      </ng-option>
                    </ng-select> 

                    <div>
                      <span class="my-slt-li" *ngFor="let list of sltservicelist;let i=index">
                        <span class="slt-name">{{list}} </span>
                        <span class="cls-close" (click)="removearr(i,2);">X </span>
                      </span>
                    </div>
                    <select class="form-control" (change)="setvaluearr($any($event.target).value,2)">
                      <option value=""> Select Service</option>
                      <option *ngFor="let list of servicelist;let i=index">
                        {{list}}
                      </option>
                    </select>


                  </div>
                </div> -->

                <div class="row form-group mb-3">
                  <div class="col-lg-12">
                    <label>Technologies*</label>
                    <div>
                      <span class="my-slt-li" *ngFor="let list of slist; let i = index">
                        <span class="slt-name pt-1">{{ list }} </span>
                        <span class="cls-close text-light" (click)="removearr(i, 3)">
                          <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                        </span>
                      </span>
                    </div>
                    <div class="keyskill" *ngIf="slist?.length > 15">Add up to 15 skills. Remove skills by deleting tags.</div>
                    <div *ngIf="slist?.length <= 15">
                      <small class="text-danger" *ngIf="ServicesForm.get('skillsearch').maxLength && ServicesForm.get('skillsearch').touched"> *Characters not more than 100. </small>
                      <input #menu type="text" class="form-control" maxlength="100" placeholder="Write in Comma Separated or Select Technologies" formControlName="skillsearch" (keyup)="getsltSkills($any($event.target).value, 1)" />
                      <span *ngIf="loderstt2" class="city_loader2">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                      </span>
                      <div #menudrop id="myDropdown" class="dropdownContent_skills {{ cls1 }}">
                        <a *ngFor="let item of templist" (click)="setskillvalue(item?.name, 1)" class="salectchanged system">
                          {{ item.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row form-group mb-3">
                  <div class="offset-lg-12 col-lg-12 text-center">
                    <!-- [disabled]="!ServicesForm.valid" -->
                    <button type="submit" class="btn btn-info" (click)="updateProfile('service', 3, false)" >Save
                    </button>
                    &nbsp;
                    <!-- [disabled]="!ServicesForm.valid" -->
                    <button type="submit" class="btn btn-info"  
                    (click)="updateProfile('service', 3, true)">Save and Next</button>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Portfolio -->
        <div class="tab-pane" [ngClass]="{ active: tablist[2] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <!-- <img src="assets/images/icon/basic-info-min.png" width="35" /> Portfolio -->
              <!-- <div class="col-lg-12 headingColor">
                                <i class="fa-solid fa-image-portrait fa-3x"></i>
                                <span class="headingSize ps-2"> Portfolio </span>
                            </div> -->
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Portfolio</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <ng-container *ngIf="paddstt == false">
                <div class="add-btn">
                  <button type="button" class="btn btn-info" (click)="addform('project')">Add Portfolio</button>
                </div>
                <div class="list-portfolio">
                  <div class="row">
                    <div *ngFor="let list of data?.companyId?.projectList; let i = index" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
                      <div class="tool-menu my-2">
                        <span class="pointer editBTN me-1" (click)="patchport(list)"> Edit </span> |
                        <span class="pointer deleteBTN" (click)="deleteport(list._id, i)"> Delete</span>
                      </div>
                      <!-- [style.backgroundImage]="'url(' + list?.imageurl[0] + ')'" -->
                      <div class="portfolio-div2 text-center" >
                        <div class="port-action"></div>
                        <div class="port-title">
                          {{ list?.projectNamed }}
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3" style="padding-top: 38px">
                      <div class="portfolio-div text-center" (click)="addform('project')">
                        <div class="port-action"></div>
                        <div class="port-main">
                          <button type="button" class="btn btn-theme-default"><i class="fa fa-plus"></i> Add Project Item</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="paddstt">
                <div class="back-btn mb-2 mt-0">
                  <!-- <a class="pointer" (click)="addform('project')">
                                    <i  class="fa-solid fa-left-long fa-3x"></i>
                                </a> -->
                  <button type="button" class="btn btn-dark" (click)="addform('project')">
                    <i class="fa-solid fa-circle-chevron-left"></i>
                    Back
                  </button>
                </div>
                <form [formGroup]="portfolioForm">
                  <!-- <div class="row form-group mb-3">
                    <div class="col-lg-6">
                      <label class="control-label">Project Named* </label>
                      <input type="text" class="form-control" formControlName="projectNamed" maxlength="50" />
                    </div>
                    <div class="col-lg-6">
                      <label class="control-label">Timeline in Day* </label>
                      <input type="number" class="form-control" formControlName="timeline" maxlength="2" (keypress)="numberOnly($event)" />
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row form-group mb-3">
                        <div class="col-lg-12">
                          <label class="control-label">Website* </label>
                          <input type="text" class="form-control" formControlName="website" maxlength="100" placeholder="https://www.website.com" />
                        </div>
                      </div>
                      <div class="row form-group mb-3">
                        <div class="col-lg-12">
                          <label class="control-label">App link </label>
                          <input type="text" class="form-control" formControlName="applink" maxlength="100" />
                        </div>
                      </div>
                      <div class="row form-group mb-3">
                        <div class="col-lg-12">
                          <label>Skills*</label>
                          <div>
                            <span class="my-slt-li" *ngFor="let list of pslist; let i = index">
                              <span class="slt-name">{{ list }}</span>
                              <!-- <span class="cls-close" (click)="removearr(i,4);">X </span> -->
                              <span class="cls-close text-light" (click)="removearr(i, 4)">
                                <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                              </span>
                            </span>
                          </div>

                          <div class="keyskill" *ngIf="pslist?.length > 15">Add up to 15 skills. Remove skills by deleting tags.</div>
                          <div *ngIf="pslist?.length <= 15">
                            <small class="text-danger" *ngIf="portfolioForm.get('skillsearch').maxLength && portfolioForm.get('skillsearch').touched"> *Characters not more than 100. </small>
                            <input #menu type="text" class="form-control" maxlength="100" placeholder="write in comma separated or Select Skills" formControlName="skillsearch" (keyup)="getsltSkills($any($event.target).value, 2)" />
                            <span *ngIf="loderstt2" class="city_loader2">
                              <i class="fa fa-circle-o-notch fa-spin"></i>
                            </span>

                            <div #menudrop id="myDropdown" class="dropdownContentSkills_inPortfolio {{ cls1 }}">
                              <a *ngFor="let item of templist" (click)="setskillvalue(item?.name, 2)" class="salectchanged system">
                                {{ item.name }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row form-group mb-3">
                        <div class="col-lg-12">
                          <label class="control-label">Project Description* </label>
                          <div>
                            <ckeditor [editor]="Editor" [config]="config" class="ck_height" formControlName="aboutProject"> </ckeditor>
                            <small class="text-warning"> * characters not more than 2000 </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <label class="control-label"> Upload File* (Upload PDF file only, Max file size: 10MB) </label>
                      <div class="uploader pointer">
                        <input id="file-upload" #portfolioclick type="file" name="fileUpload" (change)="uploadfiles($event, 'portfolio')" />
                        <label *ngIf="portfolioimg == null" for="file-upload" id="file-drag">
                          <img id="file-image" src="#" alt="Preview" class="hidden" />
                          <div id="start">
                            <i class="fa-solid fa-download"></i>
                            <!-- <i class="fa fa-download" aria-hidden="true"></i> -->
                            <div>Select a file or drag here</div>
                            <div id="notimage" class="hidden">Please select an image</div>
                            <span id="file-upload-btn" class="btn btn-primary">Select a file</span>
                          </div>
                        </label>
                        <div *ngIf="portfolioimg != null" id="start">
                          <img alt="Attach" class="close-btn" src="assets/images/icon/close.png" (click)="resetimg('project')" />
                          <img alt="Attach" src="{{ portfolioimg }}" width="100%" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row">
                        <div *ngFor="let img of imageList; let i = index" class="col-sm-4" style="margin-top: 5px">
                          <div class="remove-btn">
                            <i class="fa fa-trash text-danger pointer" (click)="removeimg(i)"></i>
                          </div>
                          <div class="img-div">
                            <img src="{{ img }}" height="100%" width="100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group mb-3">
                    <div class="col-lg-12">
                      <!-- [disabled]="portfolioForm.invalid" -->
                      <button type="button" class="btn btn-info"  (click)="updateProfile('project', 0, false)">{{ projectbtnstt }}</button>&nbsp;
                      <button type="button" class="btn btn-warning" (click)="addform('project')">Cancel</button>
                    </div>
                  </div>
                </form>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- Reviews -->
        <!-- <div class="tab-pane" [ngClass]="{ active: tablist[3] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Reviews</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <ng-container *ngIf="reviewaddstt == false">
                <div class="add-btn">
                  <button type="button" class="btn btn-info" (click)="reviewstt()">Add Review</button>
                </div>
              </ng-container>
              <ng-container *ngIf="reviewaddstt == false">
                <div *ngIf="data?.companyId?.reviewlist?.length == 0" class="not-found mt-3">
                  <h3>No Reviews Found</h3>
                </div>
                <div class="list-review" *ngFor="let list of data?.companyId?.reviewlist">
                  <div class="row">
                    <div class="col-lg-2">
                      <img *ngIf="list?.ReviewType == 'Text'" src="{{ list?.reviewimage }}" height="100" />
                      <img *ngIf="list?.ReviewType == 'Video'" src="assets/images/icon/video.png" height="100" />
                      <img *ngIf="list?.ReviewType == 'Social'" src="assets/images/icon/social-network.png" height="100" />
                    </div>
                    <div class="col-lg-8">
                      <div class="ClientName">
                        {{ list?.ClientName }}
                      </div>
                      <div class="ClientDesignation">
                        {{ list?.ClientDesignation }}
                      </div>
                      <div class="clientSayAbout">
                        {{ list?.clientSayAbout }}
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <span *ngIf="list?.reviewstt == 0" class="label label-warning">Pending</span>
                      <span *ngIf="list?.reviewstt == 1" class="label label-success">Approved</span>
                      <span *ngIf="list?.reviewstt == 2" class="label label-danger">Hold</span>&nbsp;
                      <span class="label label-primary pointer" (click)="editreview(list)">Edit</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="reviewaddstt">
                <div class="back-btn">
                  <button type="button" class="btn btn-dark" (click)="addform('review')">
                    <i class="fa-solid fa-circle-chevron-left"></i>
                    Back
                  </button>
                </div>
                <form [formGroup]="reviewForm">
                  <div class="row form-group mb-2">
                    <div class="col-lg-12">
                      <label class="control-label"> Selected Review Type*</label>
                    </div>
                  </div>
                  <div class="row form-group mb-3">
                    <div class="col-lg-4">
                      <div class="radio radio-info radio-inline radioBG">
                        <input type="radio" class="form-check-input me-2" id="inlineRadio1" value="Text" formControlName="ReviewType" (change)="reviewchange()" />
                        <label for="inlineRadio1">
                          <span class="text-secondary ps-1"><strong> Text Review</strong></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="radio radio-info radio-inline radioBG">
                        <input type="radio" class="form-check-input me-2" id="inlineRadio2" value="Social" formControlName="ReviewType" (change)="reviewchange()" />
                        <label for="inlineRadio2">
                          <span class="text-secondary ps-1"><strong> Social Media Review</strong></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="radio radio-info radio-inline radioBG">
                        <input type="radio" class="form-check-input me-2" id="inlineRadio3" value="Video" formControlName="ReviewType" (change)="reviewchange()" />
                        <label for="inlineRadio3">
                          <span class="text-secondary ps-1"><strong> Video Review</strong></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row form-group mb-3">
                    <div class="col-lg-6">
                      <label>Client Name* </label>
                      <input type="text" class="form-control" formControlName="ClientName" maxlength="100" />
                    </div>
                    <div class="col-lg-6">
                      <label>Client Designation</label>
                      <input type="text" class="form-control" formControlName="ClientDesignation" maxlength="60" />
                    </div>
                  </div>

                  <div [ngClass]="ReviewType == 'Text' ? 'block' : 'none'" class="form-group row mb-3">
                    <div class="col-lg-6">
                      <label class="control-label">Client Say About* </label>
                      <textarea type="text" class="form-control" formControlName="clientSayAbout" rows="5"> </textarea>
                    </div>
                    <div class="col-lg-6 pt-3">
                      <label class="control-label"> Upload File* (Allowed formats: JPG, PNG. Maximum file size: 1MB) </label>
                      <div class="uploader pointer">
                        <input id="file-upload" #reviewclick type="file" name="fileUpload" (change)="uploadfiles($event, 'review')" />
                        <label *ngIf="reviewimg == null" for="file-upload" id="file-drag">
                          <img id="file-image" src="#" alt="Preview" class="hidden" />
                          <div id="start">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            <div>Select a file or drag here</div>
                            <div id="notimage" class="hidden">Please select an image</div>
                            <span id="file-upload-btn" class="btn btn-primary"> Select a file </span>
                          </div>
                        </label>
                        <div *ngIf="reviewimg != null" id="start">
                          <img alt="Attach" class="close-btn" src="assets/images/icon/close.png" (click)="resetimg('review')" />
                          <img alt="Attach" src="{{ reviewimg }}" width="100%" height="200" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div [ngClass]="ReviewType == 'Video' ? 'block' : 'none'" class="form-group row mb-3">
                    <div class="col-lg-12">
                      <label class="control-label">Video Links </label>
                      <input type="text" class="form-control" formControlName="VideoLinks" />
                    </div>
                  </div>
                  <div [ngClass]="ReviewType == 'Social' ? 'block' : 'none'" class="form-group row mb-3">
                    <div class="col-lg-12">
                      <label class="control-label">Social Media Links* </label>
                      <input type="text" class="form-control" formControlName="SocialMediaLinks" maxlength="100" />
                    </div>
                  </div>
                  <div class="row form-group my-2">
                    <div class="col-lg-6 py-3">
                      <button type="button" class="btn btn-info" [disabled]="reviewForm.invalid" (click)="updateProfile('review', 0, false)">{{ reviewbtn }}</button>
                    </div>
                  </div>
                </form>
              </ng-container>
            </div>
          </div>
        </div> -->
        <!-- Company Documents -->
        <div class="tab-pane" [ngClass]="{ active: tablist[4] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <!-- <img src="assets/images/icon/compliant.png" width="35" /> Company Documents -->
              <!-- <div class="col-lg-12 headingColor">
                                <i class="fa-solid fa-clipboard-check fa-3x"></i>
                                <span class="headingSize ps-2"> Company Documents </span>
                            </div> -->
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Company Documents</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <form [formGroup]="legalForm" class="form-horizontal">
                <div style="padding: 5px 20px">
                  <div class="row form-group mb-3">
                    <div class="col-lg-6">
                      <label class="label-color">Company Name*</label>
                      <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="agencyDirector" />
                    </div>
                    <div class="col-lg-6">
                      <label class="label-color">Company Incorporated On*</label>
                      <input type="text" class="form-control" placeholder="Select Company Incorporated Date" bsDatepicker formControlName="startagency" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" />
                    </div>
                  </div>
                  <div class="row form-group mb-3">
                    <div class="col-lg-6">
                      <div class="upload-box text-center">
                        <input type="file" #proofclick class="ngx-file-drop__file-input ng-star-inserted" accept=".png,.jpg,.jpeg,.pdf,.docx,.doc" (change)="uploadfiles($event, 'proof')" />
                        <div *ngIf="proofimg == null" class="ngx-file-drop__content">
                          <h4 class="ng-star-inserted">Certificate of Incorporation*</h4>
                          <label class="cursor-pointer ng-star-inserted" (click)="getfile('proof')">
                            <!-- <img alt="Attach" src="assets/images/icon/uploading.png" width="60" /> -->
                            <i class="fa-solid fa-cloud-arrow-up fa-2x"></i>
                          </label>
                          <p class="ng-star-inserted"><label class="browse-label" (click)="getfile('proof')">Browse</label> and upload documents.</p>
                          <p class="ng-star-inserted">Files support <b>images</b> (.jpg, .jpeg, .png, .pdf,.doc), Size of <b>each document</b> can be up to<b> 5MB</b>.</p>
                        </div>
                        <div *ngIf="proofimg != null">
                          <img alt="Attach" class="close-btn2 pointer" src="assets/images/icon/error.png" width="20" (click)="resetimg('proof')" />
                          <ng-container *ngIf="pfiletype == 'png' || pfiletype == 'jpeg' || pfiletype == 'jpg'">
                            <img src="{{ proofimg }}" />
                          </ng-container>
                          <ng-container *ngIf="pfiletype == 'pdf'">
                            <img src="assets/images/icon/pdf.png" />
                          </ng-container>
                          <ng-container *ngIf="pfiletype == 'doc' || pfiletype == 'docx'">
                            <img src="assets/images/icon/word.png" />
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="upload-box text-center">
                        <input type="file" #identityclick class="ngx-file-drop__file-input ng-star-inserted" (change)="uploadfiles($event, 'identity')" accept=".png,.jpg,.jpeg,.pdf,.docx,.doc" />
                        <div *ngIf="identityimg == null" class="ngx-file-drop__content">
                          <h4 class="ng-star-inserted">GST Certificate*</h4>
                          <label class="cursor-pointer ng-star-inserted" (click)="getfile('identity')">
                            <!-- <img alt="Attach" src="assets/images/icon/uploading.png" width="60" /> -->
                            <i class="fa-solid fa-cloud-arrow-up fa-2x"></i>
                          </label>
                          <p class="ng-star-inserted"><label class="browse-label" (click)="getfile('identity')">Browse</label> and upload your cin_number_file.</p>
                          <p class="ng-star-inserted">Files support <b>document</b> (.jpg, .jpeg, .png, .pdf), Size of <b>identity proof</b> can be up to<b> 2 MB</b>.</p>
                        </div>
                        <div *ngIf="identityimg != null">
                          <img alt="Attach" class="close-btn2 pointer" src="assets/images/icon/error.png" width="20" (click)="resetimg('identity')" />
                          <ng-container *ngIf="ifiletype == 'png' || ifiletype == 'jpeg' || ifiletype == 'jpg'">
                            <img src="{{ identityimg }}" />
                          </ng-container>
                          <ng-container *ngIf="ifiletype == 'pdf'">
                            <img src="assets/images/icon/pdf.png" />
                          </ng-container>
                          <ng-container *ngIf="ifiletype == 'doc' || ifiletype == 'docx'">
                            <img src="assets/images/icon/word.png" />
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group mb-3 mt-5">
                    <div class="offset-sm-12 col-sm-12 text-center">
                      <button type="submit" class="btn btn-info px-4" (click)="updateProfile('legal', 0, false)">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Notifications --> 
        <div class="tab-pane" [ngClass]="{ active: tablist[5] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <!-- <img src="assets/images/icon/basic-info-min.png" width="35" /> Notifications -->
              <!-- <div class="col-lg-12 headingColor">
                                <i class="fa-solid fa-bell fa-3x"></i>
                                <span class="headingSize ps-2"> Notifications </span>
                            </div> -->
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Notifications</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <div *ngFor="let list of notificationList" class="post clearfix">
                <div class="user-block">
                  <span class="btn-circle-name">
                    {{ list?.companyLoginId?.name[0] }}
                  </span>
                  <span class="username2">
                    <a href="javascript:void(0)" class="color-gray">{{ list?.companyLoginId?.name }}</a>
                  </span>
                  <span class="description">{{ list?.createdAt }}</span>
                </div>
                <p *ngIf="list?.ntype == 1" class="item-info">{{ list?.jobId?.title }} {{ list?.desc }}</p>
                <p *ngIf="list?.ntype == 2" class="item-info">{{ list?.candidateId?.name }} {{ list?.desc }}</p>
                <p *ngIf="list?.ntype == 3" class="item-info">{{ list?.freelancerId?.name }} {{ list?.desc }}</p>
              </div>
              <div *ngIf="cpagedata?.pages?.length > 0" class="paginateContainer paginateCase">
                <ul class="paginateList">
                  <li [ngClass]="{ disabled: cpagedata?.currentPage === 1 }" class="paginatePrevious paginateDisabled">
                    <a class="paginateAnchor" (click)="getpagedata(1)">«</a>
                  </li>
                  <li *ngFor="let list of cpagedata.pages" class="paginatePage paginateActive" style="display: inline-block">
                    <a class="paginateAnchor" [ngClass]="cpagedata?.currentPage == list ? 'activepage' : ''" (click)="getpagedata(list)">{{ list }}</a>
                  </li>
                  <li [ngClass]="{ disabled: cpagedata?.currentPage === cpagedata?.totalPages }" class="paginateNext">
                    <a class="paginateAnchor" (click)="getpagedata(cpagedata.totalPages)">»</a>
                  </li>
                </ul>
                <ul class="paginateShowAllList">
                  <li class="paginateShowAll"><a class="paginateAnchor">⇓</a></li>
                </ul>
              </div>
              <div *ngIf="notificationList?.length == 0" class="post clearfix">
                <div class="user-block">
                  <div>Not Found Notification</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Change Password -->
        <div class="tab-pane" [ngClass]="{ active: tablist[6] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <!-- <img src="assets/images/icon/basic-info-min.png" width="35" /> Reset Password -->
              <!-- <div class="col-lg-12 headingColor">
                                <i class="fa-solid fa-vault fa-3x"></i>
                                <span class="headingSize ps-2"> Reset Password </span>
                            </div> -->
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Change Password</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <br />
              <br />
              <form [formGroup]="pwdForm" class="form-horizontal">
                <div class="row form-group mb-3">
                  <div class="col-lg-3"></div>
                  <div class="col-sm-6">
                    <div class="form-group mb-3">  
                      <label>Password* </label>
                      <input type="text" class="form-control" placeholder="Enter Password" formControlName="password" />
                      <small class="text-danger" *ngIf="this.pwdForm.get('password').invalid && this.pwdForm.get('password').touched"> Password is required</small>
                    </div>
                    <div class="form-group mb-3">
                      <label>Confirm Password* </label>
                      <input type="text" class="form-control" placeholder="Confirm Password" formControlName="confirmpwd" />
                      <small class="text-danger" *ngIf="this.pwdForm.get('confirmpwd').invalid && this.pwdForm.get('confirmpwd').touched"> Confirm Password is required </small>
                    </div>
                    <div class="form-group mt-5 text-center">
                      <button type="submit" class="btn btn-info" (click)="updateProfile('password', 0, false)">Submit</button>
                    </div>
                  </div>
                  <div class="col-sm-3"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Manage Quota -->
        <div class="tab-pane" [ngClass]="{ active: tablist[7] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Manage Quota</h2> 
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-body profileSection px-4">
              <div class="row">
                <!-- Unlimited_JobPosting -->
                <div class="col-xs-6 col-sm-3 col-lg-4 ">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block">
                      <div class="row d-flex align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                          <h5 class="text-secondary mb-0">
                            Unlimited
                            <!-- {{packagedata?.countjobPost}}/{{packagedata?.jobPost}} -->
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary text-center">Job Posting</h6>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <div class="progress mb-0">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                              [ngStyle]="{'width': ((packagedata?.countjobPost/packagedata?.jobPost)*100)+'%' }">
                              <!-- {{parsenum((packagedata?.countjobPost/packagedata?.jobPost)*100)}}% -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Unlimited_benchUpload -->
                <div class="col-xs-6 col-sm-3 col-lg-4 ">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block">
                      <div class="row d-flex align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            Unlimited
                            <!-- {{packagedata?.countaddbench}}/{{packagedata?.addbench}} -->
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12 text-center">
                          <h6 class="text-secondary">Bench Upload</h6>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <div class="progress mb-0">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                              [ngStyle]="{'width':(packagedata?.countaddbench/packagedata?.addbench)*100 + '%'}">
                              <!-- {{ parsenum((packagedata?.countaddbench/packagedata?.addbench)*100)}}% -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- profileAccess -->
                <div class="col-xs-6 col-sm-3 col-lg-4 ">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            {{packagedata?.countresumeViewTotal}}/{{packagedata?.resumeViewTotal}}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary font-weight-500 text-center"> Profile Access </h6>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <div class="progress mb-0">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                              [ngStyle]="{'width': ((packagedata?.countresumeViewTotal/packagedata?.resumeViewTotal)*100)+'%' }">
                              <!-- {{parsenum((packagedata?.countresumeViewTotal/packagedata?.resumeViewTotal)*100)}} % -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             

                <!-- <div class="col-xs-6 col-sm-3 col-lg-4 ">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            {{packagedata?.counttotalmail}}/{{packagedata?.totalmail}}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary text-center">Bulk Emails</h6>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <div class="progress mb-0">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                              [ngStyle]="{'width': ((packagedata?.counttotalmail/packagedata?.totalmail)*100)+'%' }">
                              <span class="text-secondary">
                                {{parsenum((packagedata?.counttotalmail/packagedata?.totalmail)*100)}} %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
               
                <!-- <div class="col-xs-6 col-sm-3 col-lg-4">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            {{packagedata?.countvirtualTeam}}/{{packagedata?.virtualTeam}}
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary text-center">Virtual Team</h6>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <div class="progress mb-0">
                            <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                              [ngStyle]="{'width': ((packagedata?.countvirtualTeam/packagedata?.virtualTeam)*100)+'%' }">
                              {{parsenum((packagedata?.countvirtualTeam/packagedata?.virtualTeam)*100) }} %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                
              </div>

              <div class="row mb-4">

                   <!-- companyContactView -->
                   <div class="col-xs-6 col-sm-3 col-lg-6 ">
                    <div class="card update-card mb-3 p-2">
                      <div class="card-block">
                        <div class="row align-items-end">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h5 class="text-secondary mb-0 text-center">
                              {{packagedata?.countcompanyViewTotal}}/{{packagedata?.companyViewTotal}}</h5>
                          </div>
                          <div class="col-sm-12 col-md-12">
                            <h6 class="text-secondary text-center"> Company Contact View </h6>
                          </div>
                          <div class="col-sm-12 col-md-12">
                            <div class="progress mb-0">
                              <div class="progress-bar progress-bar-success progress-bar-striped py-2" role="progressbar"
                                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                                [ngStyle]="{'width': ((packagedata?.countcompanyViewTotal/packagedata?.companyViewTotal)*100)+'%' }">
                                <!-- {{parsenum((packagedata?.countcompanyViewTotal/packagedata?.companyViewTotal)*100)}} % -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- hiringSupport -->
                <div class="col-xs-6 col-sm-3 col-lg-6 pb-2">
                  <div class="card update-card mb-3 py-3">
                    <div class="card-block" style="height:80px;">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            <span *ngIf="packagedata?.support">
                              <i class="fa-regular fa-circle-check text-success"></i>
                            </span>
                            <span *ngIf="packagedata?.support == false">
                              <i class="fa-regular fa-circle-xmark text-danger"></i>
                            </span>
                          </h5>
                        </div>
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="text-secondary text-center pt-2 pb-4">
                            Enterpise Support
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- exportExcel -->
                
                <!-- <div class="col-xs-6 col-sm-3 col-lg-4">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block" style="height:80px;">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            <span *ngIf="packagedata?.exportexcel">
                              <i class="fa fa-check" style="color: #4fda4f;"></i>
                            </span>
                            <span *ngIf="packagedata?.exportexcel==false">
                              <i class="fa fa-close" style="color: #cc130d;"></i>
                            </span>
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary text-center">
                            Export Excel
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                
                <!-- campaign -->

                <!-- <div class="col-xs-6 col-sm-3 col-lg-4">
                  <div class="card update-card mb-3 p-2">
                    <div class="card-block" style="height:80px;">
                      <div class="row align-items-end">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <h5 class="text-secondary mb-0 text-center">
                            <span *ngIf="packagedata?.onlinebranding">
                              <i class="fa fa-check" style="color: #4fda4f;"></i>
                            </span>
                            <span *ngIf="packagedata?.onlinebranding==false">
                              <i class="fa fa-close" style="color: #cc130d;"></i>
                            </span>
                          </h5>
                        </div>
                        <div class="col-sm-12 col-md-12">
                          <h6 class="text-secondary text-center">
                            Campaign
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
          
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice History -->
        <div class="tab-pane" [ngClass]="{ active: tablist[8] == true }">
          <div class="card">
            <div class="card-header block-heading">
              <div class="row text-center pt-3">
                <div class="col-lg-12">
                  <div class="section-title">
                    <h2>Invoice History</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body profileSection px-4">
              <section *ngIf="invocestt==false" class="content not-print">
                <div class="panel panel-default onpanel">
                  <div class="panel-body">
                    <table *ngIf="PagefinalDetails?.length>0" class="table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Plan Type</th> 
                          <th>Coin</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of PagefinalDetails;let i=index">
                          <td> {{ ( 5*(cPage?.currentPage-1))+(i+1)}} </td>
                          <td>
                            <span *ngIf="list?.planType==1">Basic (Free)</span>
                            <span *ngIf="list?.planType==2">Essentials </span>
                            <span *ngIf="list?.planType==3">Premium</span>
                            <span *ngIf="list?.planType==4">Customize </span>
                            <span *ngIf="list?.planType==5">Enterprise </span>          
                          </td>
                          <td><span class="badge">{{list?.coin}}</span></td>
                          <td>{{list?.subamount}}</td>
                          <td>{{list?.createdAt | date : 'mediumDate'}}</td>
                          <td>
                            <span *ngIf="list?.paymentstt==1" class="label label-warning">Pending</span>
                            <span *ngIf="list?.paymentstt==2" class="label label-success">Success</span>
                            <span *ngIf="list?.paymentstt==3" class="label label-danger">Cancel</span>
                          </td>
                          <td>
                            <a [routerLink]="['/business/invoice/view']" [queryParams]="{invoiceid: list?._id}"
                              class="btn btn-blue btn-xs" *ngIf="list?.paymentstt==2"> Invoice </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div *ngIf="cpagedata?.pages?.length>0" class="paginateContainer paginateCase">
                      <ul class="paginateList">
                        <li [ngClass]="{disabled:cpagedata?.currentPage === 1}" class="paginatePrevious paginateDisabled">
                          <a class="paginateAnchor" (click)="setPage(1)">«</a>
                        </li>
                        <li *ngFor="let list of cpagedata.pages" class="paginatePage paginateActive"
                          style="display: inline-block;">
                          <a class="paginateAnchor" [ngClass]="cpagedata?.currentPage==list ?'activepage' : ''"
                            (click)="setPage(list)">{{list}}</a>
                        </li>
                        <li [ngClass]="{disabled:cpagedata?.currentPage === cpagedata?.totalPages}" class="paginateNext">
                          <a class="paginateAnchor" (click)="setPage(cpagedata.totalPages)">»</a>
                        </li>
                      </ul>
                      <ul class="paginateShowAllList">
                        <li class="paginateShowAll"><a class="paginateAnchor">⇓</a></li>
                      </ul>
                    </div>
                    <div *ngIf="shimmer">
                      <div class="shimmer">
                        <div class="shine"></div>
                        <div class="shine"></div>
                        <div class="shine"></div>
                      </div>
                    </div>
                    <div *ngIf="PagefinalDetails?.length==0 && shimmer==false" class="text-center">
                      <!-- <h5> Not Found Payment History</h5> -->
                      <div class="row d-flex justify-content-center">
                        <div class="col-lg-3 text-center">
                          <img class="mt-4" src="/assets/img/data_not_found.png" width="150px" alt="not found jobs applicant" />
                          <p class="ps-2">Data Not Found!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        
              <section *ngIf="invocestt" class="content">
                <div class="panel panel-default onpanel">
                  <div class="panel-body">
                    <div class="back-link" (click)="closeinvoice()"> <i class="fa fa-long-arrow-left"></i> Back to payment List
                    </div>
                    <div class="receipt-content">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="invoice-wrapper" id="printableArea">
                            <div class="payment-info">
                              <div class="row">
                                <div class="col-sm-12 text-center">
                                  <img src="https://www.onbenchmark.com/ssrsystem/images/cnlogo.png" />
                                </div>
                                <div class="col-sm-6">
                                  <span>Invoice No.</span>
                                  <strong>{{invoicedata?.invoiceno}}</strong>
                                </div>
                                <div class="col-sm-6 text-right">
                                  <span>Payment Date</span>
                                  <strong>{{invoicedata?.createdAt | date: 'MMM d, y, h:mm:ss a'}}</strong>
                                </div>
                              </div>
                            </div>
                            <div class="payment-details">
                              <div class="row">
                                <div class="col-sm-6">
                                  <span>Client</span>
                                  <strong>
                                    {{invoicedata?.cname}}
                                  </strong>
                                  <p>
                                    {{invoicedata?.address}}
                                  </p>
                                  GST NO. {{invoicedata?.gst}}
                                </div>
                                <div class="col-sm-6 text-right">
                                  <span>Payment To</span>
                                  <strong>
                                    Cosettenetwork Pvt Ltd
                                  </strong>
                                  <p>
                                    J-37, J-Extension, Near Jain Mandir, Laxmi Nagar, East Delhi, Delhi, 110092
                                    GST NO. 07AAGCC4671G1ZN
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="line-items">
                              <div class="headers clearfix"> 
                                <div class="row">
                                  <div class="col-xs-3">Description</div>
                                  <div class="col-xs-2">HSN Code</div>
                                  <div class="col-xs-2">SAC Code</div>
                                  <div class="col-xs-2">Qty</div>
                                  <div class="col-xs-3 text-right">Amount</div>
                                </div>
                              </div>
                              <div class="items">
                                <div class="row item">
                                  <div class="col-xs-3 desc">
                                    <span *ngIf="invoicedata?.planType==2">
                                      {{invoicedata?.month}} month Essentails Plan
                                    </span>
                                    <span *ngIf="invoicedata?.planType==3">
                                      {{invoicedata?.month}} month Premium Plan
                                    </span>
        
                                    <span *ngIf="invoicedata?.planType==4">
                                      {{invoicedata?.month}} month Enterprise Plan
                                    </span>     
                                  </div>
                                  <div class="col-xs-2 qty">
                                    9985
                                  </div>
                                  <div class="col-xs-2 qty">
                                    00440060
                                  </div>
                                  <div class="col-xs-2 qty">
                                    1
                                  </div>
                                  <div class="col-xs-3 amount text-right">
                                    {{invoicedata?.amount}}
                                  </div>
                                </div>
                              </div>
                              <div class="total text-right">
                                <p class="extra-notes">
                                  <strong>Extra Notes</strong>
                                  Please send all items at the same time to shipping address by next week.
                                  Thanks a lot.
                                </p>
                                <div class="field">
                                  Subtotal <span> {{invoicedata?.amount}}</span>
                                </div>
                                <div *ngIf="igststt" class="field">
                                  IGST(18%) <span> {{(invoicedata?.amount*18/100)}} </span>
                                </div>
                                <div *ngIf="igststt==false" class="field">
                                  SGST(9%) <span> {{(invoicedata?.amount*9/100)}} </span>
                                </div>
                                <div *ngIf="igststt==false" class="field">
                                  CGST(9%) <span> {{(invoicedata?.amount*9/100)}} </span>
                                </div>
                                <div *ngIf="invoicedata?.coin>0" class="field border-b">
                                  Offer <span> -{{(invoicedata?.coin)}}</span>
                                </div>
                                <div class="field grand-total">
                                  Total <span> {{invoicedata?.subamount}} </span>
                                </div>
                              </div>
                              <div class="print">
                                <a (click)="printDiv('printableArea')">
                                  <i class="fa fa-print"></i>
                                  Print this receipt
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->


<!-- MOdal -->
<!-- <div *ngIf="isModalShown" [config]="{ show: true ,backdrop: 'static'}" (onHidden)="onHidden()" bsModal
#autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">Payment Status</h4>
        <button *ngIf="msgtype!=1" type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="msgtype==1">
          Your payment is not completed. Please retry to Pay
        </p>
        <div *ngIf="msgtype==2" class="alert alert-warning m-b-0">
          <strong *ngIf="profiledata?.planType==1">
            Your Trial Has Expired!
          </strong>
          <strong *ngIf="profiledata?.planType==2">
            Your Essentials plan Has Expired!
          </strong>
          <strong *ngIf="profiledata?.planType==3">
            Your Primium plan Has Expired!
          </strong>
          <strong *ngIf="profiledata?.planType==5">
            Your Enterprise plan Has Expired!
          </strong>
          <br>
          <span>
            Got questions or need an extension? mail us info&#64;onbenchmark.com
          </span>
          
          <a [routerLink]="['/business/package/plan']" [queryParams]="{checkoutstt: '1'}" class="btn btn-orange">Upgrade Now</a>
        </div>
      </div>
      <div *ngIf="msgtype==1" class="modal-footer">
        <button [routerLink]="['/business/package/plan']" [queryParams]="{checkoutstt: '1'}" type="button" class="btn btn-green"> Choose Plan </button>
      </div>
    </div>
  </div>
</div> -->
