import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import * as Rx from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public jsonheader: any;
  apiUrl: any = "https://www.onbenchmark.com/";

  userdata: any = { data: { cname: "", fname: "" } };
  profiledata = new Subject();
  private loginstt = false;
  userDetails = new Rx.BehaviorSubject({});

  constructor(private http: HttpClient) {}

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`,
    });

    this.checklogin();
  }

  async checklogin(): Promise<any> {
    return this.http
      .post(
        `${this.apiUrl}auth/checklogin`,
        { token: localStorage.getItem("token") },
        {
          headers: this.jsonheader,
        },
      )
      .subscribe(
        (resp: any) => {
          this.userdata = resp;
          this.userDetails.next(resp.data);
        },
        (error) => {},
      );
  }

  registerBusiness(user: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/paymentcreateAccount`, user, {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      })
      .pipe(
        map((response: any) => {
          if (response && response.token) {
            localStorage.setItem("token", response.token);
          }
          if (response && response.role) {
            localStorage.setItem("role", response.user.role);
          }
          return response;
        }),
      );
  }

  registerExpert(user: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/freelancercreate`, user, {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      })
      .pipe(
        map((response: any) => {
          if (response && response.token) {
            localStorage.setItem("token", response.token);
          }
          if (response && response.role) {
            localStorage.setItem("role", response.user.role);
          }
          return response;
        }),
      );
  }

  uploadResume(file: any): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}auth/registerfreelancer_upload`,
        { image: file },
        {
          headers: new HttpHeaders({ "Content-Type": "application/json" }),
        },
      )
      .pipe(
        map((response: any) => {
          if (response && response.token) {
            localStorage.setItem("token", response.token);
          }
          if (response && response.role) {
            localStorage.setItem("role", response.user.role);
          }
          return response;
        }),
      );
  }

  login(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/login`, credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .pipe(
        map((response: any) => {
          if (response && response.token) {
            localStorage.setItem("token", response.token);
          }
          if (response && response.role) {
            localStorage.setItem("role", response.user.role);
          }
          this.initialize();
          return response;
        }),
      );
  }

  getTempToken(): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}auth/login`,
        { email: "sudatt@cosettenetwork.com", password: "9716308723" },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        },
      )
      .pipe(
        map((response: any) => {
          // if (response && response.token) {
          //   localStorage.setItem("token", response.token);
          // }
          // if (response && response.role) {
          //   localStorage.setItem("role", response.user.role);
          // }
          // this.initialize();
          return response;
        }),
      );
  }

  logoutFromAll(credentials: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}auth/logoutalldevice`, credentials, {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  logoutHomeFromAll(): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}auth/logoutalldevice`,
        { email: "sudatt@cosettenetwork.com", password: "9716308723" },
        {
          headers: new HttpHeaders({ "Content-Type": "application/json" }),
        },
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  // Sudatt's work
  getloginstt() {
    return this.loginstt;
  }
  getloginData() {
    return this.userdata;
  }

  async forgotPassword(data: any): Promise<any> {
    try {
      return await this.http
        .post(`${this.apiUrl}auth/forgotpassword`, data, {
          headers: this.jsonheader,
        })
        .toPromise();
    } catch (error) {
      console.error(`Error occurred while updating password:`, error);
      throw error;
    }
  }
}
