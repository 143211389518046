<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Resources Applications</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<div class="container my-5">
    <div class="row">
        <!-- <div *ngIf="userid" class="col-md-12">
            <h3>
                {{candidatedata?.name}} (RID : {{candidatedata?.resourceId}})
            </h3>
        </div> -->
        <div class="col-md-12">
            <div class="card card_Border">
                <div class="card-body">
                    <ul class="nav nav-tabs horizontalList" role="tablist">
                        <li role="presentation" [ngClass]="{'active' : tab[0]}">
                            <a href="javascript:void(0)" class="py-2" (click)="getList(1,0)">
                            <i class="fa-solid fa-thumbs-up"></i>
                            <span> Applied to ({{total?.pending}})</span>
                            </a>
                        </li>
                        <li role="presentation" [ngClass]="{'active' : tab[1]}">
                            <a href="javascript:void(0)" class="py-2" (click)="getList(2,1)">
                            <i class="fa-solid fa-clipboard-check"></i>
                            <span> Shortlisted ({{total?.short}})</span>
                            </a>
                        </li>
                        <li role="presentation" [ngClass]="{'active' : tab[2]}">
                            <a href="javascript:void(0)" class="py-2" (click)="getList(3,2)">
                            <i class="fa-solid fa-circle-check"></i>
                            <span> Hired ({{total?.hired}})</span>
                            </a>
                        </li>
                        <li role="presentation" [ngClass]="{'active' : tab[3]}">
                            <a href="javascript:void(0)" class="py-2" (click)="getList(4,3)">
                            <i class="fa-solid fa-circle-xmark"></i>
                            <span> Rejected ({{total?.reject}})</span>
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content">            
                        <div role="tabpanel" class="tab-pane active">
                            <div class="row">
                                <div *ngIf="candidateapp?.length>0" class="col-sm-9">
                                    <form [formGroup]="searchForm" class="form-inline" action="/action_page.php">
                                    <div class="row">
                                        <div class="form-group col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                        <input type="text" formControlName="namesearch" placeholder="Search by name or email "
                                            class="form-control" id="email">
                                        </div>
                                        <div class="form-group col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                        <input type="text" placeholder="Select range of date" class="form-control" bsDaterangepicker
                                            [bsConfig]="{ isAnimated: true }" formControlName="rangedate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                        </div>
                                        <div class="col-sm-2">
                                        <button type="button" class="btn btn-green" (click)="filtercandidate()"> Search</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                                <div *ngIf="candidateapp?.length>0" class="col-sm-3">
                                    <span class="txt">Page {{querydata?.page}} of {{querydata?.totalpage}}</span>&nbsp;
                                    <button type="button" [disabled]="querydata?.page==1" class="btn btn-gray"
                                    (click)="getlistbypage('prev')">
                                    <i class="fa fa-angle-left"></i>
                                    </button>&nbsp;
                                    <button type="button" [disabled]="querydata?.page==querydata?.totalpage" class="btn btn-gray"
                                    (click)="getlistbypage('next')">
                                    <i class="fa fa-angle-right"></i> 
                                    </button>&nbsp;
                                </div>
                            </div>

                            <ul class="listService"> 
                                <li *ngFor="let list of candidateapp;let i=index">
                                    <div class="listWrpService featured-wrap" style="padding: 15px 30px;">
                                    <div class="row">

                                        <div *ngIf="userid=='' && loginType==3" class="col-md-4 col-sm-4 col-xs-12">
                                        <a [routerLink]="['/business/candidateother/my-candidate-view']"
                                            [queryParams]="{userid:list?.candidateId?._id}" target="_blank" class="title-head pointer">
                                            {{list?.candidateId?.name}}
                                        </a>
                                        <br>
                                        <span class="label label-warning">My Bench</span>
                                        <span> RID : {{list?.candidateId?.resourceId}} </span>
                                        <p class="para">
                                            Key Skills :
                                            <span *ngFor="let slist of list?.candidateId?.skill"> {{slist}}</span>
                                        </p>
                                        </div>

                                        <div *ngIf="userid=='' && loginType==3 && tab[0]" class="col-md-2 col-sm-2 col-xs-12">
                                        Applied to
                                        <br>
                                        <i class="fa fa-long-arrow-right fa-2x text-info"></i>
                                        </div>

                                        <div *ngIf="userid=='' && loginType==3 && tab[1]" class="col-md-2 col-sm-2 col-xs-12">
                                        Shortlisted by
                                        <br>
                                        <i class="fa fa-long-arrow-right fa-2x text-info"></i>
                                        </div>

                                        <div *ngIf="userid" class="col-md-2 col-sm-3 col-xs-3">
                                        <div class="listImg">
                                            <img src="assets/images/company.png" alt="">
                                        </div>
                                        </div> 

                                        <div [ngClass]="{'col-md-10 col-sm-9 col-xs-12':userid, 'col-md-6 col-sm-6 col-xs-12': userid=='' && loginType==3,'col-md-12 col-sm-12 col-xs-12': userid=='' && loginType==4}"
                                        class="">
                                        <div class="row">
                                            <div class="col-md-7">
                                            <div class="title-head">
                                                Job Title : {{list?.jobId?.title}}
                                            </div>
                                            <div class="c_name" *ngIf="list?.companyLoginId?.loginType==3">
                                                <span *ngIf="tab[0]">Applied at:</span>
                                                <span *ngIf="tab[1]">Shortlisted By :</span>
                                                {{list?.companyLoginId?.cname}}
                                                <span class="label btn-green">Company</span>
                                            </div>
                                            <div class="c_name" *ngIf="list?.companyLoginId?.loginType==4">
                                                <span *ngIf="tab[0]">Applied at:</span>
                                                <span *ngIf="tab[1]">Shortlisted By :</span>
                                                {{list?.companyLoginId?.name}}
                                                <span class="label btn-green">Freelancer</span>
                                            </div>
                                            <p>
                                                <i class="fa fa-map-marker" aria-hidden="true"></i> Location :
                                                <span *ngFor="let loc of list?.jobId?.location">{{loc?.location}}, </span>
                                            </p>
                                            <p>
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                                Joining Date {{list?.jobId?.tentativeStartDate | date: 'MMM d, y'}} to
                                                {{list?.jobId?.tentativeEndDate | date: 'MMM d, y'}}
                                            </p>
                                            <p class="para">
                                                Key Skills :
                                                <span *ngFor="let slist of list?.jobId?.skill"> {{slist}}</span>
                                            </p>
                                            </div>
                                            <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                <div class="contact-details">
                                                    <div *ngIf="currencytype=='USD'">
                                                    Rate : <i class="fa fa-dollar"></i> 
                                                    {{list?.jobId?.ratemin | currency: 'Hourly'}} to {{list?.jobId?.ratemax | currency: 'Hourly'}}
                                                    ({{list?.jobId?.ratetype}})
                                                    </div>
                                                    <div *ngIf="currencytype=='INR'">
                                                    Rate : <i class="fa fa-inr"></i> {{list?.jobId?.ratemin | currency: 'Hourly'}} to {{list?.jobId?.ratemax | currency: 'Hourly'}}
                                                    ({{list?.jobId?.ratetype}})
                                                    </div>
                                                    <div> Total Resources : {{list?.jobId?.noofResources}} </div>
                                                    <div> Experience : {{list?.jobId?.experiencemin}} to {{list?.jobId?.experiencemax}}
                                                    Year </div>
                                                    <ng-container >
                                                    <div *ngIf="list?.companyLoginId?.phone" class="contact-mobile">
                                                        <i class="fa fa-phone" aria-hidden="true"></i> : {{list?.companyLoginId?.phone}}
                                                    </div>
                                                    <div *ngIf="list?.companyLoginId?.email" class="contact-mobile">
                                                        <i class="fa fa-envelope" aria-hidden="true"></i> :
                                                        {{list?.companyLoginId?.email}}
                                                    </div>
                                                    </ng-container>
                                                    <div class="contact-email">
                                                    <i aria-hidden="true" class="fa fa-calendar"></i> : Applied : {{list?.createdAt |
                                                    date: 'MMM d, y' }}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 text-right"> 
                                        <div *ngIf="tab[0]==true">
                                            <a type="button" class="btn btn-xs btn-primary"
                                            [routerLink]="['/business/commonsystem/chating']"
                                            [queryParams]="{userid:list?.companyLoginId?._id}" target="_blank">Start Chat
                                            </a>&nbsp;
                                            <a *ngIf="loginType==3" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,candidateId :userid,name :candidatedata?.name,rid:candidatedata?.resourceId}"
                                            target="_blank" class="btn btn-xs btn-info">View Job </a>
                                            <a *ngIf="loginType==4" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,loginType:4}" target="_blank"
                                            class="btn btn-xs btn-info">View Job </a>
                                        </div>
                                        <div *ngIf="tab[1]==true">
                                            <button *ngIf="loginType==3 && list?.contactviewby==0" class="btn btn-xs btn-info"  (click)="viewshortcontact(list?._id,i)" >
                                            View Contact 
                                            </button>&nbsp;
                                            <a class="btn btn-xs btn-primary" [routerLink]="['/business/commonsystem/chating']"
                                            [queryParams]="{userid:list?.companyLoginId?._id}" target="_blank">Start Chat
                                            </a>&nbsp;
                                            <a *ngIf="loginType==3" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,candidateId :userid,name :candidatedata?.name,rid:candidatedata?.resourceId}"
                                            target="_blank" class="btn btn-xs btn-info">View Job </a>
                                            <a *ngIf="loginType==4" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,loginType:4}" target="_blank"
                                            class="btn btn-xs btn-info">View Job </a>
                                        </div>
                                        <div *ngIf="tab[2]">
                                            <a *ngIf="loginType==4" class="btn btn-xs btn-primary" [routerLink]="['/business/commonsystem/chating']"
                                            [queryParams]="{userid:list?.companyLoginId?._id}" target="_blank">Start Chat
                                            </a>&nbsp;
                                            <a *ngIf="loginType==3" class="btn btn-xs btn-primary"
                                            [routerLink]="['/business/commonsystem/chating']"
                                            [queryParams]="{userid:list?.companyLoginId?._id}" target="_blank">Start Chat
                                            </a>&nbsp;
                                            <a *ngIf="loginType==3" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,candidateId :userid,name :candidatedata?.name,rid:candidatedata?.resourceId}"
                                            target="_blank" class="btn btn-xs btn-info">View Job </a>
                                            <a *ngIf="loginType==4" [routerLink]="['/business/jobother/job-applied-candidate']"
                                            [queryParams]="{jobid:list?.jobId?._id,loginType:4}" target="_blank"
                                            class="btn btn-xs btn-info">View Job </a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </li>
                                

                                <li *ngIf="candidateapp?.length === 0 && shimmer == false">
                                    <div class="row d-flex justify-content-center">
                                      <div class="col-lg-3 text-center">
                                        <img class="mt-4" src="/assets/img/data_not_found.png" width="150px" alt="not found jobs applicant" />
                                        <p class="ps-2">Data Not Found!</p>
                                      </div>
                                    </div>
                                </li>



                                <li *ngIf="shimmer">
                                    <div class="shimmer">
                                    <div class="shine"></div>
                                    <div class="shine"></div>
                                    <div class="shine"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->

<!-- modal -->
<!-- <div *ngIf="isModalShownpackage" [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden('upgrade')" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Please Update your Package</h3>
      </div>
      <div class="modal-footer">
        <a [routerLink]="['/business/package/plan']" [queryParams]="{ checkoutstt: '1' }" class="btn btn-blue"> Select Plan</a>
      </div>
    </div>
  </div>
</div> -->

<!-- 
<div *ngIf="isModalShownpackage" [config]="{ show: true ,backdrop: 'static'}" (onHidden)="onHidden('upgrade')" bsModal #autoShownModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
         <h3>Please Update your Package</h3>
      </div>
      <div class="modal-footer">
        <a  [routerLink]="['/business/package/plan']" [queryParams]="{checkoutstt: '1'}" class="btn btn-blue"> Select Plan</a>
      </div>
    </div>
  </div>
</div> -->
