import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpertDashboardService {

  apiUrl: any;
  baseurl = window.location.hostname.toLowerCase() == "localhost" ? "http://localhost:4500/" : "https://www.onbenchmark.com/";
  jsonheader: any;

  public globalprize = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { 
    this.apiUrl = this.authService.apiUrl;
    this.jsonheader = this.authService.jsonheader;
  }

  changeprizeglobal(prize:any) {
    this.globalprize.next(prize);
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, 
    });
  }


  async Find(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}api/${tbl}?filter=${data}`, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async update(ids: any, data: any, tbl: any): Promise<any> {
    try {
      return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }

 
  async updateEmail(data: any, tbl: any): Promise<any> {
    try {
      return this.http.post(`${this.apiUrl}auth/updateemail`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while processing data:", error);
      throw error;
    }
  }


  // async FindById(data: any, tbl: any): Promise<any> {
  //   try {
  //     return this.http.get(`${this.apiUrl}api/${tbl}/${data}`, { headers: this.authService.jsonheader }).toPromise();
  //   } catch (error) {
  //     console.error("Error occurred while finding data by id:", error);
  //     throw error;
  //   }
  // }

  async FindById(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise()
      .catch(error => {
        console.error('Error occurred while finding profile data by id:', error);
        throw error;
      });
  }





  // async FindById(data: any, tbl: any): Promise<any> {
  //   try {
  //     return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise();
  //   } catch (error) {
  //     console.error("Error occurred while finding data by id:", error);
  //     throw error;
  //   }
  // }


  async resendMail(data: any, tbl: any): Promise<any> {
    try {
      return this.http.post(`${this.apiUrl}auth/resendmail`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while processing data:", error);
      throw error;
    }
  }

  // ========================================================
  // =============== ExpertAppliedHistory ===================

  async FindCandidateById(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise()
      .catch(error => {
        console.error('Error occurred while finding profile data by id:', error);
        throw error;
      });
  }

  async updateCandidate(ids: any, data: any, tbl: any): Promise<any> {
    try {
      return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }

  async candidateApplication(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/candidateapplication/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      throw error;
    }
  }

  async getTotalCandidate(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/gettotalcandidate/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      throw error;
    }
  }

  async companySystem(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/companysystem/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      throw error;
    }
  }


  
  
  
  






}
