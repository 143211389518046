import { Component, ElementRef, OnInit, Renderer2, viewChild, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AuthService } from 'src/app/services/auth.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ProfileService } from 'src/app/services/profile.service';
import { CommonService } from 'src/app/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as $ from "jquery";




// @ViewChild('autoShownModal') autoShownModal!: ModalDirective;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit { 

  userdata: any = {};

  // ========= PaymentHistory============
  invocestt = false;
  ticketData: any;
  cPage_pH: any = { totalItems: 0, currentPage: 1, pageSize: 10 }; 
  cpagedata_pH: any = {};
  PagefinalDetails: any = [];
  liststt = true;
  shimmer = true;
  invoicedata: any = {};
  cnstate = "07";
  igststt = true;
  // ====================================

  @ViewChild('autoShownModal') autoShownModal!: ModalDirective;

  public Editor = ClassicEditor;
  config: any = {};

  profileForm: any;
  imagesForm: any;
  pwdForm: any;
  ServicesForm: any;
  portfolioForm: any;
  reviewForm: any;
  legalForm: any;

  data: any = {};
  fileupload:any;
  citylist: any = [];
  loderstt = false;
  cls:any = "";
  loginId:any = "";
  companyId:any = "";
  tablist:any = [true, false, false, false, false, false, false];
  notificationList: any = [];
  jobType: any = [];
  timelist: any = [];
  @ViewChild('myInput') myInputVariable!: ElementRef;
  modules: any = {};
  servicelist:any = ["Online Mentorship", "Gamified Learning", "Online Tutor Booking", "Mock Tests & Grading", "Corporate Training", "School/College Management",
    "Vendor Courses Market", "PlaceAffiliate Courses Marketplace", "Remote Proctored Exams", "School/College Reviews"];
  sltservicelist:any = [];
  languagelist:any = ["Hindi", "English"];
  sltlanguagelist:any = [];
  selectedItems: any = [];
  skillListdata: any = [];
  paddstt = false;
  
  projectList: any = [];
  fileuploadImage: any;
  imageList: any = [];
  projectbtnstt = "Save"; 
  reviewaddstt = false;  
  logourl = null;
  docdata: any = {};
  projectId :any = "";
  cPage: any = { totalItems: 0, currentPage: 1, pageSize: 5 };
  cpagedata: any = {};
  filter :any = { limit: 5, page: 1 };
  slist: any = [];
  pslist: any = [];
  @ViewChild('getfileclick') getfileclick!: ElementRef;
  @ViewChild('portfolioclick') portfolioclick!: ElementRef;
  @ViewChild('proofclick') proofclick!: ElementRef;
  @ViewChild('identityclick') identityclick!: ElementRef;
  @ViewChild('reviewclick') reviewclick!: ElementRef;
  cls1 :any= '';
  editstt = false;
  templist: any = [];
  proofimg:any = null;
  identityimg:any = null;
  ifiletype:any = "";
  pfiletype:any = "";
  loderstt2=false;
  configck:any={};

  // ===========
  isModalShown = false;
  packagedata:any={};
  msgtype =1; 
  profiledata:any={};


  constructor(
    private formBuild: FormBuilder,
    private profileService: ProfileService,
    private authService: AuthService,
    private auth: AuthService, 
    private commonService: CommonService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private router: Router,     
    private renderer: Renderer2

  ) {
   
    this.activatedRoute.queryParams.subscribe(params => {
      if (isNaN(params['tab']) == false) {
        this.tabopen(params['tab'] - 1);
      }
    });
  }

  ngOnInit(): void {
    this.getSkill();
    this.profileFormCreate();
    this.legalFormCreate();
    this.portfolioFormCreate();
    this.reviewFormCreate();
    this.servicesFormCreate();
    this.imagesFormCreate();
    this.passwordFormCreate();
    this.getProfile();
    this.getnotification();
    this.getJobType();
    this.windowload();
    this.progressBar(20, 100);
    // =========================
    this.getPlan();

    let obj = this.auth.getloginData();
    if (Object.keys(obj).length > 0) {
      this.profiledata = this.auth.getloginData();
      if (this.profiledata.expirestt==1) {
        this.msgtype = 2 ;
        this.showModal();
      }      
    }

    this.auth.profiledata.subscribe((res: any) => {
      this.profiledata = res; 
      if (this.profiledata.expirestt==1) {
        this.msgtype = 2 ;
        this.showModal();
      }
    });

    this.getTicket();
   
  }

  // ===== Plans ==========

  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.autoShownModal.hide();
  }
  onHidden(): void {
    this.isModalShown = false; 
  }


  getPlan() {
    this.loader.startLoading(); 
    this.profileService.findPlan(JSON.stringify({}), 'packageuser').then((res: any) => {
      this.packagedata = res.data;
      console.log("this.packagedata:-- ", res.data);
      this.loader.stopLoading();
      if ( this.packagedata.paymentstt == 1 || this.packagedata.paymentstt == 3) {
          this.msgtype = 1;
          this.showModal();
       }
    }, err => {
      this.loader.stopLoading();
    });
  }

  parsenum(val:any){
    return parseInt(val);
  }

  // end of Plans ==========



  logout(): void {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigateByUrl("/");
 
  }

  // logout() {
  //   console.log("logout");
  //   localStorage.removeItem('token');

  //   const userEmail = localStorage.getItem('email');
  //   const userPasword = localStorage.getItem('password');

  //   this.authService.logoutFromAll({ email: userEmail, password: userPasword }).subscribe((res) => {
  //         localStorage.clear();
  //       },
  //       (error) => {
  //         localStorage.clear();
      
  //       },
  //     );
  // }




  // ============ 1 =============
  profileFormCreate() {
    this.profileForm = this.formBuild.group({
      "name": ['', Validators.required],
      "email":['', Validators.required],
      "cname": ['', Validators.required],
      "phone": ['', Validators.required],
      "location": ['', Validators.required],
      "locationId": [''],
      "size": ['', Validators.required],
      "gst":  [''],
      "website": ['', Validators.required],
      "aboutagency" :  [''],
      "modeOfContact": ['', Validators.required],
      "altphone": this.formBuild.array([this.formBuild.group({ "altphoneadd": ['', Validators.maxLength(12)] })])
    });
  }
  // ============ 2 =============
  get sellingPrice() {
    return this.profileForm.get('altphone') as FormArray;
  }
  // ============ 3 =============
  addItem() {
    this.sellingPrice.push(this.formBuild.group({ altphoneadd: new FormControl('', Validators.maxLength(12)) }));
  }
  // ============ 4 =============
  removeItem(inx:any) {
    this.sellingPrice.removeAt(inx);
  }
  // ============ 5 =============
  servicesFormCreate() {
    this.ServicesForm = this.formBuild.group({
      companyType: ['', Validators.required],
      experienced: ['', Validators.required],
      solutionProvideService: ['', Validators.required],
      skillsearch: ['', [Validators.maxLength(100)]],
      skills:  [''],
      language: ["English"],
      workinghours: ['', Validators.required],
      workinghourslast: ['', Validators.required] 
    });
  }
  // ============ 6 =============
  portfolioFormCreate() {
    this.portfolioForm = this.formBuild.group({
      "projectNamed": ['', Validators.required],
      "timeline": ['', Validators.required],
      "aboutProject":['', Validators.required],
      "website": ['', Validators.required],
      "applink": [''],
      "skills": ['', Validators.required],
      "skillsearch": ['', [Validators.maxLength(100)]],
      "imageurl": ['', Validators.required],
      "savestt":  ['']
    });
  }

  get formCreatePortfolio() {
    return this.portfolioForm.controls;
  }



  // ============ 7 =============
  reviewFormCreate() {
    this.reviewForm = this.formBuild.group({
      "ReviewType": ['', Validators.required],
      "ClientName": ['', Validators.required],
      "ClientDesignation": ['', []],
      "clientSayAbout": ['', []],
      "VideoLinks":['', []],
      "SocialMediaLinks": ['', []],
      "reviewimage": ['', []]
    });
  }
  // ============ 8 =============
  legalFormCreate() {
    this.legalForm = this.formBuild.group({
      "startagency": ['', Validators.required],
      "proofofRegistration": ['', Validators.required],
      "agencyDirector": ['', Validators.required],
      "proofofIdentity": ['', Validators.required]
    }); 
  }
  // ============ 9 =============
  imagesFormCreate() {
    this.imagesForm = this.formBuild.group({
      "logoImg": ['', Validators.required]
    });
  }
  // ============ 10 =============
  passwordFormCreate() {
    this.pwdForm = this.formBuild.group({
      password: ['', Validators.required],
      confirmpwd: ['', Validators.required]
    }); 
  }
  // ============ 11 =============
  getProfile() {
    let data = {}
    this.loader.startLoading();
    this.profileService.profileFind('/user/profile/profile').then((res: any) => {
      this.data = res.data;
      this.loginId = this.data._id;
      this.companyId = this.data.companyId._id;
      this.loader.stopLoading();
      this.profileForm.patchValue({
        name: this.data.name,
        email: this.data.email,
        cname: this.data.cname,
        phone: this.data.phone,
        companyType: this.data.companyId.companyType,
        aboutagency : this.data.companyId.aboutagency,
        location: this.data.location,
        locationId: this.data.locationId,
        size: this.data.companyId.size,
        gst: this.data.companyId.gst,
        modeOfContact: this.data.modeOfContact,
        website: this.data.companyId.website
      });
      // this.imagesForm.patchValue({
      this.logourl = this.data.companyId.logoImg;
      //});
      let altphone = this.data.companyId.altphone;
      this.sellingPrice.removeAt(0);
      
      if(Array.isArray(this.data.companyId.altphone)){
        for (let i = 0; i < altphone.length; i++) {
          this.profileForm.controls['altphone'].push(this.formBuild.group({
            altphoneadd: new FormControl(altphone[i])
          }));
        }
      }
      this.ServicesForm.patchValue({
        companyType: this.data.companyId.companyType,
        experienced: this.data.companyId.experienced,
        solutionProvideService: this.data.companyId.solutionProvideService,
        skills: this.data.companyId.skills,
        language: this.data.companyId.language
      });
      this.sltlanguagelist = this.data.companyId.language;
      // need to add static List of service-list
      this.sltservicelist = this.data.companyId.solutionProvideService;
      this.slist = this.data.companyId.skills;

      if (this.data.companyId.workinghours) {
        const timeval = this.data.companyId.workinghours.split("=");
        this.ServicesForm.get("workinghours").setValue(timeval[0]);
        this.ServicesForm.get("workinghourslast").setValue(timeval[1]);
      }
      this.legalForm.patchValue({
        startagency: this.data.companyId.startagency,
        agencyDirector: this.data.companyId.agencyDirector,
        proofofRegistration: this.data.companyId.proofofRegistration,
        proofofIdentity: this.data.companyId.proofofIdentity
      });
      if (this.data.companyId.proofofRegistration) {
        this.proofimg = this.data.companyId.proofofRegistration;
        const arr2 = this.proofimg.split(".");
        this.pfiletype = arr2[(arr2.length - 1)];
      }
      if (this.data.companyId.proofofIdentity) {
        this.identityimg = this.data.companyId.proofofIdentity;
        const arr2 = this.identityimg.split(".");
        this.ifiletype = arr2[(arr2.length - 1)];
      }
      this.loader.stopLoading();
    }, err => {
      this.loader.stopLoading();
      console.log(err);
    });
  }
  // ============ 12 =============
  get_city(val:any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = { $and: [{ "city_name": { $regex: val, "$options": "i" } }, { "country_id": "5c976ba641e3f849a36370aa" }] }
      this.commonService.getcity(JSON.stringify(filter)).then(res => {
        console.log("res", res.data);
        this.loderstt = false;
        this.cls = 'displayblock';
        this.citylist = res.data;
      }).catch(err => {
        this.loderstt = false;
      });
    } else {
      this.cls = '';
    }
  }
  // ============ 13 =============
  setvalue(val:any, id:any) {
    this.cls = '';
    console.log('location', val);
    this.profileForm.get('location').setValue(val);
    this.profileForm.get('locationId').setValue(id);
  }

  portfolioimg:any = null;
  reviewimg:any = null;
  reviewbtn:any = "Save";

  // ============ 14 =============
  updateProfile(val:any, inx:any, nextstt:any):any {

    if (val == 'profile') {
      let data = {
        phone: this.profileForm.value.phone,
        location: this.profileForm.value.location,
        modeOfContact: this.profileForm.value.modeOfContact,
        locationId: this.profileForm.value.locationId
      };

      let data2: any = {
        mobile: this.profileForm.value.phone,
        size: this.profileForm.value.size,
        gst: this.profileForm.value.gst,
        aboutagency : this.profileForm.value.aboutagency,
        website: this.profileForm.value.website,
        companyType: this.profileForm.value.companyType,
        altphone: []
      };

      const altphone = this.profileForm.get('altphone').value;
      if (altphone.length > 0) {
        for (let i = 0; i < altphone.length; i++) {
          data2.altphone.push(altphone[i]["altphoneadd"]);
        }
      }

      const actiobj = {
        companyLoginId: this.loginId,
        desc: "updated profile"
      };

      this.loader.startLoading();

      this.ServicesForm.get('language')?.setValue('English');

      this.profileService.update(this.loginId, data, 'user').then((res: any) => {
        this.profileService.update(this.companyId, data2, 'company').then((res: any) => {
          this.profileService.create(actiobj, 'activity').then((res: any) => {
            this.toaster.success('Profile Updated Successfully');
            if (nextstt == true) {
              this.opentab(inx)
            }
            this.loader.stopLoading();
          }, err => {
            this.loader.stopLoading();
            this.toaster.error('Please try after some time');
          });
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        });
      }, err => {
        this.loader.stopLoading();
        this.toaster.error('Please try after some time');
      });

    } else if (val == 'service') {

      if (this.slist.length == 0) {
        this.toaster.error("Please select skill");
        return false;
      }
      if (this.slist.sltlanguagelist == 0) {
        this.toaster.error("Please select language");
        return false;
      }
      if (this.slist.sltservicelist == 0) {
        this.toaster.error("Please select service");
        return false;
      }

      let data = {
        companyType: this.ServicesForm.value.companyType,
        solutionProvideService: this.sltservicelist,
        skills: this.slist,
        language: this.sltlanguagelist,
        experienced: this.ServicesForm.value.experienced,
        workinghours: ""
      };
      data.workinghours = this.ServicesForm.value.workinghours + "=" + this.ServicesForm.value.workinghourslast
      const actiobj = {
        companyLoginId: this.loginId,
        desc: "updated Services & Preferences"
      };
      this.loader.startLoading();

      // Setting the default values here as empty string because we have removed thes fields from Form
      this.portfolioForm.patchValue({
        projectNamed: '', 
        timeline: ''
      });


      this.profileService.update(this.companyId, data, 'company').then((res: any) => {
        this.profileService.create(actiobj, 'activity').then((res: any) => {
          this.toaster.success('Services & Preferences Updated Successfully');
          if (nextstt == true) {
            this.opentab(inx)
          }
          this.loader.stopLoading();
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        });
      }, err => {
        this.loader.stopLoading();
        this.toaster.error('Please try after some time');
      });

    } else if (val == 'project') {
      let data: any = {};
      if (this.projectbtnstt == "Save") {
        if (this.pslist.length==0) {
          this.toaster.error("Please select skills");
          return false;
        }
        data = {
          "$push": {
            "projectList": {
              projectNamed: this.portfolioForm.value.projectNamed,
              timeline: this.portfolioForm.value.timeline,
              aboutProject: this.portfolioForm.value.aboutProject,
              website: this.portfolioForm.value.website,
              applink: this.portfolioForm.value.applink,
              skills: this.pslist,
              imageurl: this.portfolioimg,
              savestt: this.portfolioForm.value.savestt
            }
          }
        };
        const actiobj = {
          companyLoginId: this.loginId,
          desc: "add project"
        };
        this.loader.startLoading();
        this.profileService.update(this.companyId, data, 'company').then((res: any) => {
          this.profileService.create(actiobj, 'activity').then((res: any) => {
            this.toaster.success('Project added Successfully');
            this.pslist=[];
            this.getProfile();
            if (nextstt == true) {
              this.opentab(inx)
            }
            this.paddstt = false;
            this.loader.stopLoading();
          }, err => {
            this.loader.stopLoading();
            this.toaster.error('Please try after some time');
          })
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        })
      } else {
        data = {
          query: {
            "$set": {
              'projectList.$.projectNamed': this.portfolioForm.value.projectNamed,
              'projectList.$.timeline': this.portfolioForm.value.timeline,
              'projectList.$.aboutProject': this.portfolioForm.value.aboutProject,
              'projectList.$.website': this.portfolioForm.value.website,
              'projectList.$.applink': this.portfolioForm.value.applink,
              'projectList.$.skills': this.portfolioForm.value.skills,
              'projectList.$.imageurl': this.portfolioimg
            }
          }
        };
        data.companyId = this.companyId;
        data.projectId = this.projectId;
        data.updateType = "project";
        const actiobj = {
          companyLoginId: this.loginId,
          desc: "add project"
        };
        this.loader.startLoading();
        this.profileService.companySystem(data, 'company/subput').then((res: any) => {
          this.profileService.create(actiobj, 'activity').then((res: any) => {
            this.toaster.success('Project Updated Successfully');
            if (nextstt == true) {
              this.opentab(inx)
            }
            this.paddstt = false;
            this.loader.stopLoading();
            this.getProfile();
          }, err => {
            this.loader.stopLoading();
            this.toaster.error('Please try after some time');
          })
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        })
      }
    } else if (val == 'review') {
      let data: any = {};
      if (this.reviewbtn == "Save") {
        data = {
          "$push": {
            "reviewlist": {
              ReviewType: this.reviewForm.value.ReviewType,
              ClientName: this.reviewForm.value.ClientName,
              ClientDesignation: this.reviewForm.value.ClientDesignation,
              clientSayAbout: this.reviewForm.value.clientSayAbout,
              VideoLinks: this.reviewForm.value.projectNamed,
              SocialMediaLinks: this.reviewForm.value.SocialMediaLinks,
              reviewstttext: this.reviewForm.value.reviewstttext,
              reviewimage: this.reviewForm.value.reviewimage
            }
          }
        };
        const actiobj = {
          companyLoginId: this.loginId,
          desc: "updated review"
        };
        this.loader.startLoading();
        this.profileService.update(this.companyId, data, 'company').then((res: any) => {
          this.profileService.create(actiobj, 'activity').then((res: any) => {
            this.toaster.success('Review Added Successfully');
            this.reviewaddstt = false;
            this.reviewFormCreate();
            if (nextstt == true) {
              this.opentab(inx)
            }
            this.getProfile();
            this.loader.stopLoading();
          }, err => {
            this.loader.stopLoading();
            this.toaster.error('Please try after some time');
          })
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        })
      } else {
        data = {
          query: {
            "$set": {
              'reviewlist.$.ReviewType': this.reviewForm.value.ReviewType,
              'reviewlist.$.ClientName': this.reviewForm.value.ClientName,
              'reviewlist.$.ClientDesignation': this.reviewForm.value.ClientDesignation,
              'reviewlist.$.clientSayAbout': this.reviewForm.value.clientSayAbout,
              'reviewlist.$.VideoLinks': this.reviewForm.value.VideoLinks,
              'reviewlist.$.SocialMediaLinks': this.reviewForm.value.SocialMediaLinks,
              'reviewlist.$.reviewstttext': this.reviewForm.value.reviewstttext,
              'reviewlist.$.reviewimage': this.reviewForm.value.reviewimage
            }
          }
        };
        data.companyId = this.companyId;
        data.reviewId = this.reviewId;
        data.updateType = "review";
        const actiobj = {
          companyLoginId: this.loginId,
          desc: "updated review"
        };
        this.loader.startLoading();
        this.profileService.companySystem(data, 'company/subput').then((res: any) => {
          this.profileService.create(actiobj, 'activity').then((res: any) => {
            this.toaster.success('Review Updated Successfully');
            this.reviewaddstt = false;
            this.reviewFormCreate();
            if (nextstt == true) {
              this.opentab(inx)
            }
            this.loader.stopLoading();
          }, err => {
            this.loader.stopLoading();
            this.toaster.error('Please try after some time');
          })
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        })
      }
    } else if (val == 'legal') {
      let data = {
        startagency: this.legalForm.value.startagency,
        proofofRegistration: this.proofimg,
        agencyDirector: this.legalForm.value.agencyDirector,
        proofofIdentity: this.identityimg
      };
      const actiobj = {
        companyLoginId: this.loginId,
        desc: "updated legal details"
      };
      this.loader.startLoading();
      this.profileService.update(this.companyId, data, 'company').then((res: any) => {
        this.profileService.create(actiobj, 'activity').then((res: any) => {
          this.toaster.success('Legal details Updated Successfully');
          if (nextstt == true) {
            this.opentab(inx)
          }
          this.loader.stopLoading();
        }, err => {
          this.loader.stopLoading();
          this.toaster.error('Please try after some time');
        })
      }, err => {
        this.loader.stopLoading();
        this.toaster.error('Please try after some time');
      })
    } else if (val == 'image') {
      let data: any = {};
      if (this.docdata.imageurl) {
        data.logoImg = this.docdata.imageurl;
      } else {
        this.toaster.error('Please select logo');
        return false;
      }
      this.profileService.update(this.companyId, data, 'company').then((res: any) => {
        const actiobj = {
          "companyLoginId": this.loginId,
          "desc": "updated image"
        };
        this.profileService.create(actiobj, 'activity').then((res: any) => {
          this.getfileclick.nativeElement.value = "";
          this.toaster.success('Logo Upload Successfully');
        }, err => {
          this.toaster.error('Please try after some time');
        });
      }, err => {
        this.toaster.error('Please try after some time');
      })
    } else {
      let data = { password: this.pwdForm.value.password };
      this.profileService.update(this.loginId, data, 'user').then((res: any) => {
        const actiobj = {
          companyLoginId: this.loginId,
          desc: "updated image"
        };
        this.profileService.create(actiobj, 'activity').then((res: any) => {
          this.passwordFormCreate();
          this.toaster.success('password reset successfully');
        }, err => {
          this.toaster.error('Please try after some time');
        });
      }, err => {
        this.toaster.error('Please try after some time');
      })
    }
  }
  // ============ 15 =============
  getnotification() {
    this.profileService.companySystem(this.filter, 'notification/getcomnoti').then((res: any) => {
      if (res.confirmation == "success") {
        this.notificationList = res.data.data;
        this.cPage.totalItems = res.data.total;
        this.setPage(this.filter.page);
      } else {
        this.toaster.error("Please check your internet connection");
      }
    }, error => {
      this.toaster.error("Please check your internet connection");
    });
  }
  // ============ 16 =============
  getJobType() {
    this.profileService.getJobType({ status: true }, 'jobtype').then((res: any) => {
      this.jobType = res.data;
    }).catch(err => {

    });
  }
  // ============ 17 =============
  tabopen(val:any) {
    this.tablist = [false, false, false, false, false, false, false];
    this.tablist[val] = true;
  }
  // ============ 18 =============
  opentab(inx:any) {
    this.router.navigate(['/profile'], { "queryParams": { "tab": inx } });
  }
  openPlansTab(inx:any) {
    this.router.navigate(['/profile'], { "queryParams": { "tab": inx } });
  }
  // ============ 19 =============
  onItemSelect(item: any, vtype: any) {
    console.log(item);
  }
  // ============ 20 =============
  getSkill() {
    this.skillListdata = [];
    var filter = {};
    this.profileService.homeGetSkill(JSON.stringify(filter)).then(res => {
      this.skillListdata = res.data;
    }, err => {
      console.log(err);
    });

    var filter = {};
    this.profileService.homeGetSkill(JSON.stringify(filter)).then(res => {
      this.skillListdata = res.data;
    }).catch(err => {
    });
  }
  // ============ 21 =============
  patchport(data: any) {
    this.portfolioForm.patchValue({
      projectNamed: data.projectNamed,
      timeline: data.timeline,
      aboutProject: data.aboutProject,
      website: data.website,
      applink: data.applink,
      skills: data.skills,
      imageurl: data.imageurl[0],
      savestt: this.portfolioForm.value.savestt
    });
    this.pslist = data.skills;
    this.portfolioimg = data.imageurl[0];
    this.paddstt = true;
    this.projectbtnstt = "Update";
    this.projectId = data._id;
  }
  // ============ 22 =============
  reviewId:any = "";
  // ============ 23 =============
  editreview(data: any) {
    this.reviewForm.patchValue({
      ReviewType: data.ReviewType,
      ClientName: data.ClientName,
      ClientDesignation: data.ClientDesignation,
      clientSayAbout: data.clientSayAbout,
      VideoLinks: data.VideoLinks,
      SocialMediaLinks: data.SocialMediaLinks,
      reviewstttext: data.reviewstttext,
      reviewimage: data.reviewimage
    });
    this.reviewimg = data.reviewimage;
    this.reviewaddstt = true;
    this.reviewbtn = "Update";
    this.reviewId = data._id;
    this.reviewchange();
  }
  // ============ 24 =============
  deleteport(id: any, i: any) {

  }
  // ============ 25 =============
  addform(valtype:any) {
    if (valtype == "review") {
      this.reviewaddstt = !this.reviewaddstt;
      if (this.reviewaddstt) {
        this.reviewFormCreate();
        this.reviewimg = null;
        this.reviewbtn = "Save";
      }
    }
    if (valtype == "project") {
      this.paddstt = !this.paddstt;
      if (this.paddstt) {
        this.portfolioFormCreate();
        this.portfolioimg = null;
        this.projectbtnstt = "Save";
      }
    }
  }
  // ============ 26 =============
  onFileChangedImage(event:any) {
    if (event.target.files.length > 0) {
      this.fileuploadImage = event.target.files[0];
      this.onSubmitImage();
    }
  }
  // ============ 27 =============
  onSubmitImage() {
    let fd = new FormData();
    fd.append('image', this.fileuploadImage, 'image.jpeg');
    fd.append('baseurl', this.config.getbaseurl());
    this.profileService.upload(fd, 'portfolio').then((res: any) => {
      this.imageList.push(res.imageurl);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }
  // ============ 28 =============
  removeimg(inx:any) {
    this.imageList.splice(inx, 1);
  }
  // ============ 29 =============
  reviewstt() {
    this.reviewaddstt = !this.reviewaddstt;
    if (this.reviewaddstt) {
      this.reviewFormCreate();
      this.reviewbtn = "Save";
      this.reviewimg = null;
    }
  }
  // ============ 30 =============
  getfile(valtype:any) {

    if (valtype == "logo") {
      let inputElement: HTMLElement = this.getfileclick.nativeElement as HTMLElement;
      inputElement.click();
    }

    if (valtype == "portfolio") {
      let inputElement: HTMLElement = this.portfolioclick.nativeElement as HTMLElement;
      inputElement.click();
    }

    if (valtype == "proof") {
      let inputElement: HTMLElement = this.proofclick.nativeElement as HTMLElement;
      inputElement.click();
    }

    if (valtype == "identity") {
      let inputElement: HTMLElement = this.identityclick.nativeElement as HTMLElement;
      inputElement.click();
    }

  }
  // ============ 31 =============
  uploadfiles(event:any, valtype:any) {
    if (event.target.files.length > 0) {
      this.fileuploadImage = event.target.files[0];
      let fd = new FormData();
      fd.append('image', this.fileuploadImage);
      fd.append('baseurl', this.profileService.getbaseurl());

      if (valtype == "logo") {
        fd.append('dir', "profile");
      }

      if (valtype == "portfolio") {
        fd.append('dir', "companydata");
      }

      if (valtype == "proof") {
        fd.append('dir', "companydata");
      }

      if (valtype == "identity") {
        fd.append('dir', "companydata");
      }

      if (valtype == "review") {
        fd.append('dir', "companydata");
      }

      this.loader.startLoading();
      this.profileService.companydata(fd).then((res: any) => {
        if (res.confirmation == "success") {
          this.loader.stopLoading();
          
          this.docdata = res.data;

          if (valtype == "logo") {
            this.logourl = this.docdata.imageurl;
            this.updateProfile("image", 0, false);
          }
          if (valtype == "portfolio") {
            this.portfolioimg = this.docdata.imageurl;
            this.portfolioForm.get("imageurl").setValue(this.docdata.imageurl);
          }
          if (valtype == "review") {
            this.reviewimg = this.docdata.imageurl;
            this.reviewForm.get("reviewimage").setValue(this.docdata.imageurl);
          }
          if (valtype == "proof") {
            this.proofimg = this.docdata.imageurl;
            this.pfiletype = this.docdata.filetype;
            this.legalForm.get("proofofRegistration").setValue(this.docdata.imageurl);
          }
          if (valtype == "identity") {
            this.identityimg = this.docdata.imageurl;
            this.ifiletype = this.docdata.filetype;
            this.legalForm.get("proofofIdentity").setValue(this.docdata.imageurl);
          }
        } else {
          this.loader.stopLoading();
          this.toaster.error('Please try after sometimes');
        }
      }).catch(err => {
        this.loader.stopLoading();
        this.toaster.error('Please Check internet');
      });
    } else {
      this.toaster.error("Please select file");
    }
  }
  // ============ 32 =============
  resetimg(val:any) {
    if (val == "logo") {
      this.getfileclick.nativeElement.value = "";
      this.logourl = null;
    }
    if (val == "project") {
      this.portfolioclick.nativeElement.value = "";
      this.portfolioimg = null;
      this.portfolioForm.get("imageurl").setValue("");
    }
    if (val == "review") {
      this.reviewclick.nativeElement.value = "";
      this.reviewimg = null;
      this.reviewForm.get("reviewimage").setValue("");
    }
    if (val == "proof") {
      this.proofclick.nativeElement.value = "";
      this.proofimg = null;
      this.legalForm.get("proofofRegistration").setValue("");
    }
    if (val == "identity") {
      this.identityclick.nativeElement.value = "";
      this.identityimg = null;
      this.legalForm.get("proofofIdentity").setValue("");
    }
  } 
  // ============ 33 =============
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // ============ 34 =============
  windowload() {
    this.modules = {
      toolbar: [
        ['bold', 'underline']]
    };
    this.configck = {
      uiColor: '#f2f2f2', 
      toolbarGroups: [ 
      { name: 'links' },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' }
      ],
      resize_enabled: false,
      height: 200 
    };
    this.timelist = [
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30"
    ];
    this.reviewForm.get('reviewimage').setValidators([Validators.required]);
  }

  ReviewType:any = "Text";
  // ============ 35 =============
  reviewchange() {
    const ReviewType = this.reviewForm.value.ReviewType;
    if (ReviewType == "Text") {
      this.reviewForm.get('reviewimage').setValidators([Validators.required]);
      this.reviewForm.get('reviewimage').updateValueAndValidity();
      this.reviewForm.get('clientSayAbout').setValidators([Validators.required]);
      this.reviewForm.get('clientSayAbout').updateValueAndValidity();
      this.reviewForm.get('SocialMediaLinks').clearValidators();
      this.reviewForm.get('SocialMediaLinks').updateValueAndValidity();
      this.reviewForm.get('VideoLinks').clearValidators();
      this.reviewForm.get('VideoLinks').updateValueAndValidity();
      this.ReviewType = ReviewType;
    } else if (ReviewType == "Social") {
      this.reviewForm.get('clientSayAbout').clearValidators();
      this.reviewForm.get('clientSayAbout').updateValueAndValidity();
      this.reviewForm.get('SocialMediaLinks').setValidators([Validators.required]);
      this.reviewForm.get('SocialMediaLinks').updateValueAndValidity();
      this.reviewForm.get('VideoLinks').clearValidators();
      this.reviewForm.get('VideoLinks').updateValueAndValidity();
      this.reviewForm.get('reviewimage').clearValidators();
      this.reviewForm.get('reviewimage').updateValueAndValidity();
      this.ReviewType = ReviewType;
    } else {
      this.reviewForm.get('clientSayAbout').clearValidators();
      this.reviewForm.get('clientSayAbout').updateValueAndValidity();
      this.reviewForm.get('SocialMediaLinks').clearValidators();
      this.reviewForm.get('SocialMediaLinks').updateValueAndValidity();
      this.reviewForm.get('VideoLinks').setValidators([Validators.required]);
      this.reviewForm.get('VideoLinks').updateValueAndValidity();
      this.reviewForm.get('reviewimage').clearValidators();
      this.reviewForm.get('reviewimage').updateValueAndValidity();
      this.ReviewType = ReviewType;
    }
  }
  // ============ 36 =============
  setvaluearr(val:any, type:any) { 
    if (type == 1) {
      if (this.sltlanguagelist.indexOf(val) == -1) {
        this.sltlanguagelist.push(val);
        if (this.sltlanguagelist.length > 0) {
          this.ServicesForm.get("language").setValue("fill");
        }
      }
    }
    if (type == 2) {
      if (this.sltservicelist.indexOf(val) == -1) {
        this.sltservicelist.push(val);
        if (this.sltservicelist.length > 0) {
          this.ServicesForm.get("solutionProvideService").setValue("fill");
        }
      }
    }
  }
  // ============ 37 =============
  removearr(inx:any, type:any) {
    if (type == 1) {
      this.sltlanguagelist.splice(inx, 1);
      if (this.sltlanguagelist.length == 0) {
        this.ServicesForm.get("language").setValue("");
      }
    }
    if (type == 2) {
      this.sltservicelist.splice(inx, 1);
      if (this.sltservicelist.length == 0) {
        this.ServicesForm.get("solutionProvideService").setValue("");
      }
    }
    if (type == 3) {
      this.slist.splice(inx, 1);
      if (this.slist.length == 0) {
        this.portfolioForm.get('skills').setValue('');
      }
     
    }
    if (type == 4) {
      this.pslist.splice(inx, 1);
      if (this.pslist.length == 0) {
        this.portfolioForm.get('skills').setValue('');
      }
     
    }
  }
  // ============ 38 =============
  getpagedata(page:any) {
    this.cPage.currentPage = page;
    this.filter.page = page;
    this.getnotification();
  }
  // ============ 39 =============
  setPage(page:any) {
    this.cPage.currentPage = page;
    // this.cpagedata = this.pagination.getPager(this.cPage.totalItems, this.cPage.currentPage, this.cPage.pageSize);
  }
  // ============ 40 =============
  /*Set Skill*/
  windowrendor() {
    this.renderer.listen('window', 'click', (e: Event):any => {
      let cls = (<Element>e.target).className;
      if (typeof cls == "string") {
        let arr = cls.split(" ");
        let change1 = 0;
        let change2 = 0;
        arr.forEach(element => {
          if (element == 'salectchanged') {
            change1 += 1;
          }
          if (element == 'salectchanged2') {
            change2 += 1;
          }
        });
        if (change1 == 0) {
          if ( this.tablist[1]==true) {
            this.setchangevalue(1);
          }
          if ( this.tablist[2]==true) {
            this.setchangevalue(2);
          }
        }
        if (change2 == 0) {
          if ( this.tablist[1]==true) {
            this.setchangevalue2(1);
          }
          if ( this.tablist[2]==true) {
            this.setchangevalue2(2);
          }
        }
        if (this.editstt == true) {
          if ((<Element>e.target).className == 'salectchanged') {
            return false;
          } else {
            if ( this.tablist[1]==true) {
              this.setchangevalue(1);
            }
            if ( this.tablist[2]==true) {
              this.setchangevalue(2);
            }
          }
        }
      }
    });
  }
  // ============ 41 =============
  setchangevalue(val:any) {
    if (val==1) {
      if (this.ServicesForm.value.skillsearch) {
        if (this.slist.length <= 15) {
          this.slist.push(this.ServicesForm.value.skillsearch);
          this.cls1 = '';
          this.ServicesForm.get('skillsearch').setValue('');
          this.ServicesForm.get('skills').setValue('fill');
          
        } else {
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
    }
    if (val==2) {
      if (this.portfolioForm.value.skillsearch) {
        if (this.slist.length <= 15) {
          this.pslist.push(this.portfolioForm.value.skillsearch);
          this.cls1 = '';
          this.portfolioForm.get('skillsearch').setValue('');
          this.portfolioForm.get('skills').setValue('fill');
        } else {
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
    }
  }
  // ============ 42 =============
  setchangevalue2(val:any) {
    if (val==1) {
      if (this.ServicesForm.value.skillsearch) {
        if (this.slist.length <= 15) {
          this.slist.push(this.ServicesForm.value.skillsearch);
          this.cls1 = '';
          this.ServicesForm.get('skillsearch').setValue('');
          this.ServicesForm.get('skills').setValue('fill');
        } else {
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
    }
    if (val==2) {
      if (this.portfolioForm.value.skillsearch) {
        if (this.pslist.length <= 15) {
          this.pslist.push(this.portfolioForm.value.skillsearch);
          this.cls1 = '';
          this.portfolioForm.get('skillsearch').setValue('');
          this.portfolioForm.get('skills').setValue('fill');
        } else {
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
    }
  }
  // ============ 43 =============
  getsltSkills(val:any,type:any) {
    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls1 = 'displayblock';
      this.templist = this.skillListdata.filter(function (el:any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = '';
      }
    } else {

      if (type==1) {
        if (this.slist.length <= 15) {
          this.slist.push(val.replace(",", ""));
          this.cls1 = '';
          this.ServicesForm.get('skillsearch').setValue('');
          this.ServicesForm.get('skills').setValue('fill');
        }else{
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
      if (type==2) {
        if (this.slist.length <= 15) {
          this.pslist.push(val.replace(",", ""));
          this.cls1 = '';
          this.portfolioForm.get('skillsearch').setValue('');
          this.portfolioForm.get('skills').setValue('fill');
        }else{
          this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
        }
      }
    }
  }
  // ============ 44 =============
  setskillvalue(item:any , type:any) {
    if (this.slist.length <= 15) {
      if (type==1) {
        this.slist.push(item);
        this.cls1 = '';
        this.ServicesForm.get('skillsearch').setValue('');
        this.ServicesForm.get('skills').setValue('fill');
      }
      if (type==2) {
        this.pslist.push(item);
        this.cls1 = '';
        this.portfolioForm.get('skillsearch').setValue('');
        this.portfolioForm.get('skills').setValue('fill');
      }
    } else {
      this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
    }
  }
  // ============ 45 =============
  progressBar(progressVal:any, totalPercentageVal = 100) {
    var strokeVal = (4.64 * 100) / totalPercentageVal;
    var x = document.querySelector('.progress-circle-prog');
    var loadtext = progressVal * (strokeVal) + ' 999';
    //alert(loadtext);
    $(".progress-circle-prog").css('stroke-dasharray', loadtext);
    var el = document.querySelector('.progress-text');
    var from = $('.progress-text').data('progress');
    $('.progress-text').data('progress', progressVal);
    var start = new Date().getTime();
    // setTimeout(() => {
    // }, 10);
    setTimeout(function () {
      var now = (new Date().getTime()) - start;
      var progress = now / 700;
      $(".progress-text").html(progressVal / totalPercentageVal * 100 + '%');
      //el.innerHTML = progressVal / totalPercentageVal * 100 + '%';
      //if (progress < 1) setTimeout(arguments.callee, 10);
    }, 10);
  }


  // ============== paymentHistory ====================
  getTicket() {
    this.shimmer = true;
    this.loader.startLoading();
    this.profileService.findPaymentHistory(JSON.stringify({}), 'paymentctr').then((res: any) => {
      this.loader.stopLoading();
      this.ticketData = res.data;
      this.shimmer = false;
      this.setPage_pH(1);
    }, err => {
      this.shimmer = false;
      console.log(err);
    });
  } 

  setPage_pH(page:any) {
    this.cPage_pH.currentPage = page;
    this.cPage_pH.totalItems = this.ticketData.length;
    // this.cpagedata_pH = this.pagination.getPager(this.cPage_pH.totalItems, this.cPage_pH.currentPage, this.cPage_pH.pageSize);
    this.PagefinalDetails = this.ticketData.slice(this.cpagedata_pH.startIndex, this.cpagedata_pH.endIndex + 1);
  }

  invoiceopen(data:any) {
    this.invocestt = true;
    this.invoicedata = data;
    const scode = this.invoicedata.gst.slice(0, 2);
    if (scode == this.cnstate) {
      this.igststt = false;
    }
  }

  closeinvoice() {
    this.invocestt = false;
  }
  
  printDiv(val:any){ }




}
