import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BenchApplicationsService {

  apiUrl: any = "https://www.onbenchmark.com/";
  jsonheader: any;
  public globalprize = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { 
    this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
  } 

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`, 
    });
  }

  changeprizeglobal(prize:any) {
    this.globalprize.next(prize);
  }

  async FindbyId(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise().catch((error) => {
      console.error("Error occurred while finding data by id:", error);
      throw error;
    });
  }

  // async FindbyId(data: any, tbl: any): Promise<any> {
  //   try {
  //     const response = await this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise();
  //     return response;
  //   } catch (error) {
  //     console.error("Error occurred while finding data by id:", error);
  //     throw error;
  //   }
  // }  

  async update(ids: any, data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.jsonheader }).toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while updating:", error);
      throw error;
    }
  }

  async candidateApplication(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/candidateapplication/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while fetching bench candidates data:", error);
      throw error; 
    }
  }

  async getTotalCandidate(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/gettotalcandidate/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while fetching total bench Candidates data:", error);
      throw error; 
    }
  }

  async companySystem(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/companysystem/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while fetching company system data:", error);
      throw error; 
    }
  }



  async FindById(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}` , { headers: this.authService.jsonheader }).toPromise().catch((error) => {
      console.error("Error occurred while finding data by id:", error);
      throw error;
    });
  }
  




}
