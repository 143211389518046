<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-8 col-md-12">
            <div class="left">
              <!-- <img src="assets/img/job-details1.png" alt="Details" /> --> 
              <h2 class="mt-50">{{ job.title }}</h2>
              <ul>
                <li><i class="bx bx-people"></i> No. of positions: {{ job.noofResources }}</li>
                <li *ngIf="job.location" style="color: white">
                  <i class="fa-solid fa-location-dot"></i>
                  {{ job.location[0].location }}
                </li>

                <!-- <li><i class="bx bx-time"></i> Posted date: {{ job.createdAt | date: "dd MMM, yyyy" }}</li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <div class="right">
              <ul>
                <li style="color: white"><i class="bx bx-time"></i> Last Active Date : {{ job.createdAt | date: "dd MMM, yyyy" }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Job Details -->
<div class="job-details-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">

          <div *ngIf="contactDetails.cname && contactDetails.cname != ''" class="row mb-3">
            <div class="col-lg-12 outerBorderBox">
              <div class="information widget-item" >
                <h5 class="text-dark">Contact Information</h5>
                <div class="row">
                  <div class="col-lg-3">
                    <h6>Company </h6>
                    <span class="text-info">{{ contactDetails.cname }}</span>
                  </div>
                  <div class="col-lg-2">
                    <h6>Contact </h6>
                    <span class="text-info">{{ contactDetails.name }}</span>
                  </div>
                  <div class="col-lg-5">
                    <h6>Email </h6>
                    <span class="text-info">{{ contactDetails.email }}</span>
                  </div>
                  <div class="col-lg-2">
                    <h6>Phone </h6>
                    <span class="text-info">{{ contactDetails.phone }}</span>
                  </div>
                </div>    
              
              </div>
            </div>
          </div>




          <div class="row">
            <div class="col-lg-6 col-md-12" style="margin-bottom: 10px">
              <div class="details-inner">
                <!-- <h3>Rate</h3> -->
                <h3 class="mb-0">Budget</h3>
                <span>₹{{ parseRate(job.ratemin) }} - ₹{{ parseRate(job.ratemax) }} (Monthly)</span>
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12" style="margin-bottom: 30px">
              <div class="details-inner">
                <h3>Category</h3>
                <span>{{ job.jobtype }}</span>
              </div>
            </div> -->
            <div class="col-lg-6 col-md-12" style="margin-bottom: 10px">
              <div class="details-inner">
                <h3 class="mb-0">Experience</h3>
                <span>{{ job.experiencemin }} to {{ job.experiencemax }} Years</span>
              </div>
            </div>
            <div class="col-lg-6 col-md-12" style="margin-bottom: 10px">
              <div class="details-inner">
                <h3 class="mb-0">Work From</h3>
                <span>{{ job.workFrom }}</span>
              </div>
            </div>
          </div>

          <div class="details-inner mb-2">
            <h3 class="mb-0">Required Skills</h3>
            <span *ngFor="let s of job.skill" class="span-one" style="margin: 2px 2px">{{ s }}</span>
          </div>

          <div class="details-inner">
            <h3 class="mb-0">Job Description</h3>
            <div [innerHTML]="job.jobDescription"></div>
          </div>

          <!-- <div class="candidate-item" style="max-width: 100% !important: 100%" *ngFor="let candidate of candidates">
            <div class="left">
              <h3>{{ candidate.fname }}</h3>
              <ul>
                <li *ngFor="let s of candidate.skill">{{ s }}</li>
              </ul>
              <div class="cmn-link">
                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Job / Project <i class="flaticon-right-arrow two"></i></a>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <!-- <div class="job widget-item">
            <form>
              <button style="margin-bottom: 10px" type="submit" class="btn">Deactivate</button>
              <button type="submit" class="btn">Edit</button>
            </form>
          </div> -->

          <div class="information widget-item">
            <h3>My Resources</h3>
            <div style="padding-bottom: 20px">
              <input type="text" [(ngModel)]="benchSearch" debounceTime="2000" placeholder="Name or Designation" (input)="searchCandidate($event)" />
            </div>
            <ul class="checkbox-list">
              <li class="checkbox-item" *ngFor="let bench of myBench">
                <label class="checkbox-label">
                  <input *ngIf="!bench.appliedstatus" value="true" type="checkbox" class="checkbox-input" [(ngModel)]="bench.checked" (change)="onCheckboxChange(bench)" />
                  {{ bench.name }} <span *ngIf="bench.appliedstatus" style="color: green; margin-left: 10px" class="span-one">Applied</span>
                </label>

                <span style="font-size: 14px">{{ bench.designation }}</span>
              </li>
            </ul>
            <div class="job widget-item" style="border: none; padding-bottom: 0px; margin-bottom: 0px">
              <form>
                <button (click)="jobApply()" [disabled]="!canApply" type="submit" class="btn">Apply</button>
              </form>
            </div>
          </div>

          <!-- <div class="information widget-item" *ngIf="contactDetails.cname && contactDetails.cname != ''">
            <h3>Contact Information</h3>

            <ul>
              <li>
                <h4>Company Name</h4>
                <span>{{ contactDetails.cname }}</span>
              </li>
              <li>
                <h4>Contact Name</h4>
                <span>{{ contactDetails.name }}</span>
              </li>
              <li>
                <h4>Email Address</h4>
                <span>{{ contactDetails.email }}</span>
              </li>
              <li>
                <h4>Phone Number</h4>
                <span>{{ contactDetails.phone }}</span>
              </li>
            </ul>
          </div> -->
          <!-- <div class="information widget-item">
            <h3>Job Information</h3>

            <ul>
              <li>
                <h4>Rate</h4>
                <span>${{ job.ratemin | number: "1.2-2" }} - ${{ job.ratemax | number: "1.2-2" }} ({{ job.ratetype }})</span>
              </li>
              <li>
                <h4>Category</h4>
                <span>Sales & Marketing</span>
              </li>
              <li>
                <h4>Location</h4>
                <span>10th St Long Island, NY</span>
              </li>
              <li>
                <h4>Applied</h4>
                <span>01</span>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Job Details -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
