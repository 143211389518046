<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Join the Biggest Quick Talent Marketplace</h2> 
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Tab buttons -->

<!-- pricing-area pt-100 pb-70 -->

<section class="plansPricing">
  <div class="container-fluid" style="background:#f4f4f5;">
    <div class="row px-0">
      <div class="col-lg-12">
        <!-- ====== -->
         <!-- *ngIf="checkout==false" -->
        <div  class="inner-content listing" style="background:#f4f4f5;">
          <div class="container py-5 px-0">
            <h2 class="heading-register text-center">Choose the offering
              <span class="text-info">that works best for you</span>
            </h2>
            <span class="sub-title">
              OnBenchMark is a Bench resource sharing and freelancer hiring platform. Choose your plan and achieve just-in-time
              hiring and reduce hiring & sales cost upto 50%
            </span>
            <br>
            <div class="row"> 
              <div class="col-lg-2 pt-3">
                <div tooltip="Click to Create your Plan">
                    <button routerLink="/register" type="button" class="btn cmnBtn px-5" >Try For Free</button>
                  </div>
              </div>
              <div class="col-lg-8 text-end pe-0">
               
                <div class="switch-field">
                  <input type="hidden" id="totalmonth" value="1" />
                  <!-- [ngModel]="datechange"
                    (change)="datestt($any($event.target).value)" -->
                  <input type="radio" id="radio-three" name="switch-two" value="monthly"  />
                  <label for="radio-three" style="background: linear-gradient(to right,#3eddff,#00aade); color: #fff;">Monthly</label>
                  <!--  [ngModel]="datechange"
                    (change)="datestt($any($event.target).value)" -->
                  <input type="radio" id="radio-four" name="switch-two" value="quarterly" />
                  <label for="radio-four">Quarterly</label>
                  <!-- [ngModel]="datechange"
                    (change)="datestt($any($event.target).value)" -->
                  <input type="radio" id="radio-five" name="switch-two" value="halfyearly"  />
                  <label for="radio-five">Half Yearly</label>
                  <!-- [ngModel]="datechange"
                    (change)="datestt($any($event.target).value)" -->
                  <input type="radio" id="radio-six" name="switch-two" value="yearly"  />
                  <label for="radio-six">Yearly</label>
                </div>

              </div>
              <div class="col-lg-2 text-start pt-3">
              
                <div tooltip="Click to Create your Plan">
                  <button type="button" class="btn cmnBtn" (click)="showModal(2)">Create My Plan</button>
                </div>
                

                <div id="supportModal" class="modal" [ngClass]="{'show': isModalOpen}">
                  <div class="modal-content">
                    <span class="close">&times;</span>
                    <h4>Talk to us</h4>
                    <p>Email :<strong> info&#64;OnBenchMark.com</strong> </p>
                    <p>call us at: <strong>+1-800-123-4567</strong></p>
                    <p>Leave a message<a class="click-btn" routerLink="/contact">Click here</a></p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-2 col-xs-3 col-sm-3 col-md-3"></div> -->
            </div>

            <!-- <div class="row d-flex justify-content-center mb-5">
              <div class="col-lg-4 text-center">
                <h5>Create My Plan</h5>
                
                <button class="btn btn-info cmnBtn" (click)="showModal(2)">Talk to Us</button>

                <div id="supportModal" class="modal" [ngClass]="{'show': isModalOpen}">
                  <div class="modal-content">
                    <span class="close" (click)="closeModal()">&times;</span>
                    <h4>Talk to us</h4>
                    <p>Email :<strong> info&#64;OnBenchMark.com</strong> </p>
                    <p>call us at: <strong>+1-800-123-4567</strong></p>
                    <p>Leave a message<a class="click-btn" routerLink="/contact">Click here</a></p>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
             
              <div class="col-lg-6">
                <div class="most-poplr">Most Popular</div>
                  <div class="price-top">
                    <div class="plan-head text-center pt-1">
                      <h3>Premium</h3>
                    </div>
                    <!-- <div *ngIf="datechange=='monthly'" id="premium-plan-month" class="price-text text-center" >
                      <span class="month">
                        <span *ngIf="currency=='in'" class="text-info">
                          <i class="fa fa-inr"></i>
                          {{mathceil(packagedata?.premium?.monthlyamount-(packagedata?.premium?.monthlyamount*packagedata?.premium?.monthlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='euro'" class="text-info">
                          <i class="fa fa-eur"></i>
                          {{mathceil(packagedata?.premium?.euromonthlyamount
                          -(packagedata?.premium?.euromonthlyamount*packagedata?.premium?.monthlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='doller'" class="text-info">
                          <i class="fa fa-usd"></i>
                          {{mathceil(packagedata?.premium?.dollermonthlyamount
                          -(packagedata?.premium?.dollermonthlyamount*packagedata?.premium?.monthlyoffer/100))}}
                        </span>
                      </span>
                    
                      <ng-container *ngIf="packagedata?.premium?.monthlyoffer>0">
                        <div class="offer-price" *ngIf="currency=='in'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-inr"></i>
                            {{packagedata?.premium?.monthlyamount}}
                          </span> ({{packagedata?.premium?.monthlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='euro'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-eur"></i>
                            {{packagedata?.premium?.monthlyamount}}
                          </span> ( {{packagedata?.premium?.monthlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='doller'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-usd"></i>
                            {{packagedata?.premium?.dollermonthlyamount}}
                          </span> ( {{packagedata?.premium?.monthlyoffer}} % off )
                        </div>
                      </ng-container>
                    </div> -->
          
                    <!-- <div *ngIf="datechange=='quarterly'" id="premium-plan-month" class="price-text text-center" >
                      <span class="month">
                        <span *ngIf="currency=='in'" class="text-info">
                          <i class="fa fa-inr"></i>
                          {{mathceil(packagedata?.premium?.quarterlyamount-(packagedata?.premium?.quarterlyamount*packagedata?.premium?.quarterlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='euro'" class="text-info">
                          <i class="fa fa-eur"></i>
                          {{mathceil(packagedata?.premium?.euroquarterlyamount
                          -(packagedata?.premium?.euroquarterlyamount*packagedata?.premium?.quarterlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='doller'" class="text-info">
                          <i class="fa fa-usd"></i>
                          {{mathceil(packagedata?.premium?.dollerquarterlyamount
                          -(packagedata?.premium?.dollerquarterlyamount*packagedata?.premium?.quarterlyoffer/100))}}
                        </span>
                      </span>
                      <ng-container *ngIf="packagedata?.premium?.quarterlyoffer>0">
                        <div class="offer-price" *ngIf="currency=='in'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-inr"></i>
                            {{packagedata?.premium?.quarterlyamount}}
                          </span> ({{packagedata?.premium?.quarterlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='euro'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-eur"></i>
                            {{packagedata?.premium?.quarterlyamount}}
                          </span> ( {{packagedata?.premium?.quarterlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='doller'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-usd"></i>
                            {{packagedata?.premium?.dollerquarterlyamount}}
                          </span> ( {{packagedata?.premium?.quarterlyoffer}} % off )
                        </div>
                      </ng-container>
                    </div> -->
          
                    <!-- <div *ngIf="datechange=='halfyearly'" id="premium-plan-month" class="price-text text-center" >
                      <span class="month">
                        <span *ngIf="currency=='in'" class="text-info">
                          <i class="fa fa-inr"></i>
                          {{mathceil(packagedata?.premium?.halfyearlyamount-(packagedata?.premium?.halfyearlyamount*packagedata?.premium?.halfyearlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='euro'" class="text-info">
                          <i class="fa fa-eur"></i>
                          {{mathceil(packagedata?.premium?.eurohalfyearlyamount
                          -(packagedata?.premium?.eurohalfyearlyamount*packagedata?.premium?.halfyearlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='doller'" class="text-info">
                          <i class="fa fa-usd"></i>
                          {{mathceil(packagedata?.premium?.dollerhalfyearlyamount
                          -(packagedata?.premium?.dollerhalfyearlyamount*packagedata?.premium?.halfyearlyoffer/100))}}
                        </span>
                      </span>
                      <ng-container *ngIf="packagedata?.premium?.halfyearlyoffer>0">
                        <div class="offer-price" *ngIf="currency=='in'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-inr"></i>
                            {{packagedata?.premium?.halfyearlyamount}}
                          </span> ({{packagedata?.premium?.halfyearlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='euro'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-eur"></i>
                            {{packagedata?.premium?.halfyearlyamount}}
                          </span> ( {{packagedata?.premium?.halfyearlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='doller'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-usd"></i>
                            {{packagedata?.premium?.dollerhalfyearlyamount}}
                          </span> ( {{packagedata?.premium?.halfyearlyoffer}} % off )
                        </div>
                      </ng-container>
                    </div> -->
          
                    <!-- <div *ngIf="datechange=='yearly'" id="premium-plan-year" class="price-text text-center" >
                      <span class="month">
                        <span *ngIf="currency=='in'" class="text-info">
                          <i class="fa fa-inr"></i>
                          {{(mathceil(packagedata?.premium.yearlyamount -
                          (packagedata?.premium.yearlyamount*packagedata?.premium.yearlyoffer/100)))}}
                        </span>
                        <span *ngIf="currency=='euro'" class="text-info">
                          <i class="fa fa-eur"></i>
                          {{(mathceil(packagedata?.premium.euroyearlyamount-
                          (packagedata?.premium.euroyearlyamount*packagedata?.premium.yearlyoffer/100)))}}
                        </span>
                        <span *ngIf="currency=='doller'" class="text-info">
                          <i class="fa fa-usd"></i>
                          {{(mathceil(packagedata?.premium.dolleryearlyamount -
                          (packagedata?.premium.dolleryearlyamount*packagedata?.premium.yearlyoffer/100)))}}
                        </span>
                      </span>
                      <span class="h6 d-block month">per Year</span>
                      <ng-container *ngIf="packagedata?.premium?.yearlyoffer>0">
                        <div class="offer-price">
                          <span *ngIf="currency=='in'" class="offer-line-through text-info">
                            <i class="fa fa-inr"></i>
                            {{packagedata?.premium.yearlyamount}}
                          </span>
                          <span *ngIf="currency=='euro'" class="offer-line-through text-info">
                            <i class="fa fa-eur"></i>
                            {{packagedata?.premium.euroyearlyamount}}
                          </span>
                          <span *ngIf="currency=='doller'" class="offer-line-through text-info">
                            <i class="fa fa-usd"></i>
                            {{packagedata?.premium.dolleryearlyamount}}
                          </span>
                          ( {{packagedata?.premium.yearlyoffer}} % off)
                        </div>
                      </ng-container>
                    </div> -->
          
                    <div class="text-premium-div">
                      <h4 class="plan-price text-center text-dark pt-2">12000 per month</h4>
                      <div class="text-view-btn">
                        <!-- (click)="selectpackage('premium','flat')" -->
                        <a href="javascript:void(0)" 
                          class="link-hover1 text-white h5 w-100 d-block mb-0 totalrs">
                          Select Plan
                        </a>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class=" ">
                            <p class="ps-3 pb-0 mb-0" style="font-weight: 500; font-size: 18px;">
                              <strong>Hiring</strong>
                            </p>
                          </div> 
                          <div class="text-view"> Job Posting (Contract / Freelance) <b></b></div>
                          <div class="text-view">
                            <!-- {{(packagedata?.premium?.totalcv*month)}} -->
                            Hire Freelancers ( 0 resume views )
                          </div>
                          <div class="text-view">
                            <!-- {{(packagedata?.premium?.companyViewTotal*month)}} -->
                            Hire Contract resources ( 0 vendor contacts)
                          </div>
                        </div>
                        <div class="col-lg-6">
                        
                          <div class=" ">
                            <p class="ps-3 pb-0 mb-0" style="font-weight: 500; font-size: 18px;">
                              <strong>Find Clients</strong>
                            </p>
                          </div> 
                        
                          <div class="text-view"> Deploy Bench</div>
                          <div class="text-view"> Directly connect with clients (150 client contacts)</div>
                          <div class="text-view"> Bench Campaign</div>
                        </div>
                      </div>      
                      
                    </div>
                    
                  </div>
              </div>
        
              <div class="col-lg-6">
        
                <div class="price-top">
                  <div class=" plan-head text-center pt-1">
                    <h3>Enterprise</h3>
                  </div>

                  <!-- [innerHtml]="htmldoc" -->
                  <!-- <div class="price-text text-center enterprise-plan text-info" > -->
        
                    <!-- <div *ngIf="datechange == 'monthly'">

                      <span class="month">
                        <span *ngIf="currency=='in'" class="text-info">
                          <i class="fa fa-inr text-info"></i>
                          {{mathceil(packagedata?.enterprise?.monthlyamount-(packagedata?.enterprise?.monthlyamount*packagedata?.enterprise?.monthlyoffer/100))}}                        
                        </span>
                        <span *ngIf="currency=='euro'" class="text-info">
                          <i class="fa fa-eur"></i>
                          {{mathceil(packagedata?.enterprise?.euromonthlyamount
                          -(packagedata?.enterprise?.euromonthlyamount*packagedata?.enterprise?.monthlyoffer/100))}}
                        </span>
                        <span *ngIf="currency=='doller'" class="text-info">
                          <i class="fa fa-usd"></i>
                          {{mathceil(packagedata?.enterprise?.dollermonthlyamount
                          -(packagedata?.enterprise?.dollermonthlyamount*packagedata?.enterprise?.monthlyoffer/100))}}
                        </span>
                      </span> 
                     
                      <ng-container *ngIf="packagedata?.enterprise?.monthlyoffer>0">
                        <div class="offer-price" *ngIf="currency=='in'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-inr"></i>
                            {{packagedata?.enterprise?.monthlyamount}}
                          </span> 
                          ({{packagedata?.enterprise?.monthlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='euro'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-eur"></i>
                            {{packagedata?.enterprise?.monthlyamount}}
                          </span> ( {{packagedata?.enterprise?.monthlyoffer}} % off)
                        </div>
                        <div class="offer-price" *ngIf="currency=='doller'" class="text-info">
                          <span class="offer-line-through">
                            <i class="fa fa-usd"></i>
                            {{packagedata?.enterprise?.dollermonthlyamount}}
                          </span> ( {{packagedata?.enterprise?.monthlyoffer}} % off )
                        </div>
                      </ng-container>
                      
                    </div> -->
                    
                  <!-- </div> -->

                  <div class="text-premium-div">

                    <h4 class="plan-price text-center text-dark pt-2">40000 per month</h4>

                    <div class="text-view-btn">
                        <!-- (click)="selectpackage('enterprise','flat')" -->
                      <a href="javascript:void(0)" 
                        class="link-hover1 text-white h5 w-100 d-block mb-0 totalrs">
                        Select Plan
                      </a>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-view-h4 text-title">
                          <span class="form-checkbox ms-2">
                            <!-- [(ngModel)]="contractcheckbox" -->
                             <!-- (change)="change_plan($any($event.target).value)" -->
                            <input type="checkbox" id="html1" class="checkbox-plan"  value="Contract" checked="" >
                            <label for="html1">
                              <span style="font-weight: 500; font-size: 18px;"><strong>Hiring</strong></span>
                            </label>
                            
                          </span>
                        </div>
                        <div class="text-view">
                           Dedicated Recruiter Assistance
                        </div>
                        <div class="text-view">
                           Preferred Candidate Sharing
                        </div>
                        <div class="text-view">
                           Identifying Vendors
                        </div>
                        <div class="text-view">
                           Coordination with Vendor
                        </div>
                        <div class="text-view">
                           Screen Candidates
                        </div>
                        <div class="text-view">
                           Schedule Interviews
                        </div>
                        <div class="text-view">
                           On-Boarding Assistance
                        </div>
                       
                      </div>
                      <div class="col-lg-6">
                        <div class="text-view-h4 text-title">
                          <span class="form-checkbox ps-1">
                            <!-- [(ngModel)]="benchcheckbox" value="Bench"
                              (change)="change_plan($any($event.target).value)" -->
                            <input type="checkbox" id="html" class="checkbox-plan" >
                          
                            <label for="html">
                              <span style="font-weight: 500; font-size: 18px;"><strong>Find Clients</strong></span>
                            </label>
                          </span>
                        </div>
                      
    
                        <div class="text-view">
                           Dedicated Sales Assistant
                        </div>
                        <div class="text-view">
                           Preferred Lead Sharing
                        </div>
                        <div class="text-view">
                           Identifying Clients
                        </div>
                        <div class="text-view">
                           Coordination with Clients
                        </div>
                        <div class="text-view">
                           Schedule Interviews
                        </div>
                        <div class="text-view">
                           On-Boarding Assistance
                        </div>
                        <div class="text-view">
                           Offline Assistance for new business
                        </div>   
                      </div>
                    </div>           
                   
                      
                  </div>
                </div>
              </div>
    
           
            </div>
            <br>
          </div>
        </div>
        <!-- ====== -->
        <!-- <div *ngIf="checkout" class="inner-content listing" style="background: #f4f4f5;">
          <div class="container">
            <br>
            <div class="row">
              <div class="col-sm-8">
                <div class="package-info">
                  <h4>
                    <span *ngIf="sltpackagedata?.planType==2">You've added the Essential Plan </span>
                    <span *ngIf="sltpackagedata?.planType==3">You've added the Premium Plan</span>
                    <span *ngIf="sltpackagedata?.planType==4">You've added the Customise Plan </span>
                    <span *ngIf="sltpackagedata?.planType==5">You've added the Enterprise Plan </span>
                    <span *ngIf="sltpackagedata?.totalmonth>=1"> ({{sltpackagedata.totalmonth}} Month) </span>
                    <span *ngIf="sltpackagedata?.totalmonth==12"> (1 Year) </span>
                  </h4>
                  <div class="price-body">
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.cvname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip"
                          tooltip="View contact details of freelancers & bench employers" style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                        {{sltpackagedata?.resumeViewTotal}}
                      </div>
                    </div>
                  
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.jobname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip" tooltip="Post a Job to receive applications"
                          style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                      
                        Unlimited
                      </div>
                    </div>
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.benchname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip"
                          tooltip="Upload bench resource profile to deploy bench or get project" style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                        Unlimited
                      </div>
                    </div>
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.Brandingname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip"
                          tooltip="Customized campaign to be run  by backend team to increase visibility"
                          style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                        <div *ngIf="sltpackagedata?.planType==2">
                          <span *ngIf="packagedata?.essentials?.onlineBranding">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.essentials?.onlineBranding == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                        <div *ngIf="sltpackagedata?.planType==3">
                          <span *ngIf="packagedata?.premium?.onlineBranding">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.premium?.onlineBranding == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.supportname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip"
                          tooltip="Hiring support to be provided by hiring experts" style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                        <div *ngIf="sltpackagedata?.planType==2">
                          <span *ngIf="packagedata?.essentials?.customerSupport">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.essentials?.customerSupport == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                        <div *ngIf="sltpackagedata?.planType==3">
                          <span *ngIf="packagedata?.premium?.customerSupport">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.premium?.customerSupport == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row row-li">
                      <div class="col-sm-6">
                        {{packagedata?.label?.exportname}}
                        <i class="fa fa-info-circle pointer" data-toggle="tooltip"
                          tooltip="Each row will be counted as the resume view" style="color: #a9a7a7;"></i>
                      </div>
                      <div class="col-sm-6">
                        <div *ngIf="sltpackagedata?.planType==2">
                          <span *ngIf="packagedata?.essentials?.dataExport">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.essentials?.dataExport == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                        <div *ngIf="sltpackagedata?.planType==3">
                          <span *ngIf="packagedata?.premium?.dataExport">
                            <i class="fa fa-check text-green" style="color:#459033;"></i>
                          </span>
                          <span *ngIf="packagedata?.premium?.dataExport == false">
                            <i class="fa fa-close" style="color: #eb2213;"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-info">
                  <h4> Contact and Billing Information </h4>
                  <div class="contact-form">
                    <form [formGroup]="contactForm">
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label>Name:*</label>
                          <input #name type="text" class="form-control" formControlName="name">
                        </div>
                        <div class="col-sm-6">
                          <label>Company Name:*</label>
                          <input #cname type="text" class="form-control" formControlName="cname">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label>GST NO (Optional)</label>
                          <input #gst type="text" class="form-control" formControlName="gst">
                        </div>
                        <div class="col-sm-6">
                          <label>PAN NO:</label>
                          <input type="text" class="form-control" formControlName="panno">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label>Address*</label>
                          <input #address type="text" class="form-control" formControlName="address">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="click-pay">
                  <button type="button" class="btn btn-link change-link" (click)="changeplan()"> Change Plan </button> &nbsp;
                  <button type="button" class="btn btn-orange" (click)="clickpay()">Click to Pay </button>
                </div>
                <div *ngIf="perdiscount>0 || coin>0" class="div-top margin-b-15">
                  <div class="title-box  margin-b-15">
                    <h4> <img src="assets/images/icon/discount.png" width="30" /> Apply Offer</h4>
                  </div>
                  <div class="price-body">
                   
                    <div *ngIf="sltcoin>0" class="offer-list margin-t-10" [ngClass]="offerslt=='coin' ? 'box-shadow' : ''">
                      <div class="radio radio-info radio-inline">
                        <input type="radio" id="inlineRadio" name="offer" [(ngModel)]="offerslt" value="coin"
                          (change)="applyoffer($any($event.target).value)">
                        <label for="inlineRadio">
                          {{sltcoin}} Coin to be reimbursed.
                        </label>
                      </div>
                      <span *ngIf="offerslt=='coin'" class="remove-offer" (click)="removeOffer('coin')">
                        Remove
                      </span>
                    </div>
                    <div *ngIf="perdiscount>0" class="offer-list" [ngClass]="offerslt=='flat' ? 'box-shadow' : ''">
                      <div class="radio radio-info radio-inline">
                        <input type="radio" id="inlineRadio2" name="offer" value="flat" [(ngModel)]="offerslt"
                          (change)="applyoffer($any($event.target).value)">
                        <label for="inlineRadio2">
                          Flat {{perdiscount}} % discount
                        </label>
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div class="div-top">
                  <div class="title-box2">
                    <h4>Order Summary</h4>
                  </div>
                  <div class="price-body">
                    <div class="row row-li">
                      <div class="col-sm-6">
                        <span *ngIf="sltpackagedata?.planType == 2">
                          Essential
                        </span>
                        <span *ngIf="sltpackagedata?.planType == 3">
                          Premium
                        </span>
                        <span *ngIf="sltpackagedata?.planType == 4">
                          Customise
                        </span>
                        <span *ngIf="sltpackagedata?.planType == 5">
                          Enterprise
                        </span>
                      </div>
                      <div class="col-sm-6 text-right">
                        <span *ngIf="currency=='in'">
                          <i class="fa fa-inr"></i>
                        </span>
                        {{sltpackagedata?.totalamount}}
                      </div>
                    </div>
                    <div class="row row-li">
                      <div class="col-sm-6 coin">
                        Discount
                      </div>
                      <div class="col-sm-6 coin text-right">
                        -&nbsp;<span *ngIf="currency=='in'">
                          <i class="fa fa-inr"></i>
                        </span>{{discount}}
                      </div>
                    </div>
                    <div class="row row-li row-discount">
                      <div class="col-sm-6">
                        Total
                      </div>
                      <div class="col-sm-6 text-right" style="color:#09757a!important">
                        <span *ngIf="currency=='in'">
                          <i class="fa fa-inr"></i>
                        </span>
                        <span>
                          {{sltpackagedata?.totalamount-discount}}
                        </span>
                      </div>
                    </div>
                   
                    <div class="row row-li">
                      <div class="col-sm-6">
                        Tax Estimate
                      </div>
                      <div class="col-sm-6 text-right">
                        + <span *ngIf="currency=='in'"> <i class="fa fa-inr"></i> </span>
                        {{Mathceil(((sltpackagedata?.totalamount-discount) * sltpackagedata?.taxtper) / 100)}}
                      </div>
                    </div>
                    <div class="row row-li row-total">
                      <div class="col-sm-6">
                        Final Payable
                      </div>
                      <div class="col-sm-6 text-right" style="color:#09757a!important">
                        <span *ngIf="currency=='in'">
                          <i class="fa fa-inr"></i>
                        </span>
                        <span>
                          {{totalpay}}
                        
                        </span>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        
      </div>
    </div>
  </div>
</section>

<!-- tab button -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->


<!-- modals -->

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden(1)" bsModal #autoShownModal="bs-modal"
class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">Select Payment</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal(1)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="icon-pay" (click)="changeclickpayment('payu')">
              <img src="assets/images/icon/payumoney.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-12 checkout-icon">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="letstalkModalShown" [config]="{ show: true }" (onHidden)="onHidden(2)" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-auto-name" class="modal-title pull-left">Talk to us</h5>       
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal(2)">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <row>
          <div class="col-lg-12">
            <div class="t-p"> Phone : +91 97189 28125 </div>
            <div class="t-e"> Email : info&#64;OnBenchMark.com</div>
            <div class="l-m"> Leave a message &nbsp;
              <a href="https://www.onbenchmark.com/contact-us" target="_blank">Click Here</a>
            </div>
          </div>
        </row>
      </div>
    </div>
  </div>
</div>