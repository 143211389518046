<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2><span *ngIf="openstt == 1">Search Jobs</span> <span *ngIf="openstt == 2">Hire on Contract</span></h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->
<div style="background: black; margin-top: -30px; padding-top: 20px">
  <div class="container" style="margin-top: 30px">
    <div class="container" style="margin-top: 30px">
      <ul class="nav nav-tabs horizontalList" role="tablist">
        <div class="col-lg-4 col-md-12">
          <li class="" role="presentation" [ngClass]="{ activee: tab[0] }">
            <a href="javascript:void(0)" class="py-2" (click)="getList(1, 0)">
              <i class="fa-solid fa-thumbs-up"></i>
              <!-- <i class='bx bxs-like bxIconSize'></i> -->
              <span> Jobs </span>
            </a>
          </li>
        </div>

        <div class="col-lg-4 col-md-12">
          <li role="presentation" [ngClass]="{ activee: tab[1] }">
            <a href="javascript:void(0)" class="py-2" (click)="getList(2, 1)">
              <!-- <i class='bx bx-list-check bxIconSize'></i> -->
              <i class="fa-solid fa-clipboard-check"></i>
              <span> Hire on Contract </span>
            </a>
          </li>
        </div>

        <div class="col-lg-4 col-md-12" style="pointer-events: none">
          <li role="presentation" [ngClass]="{ activee: tab[2] }">
            <a href="javascript:void(0)" class="py-2" (click)="getList(3, 2)">
              <i class="fa-solid fa-circle-check"></i>
              <span> Find Experts </span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>

<div class="container" style="margin-top: -50px">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card_Border">
        <div class="card-body">
          <!-- Tabs -->

          <!-- tabContents -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active">
              <div class="row form-group">
                <div class="row">
                  <div class="post-job-area">
                    <div class="container" style="margin-top: 30px">
                      <div class="post-item" *ngIf="!searched">
                        <!-- <div class="section-title">
                          <h2>
                            Search -
                            <span *ngIf="openstt == 1">Jobs / Projects</span>
                            <span *ngIf="openstt == 2">Hire on Contract</span>
                            <span *ngIf="openstt == 3">Hire Freelancers</span>
                          </h2>
                        </div> -->
                        <div class="row form-group">
                          <!-- First input section -->
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>All Keywords (Must Have)<span class="text-danger">*</span></label>
                              <div>
                                <span class="my-slt-li" *ngFor="let list of jobsAllSkill; let i = index">
                                  <span class="slt-name">{{ list }} </span>
                                  <span class="cls-close text-light" (click)="removeItemJobsAllSkill(i)">
                                    <i class="bx bx-x-circle bxCloseIcon mb-0 pb-0"></i>
                                  </span>
                                </span>
                              </div>
                              <input #menu1 type="text" class="form-control" placeholder="Optional skills, designation role, JobId or Bench ID separated by comma" [(ngModel)]="search.allskill" (keyup)="getsltSkillsAll($any($event.target).value)" maxlength="40" />

                              <div #menudrop1 id="myDropdown1" class="dropdownContent_skills {{ cls1 }}" *ngIf="activeDropdown == 'dropdown1'">
                                <a *ngFor="let item of templist" (click)="setskillvalueall(item?.name)" class="salectchanged system">
                                  {{ item.name }}
                                </a>
                              </div>
                            </div>
                          </div>

                          <!-- Second input section -->
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Any Keywords<span class="text-danger">*</span></label>
                              <div>
                                <span class="my-slt-li" *ngFor="let list of jobsAnySkill; let i = index">
                                  <span class="slt-name">{{ list }} </span>
                                  <span class="cls-close text-light" (click)="removeItemJobsAnySkill(i)">
                                    <i class="bx bx-x-circle bxCloseIcon mb-0 pb-0"></i>
                                  </span>
                                </span>
                              </div>
                              <input #menu2 type="text" class="form-control" placeholder="Mandatory skills separated by comma" [(ngModel)]="search.anyskill" (keyup)="getsltSkillsAny($any($event.target).value)" maxlength="40" />

                              <div #menudrop2 id="myDropdown2" class="dropdownContent_skills {{ cls2 }}" *ngIf="activeDropdown == 'dropdown2'">
                                <a *ngFor="let item of templist" (click)="setskillvalueany(item?.name)" class="salectchanged system">
                                  {{ item.name }}
                                </a>
                              </div>
                            </div>
                          </div>

                          <!-- Search In dropdown -->
                          <div class="col-lg-6 col-md-12" *ngIf="openstt == 1">
                            <div class="form-group">
                              <label>Search In<span class="text-danger">*</span></label>
                              <ng-select [searchable]="false" [multiple]="false" [(ngModel)]="advanceSearch.searchIn" placeholder="Select Job">
                                <ng-option value="any"> Any </ng-option>
                                <ng-option value="jobTitle"> Job Title </ng-option>
                                <ng-option value="jobTitleKeySkills"> Job Title & Key Skills </ng-option>
                                <ng-option value="jobDescription"> Job Description </ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12" *ngIf="openstt == 2">
                            <div class="form-group">
                              <label>Search In<span class="text-danger">*</span></label>
                              <ng-select [searchable]="false" [multiple]="false" [(ngModel)]="advanceSearch.searchIn" placeholder="Select Job">
                                <ng-option value="1"> Any </ng-option>
                                <ng-option value="2"> Resume Title & Key Skills </ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <!-- Location input -->
                          <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                              <label>Location</label>
                              <input type="text" class="form-control" [(ngModel)]="advanceSearch.location" placeholder="Search city name, Select city and country" (keyup)="get_city($any($event.target).value)" id="locationId" (change)="locationclear()" autocomplete="off" />

                              <div id="myDropdownLocation" class="dropdownContent_city {{ cls }}" #dropdownLocation>
                                <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item?.country_id?.country_name, item._id)"> {{ item.city_name }}, {{ item?.state_id?.state_name }}, ({{ item?.country_id?.country_name }}) </a>
                                <a (click)="othercityslt()"> Other City </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="submit-btn" (click)="searchAdvance()">
                          <!-- [disabled]="addJobForm.invalid" -->
                          <button type="button" class="buttn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Jobs -->
<div *ngIf="openstt == 1 && searched" class="job-area-list">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all">
            <!-- <div class="level" style="margin-top: -50px; margin-bottom: 20px">
              <form>
                <button (click)="searched = false" class="btn" style="background-color: #ccc; border: 1px dotted #222; color: #222">Modify Search</button>
              </form>
            </div> -->

            <div class="search row" style="border-bottom: 0.5px solid #ccc">
              <div class="col-lg-10">
                <h3 class="mb-0">Jobs</h3>
              </div>
              <div class="col-lg-2 text-end">
                <span class="pointer pull-right text-end" (click)="reSetJobsFilter()" tooltip="Reset Filters">
                  <i class="fa-solid fa-rotate re_iconSize"></i>
                </span>
              </div>
            </div>

            <!-- <div class="search" style="border-bottom: none">
              <h3 style="padding-bottom: 10px; border-bottom: 0.5px solid #ccc">Jobs</h3>
              <span class="pointer pull-right text-end" (click)="reload()"  tooltip="Reset Filters">
                <i class="fa-solid fa-rotate re_iconSize"></i>
              </span>
            </div> -->

            <div class="experience mt-3" style="border-top: none">
              <h3 class="mb-1 pb-1">Work From</h3>
              <ng-select [searchable]="false" style="border-bottom: none" [multiple]="false" [(ngModel)]="search.workFrom" placeholder="Select Job">
                <ng-option value="All"> All</ng-option>
                <ng-option value="Offsite"> Remote </ng-option>
                <ng-option value="Onsite"> On-Site </ng-option>
                <ng-option value="Any"> Anywhere </ng-option>
              </ng-select>
            </div>

            <div class="job-type cmn-padding">
              <h3 class="mb-1 pb-1">Rate</h3>
              <div class="job-cmn-cat flex-divide">
                <input [(ngModel)]="search.rate" type="text" value="" id="defaultCheck48" class="form-control" placeholder="Enter Rate" (keypress)="numberOnly($event)" />
              </div>
            </div>

            <div class="experience cmn-padding">
              <h3 class="mb-1 pb-1">Experience</h3>

              <div class="job-cmn-cat flex-divide">
                <input [(ngModel)]="search.experinece" type="text" value="" id="defaultCheck48" class="form-control" placeholder="Enter Experience in Years" (keypress)="numberOnly($event)" />
              </div>
            </div>

            <div class="level">
              <form>
                <button (click)="searchAdvance()" type="submit" class="btn">Filter Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <!-- <ul class="job-list-item">
          <li> -->
        <!-- <a href="javascript:void(0)">Results Found <span>({{ jobs.total }})</span></a> -->
        <!-- sudattChanges -->
        <div class="row mb-3">
          <div class="col-lg-4 col-md-12">
            <h4>
              Results Found <span>({{ jobs.total }})</span>
            </h4>
          </div>
          <div class="col-lg-4 col-md-6">
            <button (click)="searched = false" class="btn" style="background-color: #ccc; border: 1px dotted #222; color: #222">Modify Search</button>
          </div>

          <div class="col-lg-4 col-md-6 text-end">
            <div class="pagination-area">
              <ul>
                <li style="padding: 0px 10px">Page {{ currentPage }} of {{ pages }}</li>
                <li *ngIf="currentPage != 1"><a style="cursor: pointer" (click)="previousPage()">Prev</a></li>
                <li *ngIf="currentPage != pages"><a style="cursor: pointer" (click)="nextPage()">Next</a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end of SudattChanges -->
        <!-- </li>
          <li></li>
        </ul> -->

        <div *ngFor="let job of jobs.data" class="employer-item" style="position: relative">
          <div style="position: absolute; right: 60px; top: -20px">
            <img style="width: 30px" *ngIf="job.seenstatus" src="assets/img/seen-profile.png" />
          </div>
          <div class="row">
            <div class="col-lg-8">
              <!-- [routerLink]="['/job-details']" [queryParams]="{ jobId: job?._id }" -->
              <a>
                <!-- <img src="assets/img/home-one/job1.png" alt="Employer" /> -->
                <a class="pointer" [routerLink]="['/job-details']" target="_blank" [queryParams]="{ jobId: job?._id }">
                  <h3 class="mb-0">{{ job?.title }}</h3>
                  <p>
                    (JID : <span class=" ">{{ job?.resourceId }}</span
                    >)
                  </p>
                </a>

                <ul class="mb-0 text-dark">
                  <li>
                    <i class="fa-solid fa-location-dot text-dark"></i>
                    <span class="text-secondary"> {{ job?.location[0]?.location }}, {{ job?.location[0]?.country }} </span>
                  </li>

                  <!-- <li>
                    {{ job.updateDate | date: "dd MMM, yyyy" }}
                  </li> -->
                </ul>

                <!-- <div style="color: #222">
                  Work From : <span class="text-info">{{ job?.workFrom }}</span>
                </div> -->

                <!-- <span style="color: #222">{{ job.experiencemin }}-{{ job.experiencemax }} Years</span> -->
                <!-- <span style="padding: 0xp 20px; color: #ccc"> | </span>
                <i class="fa-solid fa-indian-rupee-sign text-dark me-1"></i>
                <span style="color: #222">{{ job.ratemin | number: "1.2-2" }}-{{ job.ratemax | number: "1.2-2" }} {{ job.ratetype }}</span> -->
                <!-- <span style="padding: 0xp 20px; color: #ccc"> | </span>  -->
                <span *ngIf="!job?.parmanant" class="badge bg-info"> <i class="fas fa-check"></i> Contract </span>
                <span *ngIf="job?.freelance" class="badge bg-warning"> <i class="fas fa-check"></i> Freelance / Part-time </span>

                <ul>
                  <!-- <li>Category: {{ job.jobtype }}</li> -->
                </ul>

                <span *ngFor="let s of job?.skill" class="span-one" style="margin: 2px 2px">{{ s }}</span>
              </a>
            </div>
            <div class="col-lg-4 pt-4">
              <span>
                <p class="mb-0" style="color: #222">
                  Rate :
                  <span class="text-info">
                    <i class="fa-solid fa-indian-rupee-sign me-1"></i>
                    {{ parseRate(job?.ratemin) }}-{{ parseRate(job?.ratemax) }} Monthly
                  </span>
                </p>
              </span>
              <span>
                <p class="mb-0" style="color: #222">
                  Experience : <span class="text-info">{{ job?.experiencemin }}-{{ job?.experiencemax }} Years</span>
                </p>
              </span>
              <span>
                <p class="mb-0" style="color: #222">
                  Last Active Date :
                  <span class="text-info"> {{ job?.updateDate | date: "dd MMM, yyyy" }}</span>
                </p>
              </span>
              <span>
                <p class="mb-0" style="color: #222">
                  Work From :
                  <span class="text-info"> {{ job?.workFrom }}</span>
                </p>
              </span>
            </div>
          </div>
        </div>

        <!-- <div class="pagination-area">
          <ul>
            <li><a routerLink="/jobs">Prev</a></li>
            <li><a routerLink="/jobs">1</a></li>
            <li><a routerLink="/jobs">2</a></li>
            <li><a routerLink="/jobs">3</a></li>
            <li><a routerLink="/jobs">Next</a></li>
          </ul>
        </div> -->

        <div class="pagination-area" style="padding-bottom: 30px">
          <ul>
            <li style="padding: 0px 10px">Page {{ currentPage }} of {{ pages }}</li>
            <li *ngIf="currentPage != 1"><a style="cursor: pointer" (click)="previousPage()">Prev</a></li>
            <li *ngIf="currentPage != pages"><a style="cursor: pointer" (click)="nextPage()">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- =================== Hire-On-Contract================= -->
<div *ngIf="openstt == 2 && searched" class="job-area-list">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="job-list-right">
          <div class="job-list-all">
            <!-- <div class="level" style="margin-top: -50px; margin-bottom: 20px">
              <form>
                <button (click)="searched = false" class="btn" style="background-color: #ccc; border: 1px dotted #222; color: #222">Modify Search</button>
              </form>
            </div> -->

            <!-- <div class="search">
              <h3 class="pb-0 mb-0">Hire on Contract</h3>
            </div> -->

            <div class="search row" style="border-bottom: 0.5px solid #ccc">
              <div class="col-lg-10">
                <h3 class="mb-0">Hire on Contract</h3>
              </div>
              <div class="col-lg-2 text-end">
                <span class="pointer pull-right text-end" (click)="reSetHireOnConractFilters()" tooltip="Reset Filters">
                  <i class="fa-solid fa-rotate re_iconSize"></i>
                </span>
              </div>
            </div>

            <div class="search my-3" style="border-bottom: none">
              <h3 class="mb-1 pb-1">Deployment</h3>
              <ng-select [searchable]="false" style="border-bottom: none" [multiple]="false" [(ngModel)]="search.deployment" placeholder="Select Deployment">
                <ng-option value="Any"> Anywhere </ng-option>
                <ng-option value="All"> All</ng-option>
                <ng-option value="Offsite"> Remote </ng-option>
                <ng-option value="Onsite"> Onsite </ng-option>
              </ng-select>
            </div>

            <div class="search mb-3" style="border-bottom: none">
              <h3 class="mb-1 pb-1">Availability</h3>
              <ng-select [searchable]="false" style="border-bottom: none" [multiple]="false" [(ngModel)]="search.availability" placeholder="Select Availability">
                <ng-option value="All"> All</ng-option>
                <ng-option value="Immediate"> Immediate </ng-option>
                <ng-option value="1_Week"> 1 Week </ng-option>
                <ng-option value="2_Week"> 2 Week </ng-option>
                <ng-option value="3_Week"> 3 Week </ng-option>
                <ng-option value="1_Month"> 1 Month </ng-option>
                <ng-option value="More_1_Month"> More than 1 Month </ng-option>
              </ng-select>
            </div>

            <div class="job-type cmn-padding mb-3 mt-1 pt-1 mb-0 pb-0">
              <h3 class="mb-1 pb-1">Rate</h3>
              <!-- <div class="job-cmn-cat flex-divide">
                <input [(ngModel)]="search.rate" type="text" value="" id="defaultCheck48" placeholder="Enter Rate" />
              </div> -->

              <div class="job-cmn-cat flex-divide">
                <input style="width: 45%; float: left" [(ngModel)]="search.rateMin" type="text" value="" id="defaultCheck481" placeholder="Enter Min" />
                <div style="width: 10%; float: left; text-align: center; line-height: 40px">to</div>
                <input style="width: 45%; float: left" [(ngModel)]="search.rateMax" type="text" value="" id="defaultCheck482" placeholder="Enter Max" />
              </div>
            </div>

            <div class="experience cmn-padding mb-3 mt-1 pt-1 mb-0 pb-0">
              <h3 class="mb-1 pb-1">Experience</h3>

              <div class="job-cmn-cat flex-divide">
                <input style="width: 45%; float: left" [(ngModel)]="search.experineceMin" type="text" value="" id="defaultCheck485" placeholder="Enter Min" />
                <div style="width: 10%; float: left; text-align: center; line-height: 40px">to</div>
                <input style="width: 45%; float: left" [(ngModel)]="search.experineceMax" type="text" value="" id="defaultCheck486" placeholder="Enter Max" />
              </div>
            </div>

            <div class="level">
              <form>
                <button (click)="searchAdvance()" type="submit" class="btn">Filter Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <!-- <ul class="job-list-item align-items-center">
          <li> -->

        <div class="row mb-3">
          <div class="col-lg-4 col-md-12">
            <h4>
              Results Found <span>({{ resources.total }})</span>
            </h4>
          </div>
          <div class="col-lg-4 col-md-6">
            <button (click)="searched = false" class="btn" style="background-color: #ccc; border: 1px dotted #222; color: #222">Modify Search</button>
          </div>

          <div class="col-lg-4 col-md-6 text-end">
            <div class="pagination-area">
              <ul>
                <li style="padding: 0px 10px">Page {{ currentPage_job }} of {{ pages_jobs }}</li>
                <li *ngIf="currentPage_job != 1"><a style="cursor: pointer" (click)="previousPage_jobs()">Prev</a></li>
                <li *ngIf="currentPage_job != pages_jobs"><a style="cursor: pointer" (click)="nextPage_jobs()">Next</a></li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="row   mb-3">
              <div class="col-lg-12 d-flex justify-content-end">
                <div class="pagination-area" >
                  <ul>
                    <li style="padding: 0px 10px">Page {{ currentPage_job }} of {{ pages_jobs }}</li>
                    <li *ngIf="currentPage_job != 1"><a style="cursor: pointer" (click)="previousPage_jobs()">Prev</a></li>
                    <li *ngIf="currentPage_job != pages_jobs"><a style="cursor: pointer" (click)="nextPage_jobs()">Next</a></li>
                  </ul>
                </div>
              </div>
            </div> -->

        <!-- </li>
        </ul> -->

        <div *ngFor="let bench of resources.data" style="position: relative" class="employer-item" [ngClass]="{ 'red-border': bench?.status == false }">
          <!-- (click)="openBenchDetails(bench)" -->
          <div style="position: absolute; right: 60px; top: -20px">
            <img style="width: 30px" *ngIf="bench.seenstatus" src="assets/img/seen-profile.png" />
          </div>
          <div class="row">
            <div class="col-lg-8">
              <a [routerLink]="['/candidate-details']" target="_blank" [queryParams]="{ userId: bench?._id }">
                <h3>
                  {{ bench?.fname }} <span style="font-weight: 300; font-size: 16px">(RID: {{ bench?.resourceId }})</span>
                </h3>
              </a>
              <ul class="mb-0">
                <li class="mb-0">
                  <i class="fa-solid fa-location-dot text-dark"></i>
                  <!-- , {{ bench?.currentcountry }} -->
                  <span class="text-secondary">{{ bench?.currentlocation }}</span>
                </li>
                <!-- <li>
                  {{ bench.createdAt | date: "dd MMM, yyyy" }}
                </li> -->
                <!-- <li style="color: #222">| <span class="text-dark">Deployment Type :</span> {{ bench.onsiteAvailability }}</li> -->
              </ul>
              <div>
                <span style="color: #222">
                  Deployment Type : <span class="text-info">{{ bench?.onsiteAvailability }}</span>
                </span>
              </div>
              <ul>
                <li><span class="text-dark">Designation :</span> {{ bench?.designation | titlecase }}</li>
                <!-- <li style="color: #222">| <span class="text-dark"> Rate :</span>
                  {{ bench.rate | number: "1.2-2" }} {{ bench.ratetype }}
                </li> -->
              </ul>
              <ul>
                <!-- <li><span class="text-dark">Category :</span> {{ bench?.jobtype | titlecase }}</li> -->
              </ul>
              <!-- <ul>
                <li><span class="text-dark">Experience :</span> {{ bench.experience }} Yrs</li>
              </ul> -->

              <span *ngFor="let s of bench?.skill" class="span-one" style="margin: 2px 2px">
                {{ s }}
              </span>
            </div>
            <div class="col-lg-4 pt-5">
              <p class="mb-0 pb-0">
                Rate : <span class="text-info"> ₹ {{ parseRate(bench?.rate) }} Monthly</span>
              </p>
              <p class="mb-0 pb-0">
                Experience : <span class="text-info">{{ bench?.experience }} Years</span>
              </p>
              <p class="mb-0 pb-0">
                Last Active Date : <span class="text-info"> {{ bench?.updateDate | date: "dd MMM, yyyy" }}</span>
              </p>
              <p class="mb-0 pb-0">
                Availability : <span class="text-info">{{ parseAvailability(bench?.Availability) }}</span>
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="pagination-area">
          <ul>
            <li><a routerLink="/jobs">Prev</a></li>
            <li><a routerLink="/jobs">1</a></li>
            <li><a routerLink="/jobs">2</a></li>
            <li><a routerLink="/jobs">3</a></li>
            <li><a routerLink="/jobs">Next</a></li>
          </ul>
        </div> -->

        <div class="pagination-area" style="padding-bottom: 30px">
          <ul>
            <li style="padding: 0px 10px">Page {{ currentPage_job }} of {{ pages_jobs }}</li>
            <li *ngIf="currentPage_job != 1"><a style="cursor: pointer" (click)="previousPage_jobs()">Prev</a></li>
            <li *ngIf="currentPage_job != pages_jobs"><a style="cursor: pointer" (click)="nextPage_jobs()">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
