<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container mt-50" >
        <div class="row align-items-end">
          <div class="col-lg-8 col-md-12">
            <div class="left">
              <h2>{{ candidate.name }}</h2>
              <ul>
                <li><i class="bx bx-box"></i> UX/UI Designer</li>
                <li><i class="fa-solid fa-location-dot"></i> {{ candidate.currentlocation }}</li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-12">
            <div class="right">
              <ul>
                <li>
                  <a>{{ candidate.jobtype }}</a>
                </li>
              </ul>
            </div>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div class="work bottom-item">
            <h3>Skills</h3>
            <span *ngFor="let s of candidate.skill" class="span-one" style="margin: 2px 2px">{{ s }}</span>
          </div>

          <div class="work bottom-item">
            <h3>Description</h3>

            <div [innerHTML]="candidate.description"></div>
          </div>

          <div class="portfolio">
            <h3>Resume</h3>

            <div style="padding: 20px; border: 1px solid #ccc; border-radius: 10px; background: #ececec !important" [innerHTML]="candidate.dochtml[0]"></div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <div class="d-grid gap-2 mb-4">
            <!-- <a routerLink="/">Deactivate</a>
            <a routerLink="/">Edit</a> -->
            <button type="button" class="btn btn-info" routerLink="/">Download CV</button> 
          </div>

          <div class="information widget-item">
            <h3>Candidate Overview</h3>

            <ul>
              <li>
                <h4>Rate:</h4>
                <span>${{ candidate.rate | number: "1.2-2" }} ({{ candidate.ratetype }})</span>
              </li>
              <li>
                <h4>Availability:</h4>
                <span>{{ candidate.Availability }}</span>
              </li>
              <!-- <li>
                <h4>Graduation:</h4>
                <span>{{ candidate.ugType[0].ug }}</span>
              </li> -->
              <li>
                <h4>Experience:</h4>
                <span>{{ candidate.experience }} Years</span>
              </li>
            </ul>
          </div>

          <div class="similar-candidates">
            <h4>Matching Jobs / Projects</h4>
          </div>

          <div class="candidate-item" *ngFor="let job of jobs">
            <div class="left">
              <h3>{{ job.title }}</h3>
              <ul>
                <li *ngFor="let s of job.skill">{{ s }}</li>
              </ul>
              <div class="cmn-link">
                <a [routerLink]="['/job-details']" [queryParams]="{ jobId: job?._id }"><i class="flaticon-right-arrow one"></i> View Job / Project <i class="flaticon-right-arrow two"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
