import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/common.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss'
})
export class SupportComponent implements OnInit  { 

  page = "Create Ticket";
  ticketForm: any;
  ticketData: any;
  btn = "Submit";
  fileupload:any;
  cPage: any = { totalItems: 0, currentPage: 1, pageSize: 5 };
  cpagedata: any = {};
  clistItems: any = [];
  PagefinalDetails: any = [];
  liststt=true;
  shimmer=true;  
  
  constructor(
    private formBuild: FormBuilder,
    private supportService: SupportService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private router: Router

  ) {  }

  ngOnInit() {
    this.loader.stopLoading();
    this.geticket(); 
    this.ticketFormCreate();
  }

  ticketFormCreate() {
    this.ticketForm = this.formBuild.group({
      "subject": ['' ,  [Validators.required,Validators.maxLength(100)] ],
      "imageurl" : [''],
      "description": ['' ,[Validators.required,Validators.maxLength(500)]]
    });
  }

  ticketFormSubmit() {
      let data = this.ticketForm.value;
      this.supportService.create(this.ticketForm.value, 'ticket').then((res: any) => {
          this.toaster.success('ticket Added Successfully');
          this.ticketFormCreate();
          this.liststt=true; 
          this.ticketData.unshift(res.data);
          // this.setPage(1);
      }, err => {
        console.log(err);
        this.toaster.error('Something went wrong');
      })
  }

  reopenticket(ids:any){
    this.supportService.update(ids, this.ticketForm.value, 'ticket').then((res: any) => {
      this.ticketFormCreate();
      this.btn = 'Submit';
      this.toaster.success('ticket Updated Successfully');
    }, err => {
      console.log(err);
      this.toaster.error('Something went wrong')
    })
  }

  geticket() {
    this.shimmer=true;
    this.supportService.findTicket( JSON.stringify({}) ,'ticket' ).then((res: any) => {
      this.ticketData = res.data;
      this.shimmer=false;
      this.setPage(1);
    }, err => {
      this.shimmer=false;
      console.log(err);
    });
  }

  onFileSelect(event:any) {
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
      console.log(this.fileupload);
      this.onSubmit();
    }
  }

  onSubmit() {
    let fd = new FormData();
    fd.append('image', this.fileupload);
    fd.append('baseurl', this.supportService.getbaseurl());
    this.supportService.commonUpload(fd, 'ticketdoc').then((res: any) => {
      this.ticketForm.get('imageurl').setValue(res.imageurl);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  setPage(page:any) {
    this.cPage.currentPage = page;
    this.cPage.totalItems = this.ticketData.length;
    // this.cpagedata = this.pagination.getPager(this.cPage.totalItems, this.cPage.currentPage, this.cPage.pageSize);
    this.PagefinalDetails = this.ticketData.slice(this.cpagedata.startIndex, this.cpagedata.endIndex + 1);
  }

  changestt(){
    this.liststt = !this.liststt;
  }






}
