import { FormGroup } from '@angular/forms';

export function MustMatch(newpassword: string, confirmpassword: string){
    return(formGroup: FormGroup) => {
        const newPswdControle = formGroup.controls[newpassword];
        const confrmPswdControle = formGroup.controls[confirmpassword];

        if(confrmPswdControle.errors && !confrmPswdControle.errors['mustMatchError']){
            return;
        }

        if(newPswdControle.value !== confrmPswdControle.value){
            confrmPswdControle.setErrors({mustMatchError: true});
        }else{
            confrmPswdControle.setErrors(null); 
        }
    }
}