<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Contact</h2>
        
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- contactUs_page -->
<div class="container pb-2 pt-5">
  <div class="row d-flex">
    <!-- InfoSection -->
    <!-- <div class="col-lg-6 align-self-end">
      <div class="contact-info-area pb-100">
        <div class="info-item">
          <i class="bx bx-world"></i>
          <h3>India Office</h3>
          <p>
            BSI Business Park, H-161, G10, Sector 63, Noida, Uttar Pradesh
            201301
          </p>
          <ul>
            <li>
              <span>Mobile:</span>
              <a href="tel:+919718928125">+91 97189 28125</a>
            </li>
            <li>
              <span>Email:</span>
              <a href="https://www.onbenchmark.com/">info&#64;onbenchmark.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <!-- end of InfoSection-->
    <!-- contactFormSection -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="contact-form-area pb-100">
        <div class="form-item">
          <form [formGroup]="addContactUsForm" id="contactForm">
            <div class="row">
              <!-- name -->
              <div class="col-sm-6 col-lg-12">
                <div class="form-group">
                  <label>Name<span class="text-danger">*</span></label>
                  <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Enter Full Name" [ngClass]="{'is-invalid': formCreateContactUs.name.invalid && formCreateContactUs.name.touched || formCreateContactUs.name.invalid && submitted, 'is-valid': formCreateContactUs.name.valid}" />
                  <div *ngIf="formCreateContactUs.name.hasError('pattern')">
                    <small class="text-danger" >Please enter only letters.</small>
                  </div>
                  <div *ngIf="formCreateContactUs.name.invalid && formCreateContactUs.name.touched || formCreateContactUs.name.invalid && submitted">
                    <small class="text-danger" >Name is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <label>Email<span class="text-danger">*</span></label>
                  <input type="email" name="email" id="email" formControlName="email" class="form-control" placeholder="Enter Email" [ngClass]="{'is-invalid': formCreateContactUs.email.invalid && formCreateContactUs.email.touched || formCreateContactUs.email.invalid && submitted, 'is-valid': formCreateContactUs.email.valid}" />
                  <div *ngIf="formCreateContactUs['email'].errors">
                    <div *ngIf="formCreateContactUs['email'].errors['pattern']">
                      <small class="text-danger" >Email should be <b>valid. </b></small>
                      <!-- <small class="text-danger" >Email should be <b>valid. </b>eg; abc@domainName.com</small> -->
                    </div>
                  </div>  
                  <div *ngIf="formCreateContactUs.email.invalid && formCreateContactUs.email.touched || formCreateContactUs.email.invalid && submitted">
                    <small class="text-danger" >Email is <b>required.</b></small>
                  </div>
                </div>
              </div> 
              <!-- phone -->
              <div class="col-sm-6 col-lg-6">
                <div class="form-group">
                  <label>Phone<span class="text-danger">*</span></label>
                  <input (keypress)="numberOnly($event)" type="text" name="phone_number" id="phone_number" formControlName="phone" class="form-control" placeholder="Enter Contact Number" [ngClass]="{'is-invalid': formCreateContactUs.phone.invalid && formCreateContactUs.phone.touched || formCreateContactUs.phone.invalid && submitted, 'is-valid': formCreateContactUs.phone.valid}" />
                  <div *ngIf="formCreateContactUs.phone.invalid && formCreateContactUs.phone.touched || formCreateContactUs.phone.invalid && submitted">
                    <small class="text-danger" >Phone no. is <b>required.</b></small>
                  </div> 
                  <div *ngIf="formCreateContactUs['phone'].errors">
                    <div *ngIf="formCreateContactUs['phone'].errors['pattern']">
                      <small class="text-danger" >Please enter only <b>numbers.</b></small>
                    </div>
                    <div *ngIf="formCreateContactUs['phone'].errors['minlength']">
                      <small class="text-danger" >Phone no. should have atleast <b>10 digits.</b></small>
                    </div>
                    <div *ngIf="formCreateContactUs['phone'].errors['maxlength']">
                      <small class="text-danger" >Phone no. could have max <b>10 digits.</b></small>
                    </div>
                  </div>                   
                </div>
              </div>
              <!-- message -->
              <div class="col-md-12 col-lg-12">
                <div class="form-group">
                  <label>Message<span class="text-danger">*</span></label>
                  <textarea name="message" id="message" formControlName="description"  class="form-control"  placeholder="Type your message here..." cols="30" rows="4" [ngClass]="{'is-invalid': formCreateContactUs.description.invalid && formCreateContactUs.description.touched || formCreateContactUs.description.invalid && submitted, 'is-valid': formCreateContactUs.description.valid}" ></textarea>
                  <div *ngIf="formCreateContactUs.description.invalid && formCreateContactUs.description.touched || formCreateContactUs.description.invalid && submitted">
                    <small class="text-danger" >Message is <b>required.</b></small>
                  </div> 
                </div>
              </div>
              <!-- captcha -->
              <div class="col-md-12 col-lg-12">
                <div class="form-group">
                  <re-captcha formControlName="captcha" (resolved)="resolvedCaptcha($event)" siteKey="YOUR_SITE_KEY"></re-captcha>
                  <div *ngIf="formCreateContactUs.captcha.invalid && formCreateContactUs.captcha.touched || formCreateContactUs.captcha.invalid && submitted">
                    <small class="text-danger">Captcha is <b>required.</b></small>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-12">
                <button type="button" (click)="onSubmit()" class="btn">Send Message</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- end of contactFormSection-->
  </div>
</div>
<!-- end of contactUs_page-->

<!-- Info -->

<!-- End Info -->

<!-- Contact -->

<!-- End Contact -->

<!-- Map -->
<div class="map-area">
  <div class="container-fluid p-0">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d14009.254801865813!2d77.36922062786958!3d28.620358796720314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d28.616294399999997!2d77.3783552!4m5!1s0x390cef5321cb0e41%3A0x57270daf4735cf25!2scosette%20network%20pvt%20ltd!3m2!1d28.6252107!2d77.3784148!5e0!3m2!1sen!2sin!4v1723029712704!5m2!1sen!2sin"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>

<!-- End Map -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
