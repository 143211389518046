<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Post Your Job For Free</h2>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->

<div class="free-job-post">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="about-content">
                    <div class="section-title">
                       
                        <!-- <h2>Post Your Job For Free</h2> -->
                        <p class="sub-title-right">OnBenchMark is one of the best free job posting sites for hiringContract resourcesand freelancers . Reach the top talent!
                        </p>
                        <img src="assets/img/hire-expert.png" alt="New">
                    </div>
                </div> 
            </div>

            <div class="col-md-6">
                <form formGroup="budgets">
                    <div class="JobPostForm">
                        <div class="row">

                            <div class="col-md-6">
                                <label for="email">Email*</label>
                                <input id="email" type="email" name="email" required />
                            </div>

                            <div class="col-md-6">
                                <label for="phone">Phone*</label>
                                <input id="phone" type="tel" name="phone" pattern="[0-9]+" required />
                            </div>


                            <div  class="col-lg-12">
                                <label for="jobTitle">Job Title*</label>
                                <input id="jobTitle" type="text" name="jobTitle" required />
                            </div>


                            <div class="col-lg-12">
                               
                                <label for="budget">Budget*</label>
                                <ng-select 
                                  [items]="budgets" 
                                  bindLabel="name" 
                                  bindValue="id" 
                                  placeholder="Select Budget" 
                                  formControlName="budget"
                                  required>
                                </ng-select>
                              </div>


                              <div class="col-lg-12">
                                <label for="jobDescription">Job Description*</label>
                                <textarea id="jobDescription" name="jobDescription" required></textarea>
                            </div>

                            <div class="col-lg-12">
                              <button class="submit-btn" type="submit">Submit</button>
                          </div>


                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- End New -->


<!-- Work -->
<section class="work-area bg-light two three pt-100 pb-70">
    <div class="container">
      <div class="section-title three">
        <div class="sub-title-wrap">
          <!-- <img src="assets/img/obm-favicon.png" class="favicon-img" alt="Icon"> -->
          <span class="sub-title">Working Process</span>
        </div>
        <h2>How It Works</h2>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div class="work-item two three">
            <i class="flaticon-accounting"></i>
            <h3>Register An Account</h3>
            <p>Sign Up for free If you have a hiring need for Immediate Joiner or have bench employees / looking for clients.</p>
          </div>
        </div>
  
        <div class="col-sm-6 col-lg-4">
          <div class="work-item two three">
            <i class="flaticon-file"></i>
            <h3>Upload Bench /Post Job</h3>
            <p>Post a job if you need immediate joiner on contract/ part-time. Upload profile of employees to deploy to clients</p>
          </div>
        </div>
  
        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
          <div class="work-item two three work-border">
            <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
            <h3>Connect Directly</h3>
            <p>Connect directly with clients or bench employer and hire/deploy resources.<br><br></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Work -->

<!-- Explore -->
<section class="explore-area area-bg ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
            <h2 class="text-light">Trusted by 3,500+ companies</h2>
            <p>Our strong community of businesses works in collaboration and they are our biggest asset.</p>
            
            <h4 class="text-light">Connect & collaborate</h4>
            <p>Join networked community of companies to deploy & bill your bench on contract or get projects</p>

            <h4 class="text-light">Top talent on demand</h4>
            <p>Hire the top rated Contract /part-time talent in a day</p>

            <h4 class="text-light">Get Immediate Joiner</h4>
            <p>Get immediate joiner on contract/ part-time from the biggest pool of bench.</p>
        </div>

        </div>
    </div>
</section>
<!-- End Explore -->



<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

