import { Component } from '@angular/core';

@Component({
  selector: 'app-hire-candidates-on-contract',
  // standalone: true,
  // imports: [],
  templateUrl: './hire-candidates-on-contract.component.html',
  styleUrl: './hire-candidates-on-contract.component.scss'
})
export class HireCandidatesOnContractComponent {
  videoId = '4nb5LA75_0Q'; // Set your video ID here

}


