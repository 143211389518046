<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Expert Dashboard</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="counter-area two pt-100">
  <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" /></div>

  <div class="container">
    <div class="row">
        <div class="col-sm-6 col-lg-3">
            <div routerLink="/expert-applied-history" class="counter-item pt-3">
              <a href="javascript:void(0)">
                <i class="fa-solid fa-list-check"></i>
                <h5 class="text-info">Applied History</h5>
                <!-- <h3>
                  <span>{{ freelancerDashboardData.mycandidate }}</span>
                </h3> -->
              </a>
            </div>
        </div> 
        <div class="col-sm-6 col-lg-3">
            <div class="counter-item pt-3">
              <a href="javascript:void(0)">
                <i class="fa-brands fa-searchengin"></i>
                <h5 class="text-info">Search</h5>
                <!-- <h3>
                  <span>{{ freelancerDashboardData.appliedcandidate }}</span>
                </h3> -->
              </a>
            </div>
        </div>
        <!-- routerLink="/jobs" -->
        <!-- <div class="col-sm-6 col-lg-2" >
            <div class="counter-item pt-3">
                <a href="javascript:void(0)">
                    <i class="fa-solid fa-handshake-angle"></i>
                    <h5 class="text-info">Support</h5>
                    <h3>
                    <span>{{ freelancerDashboardData.myjob }}</span>
                    </h3>
                </a>        
            </div>
        </div>    -->
        <div class="col-sm-6 col-lg-3">
            <div routerLink="/expert-user-setting" class="counter-item pt-3">
                <a href="javascript:void(0)">
                    <i class="fa-solid fa-gears"></i>
                    <h5 class="text-info">Setting</h5>
                    <!-- <h3>
                    <span>{{ freelancerDashboardData.jobapplied }}</span>
                    </h3> -->
                </a>
            </div>
        </div>
        <div class="col-sm-6 col-lg-3">
            <div class="counter-item pt-3">
                <!-- routerLink="/bench" -->
              <a routerLink="/expert-profile">
                <i class="fa-solid fa-user"></i>
                <h5 class="text-info">Profile</h5>
                <!-- <h3>
                  <span>{{ freelancerDashboardData.mycandidate }}</span>
                </h3> -->
              </a>
            </div>
        </div> 
    </div>
  </div>
</div>

<!-- Jobs -->
<div class="job-area-list" style="margin-top: 30px">
    <div class="container">
        <div class="row d-flex justify-content-center mb-4">           
            <div class="col-lg-6 ps-0">
                <form [formGroup]="homesearch">                   
                    <div class="row">
                        <div class="col-lg-12">
                            <ul style="list-style: none;">
                                <!-- <li class="slt-li" *ngFor="let list of sltskill.any; let i=index">
                                    <span class="slt-name">{{list?.name}} </span>
                                    <span class="cls-close" (click)="removeItem(i , 'any');">X </span>
                                </li> -->

                                <li class="slt-li" *ngFor="let list of sltskill.any; let i = index">
                                    <span class="slt-name">{{ list?.name }} </span>
                                    <span class="cls-close text-light" (click)="removeItem(i, 'any')">
                                      <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                                      <!-- <i class="bx bx-x-circle  mb-0 pb-0"></i> -->
                                    </span>
                                  </li>

                                <li class="text-li">
                                    <!-- <input #menu type="text" placeholder="Search for jobs / Projects"
                                    class="salectchanged form-control1 form-text" formControlName="anyskill"
                                    (keyup)="getsltSkills($any($event.target).value ,'any' )"> -->

                                    <div class="input-group">
                                        <input #menu type="text" class="form-control" placeholder="Search for jobs / Projects" formControlName="anyskill" (keyup)="getsltSkills($any($event.target).value ,'any' )" aria-label="Recipient's username" aria-describedby="button-addon2">
                                        <button (click)="searchfilter()" [disabled]="homesearch?.valid" class="btn btn-outline-info" type="button" id="button-addon2">Search</button>
                                    </div>
    
                                </li>
                               
                            </ul>
                        </div>
                        <!-- <div class="col-lg-2">
                            <button type="button" [disabled]="homesearch?.valid" class="btn-home"
                                (click)="searchfilter()"> Search
                            </button>
                        </div> -->                     
                       
                    </div>
                    <div class="row mt-0 pt-0">
                        <div class="col-lg-12 pt-0">
                            <div #menudrop id="myDropdown" class="dropdownContent_skills {{cls1}}">
                                <a *ngFor="let item of  templist1" (click)="setvalue(item)" class="salectchanged system">
                                  {{item.name}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                    <!-- <a routerLink="/business/commonsystem/advance-search" class="pointer adsearch"> 
                        Advanced Search 
                    </a> -->
                    </div>
                </form>
            </div>
        </div>    
        <div class="row mb-2">
            <div class="col-lg-1"></div>
            <div class="col-lg-5">
                <h3 class="text-dark"> Recent Jobs/Projects ({{jobs?.total}})</h3>
            </div>           
            <div *ngIf="search?.total>search?.limit" class="col-lg-5 text-end">
                <span class="txt">Page {{search?.page}} of {{search.totalpage}}</span>&nbsp;
                <button type="button" [disabled]="search?.page==1" class="btn btn-gray"
                  (click)="prev(1)">
                  <i class="fa fa-angle-left"></i>
                </button>&nbsp;
                <button type="button" [disabled]="search?.page==search?.totalpage" class="btn btn-gray"
                  (click)="next(1)">
                  <i class="fa fa-angle-right"></i>
                </button>&nbsp;
            </div>
        </div>
        <!-- Recent Jobs/Projects_List -->
        <div class="row d-flex justify-content-center">        
            <div class="col-lg-10 col-md-12">
                <div *ngFor="let job of jobs.data" class="employer-item">                 
                    <a href="javascript:void(0)" (click)="openJobDetails(job)">
                        <h3>{{ job.title }}</h3>
                    </a>           
                    <ul>
                        <li><i class="flaticon-send"></i> {{ job.location[0].location }}, {{ job.location[0].country }}</li>
        
                        <li>
                        {{ job.createdAt | date: "dd MMM, yyyy" }}
                        </li>
                    </ul>
                    <span style="color: #222">{{ job.experiencemin }}-{{ job.experiencemax }} Yrs</span>
                    <span style="padding: 0xp 20px; color: #ccc"> | </span>
                    <span style="color: #222">{{ job.ratemin | number: "1.2-2" }}-{{ job.ratemax | number: "1.2-2" }} {{ job.ratetype }}</span>
                    <span style="padding: 0xp 20px; color: #ccc"> | </span>
                    <span *ngIf="!job.parmanant" class="badge bg-info"> <i class="fas fa-check"></i> Contract </span>
                    <span *ngIf="job.freelance" class="badge bg-warning"> <i class="fas fa-check"></i> Freelance / Part-time </span>                
                    <ul style="margin-top: 10px">
                        <li>
                        Category: {{ job.jobtype }}
                        </li>
                    </ul>                
                    <span *ngFor="let s of job?.skill;let i=index"  class="span-one" style="margin: 2px 2px">{{ s }}</span>            
                </div>    
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->


<!-- End Dashboard -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
