import {
  BrowserModule,
  provideClientHydration,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { NavbarStyleOneComponent } from "./components/common/navbar-style-one/navbar-style-one.component";
import { FooterStyleOneComponent } from "./components/common/footer-style-one/footer-style-one.component";
import { NavbarStyleTwoComponent } from "./components/common/navbar-style-two/navbar-style-two.component";
import { NavbarStyleThreeComponent } from "./components/common/navbar-style-three/navbar-style-three.component";
import { FooterStyleTwoComponent } from "./components/common/footer-style-two/footer-style-two.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { EmployersComponent } from "./components/pages/employers/employers.component";
import { EmployersDetailsComponent } from "./components/pages/employers-details/employers-details.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { ResumeDetailsComponent } from "./components/pages/resume-details/resume-details.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { JobsComponent } from "./components/pages/jobs/jobs.component";
import { FavouriteJobsComponent } from "./components/pages/favourite-jobs/favourite-jobs.component";
import { JobDetailsComponent } from "./components/pages/job-details/job-details.component";
import { PostAJobComponent } from "./components/pages/post-a-job/post-a-job.component";
import { CandidatesComponent } from "./components/pages/candidates/candidates.component";
import { CandidatesDetailsComponent } from "./components/pages/candidates-details/candidates-details.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ToastrModule } from "ngx-toastr";
import { MyCandidatesDetailsComponent } from "./components/pages/my-candidates-details/my-candidates-details.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgSelectModule } from "@ng-select/ng-select";
import { AddBenchComponent } from "./components/pages/add-bench/add-bench.component";
import { HireOnContractComponent } from "./components/pages/hire-on-contract/hire-on-contract.component";
import { FindClientsComponent } from "./components/pages/find-clients/find-clients.component";
import { BenchComponent } from "./components/pages/bench/bench.component";
import { RecaptchaModule } from "ng-recaptcha";
import { JobApplicantsComponent } from "./components/pages/job-applicants/job-applicants.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PostProjectComponent } from "./components/pages/post-project/post-project.component";
import { DatePipe } from "@angular/common";
import { BenchApplicationsComponent } from "./components/pages/bench-applications/bench-applications.component";

import { FreeJobPostComponent } from "./components/pages/free-job-post/free-job-post.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { FreelancerRegisterComponent } from "./components/pages/freelancer-register/freelancer-register.component";
import { AdvancedSearchComponent } from "./components/pages/advanced-search/advanced-search.component";
import { MyJobDetailsComponent } from "./components/pages/my-job-details/my-job-details.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/pages/reset-password/reset-password.component";
import { SupportComponent } from "./components/pages/support/support.component";
import { HireExpertComponent } from "./components/pages/hire-expert/hire-expert.component";
import { HireCandidatesOnContractComponent } from "./components/pages/hire-candidates-on-contract/hire-candidates-on-contract.component";
import { RequestDemoComponent } from "./components/pages/request-demo/request-demo.component";
import { FindClientsToDeployBenchComponent } from "./components/pages/find-clients-to-deploy-bench/find-clients-to-deploy-bench.component";

import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { ExpertDashboardComponent } from "./components/pages/expert-dashboard/expert-dashboard.component";
import { ExpertProfileComponent } from "./components/pages/expert-profile/expert-profile.component";
import { ExpertUserSettingComponent } from "./components/pages/expert-user-setting/expert-user-setting.component";
import { ExpertAppliedHistoryComponent } from "./components/pages/expert-applied-history/expert-applied-history.component";
import { PricingTableComponent } from "./components/pages/pricing-table/pricing-table.component";

import { HireBenchResourcesComponent } from "./components/pages/hire-bench-resources/hire-bench-resources.component";
import { ShortlistedResourceComponent } from "./components/pages/shortlisted-resource/shortlisted-resource.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    EmployersComponent,
    EmployersDetailsComponent,
    DashboardComponent,
    ResumeDetailsComponent,
    TestimonialsComponent,
    PricingComponent,
    MyJobDetailsComponent,
    MyCandidatesDetailsComponent,
    FaqComponent,
    ComingSoonComponent,
    HireBenchResourcesComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    JobsComponent,
    FavouriteJobsComponent,
    JobDetailsComponent,
    PostAJobComponent,
    AddBenchComponent,
    BenchComponent,
    CandidatesComponent,
    CandidatesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    HireOnContractComponent,
    FindClientsComponent,
    JobApplicantsComponent,
    BenchApplicationsComponent,
    PostProjectComponent,
    FreeJobPostComponent,
    ProfileComponent,
    FreelancerRegisterComponent,
    AdvancedSearchComponent,
    SupportComponent,
    HireExpertComponent,
    ExpertDashboardComponent,
    HireCandidatesOnContractComponent,
    RequestDemoComponent,
    FindClientsToDeployBenchComponent,
    ExpertProfileComponent,
    ExpertUserSettingComponent,
    ExpertAppliedHistoryComponent,
    PricingTableComponent,
    ShortlistedResourceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CKEditorModule,
    RecaptchaModule,
    TabsModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger",
    }),
  ],
  providers: [DatePipe, provideClientHydration()],
  bootstrap: [AppComponent],
})
export class AppModule {}
