import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root' 
})
export class ProfileService {

  apiUrl: any = "https://www.onbenchmark.com/";
  baseurl = window.location.hostname.toLowerCase() == "localhost" ? "http://localhost:4500/" : "https://www.onbenchmark.com/";
  jsonheader: any;
  // public globalprize = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { 
    this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
  }

  // changeprizeglobal(prize:any) {
  //   this.globalprize.next(prize); 
  // }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`, 
    });
  }

  async logOut(data: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        this.http.post(`${this.apiUrl}auth/logoutalldevice`, data, { headers: this.authService.jsonheader })
          .subscribe({
            next: (response) => resolve(response),
            error: (error) => {
              console.error("Error occurred while processing logout:", error);
              reject(error);
            }
          });
      });
    } catch (error) {
      console.error("Error occurred while processing logout:", error);
      throw error;
    }
  }
  

  async profileFind(tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}`, { headers: this.authService.jsonheader }).toPromise().catch((error) => {
      console.error("Error occurred while finding data by id:", error);
      throw error;
    });
  }

  async update(ids: any, data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while updating profile:", error);
      throw error;
    }
  }

  async create(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}api/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error(`Error occurred while creating ${tbl}:`, error);
      throw error;
    }
  }

  async upload(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}auth/Upload/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error(`Error occurred while uploading to ${tbl}:`, error);
      throw error;
    }
  }

  async companydata(data: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}auth/companydata`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while processing company data:", error);
      throw error;
    }
  }

  async getJobType(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/getjobtype/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while fetching job type:", error);
      throw error;
    }
  }

  async homeGetSkill(data: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}notoken/skill?filter=${data}`).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async companySystem(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/companysystem/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error(`Error occurred while creating ${tbl}:`, error);
      throw error;
    }
  }


  getbaseurl() {
    return this.baseurl;
  }

  // ================= Plans ===================

  async findPlan(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}api/${tbl}?filter=${data}`, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  // ================= paymentHistory ================

  async findPaymentHistory(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}api/${tbl}?filter=${data}`, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  


}
