import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExpertDashboardService } from 'src/app/services/expert-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';


@Component({
  selector: 'app-expert-applied-history',
  templateUrl: './expert-applied-history.component.html',
  styleUrl: './expert-applied-history.component.scss',
  providers: [DatePipe]
})
export class ExpertAppliedHistoryComponent implements OnInit { 

  @ViewChild('autoShownModal') autoShownModal!: ModalDirective;
  isModalShownpackage = false;
  tab: any = [true, false, false, false];
  candidatedata: any = {};
  candidateapp: any = [];
  userid = "";
  loginType: any;
  total: any = {};
  querydata: any = { query: { $and: [] }, total: 0, limit: 25, page: 1, short: { updatedAt: -1 }, jobId: '', totalpage: 0 };
  searchForm: any;
  openstt = 1;
  tabstt = 1;
  shimmer = false;
  currencytype:any="";


  constructor(
    public datepipe: DatePipe, 
    private formBuild: FormBuilder, 
    private route: ActivatedRoute, 
    private router: Router,
    private expertDashboardService: ExpertDashboardService,
    private Toastr: ToastrService, 
    private auth: AuthService, 
    private loader: LoadingScreenService
  ) {
    this.searchFormCreate();
    this.loader.stopLoading();
    this.route.queryParams.subscribe(params => {
      this.userid = params['userid'];
      //this.gettotalapplication();
      if (this.userid) {
        this.getcandidate();
      }
      if (this.tabstt == 1) {
        this.getList(1, 0);
      } else if (this.tabstt == 2) {
        this.getList(2, 1);
      } else if (this.tabstt == 3) {
        this.getList(3, 2);
      } else {
        this.getList(4, 3);
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('currency')) {
      this.currencytype =  localStorage.getItem('currency');
    }
    this.expertDashboardService.globalprize.subscribe((res: any) => {
     this.currencytype =  res.ctype;
    });
    let obj = this.auth.getloginData();
    if (Object.keys(obj).length > 0) {
      const profiledata = this.auth.getloginData();
      this.loginType = profiledata.loginType;
    }
    this.auth.profiledata.subscribe((res: any) => {
      const profiledata = res;
      this.loginType = profiledata.loginType;
    });
  }


  showModal(val:any): void {
    if (val == "upgrade") {
      this.isModalShownpackage = true;
    }
  }
  hideModal(val:any): void {
    if (val == "upgrade") {
      this.isModalShownpackage = false;
    }
  }
  onHidden(val:any): void {
    if (val == "upgrade") {
      this.isModalShownpackage = false;
    }
  }

  searchFormCreate() {
    this.searchForm = this.formBuild.group({
      namesearch: new FormControl('', Validators.required),
      rangedate: new FormControl('', Validators.required)
    });
  }

  getList(stt:any, inx:any) {
    this.tab = [false, false, false, false];
    this.tab[inx] = true;
    this.openstt = stt;
    this.querydata.page = 1;
    this.filtercandidate();
  }

  getcandidate() {
    this.expertDashboardService.FindCandidateById(this.userid, 'candidate').then((res: any) => {
      this.candidatedata = res.data;
    }, err => {
      console.log(err);
    })
  }

  updateStatus(ids:any, val:any) {
    this.expertDashboardService.updateCandidate(ids, { status: val }, 'appliedcandidate').then((res: any) => {
      if (val == 2) {
        this.Toastr.success('Short candidate Successfully');
      } else if (val == 4) {
        this.Toastr.success('Reject Short Successfully');
      }
      this.filtercandidate();
    }, err => {
      console.log(err);
    })
  }

  filtercandidate() {
    this.querydata.query = { $and: [] };
    let fdata = this.searchForm.value;
    console.log(fdata);
    let orarr:any = { $or: [] };
    if (fdata.namesearch) {
      orarr.$or.push({ "jId": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "jname": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "benchId": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "benchname": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "freename": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "freephone": { $regex: fdata.namesearch, $options: 'i' } });
      orarr.$or.push({ "freeemail": { $regex: fdata.namesearch, $options: 'i' } });
      this.querydata.query.$and.push(orarr);
    }
    if (fdata.rangedate) {
      var todate = this.datepipe.transform(fdata.rangedate[0], 'yyyy-MM-dd');
      var fromdate = this.datepipe.transform(fdata.rangedate[1], 'yyyy-MM-dd');
      let dq = { createdAt: { '$gt': todate + " 00:00:00", '$lt': fromdate + " 23:59:00" } };
      this.querydata.query.$and.push(dq);
    }
    this.candidateapp = [];
    if (this.openstt == 1) {
      this.getappliedlist();
    } else if (this.openstt == 2) {
      this.getshortlist();
    } else if (this.openstt == 3) {
      this.gethiredtlist();
    } else {
      this.getrejecttlist();
    }
    this.gettotalapplication();
  }

  getlistbypage(val:any) {
    if (val == 'next') {
      if (this.querydata.page < this.querydata.totalpage) {
        this.querydata.page = this.querydata.page + 1;
        this.filtercandidate();
      }
      if (this.querydata.page == this.querydata.totalpage) {
        this.filtercandidate();
      }
    }
    if (val == 'prev') {
      if (this.querydata.page > 1) {
        this.querydata.page = this.querydata.page - 1;
        this.filtercandidate();
      }
    }
  }

  getappliedlist() {
    this.querydata.query.$and.push({ "status": 1 });
    this.querydata.candidateId = this.userid;
    this.querydata.loginType = this.loginType;
    this.querydata.openstt = this.openstt;
    this.shimmer = true;
    this.expertDashboardService.candidateApplication(this.querydata, 'appliedcandidate').then((res: any) => {
      this.candidateapp = res.data.data;
      this.querydata.total = res.data.total;
      this.querydata.totalpage = Math.ceil(this.querydata.total / this.querydata.limit);
      this.shimmer = false;
    }, err => {
      this.shimmer = false;
    })
  }

  getshortlist() {
    this.querydata.query.$and.push({ "status": 2 });
    this.querydata.candidateId = this.userid;
    this.querydata.loginType = this.loginType;
    this.querydata.openstt = this.openstt;
    this.shimmer = true;
    this.expertDashboardService.candidateApplication(this.querydata, 'appliedcandidate').then((res: any) => {
      this.candidateapp = res.data.data;
      this.querydata.total = res.data.total;
      this.querydata.totalpage = Math.ceil(this.querydata.total / this.querydata.limit);
      this.shimmer = false;
    }, err => {
      this.shimmer = false;
    })
  }

  gethiredtlist() {
    this.querydata.query.$and.push({ "status": 3 });
    this.querydata.candidateId = this.userid;
    this.querydata.loginType = this.loginType;
    this.querydata.openstt = this.openstt;
    this.shimmer = true;
    this.expertDashboardService.candidateApplication(this.querydata, 'appliedcandidate').then((res: any) => {
      this.candidateapp = res.data.data;
      this.querydata.total = res.data.total;
      this.querydata.totalpage = Math.ceil(this.querydata.total / this.querydata.limit);
      this.shimmer = false;
    }, err => {
      this.shimmer = false;
    })
  }

  getrejecttlist() {
    this.querydata.query.$and.push({ "status": 4 });
    this.querydata.candidateId = this.userid;
    this.querydata.loginType = this.loginType;
    this.querydata.openstt = this.openstt;
    this.shimmer = true;
    this.expertDashboardService.candidateApplication(this.querydata, 'appliedcandidate').then((res: any) => {
      this.candidateapp = res.data.data;
      this.querydata.total = res.data.total;
      this.querydata.totalpage = Math.ceil(this.querydata.total / this.querydata.limit);
      this.shimmer = false;
    }, err => {
      this.shimmer = false;
    })
  }

  gettotalapplication() {
    const data = { candidateId: this.userid };
    this.expertDashboardService.getTotalCandidate(data, 'appliedcandidate').then((res: any) => {
      this.total = res.data;
    }, err => {

    });
  }
  
  viewshortcontact(jobappId:any, inx:any) {
    const data = { jobappId: jobappId };
    this.loader.startLoading();
    this.expertDashboardService.companySystem({ query: data }, 'appliedcandidate/benchviewshortcontact').then((res: any) => {
      if (res.confirmation == "success") {
        if (res.data.package == "live") {
          this.candidateapp[inx].contactviewby = 1;
          this.candidateapp[inx].companyLoginId.phone = res.data.phone;
          this.candidateapp[inx].companyLoginId.email = res.data.email;
        } else {
          this.showModal('upgrade');
        }
      } else {
        this.Toastr.error("Please try after sometimes");
      }
      this.loader.stopLoading();
    }, err => {
      this.loader.stopLoading();
    });
  }








}
