import { Component, OnInit, Renderer2 } from "@angular/core";
import { AddBenchService } from "src/app/services/add-bench.service";
import { AuthService } from "src/app/services/auth.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-bench",
  // standalone: true,
  // imports: [],
  templateUrl: "./bench.component.html",
  styleUrl: "./bench.component.scss",
})
export class BenchComponent implements OnInit {
  searchForm: any = {};
  searchFormmain: any;
  search: any = {};
  // cls: any = "";
  benches: any = [];
  candidateresult: any = [];
  shimmer = true;
  pagebtn: any = { first: false, last: true, prev: true, next: true };
  currencytype: any = "";

  // cPage: any = { totalItems: 0, currentPage: 1, pageSize: 10 };

  pages: any = 0;
  currentPage: any = 1;
  querySearch: any = { $and: [] };

  filter: any = {
    page: this.currentPage,
    totalpage: 0,
    limit: 10,
    total: 0,
    orderval: "createdAt",
    ordertype: "desc",
    serchval: { $and: [] },
    short: { updateDate: -1, createdAt: -1 },
  };

  // ========= Searching =======
  searchingForm: any;
  citylist: any = [];
  loderstt = false;
  cls = "";

  //  ======= Skills ===========
  templist: any = [];
  cls1: any = "";
  sltskill: any = [];
  skillListdata: any = [];

  constructor(
    private addBenchSevice: AddBenchService,
    private loader: LoadingScreenService,
    private formBuild: FormBuilder,
    private toaster: ToastrService,
    private authService: AuthService,
    private renderer: Renderer2,
    private router: Router,
  ) {
    // this.searchFormCreate();
    // this.searchmainFormCreate();
    // this.searchingFormCreate();
    this.getSkillList();
  }

  ngOnInit(): void {
    if (localStorage.getItem("currency")) {
      this.currencytype = localStorage.getItem("currency");
    }
    this.addBenchSevice.globalprize.subscribe((res: any) => {
      this.currencytype = res.ctype;
    });

    this.loader.stopLoading();
    // this.getBenchList();
    // this.getCandidateList();
    // this.windowload();
    // this.getSkillList();
    this.windowrendor();
    this.getBenchList();
  }

  // searchFormCreate() {
  //   this.searchForm = this.formBuild.group({
  //     name: [""],
  //     email: [""],
  //     gender: [""],
  //     mobile: [""],
  //     onsiteAvailability: [""],
  //     ratemin: [""],
  //     ratemax: [""],
  //     experiencemin: [""],
  //     experiencemax: [""],
  //     Availability: [""],
  //     skill: [""],
  //   });
  // }

  // searchmainFormCreate() {
  //   this.searchFormmain = this.formBuild.group({
  //     name: [""],
  //     locationId: [""],
  //     currentlocation: [""],
  //     adminverified: [""],
  //   });
  // }

  // getBenchList() {
  //   this.loader.startLoading();
  //   this.addBenchSevice.getMyBench(this.filter).then(
  //     (res: any) => {
  //       if (res.confirmation === "success") {
  //         this.loader.stopLoading();
  //         this.benches = res.data;
  //         console.log("benchListData: ", this.benches);
  //         // this.cPage.totalItems = res.total;
  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //       this.loader.stopLoading();
  //     },
  //   );
  // }

  // ========== Bench-List with Pagination ==========
  getBenchList() {
    this.addBenchSevice.getMyBench(this.filter).then((resp) => {
      this.benches = resp.data;
      // console.log("BenchResData: ", this.benches);
      this.pages = Math.ceil(resp.data.total / 10);
    });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.getBenchList();
    }
  }
  nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage = this.currentPage + 1;
      this.getBenchList();
    }
  }

  // ==================================================

  getCandidateList() {
    // console.log("I am in the function");
    this.candidateresult = [];
    let data = this.search;
    this.shimmer = true;
    this.addBenchSevice.custumFind(data, "mycandidate").then(
      (res: any) => {
        this.candidateresult = res.data;
        console.log("candidateDAta: ", this.candidateresult);
        this.shimmer = false;
        if (this.search.page == 1) {
          this.pagebtn.prev = false;
        }
        this.search.total = this.candidateresult.total;
        this.search.totalpage = Math.ceil(
          this.search.total / this.search.limit,
        );
      },
      (err) => {
        this.shimmer = false;
        console.log(err);
      },
    );
  }

  openCandidateDetails(bench: any) {
    this.router.navigateByUrl("/my-candidate-details?userId=" + bench._id);
  }

  searchfilter() {
    // let side = this.searchForm.value;
    // let main = this.searchFormmain.value;

    this.benches = [];
    this.filter.serchval = {
      $and: [],
    };
    this.filter.page = 1;

    if (this.sltskill && this.sltskill.length > 0) {
      let allSkillConditions = [];

      this.sltskill.forEach((s) => {
        allSkillConditions.push({
          skill: {
            $regex: s.name,
            $options: "i",
          },
        });
      });

      this.filter.serchval["$and"].push({
        $or: allSkillConditions,
      });
    }

    if (this.searchForm.status && this.searchForm.status != "all") {
      if (this.searchForm.status == "active") {
        this.filter.serchval["$and"].push({
          status: true,
        });
      }
      if (this.searchForm.status == "inActive") {
        this.filter.serchval["$and"].push({
          status: false,
        });
      }
    }

    if (
      this.searchForm.verifiedStatus &&
      this.searchForm.verifiedStatus != "all"
    ) {
      if (this.searchForm.verifiedStatus == "yes") {
        this.filter.serchval["$and"].push({
          adminverified: 1,
        });
      }
      if (this.searchForm.verifiedStatus == "no") {
        this.filter.serchval["$and"].push({
          adminverified: 0,
        });
      }
    }

    if (this.searchForm.location && this.searchForm.location != "") {
      this.filter.serchval["$and"].push({
        "location.location": {
          $regex: this.searchForm.location,
          $options: "i",
        },
      });
    }

    if (this.searchForm.title && this.searchForm.title != "") {
      this.filter.serchval["$and"].push({
        name: {
          $regex: this.searchForm.title,
          $options: "i",
        },
      });
    }

    console.log(this.filter);
    // if (this.sltskill.length > 0) {
    //   let skill:any = { $or: [] };
    //   this.sltskill.forEach((ele:any) => {
    //     skill.$or.push({ skill: { $regex: ele.name, $options: 'i' } });
    //   });
    //   data.$and.push(skill);
    // }

    // if (side.gender) {
    //   data.$and.push({ gender: side.gender });
    // }
    // if (side.onsiteAvailability) {
    //   data.$and.push({ onsiteAvailability: side.onsiteAvailability });
    // }
    // if (side.Availability) {
    //   data.$and.push({ Availability: side.Availability });
    // }
    // if (side.experiencemin && side.experiencemax) {
    //   data.$and.push({
    //     experience: { $gte: side.experiencemin, $lte: side.experiencemax },
    //   });
    // }
    // if (side.ratemin && side.ratemax) {
    //   data.$and.push({ rate: { $gte: side.ratemin, $lte: side.ratemax } });
    // }
    // if (main.name) {
    //   let qury = {
    //     $or: [
    //       { name: { $regex: main.name, $options: "i" } },
    //       { resourceId: { $regex: main.name, $options: "i" } },
    //       { jobtype: { $regex: main.name, $options: "i" } },
    //     ],
    //   };
    //   data.$and.push(qury);
    // }
    // if (main.locationId) {
    //   data.$and.push({ locationId: main.locationId });
    // }
    // if (main.adminverified) {
    //   data.$and.push({ adminverified: main.adminverified });
    // }
    // if (data.$and.length > 0) {
    //   this.search.serchval = data;
    // } else {
    //   this.search.serchval = { $and: [] };
    // }
    this.getBenchList();
  }

  getlistbypage(val: any) {
    if (val == "next") {
      this.pagebtn.prev = true;
      if (this.search.page < this.search.totalpage) {
        this.search.page = this.search.page + 1;
        this.getCandidateList();
      }
      if (this.search.page == this.search.totalpage) {
        this.pagebtn.next = false;
        this.getCandidateList();
      }
    }
    if (val == "prev") {
      if (this.search.page > 1) {
        this.pagebtn.next = true;
        this.search.page = this.search.page - 1;
        this.getCandidateList();
      }
    }
    if (val == "first") {
      this.search.page = 1;
      this.getCandidateList();
    }
  }

  // setvalue(val: any, id: any) {
  //   this.cls = "";
  //   this.searchFormmain.get("currentlocation").setValue(val);
  //   this.searchFormmain.get("locationId").setValue(id);
  // }

  changeStatus(bench: any, ids: any, val: any, inx: any) {
    let data: any = { status: val };
    if (val == false) {
      data.adminverified = 3;
    }
    this.loader.startLoading();
    this.addBenchSevice.updateStatus(ids, data, "mycandidate").then(
      (res: any) => {
        bench.status = val;
        this.candidateresult.data[inx].status = val;
        this.toaster.success("Candidate Status Changed Successfully");
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  getValue(val: any) {
    return val.replace("_", " ");
  }

  windowrendor() {
    this.renderer.listen("window", "click", (e: Event): any => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach((element) => {
        if (element == "salectchanged") {
          change1 += 1;
        }
        if (element == "salectchanged2") {
          change2 += 1;
        }
      });
    });
  }

  // openBenchDetails(bench) {
  //   this.router.navigateByUrl("/bench-details?jobId=" + bench._id);
  // }
  
  searchingFormCreate() {
    this.searchingForm = this.formBuild.group({
      title: [""],
      locationId: [""],
      currentlocation: [""],
      jobstatus: ["all"],
    });
  }


  // ============== getCity ==================
  get_city(val: any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = {
        $and: [
          { city_name: { $regex: val, $options: "i" } },
          { country_id: "5c976ba641e3f849a36370aa" },
        ],
      };
      this.addBenchSevice
        .getCity(JSON.stringify(filter))
        .then((res) => {
          console.log("CityList: ", res.data);
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
    }
  }

  setvalue(val: any, id: any) {
    this.cls = "";
    console.log("location", val);
    // this.searchingForm.get("currentlocation").setValue(val);
    // this.searchingForm.get("locationId").setValue(id);
  }

  // ================= getSkill ================

  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.addBenchSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  getsltSkills(val: any) {
    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls1 = "displayblock";
      this.templist = this.skillListdata.filter(function (el: any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = "";
      }
    } else {
      this.sltskill.push({ name: val.replace(",", "") });
      this.cls1 = "";
      this.searchForm.get("skill").setValue("");
    }
  }

  setskillvalue(item: any) {
    this.sltskill.push(item);
    this.cls1 = "";
    // this.searchForm.get("skill").setValue("");
  }

  removeItem(inx: any) {
    this.sltskill.splice(inx, 1);
  }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 158 * 79.8).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  // ================= Reset-Filters =======
  reload() {
    this.sltskill = [];
    
    const skillInput = document.querySelector('input[placeholder="Skills separated by comma"]') as HTMLInputElement;
    if (skillInput) {
      skillInput.value = '';
    }

  }




}
