import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ExpertService {

  apiUrl: any;
  baseurl = window.location.hostname.toLowerCase() == "localhost" ? "http://localhost:4500/" : "https://www.onbenchmark.com/";
  jsonheader: any;

  userdata: any = {};

  openclosurePopup = new Subject();

  public globalprize = new Subject();
  updatecoinall = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { 
    this.apiUrl = this.authService.apiUrl;
    this.jsonheader = this.authService.jsonheader;
  }

  changeprizeglobal(prize:any) {
    this.globalprize.next(prize);
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, 
    });
  }

  updatecoin(data:any) { 
    this.updatecoinall.next(data);
  }

  getFreelancerDashboard(): Observable<any> { 
    return this.http.post(`${this.apiUrl}custom/freelancerdashboard/dashboard`, {},
        {
          headers: this.authService.jsonheader,
        },
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  getCompanydashboard(): Observable<any> { 
    return this.http.post(`${this.apiUrl}custom/companydashboard/dashboard`, {},
        {
          headers: this.authService.jsonheader,
        },
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  async searchJob(data: any, tbl: any): Promise<any> {
    return this.http.post(`${this.apiUrl}custom/searchjob/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise().catch((error) => {
        console.error("Error occurred while fetching jobs:", error);
        throw error;
      });
  }

  async homeGetSkill(data: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}notoken/skill?filter=${data}`).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async dashJobApplication(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/dashjobapplication/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      throw error;
    }
  }

  openclosurelist(profiledata:any){
    this.openclosurePopup.next(profiledata);
  }  

  async getCoin(data: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/getcoinuser`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error("Error occurred while processing data:", error);
      throw error;
    }
  }


  // ======================================================
  // =============== EXPER_PROFILE ========================


  async FindById(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise()
      .catch(error => {
        console.error('Error occurred while finding profile data by id:', error);
        throw error;
      });
  }

  // fileupload(data: any, tbl: any): Observable<HttpEvent<any>> {
  //   const req = new HttpRequest('POST', `${this.apiUrl}auth/Upload/${tbl}`, data, {
  //     reportProgress: true,
  //     responseType: 'json',
  //     headers: this.authService.jsonheader 
  //   });
  //   return this.http.request(req);
  // }


  async fileUpload(data: any, tbl: any): Promise<any> {
    return this.http.post(`${this.apiUrl}auth/Upload/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch(error => {
        console.error('Error occurred while uploading:', error);
        throw error;
      });
  }



  async updateImage(ids: any, data: any, tbl: any): Promise<any> {
    return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch(error => {
        console.error('Error occurred while updating:', error);
        throw error;
      });
  }

  async updateFreelancer(ids: any, data: any, tbl: any): Promise<any> {
    return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch(error => {
        console.error('Error occurred while updating:', error);
        throw error;
      });
  }

  async getJobType(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/getjobtype/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error while getting job types:', error);
      throw error;
    }
  }

  async getSkill(data: any): Promise<any> {
    try {
      return await this.http.get(`${this.apiUrl}notoken/skill?filter=${data}`).toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }
  
  async updateSkills(ids: any, data: any, tbl: any): Promise<any> {
    return this.http.put(`${this.apiUrl}api/${tbl}/${ids}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch(error => {
        console.error('Error occurred while updating:', error);
        throw error;
      });
  }

  async upload(data: any, tbl: any): Promise<any> {
    return this.http.post(`${this.apiUrl}auth/Upload/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise()
      .catch(error => {
        console.error('Error occurred while uploading:', error);
        throw error;
      });
  }

  async addSubarray(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error while getting data:', error);
      throw error;
    }
  }

  async updateSubarray(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.put(`${this.apiUrl}custom/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();;
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }
    
  async deleteSubarray(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.put(`${this.apiUrl}custom/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();;
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }

  // =================== addProjectItemSection ==================

  async addPortfolio(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.post(`${this.apiUrl}custom/addportfolio/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error while getting data:', error);
      throw error;
    }
  }

  async updatePortfolio(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.put(`${this.apiUrl}custom/updateportfolio/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();;
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }



  async deletePortfolio(data: any, tbl: any): Promise<any> {
    try {
      return await this.http.put(`${this.apiUrl}custom/deleteportfolio/${tbl}`, data, { headers: this.authService.jsonheader }).toPromise();
    } catch (error) {
      console.error('Error occurred while updating:', error);
      throw error;
    }
  }
  



  













  getbaseurl() {
    return this.baseurl;
  }
  
  setprofiledata(data:any){
    this.userdata.freelanerId=data;
  }
  
  
  
  







}
