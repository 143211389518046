import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-free-job-post',
  // standalone: true,
  // imports: [],
  templateUrl: './free-job-post.component.html',
  styleUrl: './free-job-post.component.scss'
})
export class FreeJobPostComponent {
  form: FormGroup;
  budgets = [
    { id: '1000-5000', name: '$1,000 - $5,000' },
    { id: '5000-10000', name: '$5,000 - $10,000' },
    { id: '10000-20000', name: '$10,000 - $20,000' },
    { id: '20000+', name: '$20,000+' },
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      jobTitle: [''],
      budget: [null], // Default value for budget
    });
  }
}
