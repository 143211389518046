import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ExpertDashboardService } from 'src/app/services/expert-dashboard.service';
import { ExpertService } from 'src/app/services/expert.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-expert-user-setting',
  templateUrl: './expert-user-setting.component.html',
  styleUrl: './expert-user-setting.component.scss'
})
export class ExpertUserSettingComponent implements OnInit {

  candidateForm:any;
  passwordForm:any;
  contactForm:any;
  emailForm:any;
  notificationForm:any;
  tab:any = [true,false,false];
  feelancerdata:any={};
  zonelistdata:any;
  profiledata:any={};
  listcountry:any=[];
  errorstt = {emailerror : false ,emailstt :false ,sent:false , countrystt : false};

  constructor(
    private formBuild: FormBuilder, 
    private auth: AuthService,
    private expertDashbordService: ExpertDashboardService, 
    private renderer: Renderer2, 
    private toaster: ToastrService, 
    private router: Router,
    private loader: LoadingScreenService,
    private _fb: FormBuilder


  ) {  }
  
  ngOnInit() {
    this.candidateFormCreate();
    this.passwordFormCreate();
    this.notificationFormCreate();
    this.emailFormCreate();
    this.contactFormCreate();

    this.getfreelancer('66e2bdb7e98b3cffa8050e04');

    let obj = this.auth.getloginData();
    if (Object.keys(obj).length > 0) {
       this.profiledata = this.auth.getloginData();
      //console.log(profiledata);

      // 66e2bdb7e98b3cffa8050e04
      // this.getfreelancer(this.profiledata.freelanerId._id);
      this.getfreelancer('66e2bdb7e98b3cffa8050e04');
    }
    this.auth.profiledata.subscribe((res: any) => {
      this.profiledata = res;
      //console.log(profiledata);
      // this.getfreelancer(this.profiledata.freelanerId._id);
      this.getfreelancer('66e2bdb7e98b3cffa8050e04');
    });

    this.zonelist();
    this.countrylist();

  }

  zonelist(){
    this.expertDashbordService.Find( JSON.stringify({}) ,'zonelist' ).then((res: any) => {

      if(res.confirmation == 'success'){
        this.zonelistdata = res.data;
        console.log("zoneList: ", res.data);
      }

      // this.zonelistdata = res.data;
    }, err => {
     
    });
  }

  countrylist(){
    this.expertDashbordService.Find( JSON.stringify({}) ,'country' ).then((res: any) => {
      this.listcountry = res.data;
    }, err => {
     
    });
  }

  candidateFormCreate() {
    this.candidateForm = this.formBuild.group({
      fname: ['', [Validators.required, Validators.maxLength(100)] ],
      lname:['', [Validators.required, Validators.maxLength(100)]],
      phone: ['', [Validators.required, Validators.maxLength(100)]],
      timezone: ['', [Validators.required, Validators.maxLength(100)]],
      
    });
  }

  contactFormCreate() {
    this.contactForm = this.formBuild.group({
      address : ['', [Validators.required, Validators.maxLength(100)]],
      location: ['', [Validators.required, Validators.maxLength(100)]],
      zipcode: ['', Validators.required],
      state: ['', [Validators.required, Validators.maxLength(100)]],
      currentcountry: ['', Validators.required]
    });
  }

  emailFormCreate() {
    this.emailForm = this.formBuild.group({
      email : ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  passwordFormCreate() {
    this.passwordForm = this.formBuild.group({
      password: ['', [Validators.required, Validators.maxLength(100)] ],
      confirmpassword: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  notificationFormCreate() {
    this.notificationForm = this.formBuild.group({
      latestjob: new FormControl(false, [Validators.required] ),
      marketing: new FormControl(false, [Validators.required]),
      newsletter: new FormControl(false, [Validators.required]),
      onbenchmarkdeal: new FormControl(false, [Validators.required])
    });
  }

  updateprofileFreelancer() {
    let data = this.candidateForm.value;
    data.timezone  = data.timezone.trim();
    this.expertDashbordService.update('66e2bdb7e98b3cffa8050e04', data, 'freelancer').then((res: any) => {
    // this.expertDashbordService.update(this.feelancerdata._id, data, 'freelancer').then((res: any) => {
      this.toaster.success('Updated Successfully');
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

   // 66e2bdb7e98b3cffa8050e04

   updatecontactFreelancer() {
    let data = this.contactForm.value;
    this.expertDashbordService.update('66e2bdb7e98b3cffa8050e04', data, 'freelancer').then((res: any) => {
    // this.expertDashbordService.update(this.feelancerdata._id, data, 'freelancer').then((res: any) => {
      this.toaster.success('Contact Updated Successfully');
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

  updatenotificationFreelancer() {
    let data = this.notificationForm.value;
    this.expertDashbordService.update('66e2bdb7e98b3cffa8050e04', { notification : data }, 'freelancer').then((res: any) => {
    // this.expertDashbordService.update(this.feelancerdata._id, { notification : data }, 'freelancer').then((res: any) => {
      this.toaster.success('Contact Updated Successfully');
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

  updateemailFreelancer() {
    let data = this.emailForm.value;
    data.feelancerId = this.feelancerdata._id;
    this.expertDashbordService.updateEmail(data, 'user').then((res: any) => {
       if (res.confirmation =='success') {
        this.profiledata.changerequest=1;
        this.profiledata.changeemail=data.email;
        this.errorstt.emailstt = true;
        this.emailFormCreate();
       } else if(res.confirmation =='duplicate') {
        this.errorstt.emailerror = true;
       }
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

  tabopen(inx:any){
   for (let i = 0; i < this.tab.length; i++) {
       this.tab[i] = false;
   }
   this.tab[inx] = true;  
  }

  getfreelancer(val:any) {
    this.loader.startLoading();
    this.expertDashbordService.FindById(val, 'freelancer').then((res: any) => {
      this.feelancerdata = res.data;
      this.loader.stopLoading();

      if (this.feelancerdata.designation == null) {
        this.toaster.info("Update your Profile");
        this.router.navigate(['/business/update-profile']);
      }

      this.patchdata();
    }, err => {
      this.loader.stopLoading();
      
    });
  }

  patchdata(){
    this.candidateForm.patchValue({
      fname: this.feelancerdata.fname,
      lname:  this.feelancerdata.lname,
      phone:  this.feelancerdata.phone,
      timezone :  this.feelancerdata.timezone
    });
    this.contactForm.patchValue({
      address:  this.feelancerdata.address,
      location :  this.feelancerdata.location,
      zipcode :  this.feelancerdata.zipcode,
      state:  this.feelancerdata.state,
      currentcountry:  this.feelancerdata.currentcountry,
    });
    this.notificationForm.patchValue({
      latestjob:  this.feelancerdata.notification.latestjob,
      marketing:  this.feelancerdata.notification.marketing,
      newsletter :  this.feelancerdata.notification.newsletter,
      onbenchmarkdeal :  this.feelancerdata.notification.onbenchmarkdeal
    });
    if (this.feelancerdata.currentcountry) {
      this.errorstt.countrystt = true;
      this.contactForm.controls['currentcountry'].disable();
    }else{
      this.contactForm.controls['currentcountry'].enable();
    }
  }

  resendmail(val:any){
    let data = this.emailForm.value;
    data.feelancerId = this.feelancerdata._id;
    data.sendtype = val;
    this.expertDashbordService.resendMail(data, 'user').then((res: any) => {
       if (res.confirmation =='success') {
         this.profiledata.changerequest=0;
       } else if(res.confirmation =='duplicate') {
       }
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

  updatepassword(){
    let data = { password : this.passwordForm.value.password};
    this.expertDashbordService.update(this.profiledata._id, data, 'user').then((res: any) => {
      this.passwordFormCreate();
         this.toaster.success('password reset successfully');
    }, err => {
      this.toaster.error('Please try after some time');
    })
  }






}
