import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ExpertService } from 'src/app/services/expert.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { timedata } from 'src/app/services/timedata';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import * as $ from "jquery";

@Component({
  selector: 'app-expert-profile',
  templateUrl: './expert-profile.component.html',
  styleUrl: './expert-profile.component.scss'
})
export class ExpertProfileComponent implements OnInit {


  // ======= addProjectSection=============
  portfolioForm: any;
  addstt = false;
  // fileuploadImage: any;
  imageList: any = [];
  porfolioId:any = "";

  // ===================================

  public Editor = ClassicEditor;
  configck:any={};
  modules: any = "";
  info: any = {};

  @ViewChild('autoShownModal') autoShownModal!: ModalDirective; 
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('inputFile') myInputVariable!: ElementRef;
  @ViewChild('binputFile') bmyInputVariable!: ElementRef;

  isModalShown = false;
  isModalportfolio = false;
  isModalexperience = false;
  // isModalproject = false;
  isModalqualification = false;
  isModalpublication = false;
  isModalprofile=false;
  isModalskill=false;
  isModalbanner=false;
  isModalProjectItem = false;

  timedata:any=timedata;

  profiledata: any;
  feelancerdata: any = {};
  candidateForm: any;
  experienceForm: any;
  educationForm: any;
  skillForm:any;
  cls:any = '';
  cls1 = '';
  skillListdata: any = [];
  templist: any = [];
  publicationForm: any;
  btnstt:any = 'Save';

  slist: any = [];
  bannerimage:any=[];

  fileuploadImage: any;
  loaderstt = false;
  dochtml: any = [];
  projectinfo: any = {};

  monthlist:any = ['January', 'February', 'March', 'April', 'May', 'June', 'July ', 'August', 'September', 'October', 'November', 'December',
  ];
  yearlist: any = [];
  d = new Date();
  maxyear = this.d.getFullYear();
  minyear = this.maxyear - 100;

  jobType: any = [];
 
  loderstt = false;
  skilllist: any = [];
  loderstt2 = false;
  cls2:any = '';
  locationlist: any = [];
 
  fileupload:any;
  dashinfo: any = {};
  editdata = false;
 
  projectObj: any = {
    title: new FormControl('', Validators.maxLength(100)),
    duration: new FormControl(''),
    role: new FormControl('', Validators.maxLength(2000)),
    description: new FormControl('', Validators.maxLength(2000))
  };

  ugListdata: any = [];
  pgListdata: any = [];
  ugListTemp: any = [];
  pgListTemp: any = [];
  dropug = false;
  droppg = false;
  ugotherstt = false;
  pgotherstt = false;
  imageChangedEvent: any = '';
  otherimg=false;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

 
  hidestt:any = [true, true];

 
  experienceId:any = "";
  publicationId:any="";
  qualificationId:any="";

  cntchr=4000;

  constructor(
    private formBuild: FormBuilder, 
    private auth: AuthService,
    private expertService: ExpertService, 
    private renderer: Renderer2, 
    private toaster: ToastrService, 
    private router: Router,
    // private config: ConfigService, 
    private loader: LoadingScreenService
  ) {
    this.candidateFormCreate();
    this.experienceFormCreate();
    this.educationFormCreate();
    this.publicationFormCreate();
    this.skillFormCreate();
    this.getJobType();
    this.getSkillList();
    this.window_load();
    this.windowrendor();
  }
  ngOnInit() {

    this.portfolioFormCreate();

    // this.getfreelancer(this.profiledata.freelanerId._id);
    this.getfreelancer("66e2bdb7e98b3cffa8050e04");    

    let obj = this.auth.getloginData();

    console.log("objData: ", obj);

    if (Object.keys(obj).length > 0) {
      this.profiledata = this.auth.getloginData();
      // this.getfreelancer(this.profiledata.freelanerId._id);
      this.getfreelancer("66e2bdb7e98b3cffa8050e04");
    }
    this.auth.profiledata.subscribe((res: any) => {
      this.profiledata = res;
      // this.getfreelancer(this.profiledata.freelanerId._id);
      this.getfreelancer("66e2bdb7e98b3cffa8050e04");
    });

    for (let i = this.maxyear; i > this.minyear; i--) {
      this.yearlist.push(i);
    }

    this.window_load();

  } 

  showModal(val:any): void {
    if (val == 'photo') {
      this.isModalShown = true;
    } else if (val == 'portfolio') {
      this.isModalportfolio = true;
    } else if (val == 'experience') {
      this.isModalexperience = true;
    } else 
    // if (val == 'project') {
    //   this.isModalproject = true;
    // } else 
    if (val == 'qualification') {
      this.isModalqualification = true;
    } else if (val == 'publication') {
      this.isModalpublication = true;
    } else if (val == 'profile') {
      this.isModalprofile = true;
    } else if (val == 'skill') {
      this.isModalskill = true;
    } else if (val == 'bannerimg') {
      this.isModalbanner = true;
    } else if (val == 'project') {
      this.isModalProjectItem = true;
    } 
  }
  hideModal(val:any): void {
    if (val == 'photo') {
      this.isModalShown = false;
    } else if (val == 'portfolio') {
      this.isModalportfolio = false;
    } else if (val == 'experience') {
      this.isModalexperience = false;
    } else 
    // if (val == 'project') {
    //   this.isModalproject = false;
    // } else 
    if (val == 'qualification') {
      this.isModalqualification = false;
    } else if (val == 'publication') {
      this.isModalpublication = false;
    } else if (val == 'profile') {
      this.isModalprofile = false;
    } else if (val == 'skill') {
      this.isModalskill = false;
    } else if (val == 'bannerimg') {
      this.isModalbanner = false;
    } else if (val == 'project') {
      this.isModalProjectItem = false;
    }
  }
  onHidden(val:any): void {
    if (val == 'photo') {
      this.isModalShown = false;
    } else if (val == 'portfolio') {
      this.isModalportfolio = false;
    } else if (val == 'experience') {
      this.isModalexperience = false;
    } else
    //  if (val == 'project') {
    //   this.isModalproject = false;
    // } else 
    if (val == 'qualification') {
      this.isModalqualification = false;
    } else if (val == 'publication') {
      this.isModalpublication = false;
    } else if (val == 'profile') {
      this.isModalprofile = false;
    } else if (val == 'skill') {
      this.isModalskill = false;
    } else if (val == 'bannerimg') {
      this.isModalbanner = false;
    } else if (val == 'project') {
      this.isModalProjectItem = false;
    }
  }

  // ==================== addProjectSection===============
  portfolioFormCreate() {
    this.portfolioForm = this.formBuild.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      duration: ['', Validators.required],
      description: ['', Validators.required],
      role: ['', Validators.required],
      website: [''],
      imageurl: [''],
      mediaUrl: [''],
      skills: ['']
    });
  }

  addform() {
    this.addstt = !this.addstt;
    this.portfolioFormCreate();
    this.imageList =[];
    this.btnstt = 'Save';
    this.porfolioId = "";
    $(".slltskill").each(function () {
          $(this).prop('checked', false);
    });

    this.hideModal('project');
  }

  submitPortfolio():any {

    let srrskill:any = [];
    $(".slltskill").each(function () {
      if ($(this).prop('checked') == true) {
        srrskill.push($(this).val());
      }
    })
    
    if (srrskill.length == 0) {
      this.toaster.error("Please select skills");
      return false;
    }
    if (this.imageList.length == 0) {
      this.toaster.error("Please select Image");
      return false;
    }
    let data: any = {
      skills: srrskill,
      title: this.portfolioForm.value.title,
      duration: this.portfolioForm.value.duration,
      description: this.portfolioForm.value.description,
      role: this.portfolioForm.value.role,
      website: this.portfolioForm.value.website,
      imageurl: this.imageList,
      mediaUrl: this.portfolioForm.value.mediaUrl,
      feelancerId: this.feelancerdata._id
    }; 
    if (this.btnstt == 'Save') {

      this.expertService.addPortfolio(data, 'freelancer').then((res: any) => {
        this.addstt = !this.addstt;
        this.getfreelancer(this.feelancerdata._id);
        this.toaster.success('Add porfolio successfully!');
       
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    } else {

      this.btnstt = 'Update'

      data.porfolioId = this.porfolioId;
      this.expertService.updatePortfolio(data, 'freelancer').then((res: any) => {

        if (res.confirmation === "success") {
          this.toaster.success('Update porfolio successfully!');
          this.hideModal('project');
        }

        // this.toaster.success('Update porfolio successfully!');
        this.addstt = !this.addstt;
        this.getfreelancer(this.feelancerdata._id);
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    }
  }

  patchport(pdata:any) {

    this.showModal('project');

    this.addstt = !this.addstt;
    this.btnstt = 'Update';
    this.porfolioId = pdata._id;
    this.portfolioForm.patchValue({
      title: pdata.title,
      duration: pdata.duration,
      description: pdata.description,
      role: pdata.role,
      website: pdata.website,
      mediaUrl: pdata.mediaUrl,
    });
    this.imageList = pdata.imageurl;
    var sdata = pdata.skills
    $(".slltskill").each(function () {
      for (let i = 0; i < sdata.length; i++) {
        //console.log($(this).val(),"==",sdata[i]);
        if ($(this).val() == sdata[i]) {
          $(this).prop('checked', true);
        }
      }
    });
  }

  deleteport(ids:any, inx:any) {

    console.log("I am in function!");

    this.expertService.deletePortfolio({ portfolioId: ids, feelancerId: '66e2bdb7e98b3cffa8050e04' }, 'freelancer')
    // this.expertService.deletePortfolio({ portfolioId: ids, feelancerId: this.feelancerdata._id }, 'freelancer')
    .then((res: any) => {

      console.log("I am in function!");
      console.log("resData : ", res.data);

      if (res.confirmation === "success") {
        console.log("data deleted!");
      }

      this.toaster.success('Delete porfolio successfully!');
      this.feelancerdata.projectList.splice(inx, 1);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  onFileChangedImage_project(event:any) {
    if (event.target.files.length > 0) {
      this.fileuploadImage = event.target.files[0];
      this.onSubmitImage_project();
    }
  }

  onSubmitImage_project() {
    let fd = new FormData();
    fd.append('image', this.fileuploadImage, 'image.jpeg');
    fd.append('baseurl', this.expertService.getbaseurl());
    this.expertService.fileUpload(fd, 'portfolio').then((res: any) => {
      this.imageList.push(res.imageurl);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  removeimg(inx:any) {
    this.imageList.splice(inx, 1);
  }



  // ================================



  // ========== 1 ==============
  candidateFormCreate() {
    this.candidateForm = this.formBuild.group({
      "gender": ['', Validators.required],
      "designation": ['', [Validators.required, Validators.maxLength(100)]],
      "onsiteAvailability": ['', Validators.required],
      "Resumename": [''],
      "originalimage": [''],
      "Resumeurl": [''],
      "resumedescrption": [''],
      "dochtml": [''],
      "rate": ['', [Validators.required, Validators.min(1), Validators.max(500)]],
      "hoursperday": ['', Validators.required],
      "starttimeday": [''],
      "ratetype": ['Hourly'],
      "experience": ['', Validators.required],
      "Availability": ['', Validators.required],
      "description": [''],
      "category": ['', Validators.required],
      "image": [null],
      "imageurl": [null]
    });
  }
  // ========== 2 ==============
  experienceFormCreate() {
    this.experienceForm = this.formBuild.group({
      "title": ['', Validators.required],
      "company": ['', [Validators.required, Validators.maxLength(100)]],
      "startmonth": ['', Validators.required],
      "startyear": ['', Validators.required],
      "endmonth": ['', Validators.required],
      "endyear": ['', Validators.required],
      "currently": [false],
      "summary": ['', Validators.required]
    });
  }
  // ========== 3 ==============
  educationFormCreate() {
    this.educationForm = this.formBuild.group({
      "edutype": ['', Validators.required],
      "degree": ['', Validators.required],
      "college": ['', [Validators.required, Validators.maxLength(100)]],
      "studyArea": ['', Validators.required],
      "startyear": ['', Validators.required],
      "endyear": ['', Validators.required],

    });
  }
  // ========== 4 ==============
  publicationFormCreate() {
    this.publicationForm = this.formBuild.group({
      title: ['', Validators.required],
      publisher: ['', Validators.required],
      summary: ['', Validators.required]
    });
  }
  // ========== 5 ==============
  skillFormCreate() {
    this.skillForm = this.formBuild.group({
      skillsearch: new FormControl(''),
    });
  }
  // ========== 6 ==============
  getfreelancer(val:any) {
    this.loader.startLoading();
    this.expertService.FindById(val, 'freelancer').then((res: any) => {
      this.loader.stopLoading();
      this.feelancerdata = res.data;
      if (this.feelancerdata.designation == null) {
        this.toaster.info("Update your Profile");
        // this.router.navigate(['/business/update-profile']);
      }
      if (this.feelancerdata.Availability) {
        this.feelancerdata.Availability2 = this.feelancerdata.Availability.replace("_", " ");
      }
      if (this.feelancerdata.designation) {
        this.patchdata();
      } 
      if (this.feelancerdata.skill.length>0) {
        this.slist = this.feelancerdata.skill;
       }
       this.bannerimage = this.feelancerdata.bannerimage;
    }, err => {
      this.loader.stopLoading();
      console.log(err);
    });
  }
  // ========== 7 ==============
  patchdata() {
    this.candidateForm.patchValue({
      "gender": this.feelancerdata.gender,
      "onsiteAvailability": this.feelancerdata.onsiteAvailability,
      "Resumename": this.feelancerdata.Resumename,
      "Resumeurl": this.feelancerdata.Resumeurl,
      "designation": this.feelancerdata.designation,
      "originalimage": this.feelancerdata.originalimage,
      "resumedescrption": this.feelancerdata.resumedescrption,
      "rate": this.feelancerdata.rate,
      "hoursperday" : this.feelancerdata.hoursperday,
      "starttimeday" : this.feelancerdata.starttimeday?this.feelancerdata.starttimeday:"",
      "experience": this.feelancerdata.experience,
      "Availability": this.feelancerdata.Availability,
      "description": this.feelancerdata.description,
      "category": this.feelancerdata.category,
      "otherquilification": this.feelancerdata.otherquilification,
      "image": this.feelancerdata.image,
      "imageurl": this.feelancerdata.imageurl
    });
  }
  // ========== 8 ==============
  updateFreelancer() {
    let data: any = this.candidateForm.value;
    if (this.dochtml.length > 0) {
      data.dochtml = this.dochtml;
    }
    this.expertService.updateFreelancer("66e2bdb7e98b3cffa8050e04", data, 'freelancer').then((res: any) => {
      this.hideModal('profile');
      this.toaster.success('Updated Successfully');
      this.feelancerdata = res.data;
      this.feelancerdata.Availability2 = this.feelancerdata.Availability.replace("_", " ");
      this.setprofiledata(this.feelancerdata);
      this.patchdata();
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }
  // ========== 9 ==============
  updateskill():any {
    let data:any = {};
        data.skill = this.slist;
        if (this.slist.length==0) {
          this.toaster.error('Please select Skill');
          return false;
        }
    this.expertService.updateSkills("66e2bdb7e98b3cffa8050e04", data, 'freelancer').then((res: any) => {
      this.toaster.success('Skill Update Successfully');
      this.hideModal('skill');
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }
  // ========== 10 ==============
  getSkill(val:any) {
    if (val.length > 2) {
      this.skilllist = [];
      this.loderstt2 = true;
      var filter = { $and: [{ "name": { $regex: val, "$options": "i" } }] }
      this.expertService.getSkill(JSON.stringify(filter)).then(res => {
        this.loderstt2 = false;
        this.cls2 = 'displayblock2';
        this.skilllist = res.data;
      }).catch(err => {
        this.loderstt2 = false;
      });
    } else {
      this.cls2 = '';
    }
  }
  // ========== 11 ==============
  setskill(val:any, id:any) {
    this.cls2 = '';
    if (this.slist.length <= 15) {
      this.slist.push(val);
    } else {
      this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
    }
  }
  // ========== 12 ==============
  onFileSelect(event:any) {
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
      this.onSubmit();
    }
  }
  // ========== 13 ==============
  onSubmit() {
    let fd = new FormData();
    fd.append('image', this.fileupload);
    this.expertService.upload(fd, 'candidatedoc').then((res: any) => {
      this.candidateForm.get('Resumename').setValue(res.image);
      this.candidateForm.get('Resumeurl').setValue(res.imageurl);
      this.candidateForm.get('originalimage').setValue(res.originalimage);
      this.dochtml = [];
      let content = res.dochtml.replace(/<img[^>]*>/g,"");
      this.dochtml.push(content);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }
  // ========== 14 ==============
  cancelform(val:any) {
    if (val == 'experience') {
      this.hideModal('experience');
      this.experienceFormCreate();
      this.btnstt = 'Save';
    } else if (val == 'project') {
      this.hideModal('project');
      // this.educationFormCreate();
      this.btnstt = 'Save';
    } else if (val == 'qualification') {
      this.hideModal('qualification');
      this.educationFormCreate();
      this.btnstt = 'Save';
    } else if (val == 'publication') {
      this.hideModal('publication');
      this.publicationFormCreate();
      this.btnstt = 'Save';
    }else if (val == 'profile') {
      this.hideModal('profile');
    }else if (val == 'skill') {
      this.getfreelancer(this.feelancerdata._id);
      this.hideModal('skill');
    }else if (val == 'banner') {
      this.showCropper = false;
      this.croppedImage = '';
      this.bmyInputVariable.nativeElement.value = '';
      this.hideModal('bannerimg');
    }
    else if (val == 'photo') {
      this.showCropper = false;
      this.croppedImage = '';
      this.myInputVariable.nativeElement.value = '';
      this.hideModal('photo');
    }
  }

  // ========== 15 ==============
  onFileChangedImage(event:any) {
    if (event.target.files.length > 0) {
      this.fileuploadImage = event.target.files[0];
      this.onSubmitImage('photo');
    }
  }
  // ========== 16 ==============
  onSubmitImage(val:any) {
    let fd = new FormData();
    fd.append('image', this.fileuploadImage, 'image.jpeg');
    fd.append('baseurl', this.expertService.getbaseurl());

    this.expertService.fileUpload(fd, 'profile').then((res: any) => {
      if (val=='photo') {
        this.candidateForm.get('image').setValue(res.image);
        this.candidateForm.get('imageurl').setValue(res.imageurl);
      } else {
        this.bannerimage.push(res.imageurl);
        this.hideModal('bannerimg');
      }
      this.loaderstt = false;
      this.updateImage(val);
      this.hideModal('photo');
    }).catch(err => {
      this.loaderstt = false;
      this.toaster.error('Please Check internet');
    });

  } 

  // ========== 17 ==============
  updateImage(val:any) {
    let data:any = {};
    if (val=='photo') {
       data.image =this.candidateForm.value.image;
       data.imageurl = this.candidateForm.value.imageurl;
      } else {
        data.bannerimage = this.bannerimage;
     }
    this.expertService.updateImage(this.profiledata.freelanerId._id, data, 'freelancer').then((res: any) => {
      if (val=='photo') {
        this.toaster.success('Photo Uploaded Successfully');
        this.feelancerdata.imageurl = res.data.imageurl;
      } else {
        this.feelancerdata.bannerimage = res.data.bannerimage;
      }
    }, err => {
      this.toaster.error('Please Try After Some time')
    });
  }
  // ========== 18 ==============
  openexperience(val:any) {
    if (val == 'experience') {
      this.showModal('experience');
    } else if (val == 'qualification') {
      this.showModal('qualification');
    } else if (val == 'publication') {
      this.showModal('publication');
    } else if (val == 'profile') {
      this.showModal('profile');
    }else if (val == 'skill') {
      this.showModal('skill');
    }else if (val == 'bannerimg') {
      this.showModal('bannerimg');
    }
  }
  // ========== 19 ==============
  getJobType() {
    this.expertService.getJobType({ status: true }, 'jobtype').then((res: any) => {
      this.jobType = res.data;
    }).catch(err => {

    });
  }
  // ========== 20 ==============
  setprofiledata(data:any) {
    this.expertService.setprofiledata(data);
  }
  // ========== 21 ==============
  projectdetails(pdata:any) {
    this.projectinfo = pdata;
    this.showModal('portfolio');
  }
  // ========== 22 ==============
  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.expertService.homeGetSkill(JSON.stringify(filter)).then(res => {
      this.skillListdata = res.data;
    }, err => {
      console.log(err);
    });
  }
  // ========== 23 ==============
  windowrendor() {
    this.renderer.listen('window', 'click', (e: Event) => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach(element => {
        if (element == 'salectchanged') {
          change1 += 1;
        }
        if (element == 'salectchanged2') {
          change2 += 1;
        }
      });
      if (change1 == 0) {
        this.setchangevalue();
      }
      if (change2 == 0) {
        this.setchangevalue2();
      }
      if ((<Element>e.target).className.indexOf("pgclass") != -1) {
        this.droppg = true;
      } else {
        this.droppg = false;
      }
      if ((<Element>e.target).className.indexOf("ugclass") != -1) {
        this.dropug = true;
      } else {
        this.dropug = false;
      }

    });
  }
  // ========== 24 ==============
  setchangevalue() {
    if (this.skillForm.value.skillsearch) {
      if (this.slist.length <= 15) {
        this.slist.push(this.skillForm.value.skillsearch);
        this.cls1 = '';
        this.skillForm.get('skillsearch').setValue('');
      } else {
        this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
      }

    }
  }
  // ========== 25 ==============
  setchangevalue2() {
    if (this.skillForm.value.skillsearch) {
      if (this.slist.length <= 15) {
        this.slist.push(this.skillForm.value.skillsearch);
        this.cls1 = '';
        this.skillForm.get('skillsearch').setValue('');
      } else {
        this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
      }
    }
  }
  // ========== 26 ==============
  getsltSkills(val:any) {
    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls1 = 'displayblock';
      this.templist = this.skillListdata.filter(function (el:any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = '';
      }
    } else {
      if (this.slist.length <= 15) {
        this.slist.push(val.replace(",", ""));
        this.cls1 = '';
        this.skillForm.get('skillsearch').setValue('');
      } else {
        this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
      }
    }
  }
  // ========== 27 ==============
  setskillvalue(item:any) {
    if (this.slist.length <= 15) {
      this.slist.push(item);
      this.cls1 = '';
      this.skillForm.get('skillsearch').setValue('');
    } else {
      this.toaster.error('Add up to 15 skills. Remove skills by deleting tags.');
    }
  }
  // ========== 28 ==============
  removeItem(inx:any) {
    this.slist.splice(inx, 1);
  }
  // ========== 29 ==============
  window_load() {
    this.modules = {
      toolbar: [
        ['bold', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }]],
    };

    this.configck = {
      uiColor: '#f2f2f2',
      toolbarGroups: [ 
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' }
      ],
      resize_enabled: false,
      height: 200 
    };

    this.info["workfrom"] = `<div><b>Offsite</b>: My office </div>
                           <div><b>Onsite</b>: Client-side</div>
                           <div><b>Anywhere</b>: My office or Client-side</div>`;
  }

  fileChangeEvent(event: any,pval:any): void {
    this.imageChangedEvent = event;
    this.otherimg =true;
  }

  imageCropped(event: ImageCroppedEvent ,val:any) {
      this.croppedImage = event.base64;
  }

  imageLoaded(val:any) {
      this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions,val:any) {
    //console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed(val:any) {
    //console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  b64toBlob(dataURI:any) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  addimage(val:any) {
    this.loaderstt = true;
    this.showCropper = false;
    this.fileuploadImage = this.b64toBlob(this.croppedImage);
    this.croppedImage = '';
    if (val=='photo') {
      this.myInputVariable.nativeElement.value = '';
    } else {
      this.bmyInputVariable.nativeElement.value = '';
    }
    this.onSubmitImage(val)
  } 

  // experience
  submitexperience():any {
    let data: any = {
      title: this.experienceForm.value.title,
      company: this.experienceForm.value.company,
      summary: this.experienceForm.value.summary,
      currently: this.experienceForm.value.currently,
      feelancerId: this.feelancerdata._id,
      startmonth: this.experienceForm.value.startmonth,
      startyear: this.experienceForm.value.startyear,
      endmonth: this.experienceForm.value.endmonth,
      endyear: this.experienceForm.value.endyear
    };
    
    if (this.experienceForm.value.currently==false) {
      let startdata = Date.parse(this.experienceForm.value.startyear + "-" + this.experienceForm.value.startmonth + "-01");
        let enddate = Date.parse(this.experienceForm.value.endyear + "-" + this.experienceForm.value.endmonth + "-01");
        if (startdata > enddate) {
        this.toaster.error('The end date can not be earlier than the start date');
      return false;
      }
    } //submitexperience
    
    if (this.btnstt == 'Save') {
      this.expertService.addSubarray(data, 'addexperience/freelancer').then((res: any) => {
        this.toaster.success('Add Experience Successfully!');
        this.hideModal('experience');
        this.experienceFormCreate();
        this.feelancerdata.experienceList.push(data);
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    } else {
      data.experienceId = this.experienceId;
      this.expertService.updateSubarray(data, 'updateexperience/freelancer').then((res: any) => {
        this.toaster.success('Update Experience successfully!');
        this.hideModal('experience');
        this.experienceFormCreate();
        this.getfreelancer(this.feelancerdata._id);
        this.btnstt = 'Save';
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    }
  }

  editpatchexperience(pdata:any) {
    this.btnstt = 'Update';
    this.experienceId = pdata._id;
    this.experienceForm.patchValue({
      title: pdata.title,
      company: pdata.company,
      summary: pdata.summary,
      currently: pdata.currently,
      startmonth: pdata.startmonth,
      startyear: pdata.startyear,
      endmonth: pdata.endmonth,
      endyear: pdata.endyear
    });
    if (pdata.currently == 1) {
      this.experienceForm.get('endmonth').setValue('');
      this.experienceForm.get('endyear').setValue('');
      this.experienceForm.controls['endmonth'].disable();
      this.experienceForm.controls['endyear'].disable();
    }
    this.showModal('experience');
  }

  deleteex(ids:any, inx:any) {
    this.expertService.deleteSubarray({ experienceId: ids, feelancerId: this.feelancerdata._id }, 'deleteexperience/freelancer').then((res: any) => {
      this.toaster.success('Delete Experience successfully!');
      this.feelancerdata.experienceList.splice(inx, 1);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  currently() {
    if (this.experienceForm.value.currently) {
      this.experienceForm.get('endmonth').setValue('');
      this.experienceForm.get('endyear').setValue('');
      this.experienceForm.controls['endmonth'].disable();
      this.experienceForm.controls['endyear'].disable();
    } else {
      this.experienceForm.controls['endmonth'].enable();
      this.experienceForm.controls['endyear'].enable();
    }
  }

  //openQualification 
  submitqualification():any {
    let data: any = {
      edutype: this.educationForm.value.edutype,
      degree: this.educationForm.value.degree,
      college: this.educationForm.value.college,
      studyArea: this.educationForm.value.studyArea,
      feelancerId: this.feelancerdata._id,
      startyear: this.educationForm.value.startyear,
      endyear: this.educationForm.value.endyear
    };
    if (this.educationForm.value.startyear > this.educationForm.value.endyear) {
      this.toaster.error('The end year can not be earlier than the start year');
      return false;
    }
    if (this.btnstt == 'Save') {
      this.expertService.addSubarray(data, 'addqualification/freelancer').then((res: any) => {
        this.toaster.success('Add Qualification Successfully!');
        let fredata = res.data;
        this.hideModal('qualification');
        this.educationFormCreate();
        if (data.edutype == 'ug') {
          this.feelancerdata.ugType=fredata.ugType ;
          //data.ug = this.educationForm.value.degree;
          //this.feelancerdata.ugType.push(data);
        } else {
          this.feelancerdata.pgType=fredata.pgType ;
          // data.pg = this.educationForm.value.degree;
          // this.feelancerdata.pgType.push(data);
        }
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    } else {
      data.qualificationId = this.qualificationId;
      this.expertService.updateSubarray(data, 'updatequalification/freelancer').then((res: any) => {
        this.toaster.success('Update Qualification successfully!');
        this.hideModal('qualification');
        this.educationFormCreate();
        this.getfreelancer(this.feelancerdata._id);
        this.btnstt = 'Save';
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    }
  }

  editpatchqualification(pdata:any,edutype:any) {
    this.btnstt = 'Update';
    this.qualificationId = pdata._id;
    this.educationForm.patchValue({
      college: pdata.college,
      studyArea: pdata.studyArea,
      startyear: pdata.startyear,
      endyear: pdata.endyear,
      edutype : edutype
    });
    if (edutype =='ug') {
      this.educationForm.get('degree').setValue(pdata.ug);
    } else {
      this.educationForm.get('degree').setValue(pdata.pg);
    }
    this.showModal('qualification');
  }

  deletequalification(ids:any, inx:any,edutype:any) {
    this.expertService.deleteSubarray({ qualificationId: ids, feelancerId: this.feelancerdata._id ,edutype : edutype }, 'deletequalification/freelancer').then((res: any) => {
      this.toaster.success('Delete Qualification successfully!');
      if (edutype=='ug') {
        this.feelancerdata.ugType.splice(inx, 1);
      } else {
        this.feelancerdata.pgType.splice(inx, 1);
      }
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  submitpublication() {
    let data: any = {
      title: this.publicationForm.value.title,
      publisher: this.publicationForm.value.publisher,
      summary: this.publicationForm.value.summary,
      feelancerId: this.feelancerdata._id
    };
    if (this.btnstt == 'Save') {
      this.expertService.addSubarray(data, 'addpublication/freelancer').then((res: any) => {
        this.toaster.success('Add publication Successfully!');
        this.hideModal('publication');
        this.publicationFormCreate();
        this.feelancerdata.publicationList.push(data);
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    } else {
      data.publicationId = this.publicationId;
      this.expertService.updateSubarray(data, 'updatepublication/freelancer').then((res: any) => {
        this.toaster.success('Update publication successfully!');
        this.hideModal('publication');
        this.publicationFormCreate();
        this.getfreelancer(this.feelancerdata._id);
        this.btnstt = 'Save';
      }).catch(err => {
        this.toaster.error('Please Check internet');
      });
    }
  }

  editpatchpublication(pdata:any) {
    this.btnstt = 'Update';
    this.publicationId = pdata._id;
    this.publicationForm.patchValue({
      title: pdata.title,
      publisher: pdata.publisher,
      summary: pdata.summary
    });
    this.showModal('publication');
  }

  deletepublication(ids:any, inx:any) {
    this.expertService.deleteSubarray({ publicationId: ids, feelancerId: this.feelancerdata._id }, 'deletepublication/freelancer').then((res: any) => {
      this.toaster.success('Delete publication successfully!');
      this.feelancerdata.publicationList.splice(inx, 1);
    }).catch(err => {
      this.toaster.error('Please Check internet');
    });
  }

  checkdate():any{
    let edata = this.experienceForm.value;
    console.log(edata);
    if ( edata.startmonth &&  edata.startyear && edata.endmonth && edata.endyear) {
       
        let startdata = Date.parse(edata.startyear + "-" + edata.startmonth + "-01");
        let enddate = Date.parse(edata.endyear + "-" + edata.endmonth + "-01");
        console.log(startdata,enddate);
        if (startdata > enddate) {
          this.toaster.error('The End Date can not be earlier than the Start Date');
          return false;
        }  
    }
  }

  getcountsummary(val:any):any {

    setTimeout(():any => {
      const summary = this.candidateForm.value.description;
      //console.log(this.candidateForm.value.description,val);
      this.cntchr = 4000 - (summary.length - 1);
      if (summary.length > 4001) {
        this.candidateForm.get("description").setValue(summary.slice(0, 4000));
        return false;
      }
    });

  }
  
  openAddProject(){
    this.showModal('project');
  }









}
