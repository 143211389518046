<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Welcome {{ authService.userdata.data.cname }}</h2>
        </div>
        <div class="row pt-3">
          <div class="job-list-right">
            <div class="job" style="text-align: center">
              <button routerLink="/post-a-job" type="submit" class="btn topRoutingLinks">Post Job</button>
              <button routerLink="/add-bench" type="submit" class="btn topRoutingLinks">Add Bench</button>
              <button routerLink="/search" [queryParams]="{ tab: 1, userType: 3 }" type="submit" class="btn topRoutingLinks">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="background: black; padding: 20px 0px">
  <div class="container">
    <div class="row">
      <div class="job-list-right">
        <div class="job" style="text-align: center">
          <button routerLink="/post-a-job" type="submit" class="btn topRoutingLinks">Post Job</button>
          <button routerLink="/add-bench" type="submit" class="btn topRoutingLinks">Add Bench</button>
          <button routerLink="/search" [queryParams]="{ tab: 1, userType: 3 }" type="submit" class="btn topRoutingLinks">Search</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="counter-area two pt-100">
  <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" /></div>

  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3" routerLink="/jobs">
        <div class="counter-item outerBorder">
          <a [routerLink]="['/jobs']">
            <i class="flaticon-hiring"></i>
            <p>MY JOBS</p>
            <h3>
              <span>{{ dashboardData.myjob }}</span>
            </h3>
          </a>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item outerBorder">
          <a [routerLink]="['/job-applicants']" [queryParams]="{ jobid: '', tab: 1, userType: 3 }">
            <i class="flaticon-candidate"></i>
            <p>JOB APPLICANTS</p>
            <h3>
              <span>{{ dashboardData.appliedcandidate }}</span>
            </h3>
          </a>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item outerBorder">
          <a routerLink="/bench">
            <i class="flaticon-working-at-home"></i>
            <p>MY BENCH</p>
            <h3>
              <span>{{ dashboardData.mycandidate }}</span>
            </h3>
          </a>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item outerBorder">
          <a [routerLink]="['/bench-applications']" [queryParams]="{ userid: '', tab: 1, userType: 3 }">
            <i class="flaticon-choice"></i>
            <p>BENCH APPLICATIONS</p>
            <h3>
              <span>{{ dashboardData.jobapplied }}</span>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="job-area-list ptb-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12"> 
        <ul class="job-list-item align-items-center">
          <li>
            <a class="text-secondary" style="font-size: 24px" href="javascript:void(0)"
              >Recent Activities
              <span class="text-secondary"> ({{ totalCounts }})</span>
            </a>
          </li>
          <li>
            <span class="sort"></span>
          </li>
        </ul>

        <div class="employer-item" *ngFor="let job of jobs">

          <div class="row">
            <div class="col-lg-5">
              <a [routerLink]="['/job-applicants']" [queryParams]="{ jobid: job.jobId?._id, tab: 1, userType: 3 }">
                <h4 class="text-dark mb-0">{{ job?.jobId?.title }}</h4>
                <p>(JID : {{job?.jobId?.resourceId}})</p>
                <ul>
                  <li>
                    {{ job.jobId.jobtype }},
                  </li>
                  <li>{{ job.createdAt | date: "dd MMM, yyyy" }}</li>
                </ul>
                <span *ngFor="let s of job.jobId.skill" class="span-one" style="margin: 0px 2px">{{ s }}</span>
              </a>
            </div>
            <div class="col-lg-5">
              <h5 class="mb-0">{{ job?.candidateId?.name }}</h5>
              <span *ngIf="job?.userType === 1">
                <p>(RID : {{job?.candidateId?.resourceId}})</p>
              </span>
             
              <span *ngIf="job?.userType === 1" class="badge bg-info">
                {{job?.company?.cname}}
              </span>
              <span *ngIf="job?.userType === 2" class="badge bg-info">
                Freelancer
              </span>
            </div>
            <div class="col-lg-2">
              <span *ngIf="job?.status === 1" class="badge bg-secondary px-3 py-2" style="font-size: 14px;">
                Pending
              </span>
              <span *ngIf="job?.status === 2" class="badge bg-success px-3 py-2" style="font-size: 14px;">
                Shortlisted
              </span>
              <span *ngIf="job?.status === 3" class="badge bg-success px-3 py-2" style="font-size: 14px;">
                Hired
              </span>
              <span *ngIf="job?.status === 4" class="badge bg-danger px-3 py-2" style="font-size: 14px;">
                Rejected
              </span>
            </div>
          </div>


   
        </div>

        <div class="pagination-area" style="padding-bottom: 30px">
          <ul>
            <li style="padding: 0px 10px">Page {{ currentPage }} of {{ pages }}</li>
            <li *ngIf="currentPage != 1"><a style="cursor: pointer" (click)="previousPage()">Prev</a></li>
            <li *ngIf="currentPage != pages"><a style="cursor: pointer" (click)="nextPage()">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-footer-style-one></app-footer-style-one> -->

<!-- Lift_BTN #toTop in custom.js -->
