<!-- Register -->
<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Sign Up</h2>
        </div>
        <div class="row pt-3">
          <div class="job-list-right">
            <div class="job" style="text-align: center">
              <button type="submit" class="btn topToggleBtN" [ngClass]="{ 'active-btn': b_loginStatus }" (click)="businessLogin()">Business</button>
              <button type="submit" class="btn topToggleBtN" [ngClass]="{ 'active-btn': e_loginStatus }" (click)="expertLogin()">Expert</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="background: black; padding: 20px 0px">
  <div class="row">
    <div class="job-list-right">
      <div class="job" style="text-align: center">
        <button type="submit" class="btn topToggleBtN" [ngClass]="{ 'active-btn': b_loginStatus }" (click)="businessLogin()">Business</button>
        <button type="submit" class="btn topToggleBtN" [ngClass]="{ 'active-btn': e_loginStatus }" (click)="expertLogin()">Expert</button>
      </div>
    </div>
  </div>
</div> -->

<div class="user-form-area">
  <div class="container-fluid">
    <!-- BusinessRegistration -->
    <div *ngIf="b_loginStatus == true" class="row text-center">
      <div class="col-lg-12">
        <div class="user-content mt-4">
          <div class="top ">
            <span class="text-primary">
              <!-- style="color: #221668;" -->
              <h2 class="my-0 py-0" >Business</h2>
            </span>
           
            <h4 class="my-3 py-0 text-info">( Free Forever )</h4>
            <form [formGroup]="B_addRegistrationForm">
              <!-- name -->
              <div class="row form-group">
                <div class="col-lg-6 col-md-12">
                  <input type="text" formControlName="fname" class="form-control" placeholder="First Name*" [ngClass]="{ 'is-invalid': (formAddRegistration.fname.invalid && formAddRegistration.fname.touched) || (formAddRegistration.fname.invalid && submitted), 'is-valid': formAddRegistration.fname.valid }" />
                  <div *ngIf="formAddRegistration.fname.hasError('pattern')">
                    <small class="text-danger">Please enter only letters.</small>
                  </div>
                  <div *ngIf="(formAddRegistration.fname.invalid && formAddRegistration.fname.touched) || (formAddRegistration.fname.invalid && submitted)">
                    <small class="text-danger">First name is <b>required.</b></small>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <input type="text" formControlName="lname" class="form-control" placeholder="Last Name*" [ngClass]="{ 'is-invalid': (formAddRegistration.lname.invalid && formAddRegistration.lname.touched) || (formAddRegistration.lname.invalid && submitted), 'is-valid': formAddRegistration.lname.valid }" />
                  <div *ngIf="formAddRegistration.lname.hasError('pattern')">
                    <small class="text-danger">Please enter only <b>letters.</b></small>
                  </div>
                  <div *ngIf="(formAddRegistration.lname.invalid && formAddRegistration.lname.touched) || (formAddRegistration.lname.invalid && submitted)">
                    <small class="text-danger">Last name is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- Phone -->
              <div class="row form-group">
                <div class="col-lg-6">
                  <ng-select [multiple]="false" formControlName="phonecode" placeholder="Select Phonecode">
                    <ng-option value="91"> <img src="../../../../assets/img/icon/inFlag32.png" alt="flagIcon" /> +91 </ng-option>
                    <ng-option value="1"> <img src="../../../../assets/img/icon/usaFlag32.png" alt="flagIcon" /> +1 </ng-option>
                  </ng-select>
                </div>
                <div class="col-lg-6">
                  <!-- <input type="text" class="form-control" placeholder="Phone*" /> -->
                  <input (keypress)="numberOnly($event)" type="text" formControlName="phone" class="form-control" placeholder="Contact Number*" [ngClass]="{ 'is-invalid': (formAddRegistration.phone.invalid && formAddRegistration.phone.touched) || (formAddRegistration.phone.invalid && submitted), 'is-valid': formAddRegistration.phone.valid }" />
                  <div *ngIf="(formAddRegistration.phone.invalid && formAddRegistration.phone.touched) || (formAddRegistration.phone.invalid && submitted)">
                    <small class="text-danger">Contact no. is <b>required.</b></small>
                  </div>
                  <div *ngIf="formAddRegistration['phone'].errors">
                    <div *ngIf="formAddRegistration['phone'].errors['pattern']">
                      <small class="text-danger">Please enter only <b>numbers.</b></small>
                    </div>
                    <div *ngIf="formAddRegistration['phone'].errors['minlength']">
                      <small class="text-danger">Contact no. should have atleast <b>10 digits.</b></small>
                    </div>
                    <div *ngIf="formAddRegistration['phone'].errors['maxlength']">
                      <small class="text-danger">Contact no. could have max <b>10 digits.</b></small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- email -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <!-- <input type="email" class="form-control" placeholder="Email*" /> -->
                  <input type="email" formControlName="email" class="form-control" placeholder="Enter Email" [ngClass]="{ 'is-invalid': (formAddRegistration.email.invalid && formAddRegistration.email.touched) || (formAddRegistration.email.invalid && submitted), 'is-valid': formAddRegistration.email.valid }" />
                  <div *ngIf="formAddRegistration['email'].errors">
                    <div *ngIf="formAddRegistration['email'].errors['pattern']">
                      <small class="text-danger">Email should be <b>valid. </b>eg; abc&#64;domainName.com</small>
                    </div>
                  </div>
                  <div *ngIf="(formAddRegistration.email.invalid && formAddRegistration.email.touched) || (formAddRegistration.email.invalid && submitted)">
                    <small class="text-danger">Email is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- Password -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <input type="password" formControlName="password" placeholder="Password*" class="form-control" [ngClass]="{ 'is-invalid': submitted && formAddRegistration.password.errors }" />
                  <div *ngIf="(formAddRegistration.password.invalid && formAddRegistration.password.touched) || (formAddRegistration.password.invalid && submitted)">
                    <small class="text-danger">Password is <b>required.</b></small>
                  </div>
                  <div *ngIf="formAddRegistration['password'].errors">
                    <div *ngIf="formAddRegistration['password'].errors['minlength']">
                      <small class="text-danger">Password must be at least <b>6 characters.</b></small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- CompanyName -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <input type="text" formControlName="cname" class="form-control" placeholder="Company Name*" [ngClass]="{ 'is-invalid': (formAddRegistration.cname.invalid && formAddRegistration.cname.touched) || (formAddRegistration.cname.invalid && submitted), 'is-valid': formAddRegistration.cname.valid }" />
                  <div *ngIf="formAddRegistration.cname.hasError('pattern')">
                    <small class="text-danger">Please enter only <b>letters.</b></small>
                  </div>
                  <div *ngIf="(formAddRegistration.cname.invalid && formAddRegistration.cname.touched) || (formAddRegistration.cname.invalid && submitted)">
                    <small class="text-danger">Company name is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- city -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <div *ngIf="othercity == false" class="col-lg-12 col-md-12">
                    <input type="text" class="form-control" formControlName="location" placeholder="Type City Name*" (keyup)="get_city($any($event.target).value)" id="locationId" (change)="locationclear()" autocomplete="off" />

                    <span *ngIf="loderstt" class="city_loader"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                    <div id="myDropdown" class="dropdownContent_city {{ cls }}" #dropdown>
                      <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item?.country_id?.country_name, item._id)"> {{ item.city_name }}, {{ item?.state_id?.state_name }}, ({{ item?.country_id?.country_name }}) </a>
                      <a (click)="othercityslt()"> Other City </a>
                    </div>
                    <div *ngIf="formAddRegistration.location.invalid && (formAddRegistration.location.touched || submitted)">
                      <small class="text-danger" *ngIf="formAddRegistration.location.errors?.required"> Location is <b>required.</b> </small>
                    </div>
                  </div>
                  <div *ngIf="othercity" class="col-lg-12 text-start mb-3">
                    <label>City Name<span class="text-danger">*</span> </label>
                    <input type="text" id="othercity" class="form-control inputFieldBorder" placeholder="Enter City Name" />
                  </div>
                  <div *ngIf="othercity" class="col-lg-12 text-start">
                    <label>Country Name<span class="text-danger">*</span></label>
                    <a href="javascript:void(0)" class="ps-3">
                      <span class="badge bg-secondary" (click)="othercityslt()">Clear</span>
                    </a>
                    <select class="form-control inputFieldBorder" id="othercountry" (change)="otherslt($any($event.target).value, 2)">
                      <option value="">Select Country *</option>
                      <option *ngFor="let list of countrylist" value="{{ list?.country_name }}">{{ list?.country_name }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <button type="button" class="btn" (click)="onSubmitBusiness()">SignUp Here</button>

              <div class="row">
                <div class="col-lg-12">
                  <p class="mt-1 ps-1">
                    Already have an account?
                    <a routerLink="/login" class="text-info">Login here</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- ExpertRegistration -->
    <div *ngIf="e_loginStatus == true" class="row text-center">
      <div class="col-lg-12">
        <div class="user-content mt-4">
          <div class="top">
            <h2>Expert</h2>

            <form [formGroup]="E_addRegistrationForm">
              <!-- name -->
              <div class="row form-group">
                <div class="col-lg-6 col-md-12">
                  <input type="text" formControlName="fname" class="form-control" placeholder="First Name*" [ngClass]="{ 'is-invalid': (formAddExpertRegistration.fname.invalid && formAddExpertRegistration.fname.touched) || (formAddExpertRegistration.fname.invalid && e_submitted), 'is-valid': formAddExpertRegistration.fname.valid }" />
                  <div *ngIf="formAddExpertRegistration.fname.hasError('pattern')">
                    <small class="text-danger">Please enter only letters.</small>
                  </div>
                  <div *ngIf="(formAddExpertRegistration.fname.invalid && formAddExpertRegistration.fname.touched) || (formAddExpertRegistration.fname.invalid && e_submitted)">
                    <small class="text-danger">First name is <b>required.</b></small>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <input type="text" formControlName="lname" class="form-control" placeholder="Last Name*" [ngClass]="{ 'is-invalid': (formAddExpertRegistration.lname.invalid && formAddExpertRegistration.lname.touched) || (formAddExpertRegistration.lname.invalid && e_submitted), 'is-valid': formAddExpertRegistration.lname.valid }" />
                  <div *ngIf="formAddExpertRegistration.lname.hasError('pattern')">
                    <small class="text-danger">Please enter only <b>letters.</b></small>
                  </div>
                  <div *ngIf="(formAddExpertRegistration.lname.invalid && formAddExpertRegistration.lname.touched) || (formAddExpertRegistration.lname.invalid && e_submitted)">
                    <small class="text-danger">Last name is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- Phone -->
              <div class="row form-group">
                <div class="col-lg-6">
                  <ng-select [multiple]="false" formControlName="phonecode" placeholder="Select Phonecode">
                    <ng-option value="91"> <img src="../../../../assets/img/icon/inFlag32.png" alt="flagIcon" /> +91 </ng-option>
                    <ng-option value="1"> <img src="../../../../assets/img/icon/usaFlag32.png" alt="flagIcon" /> +1 </ng-option>
                  </ng-select>
                </div>
                <div class="col-lg-6">
                  <!-- <input type="text" class="form-control" placeholder="Phone*" /> -->
                  <input (keypress)="numberOnly($event)" type="text" formControlName="phone" class="form-control" placeholder="Contact Number*" [ngClass]="{ 'is-invalid': (formAddExpertRegistration.phone.invalid && formAddExpertRegistration.phone.touched) || (formAddExpertRegistration.phone.invalid && e_submitted), 'is-valid': formAddExpertRegistration.phone.valid }" />
                  <div *ngIf="(formAddExpertRegistration.phone.invalid && formAddExpertRegistration.phone.touched) || (formAddExpertRegistration.phone.invalid && e_submitted)">
                    <small class="text-danger">Contact no. is <b>required.</b></small>
                  </div>
                  <div *ngIf="formAddExpertRegistration['phone'].errors">
                    <div *ngIf="formAddExpertRegistration['phone'].errors['pattern']">
                      <small class="text-danger">Please enter only <b>numbers.</b></small>
                    </div>
                    <div *ngIf="formAddExpertRegistration['phone'].errors['minlength']">
                      <small class="text-danger">Contact no. should have atleast <b>10 digits.</b></small>
                    </div>
                    <div *ngIf="formAddExpertRegistration['phone'].errors['maxlength']">
                      <small class="text-danger">Contact no. could have max <b>10 digits.</b></small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- email -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <!-- <input type="email" class="form-control" placeholder="Email*" /> -->
                  <input type="email" formControlName="email" class="form-control" placeholder="Enter Email" [ngClass]="{ 'is-invalid': (formAddExpertRegistration.email.invalid && formAddExpertRegistration.email.touched) || (formAddExpertRegistration.email.invalid && e_submitted), 'is-valid': formAddExpertRegistration.email.valid }" />
                  <div *ngIf="formAddExpertRegistration['email'].errors">
                    <div *ngIf="formAddExpertRegistration['email'].errors['pattern']">
                      <small class="text-danger">Email should be <b>valid. </b>eg; abc&#64;domainName.com</small>
                    </div>
                  </div>
                  <div *ngIf="(formAddExpertRegistration.email.invalid && formAddExpertRegistration.email.touched) || (formAddExpertRegistration.email.invalid && e_submitted)">
                    <small class="text-danger">Email is <b>required.</b></small>
                  </div>
                </div>
              </div>
              <!-- Password -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <input type="password" formControlName="password" placeholder="Password*" class="form-control" [ngClass]="{ 'is-invalid': e_submitted && formAddExpertRegistration.password.errors }" />
                  <div *ngIf="(formAddExpertRegistration.password.invalid && formAddExpertRegistration.password.touched) || (formAddExpertRegistration.password.invalid && e_submitted)">
                    <small class="text-danger">Password is <b>required.</b></small>
                  </div>
                  <div *ngIf="formAddExpertRegistration['password'].errors">
                    <div *ngIf="formAddExpertRegistration['password'].errors['minlength']">
                      <small class="text-danger">Password must be at least <b>6 characters.</b></small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- CompanyName -->
              <!-- <div class="row form-group">
                <div class="col-lg-12">
                  <input type="text" formControlName="cname" class="form-control" placeholder="Company Name*" [ngClass]="{ 'is-invalid': (formAddExpertRegistration.cname.invalid && formAddExpertRegistration.cname.touched) || (formAddExpertRegistration.cname.invalid && e_submitted), 'is-valid': formAddExpertRegistration.cname.valid }" />
                  <div *ngIf="formAddExpertRegistration.cname.hasError('pattern')">
                    <small class="text-danger">Please enter only <b>letters.</b></small>
                  </div>
                  <div *ngIf="(formAddExpertRegistration.cname.invalid && formAddExpertRegistration.cname.touched) || (formAddExpertRegistration.cname.invalid && e_submitted)">
                    <small class="text-danger">Company name is <b>required.</b></small>
                  </div>
                </div>
              </div> -->
              <!-- uploadResume -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <!--  onchange="resumeupload()" -->
                  <input class="form-control selectFile" type="file" id="formFile" formControlName="resume" name="myFile" accept=".docx,.pdf,.doc" />
                </div>
              </div>
              <!-- city -->
              <div class="row form-group">
                <div class="col-lg-12">
                  <div *ngIf="othercityForExpert == false" class="col-lg-12 col-md-12">
                    <input type="text" class="form-control" formControlName="location" placeholder="Type City Name*" (keyup)="get_city($any($event.target).value)" id="locationId" (change)="locationclear()" autocomplete="off" />

                    <span *ngIf="loderstt" class="city_loader"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                    <div id="myDropdown" class="dropdownContent_city {{ cls }}" #dropdown>
                      <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item?.country_id?.country_name, item._id)"> {{ item.city_name }}, {{ item?.state_id?.state_name }}, ({{ item?.country_id?.country_name }}) </a>
                      <a (click)="othercitysltEx()"> Other City </a>
                    </div>
                    <div *ngIf="formAddExpertRegistration.location.invalid && (formAddExpertRegistration.location.touched || e_submitted)">
                      <small class="text-danger" *ngIf="formAddExpertRegistration.location.errors?.required"> Location is <b>required.</b> </small>
                    </div>
                  </div>
                  <div *ngIf="othercityForExpert" class="col-lg-12 text-start mb-3">
                    <label>City Name<span class="text-danger">*</span> </label>
                    <input type="text" id="othercity" class="form-control inputFieldBorder" placeholder="Enter City Name" />
                  </div>
                  <div *ngIf="othercityForExpert" class="col-lg-12 text-start">
                    <label>Country Name<span class="text-danger">*</span></label>
                    <a href="javascript:void(0)" class="ps-3">
                      <span class="badge bg-secondary" (click)="othercitysltEx()">Clear</span>
                    </a>
                    <select class="form-control inputFieldBorder" id="othercountry" (change)="othersltEx($any($event.target).value, 2)">
                      <option value="">Select Country *</option>
                      <option *ngFor="let list of countrylist" value="{{ list?.country_name }}">{{ list?.country_name }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <button type="button" class="btn" (click)="onSubmitExpert()">Sign Up Here</button>

              <div class="row">
                <div class="col-lg-12">
                  <p class="mt-1 ps-1">
                    Already have an account?
                    <a routerLink="/login" class="text-info">Login here</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Register -->
<app-footer-style-two></app-footer-style-two>
