<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Terms & Conditions</h2>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Terms & Conditions -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <h3>Introduction</h3>
            <p>Document provides the Terms and Conditions of Use under which You (Business & Freelancers) may use the said website OnBenchMark.com, a product of Cosette Network Pvt Ltd (CNPL) . Please read these Terms and Conditions of Use, carefully. Your going through the website would signify that you accept these terms and conditions fully. By using the Website, you are indicating your acceptance to be bound by these Terms and Conditions of Use. OnBenchMark.com revise/up-date/modify Terms and Conditions of Use including commercial terms at any time without notice. You agree to abide by the terms and conditions for all the time of usage of the website.</p>

            
            <h5>Privacy</h5>
            <p>Please review our Privacy Notice, which also governs your visit to OnBenchMark.com, to understand industry wide best practices.</p>

            <h5>Electronic Communications</h5>
            <p>When you visit OnBenchMark.com or send e-mails to us, you are communicating with us electronically and you consent to receive communications from us amongst other things electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement.</p>

            <h5>Copyright</h5>
            <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, articles, white papers, videos and software, is the property of CNPL or its content suppliers and is protected by Indian and international copyright laws/rules/regulations/statutes. The compilation of all content on this site is the exclusive property of CNPL and protected by Indian copyright laws. All software used on this site is the exclusive property of CNPL and protected by Indian and international copyright laws /rules/ regulations/statutes.</p>

            <h5>Privacy</h5>
            <p>Please review our Privacy Notice, which also governs your visit to OnBenchMark.com, to understand industry wide best practices.</p>

            <h5>Trademark</h5>
            <p>www.OnBenchMark.com and other marks indicated on our site are registered trademarks of OnBenchMark.com and owned by CNPL. Other OnBenchMark.com graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade dress of OnBenchMark.com , and trade dress must not be used in connection with any product or service that is not OnBenchMark.com's , in any manner that is likely to cause confusion among customers and all others concerned and the general public or in any manner that disparages or discredits OnBenchMark.com. All other trademarks not owned by CNPL or its subsidiaries that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by OnBenchMark.com or its subsidiaries.</p>

            <h5>License and Access</h5>
            <p>Please review our Privacy Notice, which also governs your visit to OnBenchMark.com, to understand industry wide best practices.</p>

            <h3>OnBenchMark.com Site Security Rules</h3>
            <p>Users are prohibited from violating or attempting to violate the security of any OnBenchMark.com Site, including, without limitation,</p>

            <ul>
                <li>Accessing data not intended for such user or logging into a server or account which the user is not authorized to access</li>
                <li>Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization</li>
                <li>Sending unsolicited e-mail, including promotions and/or advertising of products or services, or Forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting.</li>
                <li>Violations of system or network security may result in civil or criminal liability. The OnBenchMark.com will investigate occurrences which may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</li>
               
            </ul>


            
            <h5>Registration and Password</h5>
            <p>You are responsible for maintaining the confidentiality of your information and password. You shall be responsible for all uses of your registration, whether or not authorized by you. You agree to immediately notify the OnBenchMark.com of any unauthorized use of your registration or password.</p>

            <h5>Display</h5>
            <p>You are responsible for maintaining the confidentiality of your information and password. You shall be responsible for all uses of your registration, whether or not authorized by you. You agree to immediately notify the OnBenchMark.com of any unauthorized use of your registration or password.</p>

            <ul>
                <li>The payment, if any, for service once subscribed to by the subscriber is not refundable. • OnBenchMark.com offers no guarantee or warranties that there would be a satisfactory response or any response at all once the profile is put on display.</li>
                <li>OnBenchMark.com neither guarantees nor offers any warranty about the credentials of the prospective employer/organization which downloads the information and uses it to contact the prospective employee / visitor / user / subscriber. </li>
                <li>OnBenchMark.com reserves the right to delete any content on the website that it finds objectionable. It also will be held harmless for any loss of content of video profiles if the content was found to be objectionable by any viewer or bots on the internet.</li>
                <li>OnBenchMark.com would not be held liable for loss of any data technical or otherwise, and particulars supplied by subscribers due to reasons beyond its control like corruption of data or loss of communication network, systemic failure, delay or failure to perform as a result of any causes or conditions that are beyond OnBenchMark.com's reasonable control including but not limited to strikes, riots, civil unrest, Govt. policies, tampering of data by unauthorized persons like hackers, war and natural calamities. </li>
                <li>It shall be the sole responsibility of the individual/Company to check the authenticity of all or any response received pursuant to the profile being displayed by OnBenchMark.com for going out of station or in station for any job / interview and OnBenchMark.com assumes no responsibility in respect thereof. </li>
                <li>City of Delhi alone shall have the sole and exclusive jurisdiction in case of any dispute.</li>
                <li> OnBenchMark.com reserves its right to reject any insertion or information/data provided by the subscriber, without assigning any reason either before uploading or after uploading the vacancy details.</li>
                <li>OnBenchMark.com will commence providing services only upon receipt of amount/charges upfront either from subscriber or from a third party on behalf of the subscriber or until the services are provided for free of cost.</li>
                <li>This subscription is not transferable i.e. it is for the same person throughout the period of subscription.</li>
                <li>OnBenchMark.com has the right to make all such modifications/editing of profile in order to fit the profile in its database and/or data formats.</li>
                <li>The liability, if any, of OnBenchMark.com is limited to the extent of the amount paid by the subscriber. </li>
                <li>The subscriber shall be assigned password (s) by OnBenchMark.com to enable the subscriber to access all the information received through its site OnBenchMark.com, but the sole responsibility of the safe custody of the password shall be that of the subscriber and OnBenchMark.com shall not be responsible for data loss/theft or data/corruption or the wrong usage/misuse of the password and any damage or leak of information and its consequential usage by a third party. OnBenchMark.com undertakes to take all reasonable precautions at its end to ensure that there is no leakage/misuse of the password granted to the subscriber.</li>
                <li>The subscriber undertakes that the data/information provided by him is true and correct in all respects.</li>
              
               
            </ul>

            <h5>Role of OnBenchMark.com</h5>
            <p>OnBenchMark.com acts as a catalyst for Business/Freelancers to post job/project opportunities and side to post profiles. Although, OnBenchMark.com provides various assessment features like the rating system for Business/Freelancers. It does not screen or censor the listings offered. You assume all risks associated with dealing with other users with whom you come in contact through the Web Site and/or the other CNPL Sites.</p>
            <p>Because user authentication on the Internet is difficult, the OnBenchMark.com cannot and does not confirm that each user is who they claim to be. Because we do not and cannot be involved in user-to-user dealings or control the behaviour of participants on any OnBenchMark.com site, in the event that you have a dispute with one or more users, you release the OnBenchMark.com (and our agents and employees) from claims, demands and damages (actual and consequential, direct and indirect) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
            <p>The Website Content may contain inaccuracies or typographical errors. Although OnBenchMark.com makes and would make best of the efforts to provide accurate information, OnBenchMark.com makes no representations about the accuracy, reliability, completeness, or timeliness of any OnBenchMark.com or the Web Site Content. Changes are periodically made to OnBenchMark.com Sites and may be made at any time. You acknowledge and agree that you are solely responsible for the form, content and accuracy of any profile or material contained therein placed by you on the OnBenchMark.com Sites. Business/Freelancers are solely responsible for their postings on OnBenchMark.com Sites. The OnBenchMark.com is not to be considered to be an employer with respect to your use of any OnBenchMark.com Site and the OnBenchMark.com shall not be responsible for any employment decisions, for whatever reason made, made by any entity posting jobs on any OnBenchMark.com Site.</p>
           
            <h3>Code of Business Ethics & Conduct for the Participants</h3>
            <p>All participants accept & agree to the following Code of business ethics & conduct when transacting, either in a Free or paid capacity, with the other party:</p>
            <ul>
               
                <li>Business (Company looking to hire Candidate): When posting and assessing candidates from a other company or Freelancer, to not solicit the candidate directly or through any other third-party. On selecting the presented candidate by the Business/Freelancer, to agree and empanel the Business/Freelancer through a duly signed required agreement. When posting and assessing Individual Contractors directly; to enter into a clear retainer-ship or suitable agreement that clearly details the engagement terms.</li>
                <li>Business: When proposing a candidate for a posting made by the Client; to ensure that the candidate is on the rolls of the Partner or in the process of becoming one.</li>
                <li>Freelancers: When responding to posting made by the Customers, to provide true information about experience and past employments, experience including educational qualifications etc.</li>
               
            </ul>

            <h3>Policies and Pricing:</h3>
            <p>Employers as well as profile Owners that Register at our site, shall be deemed to be customers of OnBenchMark.com. Accordingly, all of our Terms and Conditions of Use, rules, policies, and operating procedures concerning customers, our services, and our pricing shall apply to all those customers. We may change our policies and operating procedures at any time and without any notice to customers.</p>
            <p>Disclaimer of Warranties and Limitation of Liability To the full extent permissible by applicable law, OnBenchMark.com disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. onbenchmark.com does not warrant that this site, its servers, or e-mail sent from OnBenchMark.com are free of viruses or other harmful components. OnBenchMark.com will not be liable for any damages of any kind arising from the use of this site, including, but not limited to punitive, direct, indirect, incidental, punitive, and consequential damages. As to extraneous contents:, OnBenchMark.com hereby alerts and warns its members of the possibility of unauthorized posting of contents by any person including members and unauthorized users and advises discretion in access since such content, information or representation may not be suitable to you including being offensive. OnBenchMark.com will not in any way be responsible for such content, information or representations. OnBenchMark.com is also not responsible for alterations, modifications, deletion, reproduction, sale, transmission or any such misuse of data and content in the public domain by any user of OnBenchMark.com.</p>

            <h3>Site Policies, Modification and Severability:</h3>
            
            <p>
                Please review our other policies, such as our privacy policy, posted on this site. These policies also govern your visit to www.OnBenchMark.com and consortium of portals. We reserve the right to make changes to our site, policies, and these Terms and Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. Suggestions, Complaints, Disputes: Suggestions and complaints are to be first addressed to OnBenchMark.com's Customer Support Department at info&#64;onbenchmark.com.
              </p>
              

            <h3>Membership Policy</h3>

            <h3>Register as Business</h3>
            <ul>
                <li>Registering your company at OnBenchMark.com makes you a member of our community which will help you to Post a Job / Project or Deploys Bench/Unutilized Resources. Create your Virtual Team. Get connected to Freelancer &  Bench/Unutilized resources of other companies without any restriction and commission.</li>
                <li>We provide a paid membership which consists of three different plans based on the time duration and a customized one.</li>
                <li>Its license to use is limited to the expiry of the subscription plan. i.e. either 1 month, 3 months, 6 months or 12 months.</li>
                <li>After this duration, you have to re-purchase your membership plan.</li>
            </ul>

            <h3>Site Policies, Modification and Severability:</h3>

            <ul>
                <li>Registering your company at OnBenchMark.com makes you a member of our community. Get freelancing projects or get connected with other Freelancers. Create your Virtual Team. Get connected with the Client and other freelancers without any restriction and commission.</li>
                <li>We provide free membership to freelancers.</li>
            </ul>

            <p>When you use our Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</p>


            <h3>Return & Refund Policy:</h3>
            <ul>
                <li>OnBenchMark is providing a subscription of our product is provided for the opted package duration.</li>
                <li>There is no return option and thus refund of any amount full or partial is not applicable.</li>
            </ul>

            <h3>Cancellation Policy :</h3>
            <p>You can’t cancel your subscription once paid the amount. If there is any technical or other issues in the respective login access then OnBenchMark might consider cancellation based on the gravity of the problem. </p>

        </div>
    </div>
</div>
<!-- End Terms & Conditions -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->