<!-- Header -->
<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Reset Password</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ResetPasswordForm -->
<div class="container-fluid ps-0">

  <div class="row mainBorder">
    <div class="col-lg-6 col-md-12 ">
      <div class="user-img">
        <img src="assets/img/forgotPswd.jpg" alt="User" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 align-self-center px-5">
      <div class="top">
        <h2>Reset Password</h2> 
        
        <!-- <form [formGroup]="resetPasswordForm" class="form-horizontal">
            <div class="row form-group mb-3">
              <div class="col-lg-12">
                <div class="form-group mb-3">
                  <label>Password* </label>
                  <input type="text" class="form-control" placeholder="Enter Password*" formControlName="password" />
                  <small class="text-danger" *ngIf="this.resetPasswordForm.get('password').invalid && this.resetPasswordForm.get('password').touched"> Password is required</small>
                </div>
                <div class="form-group mb-3">
                  <label>Confirm Password* </label>
                  <input type="text" class="form-control" placeholder="Confirm Password*" formControlName="confirmpwd" />
                  <small class="text-danger" *ngIf="this.resetPasswordForm.get('confirmpwd').invalid && this.resetPasswordForm.get('confirmpwd').touched"> Confirm Password is required </small>
                </div>
                <div class="form-group mt-5 text-center">
                  <button type="submit" class="btn btn-info" (click)="onSubmit('password', 0, false)">Submit</button>
                </div>
              </div>
              <div class="col-sm-3"></div>
            </div>
        </form> -->

        <form [formGroup]="resetPasswordForm" class="">
            <div class="row form-group mb-3">
              <div class="col-lg-12">
                <div class="form-group mb-3">
                  <!-- <label>Password* </label> -->
                  <input type="password" class="form-control" placeholder="Enter Password*" formControlName="password" [ngClass]="{'is-invalid': formResetPassword.password.invalid && formResetPassword.password.touched || formResetPassword.password.invalid && submitted, 'is-valid': formResetPassword.password.valid}" />

                  <div *ngIf="formResetPassword.password.invalid && formResetPassword.password.touched || formResetPassword.password.invalid && submitted">
                    <small class="text-danger" >Password is <b>required!</b></small> 
                </div>

                <div *ngIf="formResetPassword['password'].errors">
                    <div *ngIf="formResetPassword['password'].errors['minlength']"> 
                        <small class="text-danger" >Password should have atleast <b>6 char!</b></small>
                    </div>
                    <div *ngIf="formResetPassword['password'].errors['maxlength']">
                        <small class="text-danger" >Password could have max <b>15 char!</b></small>
                    </div>
                </div>
                 
                </div>
                <div class="form-group mb-3">
                    <!-- <label>Confirm Password* </label> -->
                    <input type="password" class="form-control" placeholder="Confirm Password*" formControlName="confirmpwd" [ngClass]="{'is-invalid': formResetPassword.confirmpwd.invalid && formResetPassword.confirmpwd.touched || formResetPassword.confirmpwd.invalid && submitted, 'is-valid': formResetPassword.confirmpwd.valid}" />

                    <div *ngIf="formResetPassword.confirmpwd.invalid && formResetPassword.confirmpwd.touched || formResetPassword.confirmpwd.invalid && submitted">
                        <small class="text-danger" >Confirm Password is <b>required!</b></small>
                    </div>  

                    <div *ngIf="formResetPassword['confirmpwd'].errors">
                        <div *ngIf="formResetPassword['confirmpwd'].errors['mustMatchError']"> 	
                            <small class="text-danger" >Password & Confirm Password must be same!</small> 
                        </div>
                    </div>	
                
                </div>
                <div class="form-group mt-5 text-center">
                  <button (click)="onSubmit()" type="button" class="btn btn-info">Submit</button>
                </div>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </form>
          




      </div>
    </div>    
  </div>

 

</div>
<!-- End of ResetPasswordForm -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->

<!--  -->