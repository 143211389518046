import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../../services/common.service";
import { AuthService } from "src/app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PostJobService } from "../../../services/post-job.service";

@Component({
  selector: "app-candidates-details",
  templateUrl: "./candidates-details.component.html",
  styleUrls: ["./candidates-details.component.scss"],
})
export class CandidatesDetailsComponent implements OnInit {
  candidate: any = {};
  candidateId = "";
  jobs: any = [];
  jobsFilter: any = [];
  canApply: boolean = false;
  jobSearch: any = "";
  benchcomId: any = "";
  contactDetails: any = {};

  constructor(
    private authService: AuthService,
    private postJobService: PostJobService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.candidateId = params["userId"];
      this.getCandidateDetailsById();
    });
  }

  getCandidateDetailsById() {
    this.commonService.getCandidateDetails(this.candidateId).then((resp) => {
      this.candidate = resp.data;
    });

    // this.authService.userDetails.subscribe((resp: any) => {
    //   if (resp && resp._id) {
    this.getBenchContactDetails();
    //   }
    // });
  }

  getBenchContactDetails() {
    // let id = this.authService.userdata.data._id;
    // console.log("getBenchContactDetails ", id);

    this.postJobService
      .getBenchContactDetails({
        userType: 3,
        userid: this.candidateId,
      })
      .then((resp) => {
        this.contactDetails = resp.data.cdata;
        console.log(resp);
      });
  }

  getjobList() {
    this.postJobService
      .getMyJobsForShortlist({
        page: 1,
        limit: 5,
        totalpage: 1,
        candidateId: this.candidateId,
        userType: 3,
      })
      .then((resp) => {
        this.jobs = resp.data;
      });
  }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 168 * 83.5).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  searchJobs(ev) {
    // console.log("benchSearch ", ev.target.value);
    this.jobsFilter["searchname"] = ev.target.value;
    // this.getCandidatesForJobApply();
  }

  onCheckboxChange(bench) {
    let isChecked = this.jobs.data.filter((b) => b.checked == true);
    if (isChecked.length > 0) this.canApply = true;
    else this.canApply = false;
  }

  jobApply() {
    let id = this.authService.userdata.data._id;
    let jobs = this.jobs.data.filter((job: any) => job.checked == true);
    let jobsApplied = [];
    jobs.forEach((job: any) => {
      jobsApplied.push({
        ids: job._id,
        title: job.title,
        resourceId: job.resourceId,
      });
    });
    console.log("id ", id);
    console.log("jobsApplied ", jobsApplied);

    let payload = {
      candidateId: this.candidateId,
      jlist: jobsApplied,
      jobcomId: id,
      status: 2,
      benchcomId: this.candidate.companyId,
      userType: 3,
      type: 1,
    };

    console.log("payload ", payload);

    this.postJobService.applyBenchJob(payload).then((resp) => {
      this.getjobList();
    });
  }

  ngOnInit(): void {
    this.getjobList();
  }
}
