import { Component, ElementRef, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import * as $ from "jquery";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  // custom validators
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  namePattern = "^[a-zA-Z ]+$";

  citylist: any = [];
  countrylist: any = [];
  cls: any = "";
  loderstt = false;
  othercity = false;
  othercityForExpert = false;

  b_loginStatus = true;
  e_loginStatus = false;

  B_addRegistrationForm: any;
  submitted: boolean = false;

  E_addRegistrationForm: any;
  e_submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private commonSevice: CommonService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private router: Router,
  ) {
    this.createAddRegistrationForm();
    this.createAddExpertRegistrationForm();
  }

  ngOnInit(): void {}

  createAddRegistrationForm() {
    this.B_addRegistrationForm = this.fb.group({
      fname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      lname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      phonecode: ["91"],
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      loginType: ["3", Validators.required],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      location: ["", Validators.required],
      locationId: [""],
    });
  }

  get formAddRegistration() {
    return this.B_addRegistrationForm.controls;
  }

  onSubmitBusiness() {
    if (this.B_addRegistrationForm.invalid) {
      this.submitted = true;
      return;
    } else {
      console.log(this.B_addRegistrationForm.value);
      this.authService
        .registerBusiness(this.B_addRegistrationForm.value)
        .subscribe((resp) => {
          console.log(resp);
          this.router.navigate(["/dashboard"]);
        });
    }
  }

  onSubmitExpert() {
    if (this.E_addRegistrationForm.invalid) {
      this.submitted = true;
      return;
    } else {
      console.log(this.E_addRegistrationForm.value);
      this.authService
        .registerExpert(this.E_addRegistrationForm.value)
        .subscribe((resp) => {
          console.log(resp);
          this.router.navigate(["/expert-dashboard"]);
        });
    }
  }

  // ===================== ExpertRegistrationform =================
  createAddExpertRegistrationForm() {
    this.E_addRegistrationForm = this.fb.group({
      fname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      lname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      phonecode: ["91"],
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      loginType: ["3", Validators.required],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cname: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      location: ["", Validators.required],
      locationId: [""],
    });
  }

  get formAddExpertRegistration() {
    return this.E_addRegistrationForm.controls;
  }

  onSubmit_Expert() {
    if (this.E_addRegistrationForm.invalid) {
      this.e_submitted = true;
      return;
    }
  }

  // =============== select city =====================

  get_city(val: any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = { city_name: { $regex: "^" + val, $options: "i" } };
      this.commonSevice
        .getcity(JSON.stringify(filter))
        .then((res) => {
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
      if (!val) {
        this.B_addRegistrationForm.get("location").setValue("");
        this.B_addRegistrationForm.get("locationId").setValue("");

        this.E_addRegistrationForm.get("location").setValue("");
        this.E_addRegistrationForm.get("locationId").setValue("");
      }
      if (!val) {
        this.E_addRegistrationForm.get("location").setValue("");
        this.E_addRegistrationForm.get("locationId").setValue("");
      }
    }
  }

  setvalue(val: any, counrty: any, id: any) {
    this.cls = "";
    this.B_addRegistrationForm.get("location").setValue(val);
    this.B_addRegistrationForm.get("locationId").setValue(id);
    $("#citycountry").val(val + ", " + counrty);
  }

  othercityslt() {
    this.othercity = !this.othercity;
    this.cls = "";
    this.getcountry();
  }
  otherslt(val: any, type: any) {
    if (type == 1) {
      this.B_addRegistrationForm.get("location").setValue(val);
      this.B_addRegistrationForm.get("locationId").setValue(null);

      this.E_addRegistrationForm.get("location").setValue(val);
      this.E_addRegistrationForm.get("locationId").setValue(null);
    } else {
      this.B_addRegistrationForm.get("currentcountry").setValue(val);
      this.B_addRegistrationForm.get("locationId").setValue(null);

      this.E_addRegistrationForm.get("currentcountry").setValue(val);
      this.E_addRegistrationForm.get("locationId").setValue(null);
    }
  }
  // ======================== Expert ===============================

  setvalueEx(val: any, counrty: any, id: any) {
    this.cls = "";
    this.E_addRegistrationForm.get("location").setValue("");
    this.E_addRegistrationForm.get("locationId").setValue("");

    $("#citycountry").val(val + ", " + counrty);
  }

  othercitysltEx() {
    this.othercityForExpert = !this.othercityForExpert;
    this.cls = "";
    this.getcountry();
  }

  othersltEx(val: any, type: any) {
    if (type == 1) {
      this.E_addRegistrationForm.get("location").setValue(val);
      this.E_addRegistrationForm.get("locationId").setValue(null);
    } else {
      this.E_addRegistrationForm.get("currentcountry").setValue(val);
      this.E_addRegistrationForm.get("locationId").setValue(null);
    }
  }

  // =================== country =================
  getcountry() {
    this.countrylist = [];
    this.commonSevice
      .Find(JSON.stringify({}), "country")
      .then((res) => {
        this.countrylist = res.data;
      })
      .catch((err) => {});
  }

  // function of customValidation
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  expertLogin() {
    this.e_loginStatus = true;
    this.b_loginStatus = false;
  }

  businessLogin() {
    this.b_loginStatus = true;
    this.e_loginStatus = false;
  }
}
