<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Bench Resources</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Benches -->
<div class="job-area-list" style="margin-top: 30px">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="job-list-right">
          <!-- <button routerLink="/add-bench" type="submit" class="btn btn-info ">Add Bench</button> -->

          <div class="d-grid gap-2 mb-4">
            <button routerLink="/add-bench" type="button" class="btn btn-info">
              <span style="font-weight: bold; color: #fff;font-size: 18px;">Add Bench</span>
            </button>
          </div>

          <!-- <div class="job" style="border: none">
            <form>
              <button routerLink="/add-bench" type="submit" class="btn">Add Bench</button>
            </form>
          </div> -->

          <!-- <div class="row">
            <div class="col-lg-12">
              <h3 style="border-bottom: 0px solid #ccc" class="mb-0">Jobs</h3>
              <span class="pointer pull-right" (click)="reload()" tooltip="Reload Search">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div> -->

          <div class="job-list-all">
            <div class="search row">
              <div class="col-lg-10">
                <h3 style="border-bottom: 0px solid #ccc" class="mb-0">Search My Bench</h3>
              </div>
              <div class="col-lg-2 text-end ">
                <span class="pointer pull-right text-end" (click)="reload()"  tooltip="Reset Filters">
                  <i class="fa-solid fa-rotate re_iconSize"></i>
                </span>
              </div>             
            </div>

            <div class="mt-2">
              <!-- <form [formGroup]="searchForm"> -->
              <div class="row form-group mb-3">
                <h6>Skill</h6>
                <div class="col-lg-12">
                  <span class="my-slt-li" *ngFor="let list of sltskill; let i = index">
                    <span class="slt-name">{{ list?.name }} </span>
                    <span class="text-light" (click)="removeItem(i)">
                      <i class="fa-regular fa-circle-xmark bxCloseIcon"></i>
                    </span>
                  </span>
                  <input #menu type="text" placeholder="Skills separated by comma" class="salectchanged form-control1 my-skill form-control" (keyup)="getsltSkills($any($event.target).value)" />
                  <div #menudrop id="myDropdown" class="dropdownContent_skills {{ cls1 }}">
                    <a *ngFor="let item of templist" (click)="setskillvalue(item)" class="salectchanged system">
                      {{ item.name }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="row form-group mb-3">
                <h6>Status</h6>
                <div class="col-lg-12">
                  <ng-select [(ngModel)]="searchForm.status" [multiple]="false" placeholder="Select Option">
                    <ng-option value="" [disabled]="true"> Select Status </ng-option>
                    <ng-option value="active">Active</ng-option>
                    <ng-option value="inActive">In-Active</ng-option>
                    <ng-option value="Any">All</ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="row my-2">
                <div class="col-lg-12 d-grid">
                  <button (click)="searchfilter()" type="button" class="btn btn-info">Search</button>
                </div>
              </div>
              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- searching -->
        <div class="row mb-4 mx-1">
          <div class="col-lg-12 searchBarBorder px-2 py-3">
            <div class="row form-group">
              <div class="col-lg-4">
                <input type="text" placeholder="Name or RID" class="form-control" [(ngModel)]="searchForm.title" />
              </div>
              <!-- <div class="col-lg-3">
                  <input type="text" class="form-control" placeholder="Choose Location" (keyup)="get_city($any($event.target).value)">
                  <span *ngIf="loderstt" class="city_loader">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  </span>
                  <div id="myDropdown" class="dropdown-content {{cls}}">
                    <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item._id)">
                      {{item.city_name}}
                    </a>
                  </div>
                </div> -->
              <div class="col-lg-3">
                <ng-select (keyup)="get_city($any($event.target).value)" [multiple]="false" [(ngModel)]="searchForm.currentlocation" placeholder="Select City">
                  <ng-option value="" [disabled]="true"> Select City </ng-option>
                  <ng-option *ngFor="let list of citylist" [value]="list?.city_name">
                    {{ list?.city_name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-lg-3">
                <ng-select [multiple]="false" name="verifiedStatus" placeholder="Admin Verified Status" [(ngModel)]="searchForm.verifiedStatus">
                  <ng-option value="" [disabled]="true"> Admin Verified Status </ng-option>
                  <ng-option value="yes">Yes</ng-option>
                  <ng-option value="no">No</ng-option>
                  <ng-option value="all">All</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-2">
                <button (click)="searchfilter()" type="button" class="btn searchBtn">Search</button>
              </div>
            </div>
          </div>
        </div>

        <!-- end of searching -->
        <div style="padding-bottom: 20px">{{ benches.total }} Resources Found</div>

        <!-- inactiveEmp_item -->
        <div *ngFor="let bench of benches.data" class="employer-item" [ngClass]="{ 'red-border': bench?.status == false }">
          <!-- (click)="openBenchDetails(bench)" -->
          <!-- <a> -->

          <div class="row">
            <div class="col-lg-7">
              <span (click)="openCandidateDetails(bench)" class="pointer">
                <h3 class="mb-0">{{ bench?.name }}</h3>
                <p>( RID : {{ bench?.resourceId }} )</p>
              </span>

              <ul class="text-secondary mb-0 pb-0">
                <li>
                  <i class="fa-solid fa-location-dot text-dark"></i>
                  <span class="text-secondary"> {{ bench?.currentlocation }}, {{ bench?.currentcountry }} </span>
                </li>
                <!-- <li>
                    Last Active Date : {{ bench?.updatedAt | date: "dd MMM, yyyy" }}
                  </li> -->
              </ul>

              <ul class="mb-0">
                <li><span class="text-dark">Designation :</span> {{ bench?.designation | titlecase }}</li>
              </ul>

              <!-- <ul>
                  <li><span class="text-dark">Category :</span> {{ bench?.jobtype | titlecase }}</li>
                </ul> -->

              <ul>
                <li><span class="text-dark">Experience :</span> {{ bench.experience }} Years</li>
              </ul>

              <span *ngFor="let s of bench?.skill" class="span-one" style="margin: 2px 2px">
                {{ s }}
              </span>
            </div>
            <div class="col-lg-5">

              <div *ngIf="bench?.status==false" class="col-sm-12 text-end mb-1">
                <button type="button" class="btn btn-dark btn-sm" [routerLink]="['/add-bench']"
                  [queryParams]="{candidateId:bench?._id,repost:true}"> Re Submit </button>
              </div>

              <p class="pb-0 mb-0">
                Rate :
                <span class="text-info">
                  <i class="fa fa-inr"></i>
                  {{ parseRate(bench?.rate) }} (Monthly) 
                  <!-- ({{bench?.ratetype}}) -->
                </span>
              </p>
              <p class="py-0 my-0">
                Last Active Date : <span class="text-info">{{ bench?.updatedAt | date: "dd MMM, yyyy" }}</span>
              </p>
              <p class="py-0 my-0">
                Availability : <span class="text-info">{{ bench?.Availability }}</span>
              </p>
              <p class="py-0 my-0">
                Deployement Type : <span class="text-info">{{ bench?.onsiteAvailability }}</span>
              </p>
            </div>
          </div>

          <div style="text-align: right">
            <span class="badge bg-info me-0">
              <i class="fas fa-users"></i>
              Applied: {{ bench?.totalapplied }}
            </span>

            <span *ngIf="bench?.adminverified == 1" class="badge bg-success ms-2" tooltip="Verified By Admin"> <i class="fas fa-check-circle" tooltip="Verified By Admin"></i> Verified </span>
            &nbsp;

            <span *ngIf="bench?.adminverified == 2" class="badge bg-warning" tooltip="Under Process, Yet to be Verified"> <i class="fa fa-times-circle"></i> On-Hold </span>
            &nbsp;

            <span *ngIf="bench?.adminverified == 0" class="badge bg-danger ms-0" tooltip="Under Process, Yet to be Verified">
              <i class="fa fa-spinner fa-spin"></i>
              Not Verified
            </span>
            &nbsp;

            <!-- toggle -->

            <a *ngIf="bench?.status == true" href="javascript:void(0);" class="">
              <span class="mt-1 me-2 badge bg-warning">
                <i *ngIf="bench?.status; else other" mwlConfirmationPopover [popoverTitle]="'Do you want to deactivate?'" placement="top" (confirm)="changeStatus(bench, bench?._id, false, i)" (cancel)="cancelClicked = true" class="fa fa-toggle-on toggle-on" tooltip="Click To In-Active"></i>&nbsp;

                <ng-template #other> <i (click)="changeStatus(bench, bench?._id, true, i)" tooltip="Click To Active" class="fa fa-toggle-off toggle-off"></i>&nbsp; </ng-template>
              </span>
            </a>

            <span *ngIf="bench?.status == true">
              <!-- edit -->
              <a class="ms-1 me-2" [routerLink]="['/add-bench']" [queryParams]="{ candidateId: bench?._id }" tooltip="Click To Edit">
                <span class="badge bg-secondary">
                  <i class="fa-regular fa-pen-to-square px-1"></i>
                </span>
              </a>

              <!-- view -->
              <a (click)="openCandidateDetails(bench)" href="javascript:void(0)" tooltip="View Bench Details">
                <span class="badge bg-primary">
                  <i class="fas fa-eye"></i>
                  <!-- {{ bench.totalview }}  -->
                </span>
              </a>
            </span>

            <!-- <span class="badge bg-info">
                <i class="fas fa-users"></i>
                Applied: {{ bench.totalapplied }}
              </span> -->

            <!-- <span class="badge bg-primary">
                <i class="fas fa-eye"></i>
                {{ bench.totalview }}
              </span> -->
          </div>
          <!-- </a> -->
        </div>

        <div class="row">
          <div class="col-lg-12"></div>
        </div>

        <!-- <div class="pagination-area pb-5">
          <ul>
            <li><a routerLink="/jobs">Prev</a></li>
            <li><a routerLink="/jobs">1</a></li>
            <li><a routerLink="/jobs">2</a></li>
            <li><a routerLink="/jobs">3</a></li>
            <li><a routerLink="/jobs">Next</a></li>
          </ul>
        </div> -->
        <div class="pagination-area" style="padding-bottom: 30px">
          <ul>
            <li style="padding: 0px 10px">Page {{ currentPage }} of {{ pages }}</li>
            <li *ngIf="currentPage != 1"><a style="cursor: pointer" (click)="previousPage()">Prev</a></li>
            <li *ngIf="currentPage != pages"><a style="cursor: pointer" (click)="nextPage()">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
