import { Component, OnInit } from "@angular/core";
import { PostJobService } from "../../../services/post-job.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl } from "@angular/forms"; 

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  jobs: any = [];
  search: any = {};

  skillListdata: any = [];
  mod = ["", "Mobile", "Email", "Mobile ,Email & Chat", "Only Chat"];
  jobquerydata: any = {
    page: 1,
    totalpage: 0,
    limit: 25,
    total: 0,
    orderval: "createdAt",
    ordertype: "desc",
    serchval: {
      $and: [],
    },
    short: {
      updateDate: -1,
      createdAt: -1,
    },
  };

  // ========= Searching =======
  searchingForm: any;
  citylist: any = [];
  loderstt = false;
  cls = "";

  // ========== Pagination ==========
  pages: any = 0;
  currentPage: any = 1;

  // jobresult: any = [];
  // search: any = {};
  // shimmer=true;
  // countjobs: any =  {
  //   "verifiedjob" : 0,
  //   "notverifiedjob" : 0,
  //   "Onholdjob" : 0,
  //   "inactive" : 0
  // };

  constructor(
    private formBuild: FormBuilder,
    private postJobService: PostJobService,
    private authService: AuthService,
    private toaster: ToastrService,
    private router: Router,
  ) {
    this.searchingFormCreate();
  }

  ngOnInit(): void {
    this.getjobList(this.currentPage);
    this.getSkillList();
  }

  openJobDetails(job) {
    this.router.navigateByUrl("/my-job-details?jobId=" + job._id);
  }

  changeStatus(job: any, ids: any, val: any, i: any) {
    let data: any = { status: val };
    if (val == false) {
      data.hirestatus = 2;
    }
    this.postJobService.updateJobStatus(ids, data, "myjob").then(
      (res: any) => {
        console.log("jobStatus", this.jobs.status);

        job.status = val;

        // this.jobs[i].status = val;
        this.toaster.success("job Status Changed Successfully");

        // if (res.confirmation === "success") {
        //   this.getjobList();
        // }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  // ============ SkillsList =============
  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.postJobService.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  }
  // ============== getCity ==================
  searchingFormCreate() {
    this.searchingForm = this.formBuild.group({
      title: [""],
      locationId: [""],
      currentlocation: [""],
      jobstatus: ["all"],
    });
  }

  get_city(val: any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = {
        $and: [
          { city_name: { $regex: val, $options: "i" } },
          { country_id: "5c976ba641e3f849a36370aa" },
        ],
      };
      this.postJobService
        .getCity(JSON.stringify(filter))
        .then((res) => {
          console.log("CityList: ", res.data);
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
    }
  }

  setvalue(val: any, id: any) {
    this.cls = "";
    console.log("location", val);
    this.searchingForm.get("currentlocation").setValue(val);
    this.searchingForm.get("locationId").setValue(id);
  }

  // ========== jobList with Pagination ==========
  getjobList(page: any) {
    this.postJobService.getMyJobs(this.jobquerydata).then((resp) => {
      this.jobs = resp.data;
      console.log("resp ", this.jobs);
      this.pages = Math.ceil(resp.data.total / 10);
    });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.jobquerydata.page = this.currentPage;
      this.getjobList(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage = this.currentPage + 1;
      this.jobquerydata.page = this.currentPage;
      this.getjobList(this.currentPage);
    }
  }

  filter() {
    this.jobs = [];
    this.jobquerydata.serchval = {
      $and: [],
    };

    // this.resources = [];
    this.jobs = [];
    this.jobquerydata.page = 1;
    // this.searched = true;
    this.jobquerydata.serchval = {
      $and: [],
    };
    let anySkillConditions = [];

    if (this.search.skills && this.search.skills.length > 0) {
      // jobDescription
      // if (
      //   this.advanceSearch.searchIn == "any" ||
      //   this.advanceSearch.searchIn == "jobTitle" ||
      //   this.advanceSearch.searchIn == "jobTitleKeySkills"
      // ) {
      //   this.jobsAnySkill.forEach((s) => {
      //     anySkillConditions.push({
      //       title: {
      //         $regex: s,
      //         $options: "i",
      //       },
      //     });
      //   });
      // }
      // if (
      //   this.advanceSearch.searchIn == "any" ||
      //   this.advanceSearch.searchIn == "jobTitleKeySkills"
      // ) {
      //   this.jobsAnySkill.forEach((s) => {
      //     anySkillConditions.push({
      //       skill: {
      //         $regex: s,
      //         $options: "i",
      //       },
      //     });
      //   });
      // }
      // if (
      //   this.advanceSearch.searchIn == "any" ||
      //   this.advanceSearch.searchIn == "jobDescription"
      // ) {
      //   this.jobsAnySkill.forEach((s) => {
      //     anySkillConditions.push({
      //       jobDescription: {
      //         $regex: s,
      //         $options: "i",
      //       },
      //     });
      //   });
      // }
      // this.jobquerydata.serchval["$and"].push({
      //   $or: anySkillConditions,
      // });
    }

    if (this.search.skills && this.search.skills.length > 0) {
      let allSkillConditions = [];

      this.search.skills.forEach((s) => {
        allSkillConditions.push({
          skill: {
            $regex: s,
            $options: "i",
          },
        });
      });

      this.jobquerydata.serchval["$and"].push({
        $and: allSkillConditions,
      });
    }

    if (this.search.workFrom && this.search.workFrom != "") {
      this.jobquerydata.serchval["$and"].push({
        workFrom: {
          $regex: this.search.workFrom,
          $options: "i",
        },
      });
    }

    if (this.search.location && this.search.location != "") {
      this.jobquerydata.serchval["$and"].push({
        "location.location": {
          $regex: this.search.location,
          $options: "i",
        },
      });
    }

    if (this.search.title && this.search.title != "") {
      this.jobquerydata.serchval["$and"].push({
        title: {
          $regex: this.search.title,
          $options: "i",
        },
      });
    }

    console.log(this.search);

    if (this.search.jobstatus && this.search.jobstatus != "all") {
      if (this.search.jobstatus == "verified") {
        this.jobquerydata.serchval["$and"].push({
          adminverified: 1,
        });
        this.jobquerydata.serchval["$and"].push({
          status: true,
        });
      }
      if (this.search.jobstatus == "not-verified") {
        this.jobquerydata.serchval["$and"].push({
          adminverified: 0,
        });
        this.jobquerydata.serchval["$and"].push({
          status: true,
        });
      }

      if (this.search.jobstatus == "on-hold") {
        this.jobquerydata.serchval["$and"].push({
          adminverified: 2,
        });
      }

      if (this.search.jobstatus == "in-active") {
        this.jobquerydata.serchval["$and"].push({
          adminverified: 3,
        });
        this.jobquerydata.serchval["$and"].push({
          status: false,
        });
      }
    }

    if (this.search.rate && this.search.rate != "") {
      this.jobquerydata.serchval["$and"].push({
        $and: [
          {
            ratemin: {
              $lte: this.search.rate,
            },
          },
          {
            ratemax: {
              $gte: this.search.rate,
            },
          },
        ],
      });
    }

    if (this.search.experience && this.search.experience != "") {
      this.jobquerydata.serchval["$and"].push({
        $and: [
          {
            experiencemin: {
              $lte: this.search.experience,
            },
          },
          {
            experiencemax: {
              $gte: this.search.experience,
            },
          },
        ],
      });
    }

    this.getjobList(this.currentPage);
  }

  // parseRate(list: any) {
  //   if (list.candidateId.ratetype == "Hourly") {
  //     let newRate = parseInt((list.candidateId.rate * 168 * 83.5).toString());
  //     let roundedNumber = Math.round(newRate / 1000) * 1000;
  //     return parseInt(roundedNumber.toString()).toLocaleString();
  //   }
  // }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 168 * 80).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  // =============== Reset_Filters ===========================
  reload(){
    this.skillListdata = [];    
    this.search.workFrom = null;   
    this.search.skills = null;  
    this.search.rate = '';  
    this.search.experience = '';
  }







}
