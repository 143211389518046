import { Component } from '@angular/core';

@Component({
  selector: 'app-post-project',
  // standalone: true,
  // imports: [],
  templateUrl: './post-project.component.html',
  styleUrl: './post-project.component.scss'
})
export class PostProjectComponent {

}
