import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { CandidatesDetailsComponent } from "./components/pages/candidates-details/candidates-details.component";
import { CandidatesComponent } from "./components/pages/candidates/candidates.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { EmployersDetailsComponent } from "./components/pages/employers-details/employers-details.component";
import { EmployersComponent } from "./components/pages/employers/employers.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { FavouriteJobsComponent } from "./components/pages/favourite-jobs/favourite-jobs.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { JobDetailsComponent } from "./components/pages/job-details/job-details.component";
import { MyJobDetailsComponent } from "./components/pages/my-job-details/my-job-details.component";
import { MyCandidatesDetailsComponent } from "./components/pages/my-candidates-details/my-candidates-details.component";
import { JobsComponent } from "./components/pages/jobs/jobs.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { PostAJobComponent } from "./components/pages/post-a-job/post-a-job.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { RegisterComponent } from "./components/pages/register/register.component";
import { ResumeDetailsComponent } from "./components/pages/resume-details/resume-details.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { AddBenchComponent } from "./components/pages/add-bench/add-bench.component";
import { HireOnContractComponent } from "./components/pages/hire-on-contract/hire-on-contract.component";
import { FindClientsComponent } from "./components/pages/find-clients/find-clients.component";
import { BenchComponent } from "./components/pages/bench/bench.component";
import { JobApplicantsComponent } from "./components/pages/job-applicants/job-applicants.component";
import { PostProjectComponent } from "./components/pages/post-project/post-project.component";
import { BenchApplicationsComponent } from "./components/pages/bench-applications/bench-applications.component";
import { FreeJobPostComponent } from "./components/pages/free-job-post/free-job-post.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { FreelancerRegisterComponent } from "./components/pages/freelancer-register/freelancer-register.component";
import { AdvancedSearchComponent } from "./components/pages/advanced-search/advanced-search.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/pages/reset-password/reset-password.component";
import { SupportComponent } from "./components/pages/support/support.component";
import { HireExpertComponent } from "./components/pages/hire-expert/hire-expert.component";
import { ExpertDashboardComponent } from "./components/pages/expert-dashboard/expert-dashboard.component";
import { RequestDemoComponent } from "./components/pages/request-demo/request-demo.component";
import { HireCandidatesOnContractComponent } from "./components/pages/hire-candidates-on-contract/hire-candidates-on-contract.component";
import { FindClientsToDeployBenchComponent } from "./components/pages/find-clients-to-deploy-bench/find-clients-to-deploy-bench.component";
import { ExpertProfileComponent } from "./components/pages/expert-profile/expert-profile.component";
import { ExpertUserSettingComponent } from "./components/pages/expert-user-setting/expert-user-setting.component";
import { ExpertAppliedHistoryComponent } from "./components/pages/expert-applied-history/expert-applied-history.component";
import { PricingTableComponent } from "./components/pages/pricing-table/pricing-table.component";
import { HireBenchResourcesComponent } from "./components/pages/hire-bench-resources/hire-bench-resources.component";
import { ShortlistedResourceComponent } from "./components/pages/shortlisted-resource/shortlisted-resource.component";

const routes: Routes = [
  // {path: '', component: HomeOneComponent},
  // {path: 'home-two', component: HomeTwoComponent},
  { path: "", component: HomeThreeComponent },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "register", component: RegisterComponent },
  { path: "employers", component: EmployersComponent },
  { path: "employer-details", component: EmployersDetailsComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "single-resume", component: ResumeDetailsComponent },
  { path: "testimonials", component: TestimonialsComponent },
  { path: "pricing", component: PricingComponent },
  { path: "faq", component: FaqComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  { path: "error", component: ErrorComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-conditions", component: TermsConditionsComponent },
  { path: "about", component: AboutComponent },
  { path: "jobs", component: JobsComponent },
  { path: "favourite-jobs", component: FavouriteJobsComponent },
  { path: "job-details", component: JobDetailsComponent },
  { path: "my-job-details", component: MyJobDetailsComponent },
  { path: "my-candidate-details", component: MyCandidatesDetailsComponent },
  { path: "post-a-job", component: PostAJobComponent },
  { path: "candidates", component: CandidatesComponent },
  { path: "candidate-details", component: CandidatesDetailsComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog-details", component: BlogDetailsComponent },
  { path: "contact", component: ContactComponent },
  { path: "add-bench", component: AddBenchComponent },
  { path: "bench", component: BenchComponent },
  { path: "hire-on-contract", component: HireOnContractComponent },
  { path: "find-clients", component: FindClientsComponent },
  { path: "job-applicants", component: JobApplicantsComponent },
  { path: "bench-applications", component: BenchApplicationsComponent },
  { path: "post-project", component: PostProjectComponent },
  { path: "post-jobs-for-free", component: FreeJobPostComponent },
  { path: "profile", component: ProfileComponent },
  { path: "freelancer-register", component: FreelancerRegisterComponent },
  { path: "search", component: AdvancedSearchComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "support", component: SupportComponent },
  { path: "hire-expert", component: HireExpertComponent },
  { path: "expert-dashboard", component: ExpertDashboardComponent },
  { path: "expert-profile", component: ExpertProfileComponent },
  { path: "hire-candidates-on-contract", component: HireCandidatesOnContractComponent },
  { path: "request-demo", component: RequestDemoComponent },
  { path: "find-clients-to-deploy-bench",  component: FindClientsToDeployBenchComponent },
  { path: "expert-user-setting", component: ExpertUserSettingComponent },
  { path: "expert-applied-history", component: ExpertAppliedHistoryComponent },
  { path: "pricing-table", component: PricingTableComponent },
  { path: "hire-bench-resources", component: HireBenchResourcesComponent },
  { path: "shortlisted-resources", component: ShortlistedResourceComponent },


  { path: "**", component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
