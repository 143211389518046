import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pricing-table',
  // standalone: true,
  // imports: [],
  templateUrl: './pricing-table.component.html',
  styleUrl: './pricing-table.component.scss'
})
export class PricingTableComponent implements OnInit {

  isModalOpen = false;
  @ViewChild('autoShownModal') autoShownModal!: ModalDirective;

  letstalkModalShown = false;
  isModalShown = false;

  constructor( ) 
  { }

  ngOnInit(): void {}
   
  showModal(val: any): void {
    if (val == 1) {
      this.isModalShown = true;
    } else if (val == 2) {
      this.letstalkModalShown = true;
    }
  }
  hideModal(val: any): void {
    if (val == 1) {
      this.isModalShown = false;
    } else if (val == 2) {
      this.letstalkModalShown = false;
    }
  }
  onHidden(val: any): void {
    if (val == 1) {
      this.isModalShown = false;
    } else if (val == 2) {
      this.letstalkModalShown = false;
    }
  }



  
}
