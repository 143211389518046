import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { PostJobService } from "../../../services/post-job.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ExpertService } from 'src/app/services/expert.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-expert-dashboard',
  templateUrl: './expert-dashboard.component.html',
  styleUrl: './expert-dashboard.component.scss'
})
export class ExpertDashboardComponent {

  jobs: any = [];
  pages: any = 0;
  currentPage: any = 1;

  companyDashboardData: any = {
    myjob: 0,
    appliedcandidate: 0,
    mycandidate: 0,
    jobapplied: 0,
  };

  freelancerDashboardData: any = {
    myjob: 0,
    appliedcandidate: 0,
    mycandidate: 0,
    jobapplied: 0,
  };

  // filter: any = { page: 1, limit: 5, serchval: { $and: [] }, short: { updatedAt: -1 } };
  search: any = { page: 1, limit: 5, serchval: { $and: [] }, short: { updatedAt: -1 } };
  shimmer = false;
  pagebtn:any = { first: false, last: true, prev: true, next: true };

  userType:any = {
    company: false,
    freelancer: false,
    training: false
  };
  profiledata: any;
  feelancerdata: any = {};
  feelancerdataprofile: any = {};
  isModalShown = false;
  dashinfo: any = {};
  fldashinfo: any = {};
  per: any = 80.3;
  homesearch: any;
  sltskill: any = { any: [] };

  searchTerms:any = [];
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('menudrop') menudrop!: ElementRef;
  jobapp: any = [];
  // search: any = { page: 1, limit: 5, short: { updatedAt: -1 } };
 
  templist: any = [];
  templist1: any = [];
  skilllist: any = [];
  cls1:any = "";
  search2:any = { page: 1, totalpage: 0, limit: 5, total: 0, jobId: '' };
  pagebtn2:any = { first: false, last: true, prev: true, next: true };
  coin = 0;
  closestt=false;
  currencytype:any="";

  constructor(
    private formBuild: FormBuilder, 
    private postJobService: PostJobService,
    private expertService: ExpertService,
    private authService: AuthService,
    private toaster: ToastrService,
    private router: Router,
    private loader: LoadingScreenService, 
    private renderer: Renderer2
  ) {
    this.getjobList(); 
    this.candidateFormCreate();
    this.getSkill();
  }

  ngOnInit(): void {
    // this.expertService.getFreelancerDashboard().subscribe((resp) => {
    //   this.freelancerDashboardData = resp.data;
    //   console.log("resp ", this.freelancerDashboardData.myjob);
    // });

      let obj = this.authService.getloginData();
      if (Object.keys(obj).length > 0) {
        this.profiledata = this.authService.getloginData();
        this.setPermission();
      }
      this.authService.profiledata.subscribe((res: any) => {
        this.profiledata = res;
        this.setPermission();
      });

      this.expertService.updatecoinall.subscribe((res: any) => {
        this.profiledata.earningcoin = res.coin;
      });

      if (localStorage.getItem('currency')) {
        this.currencytype =  localStorage.getItem('currency');
      }

      this.expertService.globalprize.subscribe((res: any) => {

      this.currencytype =  res.ctype;
      this.searchfilter();

      });

      
  }

  // getjobList(){
  //   this.postJobService.getMyJobs({
  //     page: 1,
  //     totalpage: 0,
  //     limit: 125,
  //     total: 0,
  //     orderval: "createdAt",
  //     ordertype: "desc",
  //     serchval: { $and: [] },
  //     short: { createdAt: -1 },
  //   })
  //   .then((resp) => {
  //     this.jobs = resp.data;
  //     console.log("resp ", this.jobs);
  //   });
  // }

  getjobList() {
    this.jobs = [];
    let data = this.search;
    this.shimmer = true;
    this.expertService.searchJob(data, 'job').then((res: any) => {
      this.shimmer = false;
      this.jobs = res.data;
      if (this.search.page == 1) {
        this.pagebtn.prev = false;
      }
      this.search.total = this.jobs.total;
      this.search.totalpage = Math.ceil(this.search.total / this.search.limit);
    }, err => {
      this.shimmer = false;
    });
  }

  setPermission() {
    switch (this.profiledata.loginType) {
      case 3:
        this.userType.company = true;
        this.closestt = true;
        this.companyDashboard();
        this.getapplication();
        this.getcoin();
        break;
      case 4:
        this.closestt = false;
        this.userType.freelancer = true;
        this.getfreelancer();
        this.freelancerDashboard();
        this.windowrendor();
        this.searchfilter();
        this.getfreelancerprofile(this.profiledata.freelanerId);
        break;
      default:
        this.userType.training = true;
        break;
    }
  }

  candidateFormCreate() {
    this.homesearch = this.formBuild.group({
      anyskill: new FormControl('', Validators.required)
    });
  }


  getfreelancer() {
    this.feelancerdata = this.profiledata.freelanerId;
    if (this.feelancerdata.gender == null) {
      this.router.navigate(['/business/update-profile']);
    }
  }

  getcoin() {
    this.expertService.getCoin({}).then((res: any) => {
      if (res.confirmation == "success") {
        this.coin = res.data.earningcoin;
      } else {
        this.coin = 0;
      }
    }, err => {
      this.toaster.error('Something went wrong')
    });
  }

  companyDashboard() {
    this.expertService.getCompanydashboard().subscribe((resp) => {
      this.companyDashboardData = resp.data;
      console.log("resp ", this.companyDashboardData.myjob);
    });
  }  

  freelancerDashboard() {
    this.expertService.getFreelancerDashboard().subscribe((resp) => {
      this.freelancerDashboardData = resp.data;
      console.log("resp ", this.freelancerDashboardData);
    });
  }

  getfreelancerprofile(val:any) {
    this.feelancerdataprofile = val;
    if (this.feelancerdataprofile.description) {
      this.per = this.per + 6.5;
    }
    if (this.feelancerdataprofile.projectList.length > 0) {
      this.per = this.per + 6.5;
    }
    if (this.feelancerdataprofile.imageurl != null) {
      this.per = this.per + 6.5;
    }
    this.per = Math.floor(this.per) + '%';
  }

  searchfilter() {
    this.searchTerms = [];
    let query:any = { $and: [] };
    console.log(this.sltskill);
    if (this.sltskill.any.length > 0) {
      let $or = [];
      for (let i = 0; i < this.sltskill.any.length; i++) {
        if (this.sltskill.any[i].name.toLowerCase() == "c++" || this.sltskill.any[i].name.toLowerCase() == "c") {
          $or.push({ 'skill': this.sltskill.any[i].name.toUpperCase() });
          $or.push({ 'skill': this.sltskill.any[i].name.toLowerCase() });
        } else {
          $or.push({ 'skill': { $regex: "^" + this.sltskill.any[i].name, "$options": "i" } });
        }
        $or.push({ 'title': { $regex: "^" + this.sltskill.any[i].name, "$options": "i" } });
        this.searchTerms.push(this.sltskill.any[i].name);
      }
      query.$and.push({ $or });
    }
    this.search.serchval = query;
    this.getjobList();
  }

  getsltSkills(val:any, stype:any) {
    let srch = val.toLowerCase();
    this.templist = [];
    if (stype == 'any') {
      let no = val.search(",");
      if (no < 0) {
        this.cls1 = 'displayblock';
        this.templist1 = this.skilllist.filter(function (el:any) {
          let str = el.name.toLowerCase();
          return str.search(srch) !== -1;
        });
        if (!srch) {
          this.cls1 = '';
        }
      } else {
        this.sltskill.any.push({ name: val.replace(",", "") });
        this.cls1 = '';
        this.homesearch.get('anyskill').setValue('');
      }
    }
  }

  setchangevalue() {
    if (this.homesearch.value.anyskill) {
      this.sltskill.any.push({ name: this.homesearch.value.anyskill });
      this.cls1 = '';
      this.homesearch.get('anyskill').setValue('');
    }
  }

  windowrendor() {
    this.renderer.listen('window', 'click', (e: Event):any => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach(element => {
        if (element == 'salectchanged') {
          change1 += 1;
        }
        if (element == 'salectchanged2') {
          change2 += 1;
        }
      });
      if (change1 == 0) {
        this.setchangevalue();
      }
      if ((<Element>e.target).className == 'salectchanged') {
        return false;
      } else {
        this.setchangevalue();
      }
    });
  }

  getSkill() {
    var filter = {};
    this.expertService.homeGetSkill(JSON.stringify(filter)).then(res => {
      this.skilllist = res.data;
      this.templist = res.data;;
    }).catch(err => {
    });
  }

  removeItem(inx:any, type:any) {
    if (type == 'any') {
      this.sltskill.any.splice(inx, 1);
    }
  }

  setvalue(val:any) {
    this.sltskill.any.push({ name: val.name });
    this.cls1 = '';
    this.homesearch.get('anyskill').setValue('');
  }

  getapplication() {
    this.shimmer = true;
    this.expertService.dashJobApplication(this.search2, 'jobapplication').then((res: any) => {
      this.jobapp = res.data.data;
      this.shimmer = false;
      if (this.search2.page == 1) {
        this.pagebtn2.prev = false;
      }
      this.search2.total = res.data.total;
      this.search2.totalpage = Math.ceil(this.search2.total / this.search2.limit);
    }, err => {
      this.shimmer = false;
    });
  }

  getlistbypage(val:any) {
    if (val == 'next') {
      this.pagebtn2.prev = true;
      if (this.search2.page < this.search2.totalpage) {
        this.search2.page = this.search2.page + 1;
        this.getapplication();
      }
      if (this.search2.page == this.search2.totalpage) {
        this.pagebtn2.next = false;
        this.getapplication();
      }
    }
    if (val == 'prev') {
      if (this.search2.page > 1) {
        this.pagebtn2.next = true;
        this.search2.page = this.search2.page - 1;
        this.getapplication();
      }
    }
    if (val == 'first') {
      this.search2.page = 1;
      this.getapplication();
    }
  }

  prev(val:any) {
    if (val == 1) {
      this.search.page = this.search.page - 1;
      this.getjobList();
    }
  }

  next(val:any) {
    if (val == 1) {
      this.search.page = this.search.page + 1;
      this.getjobList();
    }
  }

  getloader() {
    this.loader.startLoading();
  }

  opencloselist(){
    this.expertService.openclosurelist(this.profiledata);
     // this.closestt = false;
     // this.closestt = true;
  }




 



}
