<!-- Footer -->
<footer class="footer-area three pt-50">
    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-6 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/index.html"><img style="width: 200px" src="assets/img/logo.png" alt="Logo"></a>
                        
                        <ul>
                            <li><span>Address: </span>2659 Autostrad St, London, UK</li>
                            <li><span>Message: </span><a href="mailto:hello&#64;alzo.com">hello&#64;alzo.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">215 - 123 - 4567</a></li>
                            <li><span>Open: </span>Mon - Fri / 9:00 AM - 6:00 PM</li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <div class="col-sm-12 col-lg-12">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Quick Links</h3>

                        <span><a class="link-first" routerLink="/about">Why OBM</a></span>|
                        <span><a class="link"  routerLink="/freelance-jobs">Find jobs /Projects</a></span>|
                        <span><a class="link"  routerLink="/hire-bench-resources">Hire Bench</a></span>|
                        <span><a class="link"  routerLink="/hire-freelancers">Hire Freelancers</a></span>|
                        <span><a class="link"  routerLink="/find-clients">Find Clients</a></span>|
                        <span><a class="link"  routerLink="/find-clients-to-deploy-bench">Deploy Bench</a></span>|
                        <span><a class="link"  routerLink="/post-project">Post Job</a></span>|
                        <span><a class="link"  routerLink="/contract-jobs">Contract Jobs</a></span>|
                        <span><a class="link"  routerLink="/freelance-jobs">Freelancer Jobs</a></span>|
                        <span><a class="link"  routerLink="/blog">Blog</a></span>|
                        <span><a class="link"  routerLink="/terms-conditions">Terms & Conditions</a></span>|
                        <span><a class="link"  routerLink="/privacy-policy">Privacy Policy</a></span>|
                        <span><a class="link"  routerLink="/faq">FAQ</a></span>|
                        <span><a class="link"  routerLink="/pricing-table">Pricing</a></span>|
                        <span><a class="link"  routerLink="/contact">Contact Us</a></span>|
                      

                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-12">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Extra Links</h3>

                        <span><a class="link-first" routerLink="/post-jobs-for-free">Post Your Job</a></span>|
                        <span><a class="link" routerLink="/freelancer-register">Upload Resume</a></span> |
                        <span><a class="link" routerLink="/find-clients-to-deploy-bench">Find Clients to Deploy Bench</a></span> |
                        <span><a class="link" routerLink="/hire-candidates-on-contract">Hire Candidates on Contract</a></span> |
                        <span><a class="link" routerLink="/request-demo">Request Demo</a></span> |

                     
                      

                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-12 col-lg-12">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>SERVICES IN COUNTRY</h3>

                        <span><a class="link-first" routerLink="/about">Jobs in India</a></span>|
                        <span><a class="link"  routerLink="/">Find jobs /Projects</a></span>|
                        <span><a class="link"  routerLink="/">Jobs in USA</a></span>|
                        <span><a class="link"  routerLink="/">Jobs in UK</a></span>|
                        <span><a class="link"  routerLink="/">Jobs in Canada</a></span>|
                        <span><a class="link"  routerLink="/post-job">Jobs in Russia</a></span>|
                        <span><a class="link"  routerLink="/">Jobs in Japan</a></span>|
                        <span><a class="link"  routerLink="/">Jobs in New Zealand</a></span>|
                        <span><a class="link"  routerLink="/blog">Jobs in Australia</a></span>|
                        <span><a class="link"  routerLink="/terms-conditions">Jobs in Singapore</a></span>|
                        <span><a class="link"  routerLink="/privacy-policy">Jobs in Hong Kong</a></span>|
                        <span><a class="link"  routerLink="/faq">Jobs in France</a></span>|
                        <span><a class="link"  routerLink="/pricing">Jobs in Brazil</a></span>|
                        <span><a class="link"  routerLink="/contact">Jobs in South Africa</a></span>|
                        <span><a class="link"  routerLink="/contact">Jobs in UAE</a></span>|
                        <span><a class="link"  routerLink="/contact">Jobs in Saudi Arabia</a></span>|
                        <span><a class="link"  routerLink="/contact">South Korea</a></span>|
                      

                    </div>
                </div>
            </div> -->

            <!-- <div class="col-sm-12 col-lg-12">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Categories</h3>

                        <span><a class="link-first" routerLink="/about">Information Technology & Services</a></span>|
                        <span><a class="link"  routerLink="/">Design & Multimedia</a></span>|
                        <span><a class="link"  routerLink="/">Writing & Editing</a></span>|
                        <span><a class="link"  routerLink="/">Admin Support</a></span>|
                        <span><a class="link"  routerLink="/">Engineering & Design</a></span>|
                        
                       
                        
                        <span><a class="link"  routerLink="/pricing">Apparel & Fashion</a></span>|
                        <span><a class="link"  routerLink="/contact">Health Wellness & Fitness</a></span>|
                        <span><a class="link"  routerLink="/">Operations</a></span>|
                        <span><a class="link"  routerLink="/post-job">Architecture & Planning</a></span>|
                        <span><a class="link"  routerLink="/">Marketing & Sales</a></span>|
                        <span><a class="link"  routerLink="/blog">Human Resource</a></span>|
                        <span><a class="link"  routerLink="/terms-conditions">Finance</a></span>|
                        
                        <span><a class="link"  routerLink="/contact">Education</a></span>|
                        <span><a class="link"  routerLink="/privacy-policy">Law/Legal Services</a></span>|
                        <span><a class="link"  routerLink="/faq">Trainer</a></span>|
                        <span><a class="link"  routerLink="/contact">Leisure, Travel & Tourism</a></span>|

                    </div>
                </div>
            </div> -->


            <!-- <div class="col-sm-12 col-lg-6">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Useful Links</h3>

                        <ul>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                            <li><a routerLink="/jobs">Jobs</a></li>
                            <li><a routerLink="/candidates">Candidates</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-sm-6 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-newsletter">
                        <h3>Newsletter</h3>
                        <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed</p>
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your email*" name="EMAIL" required autocomplete="off">
                            <button class="btn" type="submit"><i class="flaticon-send"></i></button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area three bg-media">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-12 col-md-12">
                <div class="copyright-item text-center ">
                    <ul>
                        <li><a href="https://www.facebook.com/onbenchmark" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://x.com/Onbenchmark" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/onbenchmark.com/mycompany/" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <li><a href="https://www.instagram.com/onbenchmark/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/@onbenchmark" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- Copyright -->
<div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            
            
            <div class="col-lg-12 col-md-12 ">
                <div class="copyright-item ">
                    <p>Copyright©  <a href="https://www.cosettenetwork.com/" target="_blank">Cosette Network </a> Private Limited All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->