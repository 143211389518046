<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Post Project & Jobs</h2>
                  
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area ptb-50">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="new-img">
          <img src="assets/img/post-project.png" alt="New" />
          <!-- <img src="assets/img/about-shape.png" alt="New" /> -->
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <div class="section-title">
            <span class="sub-title">Post a Freelance   Project & Jobs for Free</span>
            <h2>Post a Freelance   Project & Jobs for Free</h2>
          </div>
          <p>
            Post projects or jobs for free for freelancers or bench resources for part-time, work from home, remote, contractual, temporary, or flexible hiring needs.
          </p>

          <p>
            Freelance jobs, project work posting to hire trusted freelancers, or hiring bench or unutilized human resources of other companies on contract for flexible and Just-In-Time temporary hiring needs.
          </p>

          <a class="cmn-btn" routerLink="/login"> Login </a>
          &nbsp;
          <a class="cmn-btn" routerLink="/register"> SignUp</a>

          <!-- <a class="cmn-btn" routerLink="/contact">Pricing<i class='bx bx-plus'></i></a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End New -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->