import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../../services/common.service";
import { AuthService } from "src/app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PostJobService } from "../../../services/post-job.service";

@Component({
  selector: "app-my-candidates-details",
  templateUrl: "./my-candidates-details.component.html",
  styleUrls: ["./my-candidates-details.component.scss"],
})
export class MyCandidatesDetailsComponent implements OnInit {
  candidate: any = {};
  candidateId = "";
  matchingJobs: any = {
    cid: "",
    page: 1,
    limit: 15,
  };
  jobs: any = [];

  constructor(
    private authService: AuthService,
    private postJobService: PostJobService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.candidateId = params["userId"];
      this.matchingJobs.cid = this.candidateId;
      this.getCandidateDetailsById();
      this.getMatchingJobs();
    });
  }

  getCandidateDetailsById() {
    this.commonService.getCandidateDetails(this.candidateId).then((resp) => {
      this.candidate = resp.data;
      console.log(resp);
    });
  }

  getMatchingJobs() {
    this.postJobService.getMatchingJobs(this.matchingJobs).then((resp) => {
      this.jobs = resp.data.data;
    });
  }

  ngOnInit(): void {}
}
