import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  apiUrl: any = "https://www.onbenchmark.com/";
  jsonheader: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`,
    });
  }

  getcity(data: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}notoken/city?filter=${data}`) 
      .toPromise();
  }

  Find(data: any, tbl: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}notoken/${tbl}?filter=${data}`)
      .toPromise();
  }

  homeGetSkill(data: any): Promise<any> {
    // const encodedData = encodeURIComponent(data);
    return this.http
      .get(`${this.apiUrl}notoken/skill?filter=${data}`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while fetching skills:", error);
        throw error;
      });
  }

  // https://www.onbenchmark.com/auth/contactus

  async contactUsForm(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}auth/contactus`, data)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while creating job:", error);
        throw error;
      });
  }

  async getCandidateDetails(id: any): Promise<any> {
    return this.http
      .get(`${this.apiUrl}api/candidate/${id}`)
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while creating job:", error);
        throw error;
      });
  }

  async getMatchingCandidates(data: any): Promise<any> {
    return this.http
      .post(`${this.apiUrl}custom/matchingcandidate/candidate`, data, {
        headers: this.authService.jsonheader,
      })
      .toPromise()
      .catch((error) => {
        console.error("Error occurred while creating job:", error);
        throw error;
      });
  }

  // plan-pricing

  async getPlanPrices(filter: string): Promise<any> {
    try {
      const url = `${this.apiUrl}api/pricetable?filter=${filter}`;
      return await this.http
        .get(url, { headers: this.authService.jsonheader })
        .toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async getCandidatesForJobApply(filter: any): Promise<any> {
    try {
      const url = `${this.apiUrl}custom/getforapply/candidate`;
      return await this.http
        .post(url, filter, { headers: this.authService.jsonheader })
        .toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async candidatesJobApply(data: any): Promise<any> {
    try {
      const url = `${this.apiUrl}api/jobapplication`;
      return await this.http
        .post(url, data, { headers: this.authService.jsonheader })
        .toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }

  async getJobContactDetails(data: any): Promise<any> {
    try {
      const url = `${this.apiUrl}custom/checkjobapplied/jobapplication`;
      return await this.http
        .post(url, data, { headers: this.authService.jsonheader })
        .toPromise();
    } catch (error) {
      console.error("Error occurred while finding data:", error);
      throw error;
    }
  }
}
