import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BenchApplicationsService } from 'src/app/services/bench-applications.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-shortlisted-resource',
  templateUrl: './shortlisted-resource.component.html',
  styleUrl: './shortlisted-resource.component.scss'
})
export class ShortlistedResourceComponent implements OnInit {

  data: any;
  applystt = false;
  vlist: any = [];
  teamId = "";
  vertualstt = true;
  jobresult: any = [];
  jobId = "";
  candidateId = "";
  userType = 3;
  appId="";
  jobTitle = '';
  jswimer=true;
  userdata:any={};



  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private benchService: BenchApplicationsService, 
    private toaster: ToastrService
  ) { 
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.jobId = params['jobId'];
      this.appId = params['appId'];
      this.jobTitle = params['jobTitle'];
      this.candidateId = params['candidateId'];
      this.userType = params['userType'];

      if (this.userType == 1) {
        this.getCandidate(this.candidateId);
         //this.getforshortlist();
        //  this.checkapplied();
      } else {
        // this.getFreelencer(this.candidateId);
      }
      
      // this.checkVirtual();
      // this.findvirtuallist();
    });

  }

  getCandidate(id:any) {
    this.benchService.FindById(id, 'candidate').then((res: any) => {
      this.data = res.data;
      this.data.Availability  = this.data.Availability.replace("_", " ");
    }, (err) => {
      console.log(err);
    });
  }







}
