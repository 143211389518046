import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { PlansService } from "src/app/services/plans.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
  isModalOpen = false;

  // =========================
  @ViewChild("name") name!: ElementRef;
  @ViewChild("cname") cname!: ElementRef;
  @ViewChild("gst") gst!: ElementRef;
  @ViewChild("address") address!: ElementRef;
  @ViewChild("autoShownModal") autoShownModal!: ModalDirective;
  letstalkModalShown = false;
  isModalShown = false;
  userdata: any = {};
  packagedata: any = {};
  pricetabledata: any = {};
  currency: any = "";
  datechange = "monthly";
  count = 0;
  j = 100;
  totalcv = 0;
  totalsms = 0;
  totalmail = 0;
  totaljob = 0;
  totalbench = 0;
  checkout: any;
  sltpackagedata: any = {};
  priceplansdata: any = {};
  cvdata: any = [];
  smsdata: any = [];
  maildata: any = [];
  jobdata: any = [];
  benchdata: any = [];
  totalprice = 0;
  month = 1;
  coin = 0;
  sltcoin = 0;
  contactForm: any;
  updatestt = false;
  companyId: any = "";
  baseurl: any = "";
  paymentId: any = "";
  txnid: any = "";
  paystt = false;
  enterpriseBoth: any = 1;
  razorPayOptions: any = {
    key: "",
    amount: "",
    currency: "INR",
    name: "",
    description: "",
    order_id: "",
    prefill: {
      email: "",
      contact: "",
    },
    handler: function (response: any) {
      var event = new CustomEvent("payment.success", {
        detail: response,
        bubbles: true,
        cancelable: true,
      });
      window.dispatchEvent(event);
    },
  };
  discount = 0;
  totalpay = 0;
  offerslt: any = "";
  coinper = 0;
  perdiscount = 0;
  sltpack: any = "";
  checkoutstt = 0;
  userpackagedata: any = {};
  contractcheckbox: any = true;
  benchcheckbox: any = false;
  htmldoc: any = "";
  // ==============================

  constructor(
    private commonService: CommonService,
    private plansService: PlansService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private router: Router,
    private auth: AuthService,
    private formBuild: FormBuilder,
    private route: ActivatedRoute,
    // private config: ConfigService
  ) {
    // this.getPlanPrices();
    this.baseurl = this.plansService.getbaseurl();
    this.contactFormCreate();
    this.loader.startLoading();
    this.route.queryParams.subscribe((params) => {
      this.checkoutstt = params["checkoutstt"];
      if (this.checkoutstt == 1) {
        this.checkout = false;
        this.getcoinplan();
      } else if (this.checkoutstt == 2) {
        this.getcoinplan();
        this.checkout = true;
      } else {
        this.router.navigate(["/profile"]);
      }
    });
  }

  ngOnInit(): void {
    // Set the first tab as active by default
    // document.getElementsByClassName('tablinks')[0].classList.add('active');
    // document.getElementById('Monthly')!.style.display = 'block';

    let obj = this.auth.getloginData();
    if (Object.keys(obj).length > 0) {
      this.userdata = this.auth.getloginData();
      this.patchcontactform();
    }
    this.auth.profiledata.subscribe((res: any) => {
      this.userdata = res;
      this.patchcontactform();
    });
  }

  // openModal() {
  //   this.isModalOpen = true;
  // }

  // closeModal() {
  //   this.isModalOpen = false;
  // }

  // openTab(evt: any, tabName: string): void {
  //   const tabcontent = document.getElementsByClassName("tabcontent");
  //   for (let i = 0; i < tabcontent.length; i++) {
  //     (tabcontent[i] as HTMLElement).style.display = "none";
  //   }
  //   const tablinks = document.getElementsByClassName("tablinks");
  //   for (let i = 0; i < tablinks.length; i++) {
  //     tablinks[i].className = tablinks[i].className.replace(" active", "");
  //   }
  //   document.getElementById(tabName)!.style.display = "block";
  //   evt.currentTarget.className += " active";
  // }

  // getPlanPrices

  // getPlanPrices() {
  //   this.planPricesdata = [];
  //   var filter = {};
  //   this.commonService.getPlanPrices(JSON.stringify(filter)).then(res => {
  //     this.planPricesdata = res.data;
  //     console.log("priceData: ", res.data);
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  // =======================================

  showModal(val: any): void {
    if (val == 1) {
      this.isModalShown = true;
    } else if (val == 2) {
      this.letstalkModalShown = true;
    }
  }
  hideModal(val: any): void {
    if (val == 1) {
      this.isModalShown = false;
    } else if (val == 2) {
      this.letstalkModalShown = false;
    }
  }
  onHidden(val: any): void {
    if (val == 1) {
      this.isModalShown = false;
    } else if (val == 2) {
      this.letstalkModalShown = false;
    }
  }

  getcoinplan() {
    this.plansService.getCoin({}).then(
      (res: any) => {
        if (res.confirmation == "success") {
          this.coin = res.data.earningcoin;
        } else {
          this.coin = 0;
        }
        this.plansService.getCurrency().then(
          (res1: any) => {
            this.plansService.findPlan(JSON.stringify({}), "pricetable").then(
              (res: any) => {
                this.packagedata = res.data[0];
                this.currency = res1.currency;
                if (this.checkoutstt == 2) {
                  this.getuserpackage();
                } else {
                  this.change_plan("Contract");
                }
                this.loader.stopLoading();
              },
              (err) => {
                this.loader.stopLoading();
              },
            );
          },
          (err) => {
            this.loader.stopLoading();
          },
        );
      },
      (err) => {
        this.coin = 0;
        this.loader.stopLoading();
      },
    );
  }

  getuserpackage() {
    this.loader.startLoading();
    this.plansService.findPlan(JSON.stringify({}), "packageuser").then(
      (res: any) => {
        this.userpackagedata = res.data;
        //Enterprise
        const arr = [
          { month: 1, number: 2, plantype: "essentials", value: "monthly" },
          { month: 3, number: 2, plantype: "essentials", value: "quarterly" },
          { month: 6, number: 2, plantype: "essentials", value: "halfyearly" },
          { month: 12, number: 2, plantype: "essentials", value: "yearly" },
          { month: 1, number: 3, plantype: "premium", value: "monthly" },
          { month: 3, number: 3, plantype: "premium", value: "quarterly" },
          { month: 6, number: 3, plantype: "premium", value: "halfyearly" },
          { month: 12, number: 3, plantype: "premium", value: "yearly" },
          { month: 1, number: 5, plantype: "enterprise", value: "monthly" },
          { month: 3, number: 5, plantype: "enterprise", value: "quarterly" },
          { month: 6, number: 5, plantype: "enterprise", value: "halfyearly" },
          { month: 12, number: 5, plantype: "enterprise", value: "yearly" },
        ];
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].month == this.userpackagedata.totalmonth &&
            arr[i].number == this.userpackagedata.planType
          ) {
            this.datechange = arr[i].value;
            this.selectpackage(arr[i].plantype, "flat");
          }
        }
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  mathceil(val: number) {
    return Math.ceil(val);
  }

  selectpackage(val: string, vtype: any) {
    this.sltcoin = 0;
    this.sltpack = val;
    this.checkout = true;
    if (val == "essentials") {
      if (this.datechange == "monthly") {
        let tprice = Math.ceil(
          this.packagedata.essentials.monthlyamount -
            (this.packagedata.essentials.monthlyamount *
              this.packagedata.essentials.monthlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.essentials.totalcv;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.essentials.companyViewTotal;
        this.sltpackagedata.totalsms = this.packagedata.essentials.totalsms;
        this.sltpackagedata.totalmail = this.packagedata.essentials.totalemail;
        this.sltpackagedata.jobPost = this.packagedata.essentials.totalpost;
        this.sltpackagedata.addbench = this.packagedata.essentials.totalbench;
        this.sltpackagedata.planType = 2;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Essentials";
        this.sltpackagedata.amount =
          tprice - (tprice * this.packagedata.essentials.monthlyoffer) / 100;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.discount = this.packagedata.essentials.monthlyoffer;
        this.sltpackagedata.discount = this.packagedata.essentials.monthlyoffer;
        this.sltpackagedata.totalmonth = 1;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.essentials.monthlyamount;
        this.perdiscount = this.packagedata.essentials.monthlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.essentials.monthlyamount *
              (this.packagedata.essentials.monthlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.essentials.monthlyamount *
              this.packagedata.essentials.coinMonthly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.essentials.coinMonthly;
          const coinamt = Math.ceil(
            (this.packagedata.essentials.monthlyamount *
              this.packagedata.essentials.coinMonthly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "quarterly") {
        let tprice = Math.ceil(
          this.packagedata.essentials.quarterlyamount -
            (this.packagedata.essentials.quarterlyamount *
              this.packagedata.essentials.quarterlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.essentials.totalcv * 3;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.essentials.companyViewTotal * 3;
        this.sltpackagedata.totalsms = this.packagedata.essentials.totalsms * 3;
        this.sltpackagedata.totalmail =
          this.packagedata.essentials.totalemail * 3;
        this.sltpackagedata.jobPost = this.packagedata.essentials.totalpost * 3;
        this.sltpackagedata.addbench =
          this.packagedata.essentials.totalbench * 3;
        this.sltpackagedata.planType = 2;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Essentials";
        this.sltpackagedata.amount = tprice; //* 3 - (((tprice * 3) * this.packagedata.essentials.quarterlyoffer / 100));;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.discount =
          this.packagedata.essentials.quarterlyoffer;
        this.sltpackagedata.discount =
          this.packagedata.essentials.quarterlyoffer;
        this.sltpackagedata.totalmonth = 3;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.essentials.quarterlyamount;
        this.perdiscount = this.packagedata.essentials.quarterlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.essentials.quarterlyamount *
              (this.packagedata.essentials.quarterlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.essentials.quarterlyamount *
              this.packagedata.essentials.coinQuarterly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.essentials.coinQuarterly;
          const coinamt = Math.ceil(
            (this.packagedata.essentials.quarterlyamount *
              this.packagedata.essentials.coinQuarterly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "halfyearly") {
        let tprice = Math.ceil(
          this.packagedata.essentials.halfyearlyamount -
            (this.packagedata.essentials.halfyearlyamount *
              this.packagedata.essentials.halfyearlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.essentials.totalcv * 6;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.essentials.companyViewTotal * 6;
        this.sltpackagedata.totalsms = this.packagedata.essentials.totalsms * 6;
        this.sltpackagedata.totalmail =
          this.packagedata.essentials.totalemail * 6;
        this.sltpackagedata.jobPost = this.packagedata.essentials.totalpost * 6;
        this.sltpackagedata.addbench =
          this.packagedata.essentials.totalbench * 6;
        this.sltpackagedata.planType = 2;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Essentials";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.discount =
          this.packagedata.essentials.halfyearlyoffer;
        this.sltpackagedata.discount =
          this.packagedata.essentials.halfyearlyoffer;
        this.sltpackagedata.totalmonth = 6;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.essentials.halfyearlyamount;
        this.perdiscount = this.packagedata.essentials.halfyearlyoffer;

        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.essentials.halfyearlyamount *
              (this.packagedata.essentials.halfyearlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.essentials.halfyearlyamount *
              this.packagedata.essentials.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.essentials.coinHalfYearly;
          const coinamt = Math.ceil(
            (this.packagedata.essentials.halfyearlyamount *
              this.packagedata.essentials.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else {
        let tprice = Math.ceil(
          this.packagedata.essentials.yearlyamount -
            (this.packagedata.essentials.yearlyamount *
              this.packagedata.essentials.yearlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.essentials.totalcv * 12;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.essentials.companyViewTotal * 12;
        this.sltpackagedata.totalsms =
          this.packagedata.essentials.totalsms * 12;
        this.sltpackagedata.totalmail =
          this.packagedata.essentials.totalemail * 12;
        this.sltpackagedata.jobPost =
          this.packagedata.essentials.totalpost * 12;
        this.sltpackagedata.addbench =
          this.packagedata.essentials.totalbench * 12;
        this.sltpackagedata.planType = 2;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Essentials";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.discount = this.packagedata.essentials.yearlyoffer;
        this.sltpackagedata.discount = this.packagedata.essentials.yearlyoffer;
        this.sltpackagedata.totalmonth = 12;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.essentials.yearlyamount;
        this.perdiscount = this.packagedata.essentials.yearlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.essentials.yearlyamount *
              (this.packagedata.essentials.yearlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.essentials.yearlyamount *
              this.packagedata.essentials.coinYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.essentials.coinYearly;
          const coinamt = Math.ceil(
            (this.packagedata.essentials.yearlyamount *
              this.packagedata.essentials.coinYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      }
    }
    if (val == "premium") {
      if (this.datechange == "monthly") {
        let tprice = Math.ceil(
          this.packagedata.premium.monthlyamount -
            (this.packagedata.premium.monthlyamount *
              this.packagedata.premium.monthlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal = this.packagedata.premium.totalcv;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.premium.companyViewTotal;
        this.sltpackagedata.totalsms = this.packagedata.premium.totalsms;
        this.sltpackagedata.totalmail = this.packagedata.premium.totalemail;
        this.sltpackagedata.jobPost = this.packagedata.premium.totalpost;
        this.sltpackagedata.addbench = this.packagedata.premium.totalbench;
        this.sltpackagedata.planType = 3;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Premium";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 1;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.premium.monthlyamount;
        this.perdiscount = this.packagedata.premium.monthlyoffer;

        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.premium.monthlyamount *
              (this.packagedata.premium.monthlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.premium.monthlyamount *
              this.packagedata.premium.coinMonthly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.premium.coinMonthly;
          const coinamt = Math.ceil(
            (this.packagedata.premium.monthlyamount *
              this.packagedata.premium.coinMonthly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "quarterly") {
        let tprice = Math.ceil(
          this.packagedata.premium.quarterlyamount -
            (this.packagedata.premium.quarterlyamount *
              this.packagedata.premium.quarterlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.premium.totalcv * 3;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.premium.companyViewTotal * 3;
        this.sltpackagedata.totalsms = this.packagedata.premium.totalsms * 3;
        this.sltpackagedata.totalmail = this.packagedata.premium.totalemail * 3;
        this.sltpackagedata.jobPost = this.packagedata.premium.totalpost * 3;
        this.sltpackagedata.addbench = this.packagedata.premium.totalbench * 3;
        this.sltpackagedata.planType = 3;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Premium";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 3;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.premium.quarterlyamount;
        this.perdiscount = this.packagedata.premium.quarterlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.premium.quarterlyamount *
              (this.packagedata.premium.quarterlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.premium.quarterlyamount *
              this.packagedata.premium.coinQuarterly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.premium.coinQuarterly;
          const coinamt = Math.ceil(
            (this.packagedata.premium.quarterlyamount *
              this.packagedata.premium.coinQuarterly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "halfyearly") {
        let tprice = Math.ceil(
          this.packagedata.premium.halfyearlyamount -
            (this.packagedata.premium.halfyearlyamount *
              this.packagedata.premium.halfyearlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.premium.totalcv * 6;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.premium.companyViewTotal * 6;
        this.sltpackagedata.totalsms = this.packagedata.premium.totalsms * 6;
        this.sltpackagedata.totalmail = this.packagedata.premium.totalemail * 6;
        this.sltpackagedata.jobPost = this.packagedata.premium.totalpost * 6;
        this.sltpackagedata.addbench = this.packagedata.premium.totalbench * 6;
        this.sltpackagedata.planType = 3;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Premium";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 6;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount =
          this.packagedata.premium.halfyearlyamount;
        this.perdiscount = this.packagedata.premium.halfyearlyoffer;

        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.premium.halfyearlyamount *
              (this.packagedata.premium.halfyearlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.premium.halfyearlyamount *
              this.packagedata.premium.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.premium.coinHalfYearly;
          const coinamt = Math.ceil(
            (this.packagedata.premium.halfyearlyamount *
              this.packagedata.premium.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else {
        let tprice = Math.ceil(
          this.packagedata.premium.yearlyamount -
            (this.packagedata.premium.yearlyamount *
              this.packagedata.premium.yearlyoffer) /
              100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.premium.totalcv * 12;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.premium.companyViewTotal * 12;
        this.sltpackagedata.totalsms = this.packagedata.premium.totalsms * 12;
        this.sltpackagedata.totalmail =
          this.packagedata.premium.totalemail * 12;
        this.sltpackagedata.jobPost = this.packagedata.premium.totalpost * 12;
        this.sltpackagedata.addbench = this.packagedata.premium.totalbench * 12;
        this.sltpackagedata.planType = 3;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Premium";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 12;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount = this.packagedata.premium.yearlyamount;
        this.perdiscount = this.packagedata.premium.yearlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(
            this.packagedata.premium.yearlyamount *
              (this.packagedata.premium.yearlyoffer / 100),
          );
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (this.packagedata.premium.yearlyamount *
              this.packagedata.premium.coinYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.premium.coinYearly;
          const coinamt = Math.ceil(
            (this.packagedata.premium.yearlyamount *
              this.packagedata.premium.coinYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      }
    }

    if (val == "enterprise") {
      let amountoffer = 0;
      let amount = 0;
      let amountmultiply = 1;
      if (this.contractcheckbox == true && this.benchcheckbox == true) {
        amountmultiply = 2;
      }
      if (
        (this.contractcheckbox == true && this.benchcheckbox == false) ||
        (this.contractcheckbox == false && this.benchcheckbox == true)
      ) {
        amountmultiply = 1;
      }

      if (this.datechange == "monthly") {
        const monthlyamount =
          this.packagedata.enterprise.monthlyamount * amountmultiply;
        const monthlyoffer = this.packagedata.enterprise.monthlyoffer;

        let tprice = Math.ceil(
          monthlyamount - (monthlyamount * monthlyoffer) / 100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.enterprise.totalcv;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.enterprise.companyViewTotal;
        this.sltpackagedata.totalsms = this.packagedata.enterprise.totalsms;
        this.sltpackagedata.totalmail = this.packagedata.enterprise.totalemail;
        this.sltpackagedata.jobPost = this.packagedata.enterprise.totalpost;
        this.sltpackagedata.addbench = this.packagedata.enterprise.totalbench;
        this.sltpackagedata.planType = 5;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Enterprise";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 1;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount = monthlyamount;
        this.perdiscount = monthlyoffer;

        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(monthlyamount * (monthlyoffer / 100));
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (monthlyamount * this.packagedata.enterprise.coinMonthly) / 100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.enterprise.coinMonthly;
          const coinamt = Math.ceil(
            (monthlyamount * this.packagedata.enterprise.coinMonthly) / 100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "quarterly") {
        const quarterlyamount =
          this.packagedata.enterprise.quarterlyamount * amountmultiply;
        const quarterlyoffer = this.packagedata.enterprise.quarterlyoffer;
        let tprice = Math.ceil(
          quarterlyamount - (quarterlyamount * quarterlyoffer) / 100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.enterprise.totalcv * 3;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.enterprise.companyViewTotal * 3;
        this.sltpackagedata.totalsms = this.packagedata.enterprise.totalsms * 3;
        this.sltpackagedata.totalmail =
          this.packagedata.enterprise.totalemail * 3;
        this.sltpackagedata.jobPost = this.packagedata.enterprise.totalpost * 3;
        this.sltpackagedata.addbench =
          this.packagedata.enterprise.totalbench * 3;
        this.sltpackagedata.planType = 5;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Enterprise";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 3;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount = quarterlyamount;
        this.perdiscount = quarterlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(quarterlyamount * (quarterlyoffer / 100));
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (quarterlyamount * this.packagedata.enterprise.coinQuarterly) / 100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.enterprise.coinQuarterly;
          const coinamt = Math.ceil(
            (quarterlyamount * this.packagedata.enterprise.coinQuarterly) / 100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else if (this.datechange == "halfyearly") {
        let halfyearlyamount =
          this.packagedata.enterprise.halfyearlyamount * amountmultiply;
        let halfyearlyoffer = this.packagedata.enterprise.halfyearlyoffer;
        let tprice = Math.ceil(
          halfyearlyamount - (halfyearlyamount * halfyearlyoffer) / 100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.enterprise.totalcv * 6;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.enterprise.companyViewTotal * 6;
        this.sltpackagedata.totalsms = this.packagedata.enterprise.totalsms * 6;
        this.sltpackagedata.totalmail =
          this.packagedata.enterprise.totalemail * 6;
        this.sltpackagedata.jobPost = this.packagedata.enterprise.totalpost * 6;
        this.sltpackagedata.addbench =
          this.packagedata.enterprise.totalbench * 6;
        this.sltpackagedata.planType = 5;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Enterprise";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 6;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount = halfyearlyamount;
        this.perdiscount = halfyearlyoffer;

        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(halfyearlyamount * (halfyearlyoffer / 100));
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (halfyearlyamount * this.packagedata.enterprise.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.enterprise.coinHalfYearly;
          const coinamt = Math.ceil(
            (halfyearlyamount * this.packagedata.enterprise.coinHalfYearly) /
              100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      } else {
        const yearlyamount =
          this.packagedata.enterprise.yearlyamount * amountmultiply;
        const yearlyoffer = this.packagedata.enterprise.yearlyoffer;
        let tprice = Math.ceil(
          yearlyamount - (yearlyamount * yearlyoffer) / 100,
        );
        this.sltpackagedata.resumeViewTotal =
          this.packagedata.enterprise.totalcv * 12;
        this.sltpackagedata.companyViewTotal =
          this.packagedata.enterprise.companyViewTotal * 12;
        this.sltpackagedata.totalsms =
          this.packagedata.enterprise.totalsms * 12;
        this.sltpackagedata.totalmail =
          this.packagedata.enterprise.totalemail * 12;
        this.sltpackagedata.jobPost =
          this.packagedata.enterprise.totalpost * 12;
        this.sltpackagedata.addbench =
          this.packagedata.enterprise.totalbench * 12;
        this.sltpackagedata.planType = 5;
        this.sltpackagedata.totaluser = 1;
        this.sltpackagedata.virtualTeam = 15;
        this.sltpackagedata.planTypename = "Enterprise";
        this.sltpackagedata.amount = tprice;
        this.sltpackagedata.subamount = this.Mathceil(
          this.sltpackagedata.amount +
            (this.sltpackagedata.amount * 18) / 100 -
            this.coin,
        );
        this.sltpackagedata.totalmonth = 12;
        this.sltpackagedata.taxtper = 18;
        this.sltpackagedata.currency = this.currency;
        this.sltpackagedata.paymentstt = 1;
        this.sltpackagedata.totalamount = yearlyamount;
        this.perdiscount = yearlyoffer;
        if (vtype == "flat") {
          this.offerslt = "flat";
          this.discount = Math.ceil(yearlyamount * (yearlyoffer / 100));
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
          const coinamt = Math.ceil(
            (yearlyamount * this.packagedata.enterprise.coinYearly) / 100,
          );
          if (coinamt < this.coin) {
            this.sltcoin = coinamt;
          } else {
            this.sltcoin = this.coin;
          }
        }
        if (vtype == "coin") {
          this.coinper = this.packagedata.enterprise.coinYearly;
          const coinamt = Math.ceil(
            (yearlyamount * this.packagedata.enterprise.coinYearly) / 100,
          );
          if (coinamt < this.coin) {
            this.discount = coinamt;
            this.sltcoin = coinamt;
          } else {
            this.discount = this.coin;
            this.sltcoin = this.coin;
          }
          this.sltpackagedata.subamount = this.totalpay = Math.ceil(
            this.sltpackagedata.totalamount -
              this.discount +
              ((this.sltpackagedata.totalamount - this.discount) *
                this.sltpackagedata.taxtper) /
                100,
          );
        }
      }
    }
    this.patchcontactform();
  }

  changeplan() {
    this.checkout = false;
    if (this.checkoutstt == 2) {
      this.router.navigate(["/business/package/plan"], {
        queryParams: { checkoutstt: 1 },
      });
    } else {
      this.change_plan("Contract");
    }
  }

  openmodal(): any {
    const cdata = this.contactForm.value;
    if (!cdata.name) {
      this.toaster.error("Please Enter Name");
      this.name.nativeElement.focus();
      return false;
    }
    if (!cdata.cname) {
      this.toaster.error("Please Enter company Name");
      this.cname.nativeElement.focus();
      return false;
    }
    if (!cdata.address) {
      this.toaster.error("Please Enter Address");
      this.address.nativeElement.focus();
      return false;
    }
    this.showModal(1);
    if (this.updatestt) {
      this.updateprofile();
    }
  }

  changeclickpayment(val: any) {
    const cdata = this.contactForm.value;
    this.sltpackagedata.paymentType = val;
    this.sltpackagedata.coin = this.sltcoin;
    this.sltpackagedata.name = cdata.name;
    this.sltpackagedata.cname = cdata.cname;
    this.sltpackagedata.gst = cdata.gst ? cdata.gst : null;
    this.sltpackagedata.address = cdata.address;
    this.sltpackagedata.panno = cdata.panno;
    this.loader.startLoading();
    this.plansService.payment(this.sltpackagedata, "changeclickpayment").then(
      (res: any) => {
        this.loader.stopLoading();
        const paydata = res.data;
        if (paydata.paymentType == "paypal") {
          if (paydata.payment != null) {
            window.location.href = paydata.payment;
          } else {
            this.toaster.error("Please try after sometime!");
          }
        }
        if (paydata.paymentType == "payu") {
          if (paydata.payment != null) {
            window.location.href = paydata.payment;
          } else {
            this.toaster.error("Please try after sometime!");
          }
        }
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  Mathceil(val: number) {
    return Math.ceil(val);
  }

  datestt(val: string) {
    this.datechange = val;
    if (val == "monthly") {
      this.month = 1;
    } else if (val == "quarterly") {
      this.month = 3;
    } else if (val == "halfyearly") {
      this.month = 6;
    } else {
      this.month = 12;
    }
    this.change_plan("Contract");
  }

  contactFormCreate() {
    this.contactForm = this.formBuild.group({
      name: [""],
      cname: [""],
      gst: [""],
      panno: [""],
      address: [""],
    });
  }

  patchcontactform() {
    this.companyId = this.userdata.companyId._id;
    this.contactForm.patchValue({
      name: this.userdata.name,
      cname: this.userdata.cname,
      gst: this.userdata.companyId.gst,
      panno: this.userdata.companyId.panno,
      address: this.userdata.companyId.address,
    });
    if (this.userdata.companyId.gst == null) {
      this.updatestt = true;
    }
  }

  updateprofile() {
    const cdata = this.contactForm.value;
    let data2 = {
      gst: cdata.gst,
      address: cdata.address,
      panno: cdata.panno,
    };
    this.plansService.update(this.companyId, data2, "company").then(
      (res: any) => {},
      (err) => {},
    );
  }

  applyoffer(val: any) {
    this.selectpackage(this.sltpack, val);
  }

  removeOffer(val: any) {
    if (val == "coin") {
      this.offerslt = "flat";
      this.selectpackage(this.sltpack, "flat");
    }
    if (val == "flat") {
      this.offerslt = "";
      this.selectpackage(this.sltpack, "flat");
    }
  }

  change_plan(val: any) {
    console.log("this.packagedata", this.packagedata);

    if (this.contractcheckbox && this.benchcheckbox) {
      this.enterpriseBoth = 2;
    } else {
      this.enterpriseBoth = 1;
    }

    var monthlyoffer = parseInt(this.packagedata.enterprise.monthlyoffer);
    var quarterlyoffer = parseInt(this.packagedata.enterprise.quarterlyoffer);
    var halfyearlyoffer = parseInt(this.packagedata.enterprise.halfyearlyoffer);
    var yearlyoffer = parseInt(this.packagedata.enterprise.yearlyoffer);
    var amount = 0;
    var offer = 0;
    var checkcount = 0;
    if (this.contractcheckbox == true && this.benchcheckbox == true) {
      amount += 50000;
    }
    if (
      (this.contractcheckbox == true && this.benchcheckbox == false) ||
      (this.contractcheckbox == false && this.benchcheckbox == true)
    ) {
      amount += 25000;
    }
    if (this.contractcheckbox == false && this.benchcheckbox == false) {
      if (val == "Contract") {
        amount = 25000;
        this.contractcheckbox = false;
        this.benchcheckbox = true;
      }
      if (val == "Bench") {
        amount = 25000;
        this.contractcheckbox = true;
        this.benchcheckbox = false;
      }
    }
    var offerper = 0;
    var amountoffer = 0;
    if (this.datechange == "monthly") {
      amount = amount * 1;
      amountoffer = Math.ceil(amount - (amount * monthlyoffer) / 100);
      offerper = monthlyoffer;
    } else if (this.datechange == "quarterly") {
      amount = amount * 3;
      amountoffer = Math.ceil(amount - (amount * quarterlyoffer) / 100);
      offerper = quarterlyoffer;
    } else if (this.datechange == "halfyearly") {
      amount = amount * 6;
      amountoffer = Math.ceil(amount - (amount * halfyearlyoffer) / 100);
      offerper = halfyearlyoffer;
    } else if (this.datechange == "yearly") {
      amount = amount * 12;
      amountoffer = Math.ceil(amount - (amount * yearlyoffer) / 100);
      offerper = yearlyoffer;
    }
    this.htmldoc = '<i class="fa fa-inr"></i>';
    this.htmldoc += amountoffer;
    this.htmldoc += '<div class="offer-price">';
    this.htmldoc += '<span class="offer-line-through">';
    this.htmldoc += '<i class="fa fa-inr"></i>';
    this.htmldoc += amount;
    this.htmldoc += "</span>";
    this.htmldoc += "( " + offerper + " % off )";
    this.htmldoc += "</div>";
  }
}
