import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/common.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { PostJobService } from "src/app/services/post-job.service";
import * as $ from "jquery";

@Component({
  selector: "app-post-a-job",
  templateUrl: "./post-a-job.component.html",
  styleUrls: ["./post-a-job.component.scss"],
})
export class PostAJobComponent implements OnInit {
  @ViewChild("dropdown", { static: false }) dropdown!: ElementRef;
  @ViewChild("menu") menu!: ElementRef;

  public Editor = ClassicEditor;

  addJobForm: any;
  submitted: boolean = false;
  btn = "Submit";
  id: any;
  jobData: any;

  citylist: any = [];
  cls: any = "";
  loderstt = false;
  loderstt2 = false;
  locationlist: any = [];
  othercity = false;
  cls1: any = "";
  cls2: any = "";
  countrylist: any = [];
  slist: any = [];
  jobType: any = [];
  jobId: any = "";
  repost = false;
  skilllist: any = [];
  skillListdata: any = [];
  templist: any = [];
  currency = "in";
  minDate = new Date();
  ratetype = "Monthly";
  totalhour = 168;
  doller = 80;
  showtestmin: any = "";
  showtestmax: any = "";
  inrratemin = 0;
  inrratemax = 0;
  expmin = 0;
  expmax = 0;
  per: any = [
    { max: 100, min: 4, per: 87 },
    { max: 4, min: 3.5, per: 71 },
    { max: 3.5, min: 3, per: 52 },
    { max: 3, min: 2.5, per: 37 },
    { max: 2.5, min: 2, per: 21 },
    { max: 2, min: 1.5, per: 5 },
  ];

  config: any = {};
  info: any = {};
  modules: any = {};
  cntchr = 7000;

  // =================================

  // min-max_Rates_modal
  isMinRatesModalShown = false;
  isMaxRatesModalShown = false;

  constructor(
    private formBuild: FormBuilder,
    private postJobService: PostJobService,
    private commonSevice: CommonService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.jobId = params["jobId"];
      if (params["repost"] == "true") {
        this.repost = true;
      }
      if (this.jobId) {
        this.getJobInfo();
      } else {
        this.getcurrency();
      }
    });
  }

  ngOnInit(): void {
    this.loader.stopLoading();
    this.createAddJobForm();
    this.getJobType();
    this.getSkillList();
    this.window_load();
    this.windowrendor();
  }

  // minRates_modal
  show_minRatesModal(val: any): void {
    if (val == "min") {
      this.isMinRatesModalShown = true;
    }
  }
  hide_minRatesModal(val: any): void {
    if (val == "min") {
      this.isMinRatesModalShown = false;
    }
  }
  onHidden_minRatesModal(val: any): void {
    if (val == "min") {
      this.isMinRatesModalShown = false;
    }
  }
  // maxRates_modal
  show_maxRatesModal(val: any): void {
    if (val == "max") {
      this.isMaxRatesModalShown = true;
    }
  }
  hide_maxRatesModal(val: any): void {
    if (val == "max") {
      this.isMaxRatesModalShown = false;
    }
  }
  onHidden_maxRatesModal(val: any): void {
    if (val == "max") {
      this.isMaxRatesModalShown = false;
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen("document", "click", (event: Event) => {
      const target = event.target as HTMLElement;
      if (this.dropdown && !this.dropdown.nativeElement.contains(target)) {
        this.cls = "";
      }
    });
  }

  ngOnDestroy(): void {
    // Clean up the listener when the component is destroyed
    this.renderer.listen("document", "click", () => {});
  }

  createAddJobForm() {
    this.addJobForm = this.formBuild.group({
      title: [ "", Validators.compose([ Validators.required, Validators.minLength(5), Validators.maxLength(70)])],
      location: [""],
      noofResources: [ "", Validators.compose([ Validators.required, Validators.min(1), Validators.max(999)])],
      workFrom: ["", Validators.required],
      tentativeStartDate: ["", Validators.required],
      modeOfContact: ["", Validators.required],
      duration: ["", Validators.required],
      ratemin: ["", [Validators.required]],
      ratemax: ["", [Validators.required]],
      ratecurrency: ["in", Validators.required],
      jobtype: ["na"],
      parmanant: [false, Validators.required],
      contract: [false, Validators.required],
      freelance: [false, Validators.required],
      ratetype: ["Hourly"],
      experiencemin: [
        "",
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(40),
        ]),
      ],
      experiencemax: [
        "",
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(60),
        ]),
      ],
      jobDescription: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(7000),
        ]),
      ],
      sltratetype: ["Monthly"],
      desdoc: [""],
      desdocpath: [""],
      dochtml: [""],
      skillsearch: [""],
    });
  }

  get formCreateJob() {
    return this.addJobForm.controls;
  }

  // ======================== 1 =============================
  getJobInfo() {
    this.loader.startLoading();
    this.postJobService.FindById(this.jobId, "job").then(
      (res: any) => {
        this.loader.stopLoading();
        this.btn = "Update";
        if (this.repost) {
          this.btn = "Re-Post";
        } 
        this.addJobForm.patchValue({
          title: res.data.title,
          noofResources: res.data.noofResources,
          workFrom: res.data.workFrom,
          sltratetype: res.data.sltratetype,
          ratetype: res.data.ratetype,
          duration: res.data.duration,
          modeOfContact: res.data.modeOfContact,
          experiencemin: res.data.experiencemin,
          experiencemax: res.data.experiencemax,
          jobDescription: res.data.jobDescription,
          parmanant: res.data.parmanant,
          contract: res.data.contract,
          freelance: res.data.freelance,
          // jobtype: res.data.jobtype,
          jobtype: res.data.jobtype || 'na', 
          desdoc: res.data.desdoc,
          desdocpath: res.data.desdocpath,
          dochtml: res.data.dochtml,
        });
        if (this.repost) {
          let today = new Date().toISOString().slice(0, 10);
          this.addJobForm.get("tentativeStartDate").setValue(today);
        } else {
          this.addJobForm
            .get("tentativeStartDate")
            .setValue(res.data.tentativeStartDate);
        }
        if (res.data.sltratetype == "Monthly") {
          if (res.data.currency == "in") {
            this.addJobForm
              .get("ratemin")
              .setValue(
                Math.floor(res.data.ratemin * this.totalhour * this.doller),
              );
            this.addJobForm
              .get("ratemax")
              .setValue(
                Math.floor(res.data.ratemax * this.totalhour * this.doller),
              );
          }
          if (res.data.currency == "doller") {
            this.addJobForm
              .get("ratemin")
              .setValue(Math.floor(res.data.ratemin * this.totalhour));
            this.addJobForm
              .get("ratemax")
              .setValue(Math.floor(res.data.ratemax * this.totalhour));
          }
        } else {
          if (res.data.currency == "in") {
            this.addJobForm
              .get("ratemin")
              .setValue(Math.floor(res.data.ratemin * this.doller));
            this.addJobForm
              .get("ratemax")
              .setValue(Math.floor(res.data.ratemax * this.doller));
          }
        }
        //this.inrratemin = Math.ceil(res.data.experiencemin * this.doller * 168);
        //this.inrratemax = Math.ceil(res.data.experiencemax * this.doller * 168);
        this.slist = res.data.skill;
        // this.skillListdata = res.data.skill;
        this.locationlist = res.data.location;
        if (this.locationlist.length > 0) {
          let strloc = this.locationlist[0].location;
          if (this.locationlist[0].country) {
            strloc += ", " + this.locationlist[0].country;
          }
          $("#locationId").val(strloc);
        }
      },
      (err) => {
        this.loader.stopLoading();
        this.toaster.error("Please try aftersome time!");
      },
    );
  }
  // ======================== 2 =============================

  // if (this.addJobForm.valid) {
  //   this.submitted = false;
  //   if (!this.id) {

  //   }
  //   else{

  //   }
  // }

  jobFormSubmit(): any {
    let jobFormData: any = this.addJobForm.value; 

    // if (this.addJobForm.valid) {
    //   this.submitted = false;

    if (this.btn == "Submit" || this.btn == "Re-Post") {
      console.log("this.locationlist.length", this.locationlist.length);

      if (this.locationlist.length == 0) {
        this.toaster.error("Please Select City and Country");
        return false;
      }

      if (this.slist.length == 0) {
        this.toaster.error("Please Select skill");
        return false;
      }

      // if (this.skillListdata.length == 0) {
      //   this.toaster.error("Please Select skill");
      //   return false;
      // }

      jobFormData.location = this.locationlist;
      jobFormData.skill = this.slist;
      // jobFormData.skill = this.skillListdata;

      if (
        !jobFormData.parmanant &&
        !jobFormData.contract &&
        !jobFormData.freelance
      ) {
        this.toaster.error(
          "Please Select Job type like parmanant , contract or freelance",
        );
        return false;
      }

      jobFormData.title = jobFormData.title.trim();
      if (jobFormData.ratecurrency == "in") {
        if (jobFormData.sltratetype == "Monthly") {
          jobFormData.ratemin =
            jobFormData.ratemin / this.totalhour / this.doller;
          jobFormData.ratemax =
            jobFormData.ratemax / this.totalhour / this.doller;
        }
        if (jobFormData.sltratetype == "Hourly") {
          jobFormData.ratemin = jobFormData.ratemin / this.doller;
          jobFormData.ratemax = jobFormData.ratemax / this.doller;
        }
      }

      if (jobFormData.ratecurrency == "doller") {
        if (jobFormData.sltratetype == "Monthly") {
          jobFormData.ratemin = jobFormData.ratemin / this.totalhour;
          jobFormData.ratemax = jobFormData.ratemax / this.totalhour;
        }
      }

      this.loader.startLoading();
      this.postJobService.createJob(jobFormData, "job").then(
        (res: any) => {
          if (res.data != "updateerror") {
            this.toaster.info(
              "Your Job/Project Successfully Posted ",
              "Under Review",
              { timeOut: 5000 },
            );
            this.createAddJobForm();
            this.locationlist = [];
            this.skilllist = [];
            this.isMinRatesModalShown = false;
            this.loader.stopLoading();
            this.router.navigate(["/jobs"]);
          } else {
            this.loader.stopLoading();
            this.toaster.error("Today limit exceed for posted of job", "Info", {
              timeOut: 6000,
            });
          }
        },
        (err) => {
          this.loader.stopLoading();
          this.toaster.error("Please try after sometime");
        },
      );
    } else {
      console.log("this.locationlist.length", this.locationlist.length);
      if (this.locationlist.length == 0) {
        this.toaster.error("Please Select City and Country");
        return false;
      }

      if (this.slist.length == 0) {
        this.toaster.error("Please Select skill");
        return false;
      }
      // if (this.skillListdata.length == 0) {
      //   this.toaster.error("Please Select skill");
      //   return false;
      // }

      this.addJobForm.value.location = this.locationlist;
      this.addJobForm.value.skill = this.slist;
      // this.addJobForm.value.skill = this.skillListdata;


      if (
        !jobFormData.parmanant &&
        !jobFormData.contract &&
        !jobFormData.freelance
      ) {
        this.toaster.error(
          "Please Select Job type, contract or freelance or fill-time",
        );
        return false;
      }
      jobFormData.title = jobFormData.title.trim();
      if (jobFormData.ratecurrency == "in") {
        if (jobFormData.sltratetype == "Monthly") {
          jobFormData.ratemin =
            jobFormData.ratemin / this.totalhour / this.doller;
          jobFormData.ratemax =
            jobFormData.ratemax / this.totalhour / this.doller;
        }
        if (jobFormData.sltratetype == "Hourly") {
          jobFormData.ratemin = jobFormData.ratemin / this.doller;
          jobFormData.ratemax = jobFormData.ratemax / this.doller;
        }
      }
      if (jobFormData.ratecurrency == "doller") {
        if (jobFormData.sltratetype == "Monthly") {
          jobFormData.ratemin = jobFormData.ratemin / this.totalhour;
          jobFormData.ratemax = jobFormData.ratemax / this.totalhour;
        }
      }

      this.loader.startLoading();
      this.postJobService.updateJob(this.jobId, jobFormData, "job").then(
        (res: any) => {
          this.loader.stopLoading();
          this.btn = "Submit";

          console.log("resData: ", res.data);

          if (res.data != "updateerror") {
            this.toaster.info(
              "Your Job/Project Successfully Updated",
              "Under Review",
              { timeOut: 5000 },
            );
            this.router.navigate(["/jobs"]);
          } else {
            this.toaster.error("Today limit exceed for update of job", "Info", {
              timeOut: 5000,
            });
          }
        },
        (err) => {
          this.loader.stopLoading();
          this.toaster.error("Please try after sometime");
        },
      );
    }
  // } else {
  //   this.submitted = true;
  // }

  }

  // ======================== 3 =============================
  getlogoInfo(id: any) {
    this.id = id;
    this.postJobService.FindById(id, "job").then(
      (res: any) => {
        this.jobData = res.data;
        this.addJobForm.patchValue({
          usertype: this.jobData.usertype,
          plantype: this.jobData.plantype,
          jobtype: this.jobData.jobtype,
          monthlytotaljob: this.jobData.monthlytotaljob,
          yearlytotaljob: this.jobData.yearlytotaljob,
          monthlyoffer: this.jobData.monthlyoffer,
          yearlyoffer: this.jobData.yearlyoffer,
        });
        this.btn = "Update";
      },
      (err) => {},
    );
  }
  // ======================== 4 =============================
  get_city(val: any) {
    if (val.length >= 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = { city_name: { $regex: "^" + val, $options: "i" } };
      this.commonSevice
        .getcity(JSON.stringify(filter))
        .then((res) => {
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
          console.log("cityName:==", this.citylist);
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
      this.locationlist = [];
    }
  }
  // ========================= 5 ============================
  setvalue(val: any, cname: any, id: any) {
    this.cls = "";
    this.locationlist = [];
    this.locationlist.push({ locationId: id, location: val, country: cname });
    $("#locationId").val(val + " " + cname);
  }
  // ========================== 6 ===========================
  locationclear() {
    $("#locationId").val("");
  }
  // ========================= 7 ============================
  removelocation(inx: any) {
    this.locationlist.splice(inx, 1);
  }
  // ======================== 8 =============================
  othercityslt() {
    this.locationlist = [];
    this.othercity = !this.othercity;
    this.cls = "";
    this.getcountry();
  }
  // ======================== 9 =============================
  otherslt(val: any, type: any) {
    this.locationlist = [];
    if (type == 1) {
      this.locationlist.push({
        locationId: null,
        location: $("#othercity").val(),
        country: $("#othercountry").val(),
      });
    } else {
      this.locationlist.push({
        locationId: null,
        location: $("#othercity").val(),
        country: $("#othercountry").val(),
      });
    }
    console.log(this.locationlist);
  }
  // ======================== 10 =============================
  getcountry() {
    this.countrylist = [];
    this.loader.startLoading();
    this.commonSevice
      .Find(JSON.stringify({}), "country")
      .then((res) => {
        this.countrylist = res.data;
        this.loader.stopLoading();
      })
      .catch((err) => {
        this.loader.stopLoading();
      });
  }
  // ====================== 11 ===============================
  // getJobType() {
  //   this.postJobService
  //     .getJobType({ status: true }, "jobtype").then((res: any) => {
  //       this.jobType = res.data;
  //       console.log("JobType: ", this.jobType[0].name);
  //     })
  //     .catch((err) => {});
  // }

  getJobType() {
    this.postJobService
      .getJobType({ status: true }, "jobtype")
      .then((res: any) => {
        if (res && res.data) {
          this.jobType = res.data;
          console.log("JobType: ", this.jobType);
        } else {
          console.error("No job type data received.");
        }
      })
      .catch((err) => {
        console.error("Error fetching job type:", err);
      });
  }

  // ====================== 12 ===============================
  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.commonSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
        console.log("skillListdata: ", this.skillListdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }
  // ====================== 13 ===============================
  windowrendor() {
    this.renderer.listen("window", "click", (e: Event): any => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach((element) => {
        if (element == "salectchanged") {
          change1 += 1;
        }
        if (element == "salectchanged2") {
          change2 += 1;
        }
      });
      if (change1 == 0) {
        this.setchangevalue();
      }
      if (change2 == 0) {
        this.setchangevalue2();
      }
      if (
        e.target == this.menu.nativeElement ||
        (<Element>e.target).className == "salectchanged"
      ) {
        return false;
      } else {
        this.setchangevalue();
      }
    });
  }
  // ===================== 14 ================================
  setchangevalue() {
    if (this.addJobForm.value.skillsearch) {
      if (this.slist.length < 15) {
        // if (this.skillListdata.length < 15) {
        this.slist.push(this.addJobForm.value.skillsearch.trim());
        // this.skillListdata.push(this.addJobForm.value.skillsearch.trim());
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
      } else {
        this.toaster.info("Select only 15 skill");
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
      }
    }
  }
  // ======================= 15 ==============================
  setchangevalue2() {
    if (this.addJobForm.value.skillsearch) {
      if (this.slist.length < 15) {
        this.slist.push(this.addJobForm.value.skillsearch.trim());
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
      } else {
        this.toaster.info("Select only 15 skill");
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
      }
    }
  }
  // ====================== 16 ===============================
  getsltSkills(val: any) {
    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls1 = "displayblock";
      this.templist = this.skillListdata.filter(function (el: any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = "";
      }
    } else {
      if (this.slist.length < 15) {
        this.slist.push(val.replace(",", ""));
      // if (this.skillListdata.length < 15) {
      //   this.skillListdata.push(val.replace(",", ""));
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
      } else {
        this.cls1 = "";
        this.addJobForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
  }
  // ====================== 17 ===============================
  setskillvalue(item: any) {
    if (this.slist.length < 15) {
      this.slist.push(item.trim());
    // if (this.skillListdata.length < 15) {
    //   this.skillListdata.push(item.trim());
    this.cls1 = "";
      this.addJobForm.get("skillsearch").setValue("");
    } else {
      this.toaster.info("Select only 15 skill");
      this.cls1 = "";
      this.addJobForm.get("skillsearch").setValue("");
    }
  }
  // ======================= 18 ==============================
  removeItem(inx: any) {
    this.slist.splice(inx, 1);
  }
  // ==================== 19 =================================
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // ====================== 20 ===============================
  window_load() {
    this.locationlist = [];
    this.modules = {
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ size: ["small", "normal", "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ],
    };
    this.info["workfrom"] = `<div ><b>Offsite</b>: Remote  </div>
                           <div><b>Onsite</b>: My office  </div>
                           <div><b>Anywhere</b>: My office or remote </div>`;

    this.config = {
      uiColor: "#f2f2f2",
      toolbarGroups: [
        { name: "links" },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align"] },
        { name: "styles" },
        { name: "colors" },
      ],
      resize_enabled: false,
      height: 200,
    };
  }
  // ====================== 21 ===============================
  getcountsummary(val: any): any {
    setTimeout((): any => {
      const summary = this.addJobForm.value.jobDescription;
      console.log(this.addJobForm.value.jobDescription, val);
      this.cntchr = 7000 - (summary.length - 1);
      if (summary.length > 7001) {
        this.addJobForm.get("jobDescription").setValue(summary.slice(0, 7000));
        return false;
      }
    });
  }
  // ======================= 22 ==============================
  ratechange(val: any, minstt: any, type: any) {
    if (val) {
      if (type == "in" && minstt == "min") {
        this.inrratemin = Math.ceil(val / 160);
        this.addJobForm
          .get("ratemin")
          .setValue((this.inrratemin / 75).toFixed(2));
      }
      if (type == "in" && minstt == "max") {
        this.inrratemax = Math.ceil(val / 160);
        this.addJobForm
          .get("ratemax")
          .setValue((this.inrratemax / 75).toFixed(2));
      }
    } else {
      if (type == "in" && minstt == "min") {
        this.addJobForm.get("ratemin").setValue("");
      }
      if (type == "in" && minstt == "max") {
        this.addJobForm.get("ratemax").setValue("");
      }
    }
  }
  // ===================== 23 ================================
  getcurrency() {
    this.postJobService.getcurrency().then(
      (res1: any) => {
        this.currency = res1.currency;
        this.addJobForm.get("ratecurrency").setValue(this.currency);
      },
      (err) => {},
    );
  }
  // ======================= 24 ==============================
  changecurrency(val: any) {
    this.currency = val;
    if (val == "in") {
      this.inrratemin = 0;
      this.inrratemax = 0;
      this.addJobForm.get("ratemin").setValue("");
      this.addJobForm.get("ratemax").setValue("");
      $("#inrmaxrate").val("");
      $("#inrminrate").val("");
    }
    if (val == "doller") {
      this.inrratemin = 0;
      this.inrratemax = 0;
      $("#inrmaxrate").val("");
      $("#inrminrate").val("");
      this.addJobForm.get("ratemin").setValue("");
      this.addJobForm.get("ratemax").setValue("");
    }
  }
  // ======================= 25 ==============================
  changerate(val: any) {
    this.ratetype = val;
  }
  // ======================== 26 =============================
  checkexp(val: any) {
    const experiencemin = this.addJobForm.value.experiencemin;
    const experiencemax = this.addJobForm.value.experiencemax;
    if (val == "min") {
      if (!experiencemin) {
        this.expmin = 0;
        this.toaster.error("Please Enter Experience Min of Job");
        $("#experiencemin").focus();
      } else {
        this.expmin = experiencemin;
        this.show_minRatesModal("min");
      }
    }
    if (val == "max") {
      if (!experiencemax) {
        this.expmax = 0;
        this.toaster.error("Please Enter Experience Max of Job");
        $("#experiencemax").focus();
      } else {
        this.expmax = experiencemax;
        this.show_maxRatesModal("max");
      }
    }
  }
  // ======================== 27 =============================
  checkRate(val: any, tval: any): any {
    const num = parseInt(tval);
    if (val == "min") {
      const expmin = this.addJobForm.value.experiencemin;
      if (tval == "") {
        this.inrratemin = 0;
      }
      if (this.currency == "in") {
        if (this.ratetype == "Hourly") {
          if (parseInt(this.addJobForm.value.ratemin) > 10000) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please Enter Valid Min Rate, less than 10,000");
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(
              this.addJobForm.value.ratemax && this.addJobForm.value.ratemax,
            )
          ) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please enter rate less than max rate");
            return false;
          }
          if (expmin > 0) {
            this.showtestmin = "";
            for (let i = 0; i < this.per.length; i++) {
              if (!this.showtestmin) {
                if (num > this.per[i].max * expmin * this.doller) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
                if (
                  num >= this.per[i].min * expmin * this.doller &&
                  num <= this.per[i].max * expmin * this.doller
                ) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmin) {
              this.showtestmin = "3% chance of shortlisting";
            }
          }
        }
        if (this.ratetype == "Monthly") {
          if (parseInt(this.addJobForm.value.ratemin) > 1680000) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error(
              "Please Enter Valid Min Rate, less than 16,80000",
            );
            this.showtestmin = "";
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(
              this.addJobForm.value.ratemax && this.addJobForm.value.ratemax,
            )
          ) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please enter rate less than max rate");
            this.showtestmin = "";
            return false;
          }
          //if (num >= this.per[i].min * expmax * this.doller && num <= this.per[i].max * expmax * this.doller) {
          this.showtestmin = "";
          if (expmin > 0) {
            for (let i = 0; i < this.per.length; i++) {
              //console.log(num + ">=" + this.per[i].min * expmin * this.totalhour * this.doller + "&&" + num + "<=" + this.per[i].max * expmin * this.totalhour * this.doller);
              console.log();
              if (this.showtestmin == "") {
                if (
                  num >
                  this.per[i].max * expmin * this.totalhour * this.doller
                ) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  //alert("shiv");
                  break;
                }
                if (
                  num >=
                    this.per[i].min * expmin * this.totalhour * this.doller &&
                  num <= this.per[i].max * expmin * this.totalhour * this.doller
                ) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (this.showtestmin == "") {
              this.showtestmin = "3% chance of getting resource";
            }
          }
        }
      }
      if (this.currency == "doller") {
        if (this.ratetype == "Hourly") {
          //console.log("Hourly")
          if (parseInt(this.addJobForm.value.ratemin) >= 500) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please Enter Valid Min Rate");
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(
              this.addJobForm.value.ratemax && this.addJobForm.value.ratemax,
            )
          ) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please enter rate less than max rate");
            return false;
          }
          if (expmin > 0) {
            console.log("expmin");
            this.showtestmin = "";
            for (let i = 0; i < this.per.length; i++) {
              if (!this.showtestmin) {
                if (num > this.per[i].max * expmin) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }

                if (
                  num >= this.per[i].min * expmin &&
                  num <= this.per[i].max * expmin
                ) {
                  console.log(
                    num +
                      ">=" +
                      this.per[i].min * expmin +
                      "&&" +
                      num +
                      "<=" +
                      this.per[i].max * expmin,
                  );
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmin) {
              this.showtestmin = "3% chance of shortlisting";
            }
            console.log(this.showtestmin);
          }
        }
        if (this.ratetype == "Monthly") {
          if (parseInt(this.addJobForm.value.ratemin) >= 50000) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please Enter Valid Min Rate");
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(
              this.addJobForm.value.ratemax && this.addJobForm.value.ratemax,
            )
          ) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please enter rate less than max rate");
            return false;
          }
          this.showtestmin = "";
          if (expmin > 0) {
            for (let i = 0; i < this.per.length; i++) {
              console.log(
                num +
                  ">=" +
                  this.per[i].min * expmin * this.totalhour +
                  "&&" +
                  num +
                  "<=" +
                  this.per[i].max * expmin * this.totalhour,
              );
              if (!this.showtestmin) {
                if (num > this.per[i].max * expmin * this.totalhour) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }

                if (
                  num >= this.per[i].min * expmin * this.totalhour &&
                  num <= this.per[i].max * expmin * this.totalhour
                ) {
                  this.showtestmin =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmin) {
              this.showtestmin = "3% chance of getting resource";
            }
          }
        }
      }
    }
    if (val == "max") {
      const expmax = this.addJobForm.value.experiencemax;
      if (tval == "") {
        this.inrratemax = 0;
      }
      if (this.currency == "in") {
        if (this.ratetype == "Hourly") {
          if (parseInt(this.addJobForm.value.ratemax) > 10000) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemax = 0;
            this.toaster.error(
              "Please Enter Valid Max Rate , Not more than 6000",
            );
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(this.addJobForm.value.ratemax)
          ) {
            this.addJobForm.get("ratemax").setValue("");
            this.inrratemax = 0;
            this.toaster.error("Please enter rate more than min rate");
            return false;
          }
          if (expmax > 0) {
            this.showtestmax = "";
            for (let i = 0; i < this.per.length; i++) {
              if (!this.showtestmax) {
                if (num > this.per[i].max * expmax * this.doller) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
                if (
                  num >= this.per[i].min * expmax * this.doller &&
                  num <= this.per[i].max * expmax * this.doller
                ) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmax) {
              this.showtestmax = "3% chance of shortlisting";
            }
          }
        }
        if (this.ratetype == "Monthly") {
          if (parseInt(this.addJobForm.value.ratemax) > 1680000) {
            this.addJobForm.get("ratemax").setValue("");
            this.inrratemax = 0;
            this.toaster.error(
              "Please Enter Valid Max Rate , Not more than 16,80000",
            );
            this.showtestmax = "";
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(this.addJobForm.value.ratemax)
          ) {
            this.addJobForm.get("ratemax").setValue("");
            this.inrratemax = 0;
            this.toaster.error("Please enter rate more than min rate");
            this.showtestmax = "";
            return false;
          }
          this.showtestmax = "";
          if (expmax > 0) {
            for (let i = 0; i < this.per.length; i++) {
              console.log("i", i);
              //console.log(num + ">=" + this.per[i].min * expmax * this.totalhour * this.doller + "&&" + num + "<=" + this.per[i].max * expmax * this.totalhour * this.doller);
              if (!this.showtestmax) {
                if (
                  num >
                  this.per[i].max * expmax * this.totalhour * this.doller
                ) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
                console.log(
                  num +
                    ">=" +
                    this.per[i].min * expmax * this.totalhour * this.doller +
                    "&&" +
                    num +
                    "<=" +
                    this.per[i].max * expmax * this.totalhour * this.doller,
                );
                if (
                  num >=
                    this.per[i].min * expmax * this.totalhour * this.doller &&
                  num <= this.per[i].max * expmax * this.totalhour * this.doller
                ) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmax) {
              this.showtestmax = "3% chance of getting resource";
            }
          }
        }
      }
      if (this.currency == "doller") {
        if (this.ratetype == "Hourly") {
          if (parseInt(this.addJobForm.value.ratemax) > 500) {
            this.addJobForm.get("ratemax").setValue("");
            this.inrratemax = 0;
            this.toaster.error(
              "Please Enter Valid Max Rate , Not more than $500",
            );
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(this.addJobForm.value.ratemax)
          ) {
            this.addJobForm.get("ratemax").setValue("");
            this.inrratemax = 0;
            this.toaster.error("Please enter rate more than min rate");
            return false;
          }
          if (expmax > 0) {
            console.log("expmin");
            this.showtestmax = "";
            for (let i = 0; i < this.per.length; i++) {
              if (!this.showtestmax) {
                if (num > this.per[i].max * expmax) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
                if (
                  num >= this.per[i].min * expmax &&
                  num <= this.per[i].max * expmax
                ) {
                  console.log(
                    num +
                      ">=" +
                      this.per[i].min * expmax +
                      "&&" +
                      num +
                      "<=" +
                      this.per[i].max * expmax,
                  );
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmax) {
              this.showtestmax = "3% chance of shortlisting";
            }
            console.log(this.showtestmax);
          }
        }
        if (this.ratetype == "Monthly") {
          if (parseInt(this.addJobForm.value.ratemin) >= 50000) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please Enter Valid Min Rate");
            return false;
          }
          if (
            parseInt(this.addJobForm.value.ratemin) >=
            parseInt(
              this.addJobForm.value.ratemax && this.addJobForm.value.ratemax,
            )
          ) {
            this.addJobForm.get("ratemin").setValue("");
            this.inrratemin = 0;
            this.toaster.error("Please enter rate less than max rate");
            return false;
          }
          this.showtestmax = "";
          if (expmax > 0) {
            for (let i = 0; i < this.per.length; i++) {
              console.log(
                num +
                  ">=" +
                  this.per[i].min * expmax * this.totalhour +
                  "&&" +
                  num +
                  "<=" +
                  this.per[i].max * expmax * this.totalhour,
              );
              if (!this.showtestmax) {
                if (num > this.per[i].max * expmax * this.totalhour) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
                if (
                  num >= this.per[i].min * expmax * this.totalhour &&
                  num <= this.per[i].max * expmax * this.totalhour
                ) {
                  this.showtestmax =
                    this.per[i].per + "% chance of getting resource";
                  break;
                }
              }
            }
            if (!this.showtestmax) {
              this.showtestmax = "3% chance of getting resource";
            }
          }
        }
      }
    }
  }
  // ===================== 28 =============================
  setrate(rate: any, vtype: any) {
    if (vtype == "min") {
      this.addJobForm.get("ratemin").setValue(rate);
      this.hide_minRatesModal("min");
      this.checkRate("min", rate);
    } else if (vtype == "max") {
      this.addJobForm.get("ratemax").setValue(rate);
      this.hide_maxRatesModal("max");
      this.checkRate("max", rate);
    }
  }
  // =============== 29 =====================

  // jobtypeslect(val: any) {
  //   if (val == 1) {
  //     this.addJobForm.get("contract").setValue(true);
  //     this.addJobForm.get("parmanant").setValue(false);
  //   }
  //   if (val == 2) {
  //     this.addJobForm.get("freelance").setValue(true);
  //     this.addJobForm.get("parmanant").setValue(false);
  //   }
  // } 
  jobtypeslect(val: any) {
    if (val == 1) {
      this.addJobForm.get("parmanant").setValue(false);
    }
    if (val == 2) {
      this.addJobForm.get("parmanant").setValue(false);
    }
  }
  

  // =========================================
}


// ============== testingOFAddPost_ Form =============
// payload data
// {
//   "title":"",
//   "location":[{"locationId":"5e42ea1eb4866b152024ed63","location":"Ghaziabad","country":"India"}],
//   "noofResources":1,
//   "workFrom":"",
//   "tentativeStartDate":"2024-09-25T09:28:12.000Z",
//   "modeOfContact":"",
//   "duration":"",
//   "ratemin":7,
//   "ratemax":20,
//   "ratecurrency":"in",
//   "jobtype":"Apparel & Fashion",
//   "parmanant":false,
//   "contract":true,
//   "freelance":false,
//   "ratetype":"Hourly",
//   "experiencemin":2,
//   "experiencemax":5,
//   "jobDescription":"",
//   "sltratetype":"Monthly",
//   "desdoc":"",
//   "desdocpath":"",
//   "dochtml":"",
//   "skillsearch":"",
//   "skill":["Node.JS"]
// }