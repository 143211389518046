import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { PostJobService } from "../../../services/post-job.service";


@Component({
  selector: 'app-hire-expert',
  // standalone: true,
  // imports: [],
  templateUrl: './hire-expert.component.html',
  styleUrl: './hire-expert.component.scss'
})
export class HireExpertComponent implements OnInit {
  hireTalentStatus = true;
  findProjectsStatus = false;
  search: any = {};
  citylist: any = [];
  resources: any = [];
  skillListdata: any = [];

  constructor(
    private commonSevice: CommonService,
    private postJobService: PostJobService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getSkillList();
  }

  hireTalentSearch() {
    this.hireTalentStatus = true;
    this.findProjectsStatus = false;
  }

  findProjectsSearch() {
    this.findProjectsStatus = true;
    this.hireTalentStatus = false;
  }

  homeSearch() {
    console.log(this.search);
    this.router.navigateByUrl(
      "/hire-bench-resources?page=1&limit=15&skills=java&locations=noida",
    );
  }

  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.commonSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
        console.log("skillListdata: ", this.skillListdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  get_city(ev: any) {
    // console.log("ev ", ev.target.value);
    let val = ev.target.value;
    if (val.length >= 2) {
      this.citylist = [];
      // this.loderstt = true;
      var filter = { city_name: { $regex: "^" + val, $options: "i" } };
      this.commonSevice
        .getcity(JSON.stringify(filter))
        .then((res) => {
          // this.loderstt = false;
          // this.cls = "displayblock";
          this.citylist = res.data;
          console.log("cityName:==", this.citylist);
        })
        .catch((err) => {
          // this.loderstt = false;
        });
    } else {
      // this.cls = "";
      // this.locationlist = [];
    }
  }
}



