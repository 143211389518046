import { Component, OnInit } from "@angular/core";
import { PostJobService } from "../../../services/post-job.service";
import { AuthService } from "../../../services/auth.service";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  jobs: any = [];
  pages: any = 0;
  currentPage: any = 1;
  dashboardData: any = {
    myjob: 0,
    appliedcandidate: 0,
    mycandidate: 0,
    jobapplied: 0,
  };

  totalCounts: any;

  constructor(
    private postJobService: PostJobService,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.userService.getDashboard().subscribe((resp) => {
      this.dashboardData = resp.data;
      console.log("resp ", this.dashboardData);
    });

    this.loadPage(this.currentPage); 
  }

  loadPage(page: any) {
    this.postJobService
      .getJobs({
        page: page,
        totalpage: 0,
        limit: 5,
        total: 0,
        jobId: "",
      })
      .then((resp) => {
        this.totalCounts = resp.data.total;
        this.jobs = resp.data.data;
        this.pages = Math.ceil(resp.data.total / 5);
        // console.log("resp ", resp.data.data);
      });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.loadPage(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage = this.currentPage + 1;
      this.loadPage(this.currentPage);
    }
  }
}
