import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/common.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AddBenchService } from "src/app/services/add-bench.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ActivatedRoute, Router } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-add-bench",
  templateUrl: "./add-bench.component.html",
  styleUrl: "./add-bench.component.scss",
})
export class AddBenchComponent implements OnInit {
  // @ViewChild('dropdown', { static: false }) dropdown!: ElementRef;
  @ViewChild("menu") menu!: ElementRef;
  @ViewChild("dropmenupg") dropmenupg!: ElementRef;
  @ViewChild("myInput") myInputVariable!: ElementRef;

  public Editor = ClassicEditor;

  btn: any = "Add";
  page = "Resource";

  addBenchForm: any;
  submitted: boolean = false;

  id: any;
  candidateId: any = "";
  fileupload: any;
  candidateData: any;

  isModalShown = false;

  citylist: any = [];
  cls: any = "";
  loderstt = false;
  loderstt2 = false;
  locationlist: any = [];
  othercity = false;
  cls1: any = "";
  cls2: any = "";
  ugListdata: any = [];
  pgListdata: any = [];
  ugListTemp: any = [];
  pgListTemp: any = [];
  dropug = false;
  droppg = false;
  ugotherstt = false;
  pgotherstt = false;
  countrylist: any = [];
  sltratetype: any = "Monthly";
  totalhour = 168;
  doller = 75;
  repost = false;
  currency: any = "";
  jobType: any = [];
  slist: any = [];
  skillListdata: any = [];
  templist: any = [];
  showtest: any = "";
  cntchr = 5000;
  Uploadstt: any = "";
  minerror = false;
  exp = 0;
  per: any = [
    { min: 1.5, max: 2, per: 87 },
    { min: 2, max: 2.5, per: 71 },
    { min: 2.5, max: 3, per: 52 },
    { min: 3, max: 3.5, per: 37 },
    { min: 3.5, max: 4, per: 21 },
    { min: 4, max: 5, per: 5 },
  ];
  loaderstt = false;
  progress = 0;
  showstt = false;
  loaderimg = false;
  modules: any = "";
  info: any = {};
  configck: any = {};

  constructor(
    private formBuild: FormBuilder,
    private _fb: FormBuilder,
    private commonSevice: CommonService,
    private addBenchSevice: AddBenchService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private loader: LoadingScreenService,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.candidateId = params["candidateId"];
      if (params["repost"] == "true") {
        this.repost = true;
      }
      if (this.candidateId) {
        this.loader.stopLoading();
        this.getcandidateInfo();
      } else {
        this.getcurrency();
      }
    });
  }

  ngOnInit(): void {
    this.createBenchForm();
    this.windowrendor();
    this.window_load();
    this.getJobType();
    this.getugList();
    this.getpgList();
    this.getSkillList();
  }

  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.isModalShown = false;
  }
  onHidden(): void {
    this.isModalShown = false;
  }

  // ngAfterViewInit(): void {
  //   this.renderer.listen('document', 'click', (event: Event) => {
  //     const target = event.target as HTMLElement;
  //     if (this.dropdown && !this.dropdown.nativeElement.contains(target)) {
  //       this.cls = '';
  //     }
  //   });
  // }

  // ngOnDestroy(): void {
  //   this.renderer.listen('document', 'click', () => {});
  // }

  // =================== 1 =================
  createBenchForm() {
    this.addBenchForm = this.formBuild.group({
      fname: ["", [Validators.required, Validators.maxLength(50)]],
      lname: ["", [Validators.required, Validators.maxLength(50)]],
      gender: ["", Validators.required],
      designation: ["", [Validators.required, Validators.maxLength(100)]],
      // "jobtype": ['', Validators.required],
      jobtype: ["na"],
      currentlocation: [""],
      locationId: [""],
      currentcountry: [""],
      onsiteAvailability: [""],
      Resumename: [""],
      Resumeurl: ["", Validators.required],
      dochtml: [""],
      resumedescrption: [""],
      rate: [""],
      ratetype: ["Hourly"],
      sltratetype: ["Monthly"],
      ratecurrency: ["in", Validators.required],
      ugType: [""],
      pgType: [""],
      experience: [""],
      Availability: ["", Validators.required],
      skillsearch: [""],
      description: ["", Validators.required],
      otherquilification: [""],
      projectList: this._fb.array([]),
    });
  }

  get formCreateBench() {
    return this.addBenchForm.controls;
  }
  // =================== 2 =================
  get sellingPrice() {
    return this.addBenchForm.get("projectList") as FormArray;
  }
  // =================== 3 =================
  addmoreproject() {
    const listval = this.addBenchForm.value.projectList;
    let contstt = 0;
    listval.forEach((ele: any) => {
      if (
        ele.title.trim() == "" ||
        ele.duration == "" ||
        ele.role.trim() == "" ||
        ele.description.trim() == ""
      ) {
        contstt = contstt + 1;
      }
    });
    if (contstt > 0) {
      this.toaster.error(
        "Please enter title ,duration ,role and Project Description",
      );
      return false;
    }
    this.sellingPrice.push(
      this._fb.group({
        title: new FormControl("", Validators.maxLength(100)),
        duration: new FormControl(""),
        role: new FormControl("", Validators.maxLength(4000)),
        description: new FormControl("", Validators.maxLength(4000)),
      }),
    );
    return true;
  }
  // =================== 4 =================
  deleteProject(index: any) {
    this.sellingPrice.removeAt(index);
  }
  // =================== 5 =================
  getcandidateInfo() {
    this.loader.startLoading();
    this.addBenchSevice.FindbyId(this.candidateId, "candidate").then(
      (res: any) => {
        this.btn = "Update";
        if (this.repost) {
          this.btn = "Re-Submit";
        }
        this.addBenchForm.patchValue({
          name: res.data.name,
          fname: res.data.fname,
          lname: res.data.lname,
          email: res.data.email,
          gender: res.data.gender,
          mobile: res.data.mobile,
          designation: res.data.designation,
          joiningdate: res.data.joiningdate,
          currentlocation: res.data.currentlocation,
          currentcountry: res.data.currentcountry,
          locationId: res.data.locationId,
          onsiteAvailability: res.data.onsiteAvailability,
          resumedescrption: res.data.resumedescrption,
          rate: res.data.rate,
          sltratetype: res.data.sltratetype,
          ugType: res.data.ugType.length > 0 ? res.data.ugType[0].ug : "",
          pgType: res.data.pgType.length > 0 ? res.data.pgType[0].pg : "",
          // jobtype: res.data.jobtype,
          jobtype: res.data.jobtype || "na",
          ratetype: res.data.ratetype,
          experience: res.data.experience,
          Availability: res.data.Availability,
          description: res.data.description,
          Resumename: res.data.Resumename,
          Resumeurl: res.data.Resumeurl,
          dochtml: res.data.dochtml,
          ratecurrency: res.data.ratecurrency,
          monthlyrate: res.data.monthlyrate,
        });
        if (res.data.currentlocation) {
          $("#citycountry").val(
            res.data.currentlocation + ", " + res.data.currentcountry,
          );
        }
        this.slist = res.data.skill;
        this.locationlist = res.data.location;
        let projectList = res.data.projectList;
        this.currency = res.data.ratecurrency;
        this.sltratetype = res.data.sltratetype;
        if (res.data.sltratetype == "Monthly") {
          if (res.data.ratecurrency == "in") {
            this.addBenchForm
              .get("rate")
              .setValue(
                Math.floor(res.data.rate * this.totalhour * this.doller),
              );
          }
          if (res.data.ratecurrency == "doller") {
            this.addBenchForm
              .get("rate")
              .setValue(Math.floor(res.data.rate * this.totalhour));
          }
        } else {
          if (res.data.ratecurrency == "in") {
            this.addBenchForm
              .get("rate")
              .setValue(Math.floor(res.data.rate * this.doller));
          }
        }
        //if (res.data.ratecurrency=="in") {
        //   setTimeout(() => {
        //     this.inrrate = res.data.inrrate;
        //   }, 400);
        //} else {
        //   this.inrrate = Math.ceil(res.data.rate * this.doller * 168);
        //}
        this.sellingPrice.removeAt(0);
        for (let i = 0; i < projectList.length; i++) {
          this.addBenchForm.controls["projectList"].push(
            this._fb.group({
              title: new FormControl(projectList[i].title),
              duration: new FormControl(projectList[i].duration),
              role: new FormControl(projectList[i].role),
              description: new FormControl(projectList[i].description),
            }),
          );
        }
        if (res.data.ugType.length > 0) {
          this.addBenchForm.get("ugType").setValue(res.data.ugType[0].ug);
        }
        if (res.data.pgType.length > 0) {
          this.addBenchForm.get("pgType").setValue(res.data.pgType[0].pg);
        }
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
        this.toaster.error("Please try aftersome time!");
      },
    );
  }
  // =================== 6 =================
  candidateFormSubmit(): any {
    let data: any = this.addBenchForm.value;

    // if (this.btn == "Add" || this.btn == "Re-Submit") {
    // } else {
    // }

    if (this.addBenchForm.valid) {
      this.submitted = false;
      // if (!this.candidateId) {
      if (this.btn == "Add" || this.btn == "Re-Submit") {
        data.skill = this.slist;
        data.name = data.fname + " " + data.lname;
        const listval = data.projectList;
        if (this.slist.length == 0) {
          this.toaster.error("Please Select skill");
          return false;
        }
        if (listval.length > 1) {
          let contstt = 0;
          listval.forEach((ele: any) => {
            if (
              ele.title.trim() == "" ||
              ele.duration == "" ||
              ele.role.trim() == "" ||
              ele.description.trim() == ""
            ) {
              contstt = contstt + 1;
            }
          });
          if (contstt > 0) {
            this.toaster.error(
              "Please enter title ,duration ,role and Project Description",
            );
            return false;
          }
        }
        if (this.othercity == false) {
          if (!data.currentlocation) {
            // this.toaster.error('Please select city');
            this.toaster.error("Please select Location");
            return false;
          }
        } else {
          if (!data.currentlocation) {
            this.toaster.error("Please Enter city");
            return false;
          }
          if (!data.currentcountry) {
            this.toaster.error("Please select Country");
            return false;
          }
        }
        data.ugType = [{ ug: data.ugType }];
        data.pgType = [{ pg: data.pgType }];

        if (data.ratecurrency == "in") {
          if (data.sltratetype == "Monthly") {
            data.rate = data.rate / this.totalhour / this.doller;
          }
          if (data.sltratetype == "Hourly") {
            data.rate = data.rate / this.doller;
          }
        }
        if (data.ratecurrency == "doller") {
          if (data.sltratetype == "Monthly") {
            data.rate = data.rate / this.totalhour;
          }
        }
        //return false;

        this.loader.startLoading();
        this.addBenchSevice.createBench(data, "candidate").then(
          (res: any) => {
            this.loader.stopLoading();
            if (res.data != "updateerror") {
              this.toaster.success("Candidate Added Successfully");
              this.slist = [];
              this.createBenchForm();
              this.resetProjectlist();
              window.scroll(0, 0);
              this.router.navigate(["/bench"]);
            } else {
              this.toaster.error(
                "Today limit exceed for added of candidate",
                "Info",
                { timeOut: 5000 },
              );
            }

            this.isModalShown = false;
          },
          (err) => {
            this.loader.stopLoading();
            this.toaster.error("Something went wrong");
          },
        );
      } else {
        this.loader.startLoading();

        data.skill = this.slist;
        data.name = data.fname + " " + data.lname;
        if (this.slist.length == 0) {
          this.toaster.error("Please Select skill");
          return false;
        }
        const listval = data.projectList;
        if (listval.length > 1) {
          let contstt = 0;
          listval.forEach((ele: any) => {
            if (
              ele.title.trim() == "" ||
              ele.duration == "" ||
              ele.role.trim() == "" ||
              ele.description.trim() == ""
            ) {
              contstt = contstt + 1;
            }
          });
          if (contstt > 0) {
            this.toaster.error(
              "Please enter title ,duration ,role and Project Description",
            );
            return false;
          }
        }
        if (this.othercity == false) {
          if (!data.currentlocation) {
            this.toaster.error("Please select city");
            return false;
          }
        } else {
          if (!data.currentlocation) {
            this.toaster.error("Please Enter city");
            return false;
          }
          if (!data.currentcountry) {
            this.toaster.error("Please select Country");
            return false;
          }
        }
        data.ugType = [{ ug: data.ugType }];
        data.pgType = [{ pg: data.pgType }];
        data.adminverified = 0;
        if (data.ratecurrency == "in") {
          if (data.sltratetype == "Monthly") {
            data.rate = data.rate / this.totalhour / this.doller;
          }
          if (data.sltratetype == "Hourly") {
            data.rate = data.rate / this.doller;
          }
        }
        if (data.ratecurrency == "doller") {
          if (data.sltratetype == "Monthly") {
            data.rate = data.rate / this.totalhour;
          }
        }

        // ======== EDIT ======================================
        this.loader.startLoading();
        this.addBenchSevice
          .updateBench(this.candidateId, data, "candidate")
          .then(
            (res: any) => {
              this.loader.stopLoading();
              if (res.data != "updateerror") {
                this.router.navigate(["/bench"]);
                this.btn = "Add";
                this.toaster.info(
                  "Candidate Updated Successfully",
                  "Under Review",
                  { timeOut: 6000 },
                );
              } else {
                this.toaster.error(
                  "Today limit exceed for update of candidate",
                  "Info",
                  { timeOut: 6000 },
                );
              }
            },
            (err) => {
              this.loader.stopLoading();
              this.toaster.error("Something went wrong");
            },
          );
      }
    } else {
      this.submitted = true;
    }
  }
  // =================== 7 =================
  getlogoInfo(id: any) {
    this.id = id;
    this.addBenchSevice.FindbyId(id, "candidate").then(
      (res: any) => {
        this.candidateData = res.data;
        this.addBenchForm.patchValue({
          usertype: this.candidateData.usertype,
          plantype: this.candidateData.plantype,
          candidatetype: this.candidateData.candidatetype,
          monthlytotalcandidate: this.candidateData.monthlytotalcandidate,
          yearlytotalcandidate: this.candidateData.yearlytotalcandidate,
          monthlyoffer: this.candidateData.monthlyoffer,
          yearlyoffer: this.candidateData.yearlyoffer,
        });
        this.btn = "Update";
      },
      (err) => {},
    );
  }
  // =================== 8 =================
  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
      if (this.fileupload.size < 5000000) {
        let arr = this.fileupload.name.split(".");
        if (
          arr[arr.length - 1] == "pdf" ||
          arr[arr.length - 1] == "docx" ||
          arr[arr.length - 1] == "doc"
        ) {
          this.onSubmit();
        } else {
          this.toaster.error("Please upload Only PDF and Docx File");
        }
      } else {
        this.toaster.error("Please upload less than 500KB");
      }
    }
  }
  // =================== 9 =================
  onSubmit() {
    let fd = new FormData();
    fd.append("image", this.fileupload);
    this.loaderimg = true;
    this.progress = 0;
    this.Uploadstt = "Uploading";
    this.addBenchSevice.fileupload(fd, "candidatedoc").subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.showstt = true;
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          this.loaderimg = false;
          const res = event.body;
          if (res.confirmation == "success") {
            this.Uploadstt = "Uploaded";
            this.addBenchForm.get("Resumename").setValue(res.image);
            this.addBenchForm.get("Resumeurl").setValue(res.imageurl);
            this.addBenchForm.get("dochtml").setValue(res.dochtml);
            if (res.dochtml && !this.addBenchForm.value.description) {
              this.addBenchForm.get("description").setValue(res.dochtml);
            }
            this.myInputVariable.nativeElement.value = "";
          } else if (res.confirmation == "exceed") {
            this.Uploadstt = "";
            this.showstt = false;
            this.progress = 0;
            this.toaster.error("Please upload resume below 5MB", "Error", {
              timeOut: 5000,
              positionClass: "toast-bottom-left",
            });
            this.myInputVariable.nativeElement.value = "";
          } else if (res.confirmation == "filetype") {
            this.Uploadstt = "";
            this.showstt = false;
            this.progress = 0;
            this.toaster.error(
              "Please upload only PDF, DOC and DOCX files",
              "Error",
              { timeOut: 5000, positionClass: "toast-bottom-left" },
            );
            this.myInputVariable.nativeElement.value = "";
          } else {
            this.Uploadstt = "";
            this.showstt = false;
            this.progress = 0;
            this.toaster.error(
              "Please upload only PDF, DOC and DOCX files",
              "Error",
              { timeOut: 5000, positionClass: "toast-bottom-left" },
            );
            this.myInputVariable.nativeElement.value = "";
          }
        }
      },
      (err) => {
        this.Uploadstt = "";
        this.loaderimg = false;
        this.progress = 0;
        this.showstt = false;
        this.myInputVariable.nativeElement.value = "";
      },
    );
  }

  // =================== 10 =================
  getugList() {
    this.addBenchSevice.servicecommon({ status: true }, "ug").then(
      (res: any) => {
        this.ugListdata = res.data;
        this.ugListTemp = this.ugListdata;
      },
      (err) => {
        console.log(err);
      },
    );
  }
  // =================== 11 =================
  getpgList() {
    this.addBenchSevice.servicecommon({ status: true }, "pg").then(
      (res: any) => {
        this.pgListdata = res.data;
        this.pgListTemp = this.pgListdata;
      },
      (err) => {
        console.log(err);
      },
    );
  }
  // =================== 12 =================
  filterFunction(valType: any, text: any) {
    if (valType == "ug") {
      if (text) {
        this.ugListTemp = this.ugListdata.filter(function (el: any) {
          return el.name.toLowerCase().indexOf(text) != -1;
        });
      } else {
        this.ugListTemp = this.ugListdata;
      }
    } else {
      if (text) {
        this.pgListTemp = this.pgListdata.filter(function (el: any) {
          return el.name.toLowerCase().indexOf(text) != -1;
        });
      } else {
        this.pgListTemp = this.pgListdata;
      }
    }
  }
  // =================== 13 =================
  opendropdown(valtype: any) {
    if (valtype == "ug") {
      this.dropug = true;
    } else {
      this.droppg = true;
    }
  }
  // =================== 14 =================
  otherselect(val: any, valtype: any) {
    if (valtype == "ug") {
      if (val != "Other") {
        this.addBenchForm.get("ugType").setValue(val);
      } else {
        this.ugotherstt = true;
      }
      this.dropug = false;
    } else {
      if (val != "Other") {
        this.addBenchForm.get("pgType").setValue(val);
      } else {
        this.pgotherstt = true;
      }
      this.droppg = false;
    }
  }
  // =================== 15 =================
  closeOther(val: any) {
    if (val == "ug") {
      this.ugotherstt = false;
    } else {
      this.pgotherstt = false;
    }
  }
  // =================== 16 =================
  get_city(val: any) {
    if (val.length > 2) {
      this.citylist = [];
      this.loderstt = true;
      var filter = { city_name: { $regex: "^" + val, $options: "i" } };
      this.commonSevice
        .getcity(JSON.stringify(filter))
        .then((res) => {
          this.loderstt = false;
          this.cls = "displayblock";
          this.citylist = res.data;
        })
        .catch((err) => {
          this.loderstt = false;
        });
    } else {
      this.cls = "";
      if (!val) {
        this.addBenchForm.get("currentlocation").setValue("");
        this.addBenchForm.get("currentcountry").setValue("");
        this.addBenchForm.get("locationId").setValue("");
      }
    }
  }
  // =================== 17 =================
  setvalue(val: any, counrty: any, id: any) {
    this.cls = "";
    this.addBenchForm.get("currentlocation").setValue(val);
    this.addBenchForm.get("currentcountry").setValue(counrty);
    this.addBenchForm.get("locationId").setValue(id);
    $("#citycountry").val(val + ", " + counrty);
  }
  // =================== 18 =================
  othercityslt() {
    this.othercity = !this.othercity;
    this.cls = "";
    this.getcountry();
  }
  // =================== 19 =================
  otherslt(val: any, type: any) {
    if (type == 1) {
      this.addBenchForm.get("currentlocation").setValue(val);
      this.addBenchForm.get("locationId").setValue(null);
    } else {
      this.addBenchForm.get("currentcountry").setValue(val);
      this.addBenchForm.get("locationId").setValue(null);
    }
  }
  // =================== 20 =================
  getcountry() {
    this.countrylist = [];
    this.commonSevice
      .Find(JSON.stringify({}), "country")
      .then((res) => {
        this.countrylist = res.data;
      })
      .catch((err) => {});
  }
  // =================== 21 =================
  resetProjectlist() {
    if (this.sellingPrice.length > 1) {
      for (let i = 1; i <= this.sellingPrice.length; i++) {
        this.sellingPrice.removeAt(i);
      }
    } else {
      this.sellingPrice.removeAt(0);
      this.addBenchForm.controls["projectList"].push(
        this._fb.group({
          title: new FormControl(""),
          duration: new FormControl(""),
          role: new FormControl(""),
          description: new FormControl(""),
        }),
      );
    }
  }
  // =================== 22 =================
  getJobType() {
    this.loader.startLoading();
    this.addBenchSevice
      .getjobtype({ status: true }, "jobtype")
      .then((res: any) => {
        this.jobType = res.data;
        this.loader.stopLoading();
      })
      .catch((err) => {
        this.loader.stopLoading();
      });
  }
  // =================== 23 =================
  getSkillList() {
    this.skillListdata = [];
    var filter = {};
    this.commonSevice.homeGetSkill(JSON.stringify(filter)).then(
      (res) => {
        this.skillListdata = res.data;
        console.log("skillListdata: ", this.skillListdata);
      },
      (err) => {
        console.log(err);
      },
    );
  }
  // =================== 24 =================
  windowrendor() {
    this.renderer.listen("window", "click", (e: Event): any => {
      let cls = (<Element>e.target).className;
      let arr = cls.split(" ");
      let change1 = 0;
      let change2 = 0;
      arr.forEach((element) => {
        if (element == "salectchanged") {
          change1 += 1;
        }
        if (element == "salectchanged2") {
          change2 += 1;
        }
      });
      if (change1 == 0) {
        this.setchangevalue();
      }
      if (change2 == 0) {
        this.setchangevalue2();
      }
      if (
        e.target == this.menu.nativeElement ||
        (<Element>e.target).className == "salectchanged"
      ) {
        return false;
      } else {
        this.setchangevalue();
      }
      if ((<Element>e.target).className.indexOf("pgclass") != -1) {
        this.droppg = true;
      } else {
        this.droppg = false;
      }
      if ((<Element>e.target).className.indexOf("ugclass") != -1) {
        this.dropug = true;
      } else {
        this.dropug = false;
      }
    });
  }
  // =================== 25 =================
  setchangevalue() {
    if (this.addBenchForm.value.skillsearch) {
      if (this.slist.length < 15) {
        this.slist.push(this.addBenchForm.value.skillsearch);
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
      } else {
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
  }
  // =================== 26 =================
  setchangevalue2() {
    if (this.addBenchForm.value.skillsearch) {
      if (this.slist.length < 15) {
        this.slist.push(this.addBenchForm.value.skillsearch);
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
      } else {
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
  }
  // =================== 27 =================
  getsltSkills(val: any) {
    let srch = val.toLowerCase();
    this.templist = [];
    let no = val.search(",");
    if (no < 0) {
      this.cls1 = "displayblock";
      this.templist = this.skillListdata.filter(function (el: any) {
        let str = el.name.toLowerCase();
        return str.search(srch) !== -1;
      });
      if (!srch) {
        this.cls1 = "";
      }
    } else {
      if (this.slist.length < 15) {
        this.slist.push(val.replace(",", ""));
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
      } else {
        this.cls1 = "";
        this.addBenchForm.get("skillsearch").setValue("");
        this.toaster.info("Select only 15 skill");
      }
    }
  }
  // =================== 28 =================
  setskillvalue(item: any) {
    if (this.slist.length < 15) {
      this.slist.push(item.trim());
      this.cls1 = "";
      this.addBenchForm.get("skillsearch").setValue("");
    } else {
      this.toaster.info("Select only 15 skill");
      this.cls1 = "";
      this.addBenchForm.get("skillsearch").setValue("");
    }
  }
  // =================== 29 =================
  removeItem(inx: any) {
    this.slist.splice(inx, 1);
  }
  // =================== 30 =================
  window_load() {
    this.modules = {
      toolbar: [
        ["bold", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
      ],
    };

    this.configck = {
      uiColor: "#f2f2f2",
      toolbarGroups: [
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align"] },
        { name: "styles" },
        { name: "colors" },
      ],
      resize_enabled: false,
      height: 200,
    };

    this.info["workfrom"] = `<div><b>Offsite</b>: My office </div>
                           <div><b>Onsite</b>: Client-side</div>
                           <div><b>Anywhere</b>: My office or Client-side</div>`;
  }
  // =================== 31 =================
  getcountsummary(val: any): any {
    setTimeout((): any => {
      const summary = this.addBenchForm.value.description;
      //console.log(this.addBenchForm.value.description,val);
      this.cntchr = 7000 - (summary.length - 1);
      if (summary.length > 7001) {
        this.addBenchForm.get("description").setValue(summary.slice(0, 5000));
        return false;
      }
      if (summary.length < 50) {
        this.minerror = true;
      } else {
        this.minerror = false;
      }
    });
  }
  // =================== 32 =================
  getcurrency() {
    this.addBenchSevice.getcurrency().then(
      (res1: any) => {
        this.currency = res1.currency;
        if (this.currency == "in") {
          $("#currency").val("in");
        } else {
          $("#currency").val("doller");
        }
      },
      (err) => {},
    );
  }
  // =================== 33 =================
  //
  changerate(selectedValue: string) {
    console.log("Selected Rate Type:", selectedValue);
  }
  // changerate(event: Event) {
  //   const selectedValue = (event.target as HTMLSelectElement).value;
  //   console.log("Selected Rate Type:", selectedValue);
  //   // console.log("val ", val);
  //   // this.sltratetype = val;
  //   // this.showtest = "";
  // }
  // =================== 34 =================
  changecurrency(val: any) {
    console.log("Selected Rate Type:", val);

    this.currency = val;
    if (val == "in") {
      this.addBenchForm.get("rate").setValue("");
    }
    if (val == "doller") {
      $("#rate").val("");
      this.addBenchForm.get("rate").setValue("");
    }
    this.showtest = "";
  }
  // =================== 35 =================
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; 
  }
  // =================== 36 =================
  checkexp() {
    const experience = this.addBenchForm.value.experience;
    if (!experience) {
      this.exp = 0;
      this.toaster.error("Please Enter experience of candidate");
      $("#expid").focus();
    } else {
      this.exp = experience;
      this.showModal();
    }
  }
  // =============== 37 =================
  shortingchange(val: any) {
    this.showtest = "";
    const exp = parseInt(this.addBenchForm.value.experience);
    console.log("this.currency1", this.currency);
    if (exp > 0) {
      const num = parseInt(val);
      if (this.currency == "in") {
        if (this.sltratetype == "Hourly") {
          console.log("Hourly");
          for (let i = 0; i < this.per.length; i++) {
            if (!this.showtest) {
              if (num < this.per[i].min * exp * this.doller) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
              if (
                num >= this.per[i].min * exp * this.doller &&
                num <= this.per[i].max * exp * this.doller
              ) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
            }
          }
          if (!this.showtest) {
            this.showtest = "0% chance of shortlisting";
          }
        }
        if (this.sltratetype == "Monthly") {
          for (let i = 0; i < this.per.length; i++) {
            if (!this.showtest) {
              if (num < this.per[i].min * exp * this.totalhour * this.doller) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
              if (
                val >= this.per[i].min * exp * this.totalhour * this.doller &&
                val <= this.per[i].max * exp * this.totalhour * this.doller
              ) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
            }
          }
          if (!this.showtest) {
            this.showtest = "0% chance of shortlisting";
          }
        }
      }
      console.log("this.currency 2", this.currency);
      if (this.currency == "doller") {
        console.log("doller");
        if (this.sltratetype == "Hourly") {
          console.log("Hourly");
          for (let i = 0; i < this.per.length; i++) {
            if (!this.showtest) {
              if (num < this.per[i].min * exp) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
              if (
                num >= this.per[i].min * exp &&
                num <= this.per[i].max * exp
              ) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
            }
          }
          if (!this.showtest) {
            this.showtest = "0% chance of shortlisting";
          }
        }
        if (this.sltratetype == "Monthly") {
          console.log("Monthly");
          for (let i = 0; i < this.per.length; i++) {
            if (!this.showtest) {
              if (num < this.per[i].min * exp * this.totalhour) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
              if (
                val >= this.per[i].min * exp * this.totalhour &&
                val <= this.per[i].max * exp * this.totalhour
              ) {
                this.showtest = this.per[i].per + "% chance of shortlisting";
              }
            }
          }
          if (!this.showtest) {
            this.showtest = "0% chance of shortlisting";
          }
        }
      }
    }
  }

  setrate() {}
}
