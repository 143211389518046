<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>My Jobs</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list" style="margin-top: 30px">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="job-list-right">
          <!-- <div class="job">
            <form>
              <button routerLink="/post-a-job" type="submit" class="btn">Post Job</button>
            </form>
          </div> -->

          <div class="d-grid gap-2 mb-4">
            <button routerLink="/post-a-job" type="button" class="btn btn-info">
              <span style="font-weight: bold; color: #fff;font-size: 18px;">Post Job</span>
            </button>
          </div>

          <div class="job-list-all">
            <!-- <div class="search">
              <h3 style="border-bottom: 0px solid #ccc" class="mb-0">Jobs</h3>
            </div> -->
            <div class="search row">
              <div class="col-lg-10">
                <h3 style="border-bottom: 0px solid #ccc" class="mb-0">Search My Jobs</h3>
              </div>
              <div class="col-lg-2 text-end ">
                <span class="pointer pull-right text-end" (click)="reload()"  tooltip="Reset Filters">
                  <i class="fa-solid fa-rotate re_iconSize"></i>
                </span>
              </div>             
            </div>


            <div class="experience mt-3" style="border-top: none">
              <h6 class="mb-1">Work From</h6>

              <ng-select [searchable]="false" style="border-bottom: none" [multiple]="false" [(ngModel)]="search.workFrom" placeholder="Select Job">
                <ng-option value="All"> All</ng-option>
                <ng-option value="Offsite"> Home </ng-option>
                <ng-option value="Onsite"> Office </ng-option>
                <ng-option value="Any"> Anywhere </ng-option>
              </ng-select>
            </div>

            <!-- sudattChanges -->
            <div class="experience mt-3" style="border-top: none">
              <h6 class="mb-1 mt-0">Skills</h6>
              <ng-select [multiple]="true" [(ngModel)]="search.skills" placeholder="Select Skill" style="border-bottom: none">
                <ng-option value="" [disabled]="true"> Select Skill </ng-option>
                <ng-option *ngFor="let list of skillListdata" [value]="list?.name">
                  {{ list?.name }}
                </ng-option>
              </ng-select>
            </div>
            <!-- =================================== -->

            <div class="job-type mt-3">
              <h6 class="mb-1 mt-0">Rate</h6>
              <div class="job-cmn-cat flex-divide">
                <input [(ngModel)]="search.rate" class="form-control" type="text" value="" id="defaultCheck48" placeholder="Enter Rate" />
              </div>
            </div>

            <div class="experience mt-3">
              <h6 class="mb-1 mt-0">Experience</h6>

              <div class="job-cmn-cat flex-divide">
                <input [(ngModel)]="search.experience" class="form-control" type="text" value="" id="defaultCheck48" placeholder="Enter Experience" />
              </div>
            </div>

            <div class="level">
              <form>
                <button (click)="filter()" type="submit" class="btn">Filter Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="row mb-4 mx-1">
          <div class="col-lg-12 searchBarBorder px-2 py-3">
            <div class="row form-group">
              <div class="col-lg-4">
                <input type="text" placeholder="Job Title" class="form-control" name="title" [(ngModel)]="search.title" />
              </div>
              <!-- <div class="col-lg-3">
                  <input type="text" class="form-control" placeholder="Choose Location" (keyup)="get_city($any($event.target).value)">
                  <span *ngIf="loderstt" class="city_loader">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  </span>
                  <div id="myDropdown" class="dropdown-content {{cls}}">
                    <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item._id)">
                      {{item.city_name}}
                    </a>
                  </div>
                </div> -->
              <div class="col-lg-3">
                <ng-select (keyup)="get_city($any($event.target).value)" name="location" [multiple]="false" [(ngModel)]="search.location" placeholder="Select City">
                  <ng-option value="" [disabled]="true"> Select City </ng-option>
                  <ng-option *ngFor="let list of citylist" [value]="list?.city_name">
                    {{ list?.city_name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-lg-3">
                <ng-select [multiple]="false" name="jobstatus" placeholder="Select Job Status" [(ngModel)]="search.jobstatus">
                  <ng-option value="" [disabled]="true"> Select Job Status </ng-option>
                  <ng-option value="verified">Verified</ng-option>
                  <ng-option value="not-verified">Not Verified</ng-option>
                  <ng-option value="on-hold">On-Hold</ng-option>
                  <ng-option value="in-active">In-Active</ng-option>
                  <ng-option value="all">All</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-2">
                <button (click)="filter()" type="button" class="btn searchBtn">Search</button>
              </div>
            </div>
          </div>
        </div>

        <div style="padding-bottom: 20px">{{ jobs?.total }} Jobs Found</div>

        <div *ngFor="let job of jobs?.data" class="employer-item" [ngClass]="{ 'red-border': job?.status == false }">
          <!-- <img src="assets/img/home-one/job1.png" alt="Employer" /> -->

          <div class="row">

            <div class="col-lg-7">

              <!-- <div *ngIf="job?.status==false" class="repost-job text-end">
                <button type="button" class="btn btn-info btn-sm" [routerLink]="['/post-a-job']"
                  [queryParams]="{jobId:job?._id,repost:true}"> Re Post </button>
              </div> -->


              <a href="javascript:void(0)" (click)="openJobDetails(job)">  
                <h3 class="mb-0">{{ job.title }}</h3>
              </a>

              <div class="pb-2">
                (JID : {{ job?.resourceId }}) 
                <span style="padding: 0xp 20px; color: #ccc"> | </span>
                <span *ngIf="!job.parmanant" class="badge bg-info"> <i class="fas fa-check"></i> Contract </span>
                <span *ngIf="job.freelance" class="badge bg-warning"> <i class="fas fa-check"></i> Freelance / Part-time </span>
              </div>

              <ul class="mb-0">
                <li>
                  <i class="fa-solid fa-location-dot text-dark"></i>
                  <span class="text-secondary">{{ job.location[0].location }}, {{ job.location[0].country }}</span>
                </li>
              </ul>

              <span style="color: #222">
                Experience :
                <span class="text-info">{{ job.experiencemin }}-{{ job.experiencemax }} Years</span>
              </span>
              <p class="py-0 my-0" style="color: #222">
                No. of Resources : <span class="text-info"> {{ job?.noofResources }} </span>
              </p>

              <!-- noofResources -->

              <!-- <ul style="margin-top: 10px">
                <li>
                  Category: {{ job.jobtype }}
                </li>
              </ul> -->

              <ul class="pt-1">
                <li>
                  <span *ngFor="let s of job?.skill; let i = index" class="span-one" style="margin: 2px 2px">
                    {{ s }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-lg-5 pt-2">

              <div *ngIf="job?.status==false" class="repost-job text-end mb-1">
                <button type="button" class="btn btn-dark btn-sm" [routerLink]="['/post-a-job']"
                  [queryParams]="{jobId:job?._id,repost:true}"> Re Post </button>
              </div>

              <p style="color: #222" class="py-0 my-0">
                Rate : <i class="fa-solid fa-indian-rupee-sign text-info"></i>
                <span class="text-info"> {{ parseRate(job?.ratemin) }}-{{ parseRate(job?.ratemax) }} Monthly</span>
              </p>
              <p style="color: #222" class="py-0 my-0">
                Work From : <span class="text-info"> {{ job?.workFrom }} </span>
              </p>
              <p style="color: #222" class="py-0 my-0">
                <i class="fa-solid fa-calendar-days "></i> Posted : <span class="text-info"> {{ job?.createdAt | date: "dd MMM, yyyy" }} </span>
              </p>
              <p style="color: #222" class="py-0 my-0">
                <i class="fa-solid fa-calendar-days "></i> Tentative Start : <span class="text-info"> {{ job?.tentativeStartDate | date: "dd MMM, yyyy" }} </span>
              </p>
              <p style="color: #222" class="py-0 my-0">
                Mode of Contact :
                <span class="text-info"> {{ mod[job?.modeOfContact] }} </span>
              </p>

              <!-- modeOfContact -->
            </div>
          </div>

          <!-- ===================================== -->
          <div class="text-end pt-0 mt-0">
            <!--*ngIf="job?.status"  -->
            <ng-container>
              <span *ngIf="job?.adminverified == 1" class="badge bg-success verify" tooltip="Verified By Admin"> <i class="fa fa-check"></i> Verified </span>

              <span *ngIf="job?.adminverified == 2" class="label label-warning" tooltip="Under Process, Yet to be Verified"> <i class="fa fa-times-circle"></i> On-Hold </span>

              <span *ngIf="job?.adminverified == 0" class="badge bg-danger not-verify" tooltip="Under Process, Yet to be Verified">
                <i class="fa fa-spinner fa-spin"></i>
                Not Verified
              </span>

              <!-- ============================================================ -->

              <!-- <span class="badge bg-info"> <i class="fas fa-users"></i> Applicants: {{ job?.totalapplied }} </span> -->

              <a href="javascript:void(0)" [routerLink]="['/job-applicants']" [queryParams]="{ jobid: job?._id, tab: 1 }">
                <span class="badge bg-info" tooltip="Get Applicants list"
                  ><i class="fas fa-users"></i>
                  Applicants :
                  {{ job?.totalapplied }}
                </span> </a
              >&nbsp;

              <!-- ==================================== -->

              <!-- toggle -->
              <a *ngIf="job?.status == true" href="javascript:void(0);" class="">
                <span class="mt-1 badge bg-warning popOver">
                  <i *ngIf="job?.status; else other" mwlConfirmationPopover [popoverTitle]="'Do you want to Deactivate?'" placement="top" (confirm)="changeStatus(job, job?._id, false, i)" (cancel)="cancelClicked = true" class="fa fa-toggle-on toggle-on" tooltip="Click To In-active"></i>&nbsp;
                  <ng-template #other> <i (click)="changeStatus(job, job?._id, true, i)" class="fa fa-toggle-off toggle-off" tooltip="Click To Active"></i>&nbsp; </ng-template>
                </span>
              </a>

              <span *ngIf="job?.status == true">
                <!-- edit -->
                <a href="javascript:void(0)" [routerLink]="['/post-a-job']" [queryParams]="{ jobId: job?._id }" tooltip="Edit">
                  <span class="badge bg-primary">
                    <i class="fa-regular fa-pen-to-square px-1"></i>
                  </span>
                </a>
                <!-- view -->
                <!-- <a href="javascript:void(0)" (click)="openJobDetails(job)" tooltip="View Job Details">
                  <span class="badge bg-primary"> <i class="fas fa-eye"></i> {{ job.totalview }} </span>
                </a> -->
              </span>
            </ng-container>
          </div>
          <!-- ===================================== -->
        </div>

        <!-- <div class="pagination-area pb-5">
          <ul>
            <li><a routerLink="/jobs">Prev</a></li>
            <li><a routerLink="/jobs">1</a></li>
            <li><a routerLink="/jobs">2</a></li>
            <li><a routerLink="/jobs">3</a></li>
            <li><a routerLink="/jobs">Next</a></li>
          </ul>
        </div> -->

        <div class="pagination-area" style="padding-bottom: 30px">
          <ul>
            <li style="padding: 0px 10px">Page {{ currentPage }} of {{ pages }}</li>
            <li *ngIf="currentPage != 1"><a style="cursor: pointer" (click)="previousPage()">Prev</a></li>
            <li *ngIf="currentPage != pages"><a style="cursor: pointer" (click)="nextPage()">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<!-- <app-footer-style-one></app-footer-style-one> -->
<!-- End Footer -->
