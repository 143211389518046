import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-navbar-style-three",
  templateUrl: "./navbar-style-three.component.html",
  styleUrls: ["./navbar-style-three.component.scss"],
})
export class NavbarStyleThreeComponent implements OnInit {
  userLoggedIn: any;

  constructor(
    public authService: AuthService,
    private router: Router,
    private toaster: ToastrService
  ) {
    this.userLoggedIn = localStorage.getItem("token");
  }

  ngOnInit(): void {}

  logout(): void {
    this.resetLogin();
    localStorage.removeItem("token");
    localStorage.clear();
   
    this.router.navigateByUrl("/");
  }

  resetLogin() {
    const userEmail = localStorage.getItem("email");
    const userPassword = localStorage.getItem("password");

    console.log("userEmail:==", userEmail);
    console.log("userPassword:==", userPassword);

    if (userEmail && userPassword) {
      this.authService.logoutFromAll({ email: userEmail, password: userPassword }).subscribe((res) => {
            if (res.confirmation === "success") {
              // this.hideModal("resetLoginModal");
              // this.login();
              console.log("Logout Successfully!");
              this.toaster.success("You have been logged out successfully!");
            }
          },
          (error) => {
            localStorage.clear();
          },
        );
    }
  }

  // logout() {
  //   console.log("logout");
  //   this.authService
  //     .logout({
  //       email: "hr@cosettenetwork.com",
  //       password: "",
  //     })
  //     .subscribe(
  //       (res) => {
  //         localStorage.clear();
  //         this.router.navigateByUrl("/");
  //       },
  //       (error) => {
  //         localStorage.clear();
  //         this.router.navigateByUrl("/");
  //       },
  //     );
  // }
}
