import { Component, OnInit } from "@angular/core";
import { PostJobService } from "../../../services/post-job.service";
import { CommonService } from "../../../services/common.service";
import { AuthService } from "../../../services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingScreenService } from "src/app/services/loading-screen.service";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
})
export class JobDetailsComponent implements OnInit {
  job: any = {};
  contactDetails: any = {};
  myBench: any = [];
  benchSearch: any = [];
  jobId = "";
  canApply: boolean = false;
  candidateFilter: any = {
    // jobId: "66daae8be98b3cffa8f8961c",
    appliedFor: "",
    page: 1,
    limit: 10,
    // searchname: "mayank",
    // searchskill: "",
  };

  constructor(
    private postJobService: PostJobService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.jobId = params["jobId"];
      this.candidateFilter["jobId"] = this.jobId;
      this.getJobDetailsById();
      this.getCandidatesForJobApply();
      this.getJobContactDetails();
    });
  }

  getCandidatesForJobApply() {
    this.commonService
      .getCandidatesForJobApply(this.candidateFilter)
      .then((resp) => {
        let candidates = resp.data.data;
        this.myBench = candidates.filter((c) => c.adminverified == 1);
        console.log(this.myBench);
      });
  }

  getJobContactDetails() {
    this.commonService
      .getJobContactDetails({ jobId: this.jobId })
      .then((resp) => {
        this.contactDetails = resp.data;
      });
  }

  onCheckboxChange(bench) {
    let isChecked = this.myBench.filter((b) => b.checked == true);
    if (isChecked.length > 0) this.canApply = true;
    else this.canApply = false;
  }

  searchCandidate(ev) {
    console.log("benchSearch ", ev.target.value);
    this.candidateFilter["searchname"] = ev.target.value;
    this.getCandidatesForJobApply();
  }

  jobApply() {
    let filteredBench = this.myBench.filter((b) => b.checked == true);
    let clist = [];

    filteredBench.forEach((b) => {
      clist.push({
        ids: b._id,
        name: b.name,
        resourceId: b.resourceId,
      });
    });

    console.log("clist ", clist);
    let payload: any = {
      jobId: this.job._id,
      clist: clist,
      status: 1,
      type: 3,
      benchcomId: this.myBench[0].companyLoginId,
      jobcompanyId: this.job.companyId,
    };

    this.commonService.candidatesJobApply(payload).then((resp) => {
      this.getCandidatesForJobApply();
      this.getJobContactDetails();
    });
  }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   let jobId = params["jobId"];
    //   this.postJobService.getJobDetails(jobId).then((resp) => {
    //     this.job = resp.data;
    //     console.log(resp);
    //   });
    // });
    // sudatt's work
  }

  getJobDetailsById() {
    this.postJobService.getJobDetails(this.jobId).then((resp) => {
      this.job = resp.data;
      console.log(resp);
    });
  }

  parseRate(rate: any) {
    let newRate = parseInt((rate * 168 * 83.5).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  // this.postJobService.getJobDetails()

  // ================================================
}
