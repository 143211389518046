<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Find & Hire Bench Resources</h2>
          <p class="text-light pb-0 mb-0">Hire & find the best Bench employees or unutilized human resources of other companies on contract. These are the verified bench resources and existing employees of networked companies and available on contract or temporary for your flexible and temp hiring needs</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Benches -->
<div class="job-area-list" style="margin-top: 30px">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="job-list-right">
          <!-- <div class="job">
            <form>
              <button routerLink="/post-a-job" type="submit" class="btn">Post Job</button>
            </form>
          </div> -->

          <!-- <div class="d-grid gap-2 mb-4">
            <button routerLink="/post-a-job" type="button" class="btn btn-info">Post Job</button>
          </div> -->

          <div class="job-list-all">
            <div class="search">
              <h3 style="border-bottom: 0px solid #ccc" class="mb-0">Bench Resources</h3>
            </div>

            <div class="experience mt-3" style="border-top: none">
              <h3 class="mb-1 mt-0">Location (City)</h3>
              <ng-select (keyup)="get_city($any($event.target).value)" [multiple]="false" [(ngModel)]="search.location" placeholder="Select City">
                <ng-option value="" [disabled]="true"> Select City </ng-option>
                <ng-option *ngFor="let list of citylist" [value]="list?.city_name">
                  {{ list?.city_name }}
                </ng-option>
              </ng-select>
            </div>

            <div class="experience mt-3" style="border-top: none">
              <h3 class="mb-1 mt-0">Experience</h3>
              <div class="row">
                <div class="col-lg-5">
                  <input type="text" class="form-control" [(ngModel)]="search.experienceMin" placeholder="Enter in years" (keypress)="numberOnly($event)" />
                </div>
                <div class="col-lg-1">
                  <p>to</p>
                </div>
                <div class="col-lg-5">
                  <input type="text" class="form-control" [(ngModel)]="search.experienceMax" placeholder="Enter in years" (keypress)="numberOnly($event)" />
                </div>
              </div>
            </div>

            <div class="experience mt-2" style="border-top: none">
              <h3 class="mb-1">Work From</h3>
              <ng-select [searchable]="false" style="border-bottom: none" [multiple]="false" [(ngModel)]="search.workFrom" placeholder="Select Job">
                <ng-option value="All"> All</ng-option>
                <ng-option value="Offsite"> Home </ng-option>
                <ng-option value="Onsite"> Office </ng-option>
                <ng-option value="Any"> Anywhere </ng-option>
              </ng-select>
            </div>

            <div class="experience mt-3 mb-0" style="border-top: none">
              <h3 class="mb-1 mt-0">Rate</h3>
              <div class="row">
                <div class="col-lg-5">
                  <input type="text" class="form-control" [(ngModel)]="search.rateMin" placeholder="Min" (keypress)="numberOnly($event)" />
                </div>
                <div class="col-lg-1">
                  <p>to</p>
                </div>
                <div class="col-lg-5">
                  <input type="text" class="form-control" [(ngModel)]="search.rateMax" placeholder="Max" (keypress)="numberOnly($event)" />
                </div>
              </div>
            </div>

            <!-- =================================== -->

            <div class="level mt-0" style="border-top: none">
              <form>
                <button (click)="getResources()" type="submit" class="btn">Search</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- searching -->
        <div class="row mb-4 mx-1">
          <div class="col-lg-12 searchBarBorder px-2 py-3">
            <div class="row form-group">
              <div class="col-lg-10">
                <ng-select [multiple]="true" [(ngModel)]="search.skills" placeholder="Select Skill" style="width: calc(100% - 50px); border: none">
                  <ng-option *ngFor="let skill of skillListdata" [value]="skill.name">{{ skill.name }}</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-2">
                <button (click)="getResources()" type="button" class="btn searchBtn">Search</button>
              </div>
            </div>
          </div>
        </div>

        <!-- end of searching -->

        <!-- inactiveEmp_item -->
        <div *ngFor="let bench of benches?.data" class="employer-item" [ngClass]="{ 'red-border': bench?.status == false }">
          <!-- (click)="openBenchDetails(bench)" -->
          <!-- <a> -->
          <div class="row">
            <div class="col-lg-8">
              <h3>{{ bench.fname }}</h3>

              <ul class="mb-0">
                <li>
                  <i class="fa-solid fa-location-dot text-dark"></i>
                  <span class="text-secondary"> {{ bench?.currentlocation }}, {{ bench?.currentcountry }} </span>
                </li>
                <!-- <li>
                    {{ bench.createdAt | date: "dd MMM, yyyy" }}
                  </li> -->
              </ul>
              <ul class="mb-0">
                <li><span class="text-dark">Designation :</span> {{ bench?.designation | titlecase }}</li>
              </ul>
              <p class="py-0 my-0">
                Work From : <span class="text-info">{{ bench?.onsiteAvailability }}</span>
              </p>
              <!-- <ul>
                  <li><span class="text-dark">Category :</span> {{ bench?.jobtype | titlecase }}</li>
                </ul> -->

              <span *ngFor="let s of bench.skill" class="span-one" style="margin: 2px 2px">
                {{ s }}
              </span>
              <div style="text-align: right">
                <span *ngIf="bench?.adminverified == 3" class="badge bg-success"> <i class="fas fa-check"></i> Verified </span>
                &nbsp;
              </div>
            </div>
            <div class="col-lg-4 pt-2">
              <p class="pb-0 mb-0">
                Rate :
                <span class="text-info">
                  <i class="fa fa-inr"></i>
                  {{ parseRate(bench?.rate) }} (Monthly)
                </span>
              </p>
              <ul class="mb-0">
                <li><span class="text-dark">Experience :</span> {{ bench?.experience }} Years</li>
              </ul>
              <ul class="mb-0">
                <li><span class="text-dark">Availability :</span> {{ bench?.Availability }}</li>
              </ul>
              <ul class="mb-0">
                <li><span class="text-dark">Last Active Date :</span> {{ bench?.updatedAt | date: "dd MMM, yyyy" }}</li>
              </ul>
            </div>
          </div>

          <!-- </a> -->
        </div>

        <div class="row">
          <div class="col-lg-12"></div>
        </div>

        <div class="pagination-area pb-5">
          <ul>
            <li><a routerLink="/jobs">Prev</a></li>
            <li><a routerLink="/jobs">1</a></li>
            <li><a routerLink="/jobs">2</a></li>
            <li><a routerLink="/jobs">3</a></li>
            <li><a routerLink="/jobs">Next</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
