<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-8 col-md-12">
            <div class="left">
              <!-- <img src="assets/img/job-details1.png" alt="Details" /> -->
              <h2 class="mt-50">{{ job.title }}</h2>
              <ul>
                <li><i class="bx bx-people"></i> No. of positions: {{ job.noofResources }}</li>
                <li style="color: white"><i class="fa-solid fa-location-dot"></i> {{ job.location[0].location }}</li>

                <!-- <li><i class="bx bx-time"></i> Posted date: {{ job.createdAt | date: "dd MMM, yyyy" }}</li> -->
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <div class="right">
              <ul>
                <li style="color: white"><i class="bx bx-time"></i> Posted date: {{ job.createdAt | date: "dd MMM, yyyy" }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Job Details -->
<div class="job-details-area pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div class="details-inner">
            <h3>Required Skills</h3>
            <span *ngFor="let s of job.skill" class="span-one" style="margin: 2px 2px">{{ s }}</span>
          </div>

          <div class="details-inner">
            <h3>Job Description</h3>
            <div [innerHTML]="job.jobDescription"></div>
          </div>

          <div class="details-inner">
            <h3>Matching Resources</h3>
          </div>

          <div *ngFor="let candidate of candidates" style="padding: 20px; border: 0.5px solid #ccc; margin: 5px; border-radius: 10px">
            <div class="details-inner">
              <h3>
                {{ candidate.fname }} <span style="font-size: 15px; color: #222">( {{ candidate.currentlocation }}, {{ candidate.experience }} Years )</span>
              </h3>
              <div></div>
              <span *ngFor="let s of candidate.skill" class="span-one" style="margin: 2px 2px; color: black">{{ s }}</span>
              <div class="cmn-link">
                <a [routerLink]="['/candidate-details']" [queryParams]="{ userId: candidate?._id }"><i class="flaticon-right-arrow one"></i> View Candidate <i class="flaticon-right-arrow two"></i></a>
              </div>
            </div>
          </div>

          <!-- <div class="candidate-item" style="max-width: 100% !important: 100%" *ngFor="let candidate of candidates">
            <div class="left">
              <h3>{{ candidate.fname }}</h3>
              <ul>
                <li *ngFor="let s of candidate.skill">{{ s }}</li>
              </ul>
              <div class="cmn-link">
                <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Job / Project <i class="flaticon-right-arrow two"></i></a>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="widget-area">
          <!-- class="job widget-item" -->
          <div class="d-grid gap-2 mb-4">
            <!-- <form> -->
            <!-- <button  style="margin-bottom: 10px" type="submit" class="btn">Deactivate</button>  -->

            <!-- <i (click)="changeStatus(job?._id,true)" class="fa fa-toggle-off toggle-off"
              tooltip="Click To Active"></i> -->

            <button type="button" [routerLink]="['/post-a-job']" [queryParams]="{ jobId: job?._id }" class="btn btn-info">Edit</button>
            <!-- </form> -->
          </div>

          <div class="information widget-item">
            <h3>Job Information</h3>

            <ul>
              <li>
                <h4>Rate</h4>
                <span>₹ {{ parseRate(job?.ratemin) }} - ₹ {{ parseRate(job?.ratemax) }} (Monthly)</span>
              </li>
              <!-- <li>
                <h4>Category</h4>
                <span>Sales & Marketing</span>
              </li> -->
              <li>
                <h4>Location</h4>
                <span>{{ job?.location[0]?.location }}, {{ job?.location[0]?.country }}</span>
              </li>
              <!-- <li>
                <h4>Applied</h4>
                <span>01</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Job Details -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
