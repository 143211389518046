<app-navbar-style-three></app-navbar-style-three>
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Login</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Login -->
<div class="user-form-area">
  <div class="container-fluid ">
    <div class="row m-0 align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="user-img login top">
          <img src="assets/img/login.png" alt="User" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="user-content ">
         
          <div class="top ptb-50">
            <h2>Login</h2>
            <form>
              <div class="form-group mb-0 pb-0 mb-4">
                <input [(ngModel)]="email" type="email" name="email" class="form-control" placeholder="Email" />
              </div>

              <div class="form-group mb-0 pb-0 mb-4">
                <input [(ngModel)]="password" type="password" name="password" class="form-control" placeholder="Password" />
              </div>

              <div class="text-end mt-0 pt-0 mb-4 text-info">
                <a routerLink="/forgot-password" class="text-info" href="">Forgot Password?</a>                
              </div>

              <button (click)="login()" type="submit" class="btn">Login here</button>

              <div class="row">
                <div class="col-lg-12">
                  <p class="mt-1 ps-1">
                    Already have an account?
                    <a routerLink="/register" class="text-info">SignUp here</a>
                  </p>
                </div>
              </div>

            </form>
          </div>

          <!-- <div class="end">
            <ul>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>

<!-- End Login -->

<!-- ======== Permission-Modal  ========= -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden('resetLoginModal')" bsModal
  #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name"
  data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modalHeader">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-dark">Already Logged In</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal('resetLoginModal')">
          <span aria-hidden="true" class="visually-hidden"> 
            <i class="fa fa-times text-light"></i> 
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-lg-12 text-center">
            <button type="button" class="btn btn-dark" (click)="resetLogin()">Reset Other Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
