import { Component } from '@angular/core';

@Component({
  selector: 'app-request-demo',
  // standalone: true,
  // imports: [],
  templateUrl: './request-demo.component.html',
  styleUrl: './request-demo.component.scss'
})
export class RequestDemoComponent {

}
