<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2 class="banner-title">Connect with Global Clients to Sell Bench or Get Projects</h2>
         
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area ptb-50">
  <div class="container">
    <!-- <h3 class="banner-subtitle">Join the Biggest Talent Marketplace</h3> -->

    <!-- <div class="title-item text-center pb-5">
      <h2>Join the Biggest Talent Marketplace</h2>
      
    </div> -->

    <div class="row align-items-center">
     

      <div class="col-lg-7 col-md-12">
        <div class="about-content">
          <div class="section-title">
            <h1 class="text-dark">Join the Biggest Talent Marketplace</h1>
            <!-- <span class="sub-title">Expand your Business & Reduce Sales Cost</span> -->
            <h2>Expand your Business & Reduce Sales Cost</h2>
          </div>
          <p >
            Deploy bench & get clients to expand your business & increase revenue. Reduce your sales cost as we will do help you sell your bench employees or get projects
          </p>

          <h6 class="pt-4">Let's talk +91 97189 28125    <span><a class="cmn-btn" routerLink="/request-demo"> Request Demo</a></span></h6>
          
<!-- <a class="cmn-btn" routerLink="/contact">Pricing<i class='bx bx-plus'></i></a> -->
        </div>
      </div>

      <div class="col-lg-5 col-md-12">

        <div class="connect-form">
          <form>
            <h5 class="text-dark">Find clients to deploy bench or get projects </h5>
            <p class="text-dark"> Fill the details & Get started & expand your reach to new clients</p>
              <div>
                <label for="name">Enter Name:</label>
                <input type="text" id="name" name="name" placeholder="Your name" required>
              </div>
             
              <div>
                <label for="email">Enter Email:</label>
                <input type="email" id="email" name="email" placeholder="Your email" required>
              </div>
            
              <div>
                <label for="phone">Enter Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Your phone number" required>
              </div>
           
              <div>
                <label for="message">Type Message:</label>
                <textarea id="message" name="message" placeholder="Your message" required></textarea>
              </div>
            
              <div>
                <button type="submit">Submit</button>
              </div>
            </form>
            
         </div>

        <!-- <div class="new-img">
          <img src="assets/img/find-client.png" alt="New" />
         
        </div> -->
      </div>

    </div>
  </div>
</div>
<!-- End New -->


<!-- Work -->
<section class="work-area pt-50 pb-70 bg-light">
  <div class="container">
    <div class="section-title">
            <!-- <span class="text-center sub-title">Sell your Bench at Zero Commission</span> -->
            <h2 class="text-center">Sell your Bench at Zero Commission</h2>
        </div>

    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <i class="img-icon "><img src="assets/img/icon/customer-satisfaction.png"></i>
          <!-- <span>01</span> -->
          <h3>Connect & Deploy Bench</h3>
          <p class="txt-p">
            Get connected directly with global clients to deploy & bill your bench on contract or get projects.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <!-- <i class="flaticon-file"></i> -->
          <i class="img-icon "><img src="assets/img/icon/public-relation.png"></i>
          <!-- <span>02</span> -->
          <h3>Make Clients & Expand Business</h3>
          <p class="txt-p">
            No need to hire a sales team. Make relevant clients to deploy your bench through platform.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
        <div class="sell-bench">
          <!-- <i class="flaticon-comment"></i> -->
          <i class="img-icon "><img src="assets/img/icon/candidates.png"></i>
          <!-- <span>03</span> -->
          <h3>Get Immediate Joiner</h3>
          <p class="txt-p">
            Get immediate joiner on contract/ part-time from the biggest pool of real time active bench.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6">
                <div class="sell-bench">
                    <!-- <i class="flaticon-comment"></i> -->
                    <i class="img-icon "><img src="assets/img/icon/communication.png"></i>
                    <!-- <span>04</span> -->
                    <h3>Find Talent Your Way</h3>
                    <p class="txt-p">Connect directly with bench employers or freelancers to hire candidates on contract</p>
                </div>
            </div>

    
    </div>
  </div>
</section>
<!-- End Work -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
