import { Component, OnInit } from "@angular/core";
import { PostJobService } from "../../../services/post-job.service";
import { AuthService } from "../../../services/auth.service";
import { CommonService } from "../../../services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-my-job-details",
  templateUrl: "./my-job-details.component.html",
  styleUrls: ["./my-job-details.component.scss"],
})
export class MyJobDetailsComponent implements OnInit {
  job: any = {};
  jobId = "";
  matchingCandidates: any = {
    jobId: "6470cb64b6dee8904b521011",
    page: 1,
    limit: 15,
  };
  candidates: any = [];

  jobresult: any = [];
  search: any = {};
  shimmer = true;
  countjobs: any = {
    verifiedjob: 0,
    notverifiedjob: 0,
    Onholdjob: 0,
    inactive: 0,
  };

  constructor(
    private postJobService: PostJobService,
    private authService: AuthService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private loader: LoadingScreenService,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.jobId = params["jobId"];
      this.matchingCandidates.jobId = this.jobId;
      this.getJobDetailsById();
      this.getMatchingCandidates();
    });
  }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   let jobId = params["jobId"];
    //   this.postJobService.getJobDetails(jobId).then((resp) => {
    //     this.job = resp.data;
    //     console.log(resp);
    //   });
    // });
    // sudatt's work
  }

  getJobDetailsById() {
    this.postJobService.getJobDetails(this.jobId).then((resp) => {
      this.job = resp.data;
      console.log(resp);
    });
  }

  getMatchingCandidates() {
    this.commonService
      .getMatchingCandidates(this.matchingCandidates)
      .then((resp) => {
        this.candidates = resp.data.cdata;
      });
  }
  // this.postJobService.getJobDetails()

  parseRate(rate: any) {
    let newRate = parseInt((rate * 168 * 83.5).toString());
    let roundedNumber = Math.round(newRate / 1000) * 1000;
    return parseInt(roundedNumber.toString()).toLocaleString();
  }

  // ================================================
}
