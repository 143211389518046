<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Privacy Policy</h2>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Rules -->
<div class="rules-area ptb-100">
    <div class="container">
        <div class="rules-item">
            <h3>Introduction</h3>
            <p>Your privacy, and the privacy of all registered users of OnBenchMark.com, is paramount to OnBenchMark.com, a B2B portal of Cosette Network Pvt Ltd , a company incorporated in Delhi, India, Accordingly, we have adopted the following privacy policy. Our policy is based on the principles of notice, choice, access and security in the collection and use of all information regarding our users and their activities at our site. This policy is subject to, and shall be superseded by, any other agreement between you and OnBenchMark.com</p>
            <p>Cosette Network Pvt Ltd. (hereinafter referred to as "CNPL") is committed to safeguarding its users' privacy. We request all our users to read the following 'privacy policy' to understand how their personal & business information will be treated, as they make full use of our services to their benefit. This policy is applicable only to the entire network of marketplaces operated by CNPL and not by any other company.</p>

            <h3>Notice</h3>
            <p>This policy tells you, among other things, what information we gather from you, how we may use or disclose that information and our efforts to protect it. Please read this policy carefully, and feel free to contact us if you have any questions regarding its contents.</p>

            <h3>Scope of Privacy Statement</h3>
            <p>We are committed to the privacy and confidentiality of information provided by you to us. This Privacy Statement describes our current policies and practices with regard to Personal Data collected by us from you directly and/or through the website to the extent that such Personal Data is protected by applicable Indian data protection laws. The term "Personal Data" refers to personally identifiable information about you, such as your name, Job description, health related data, birthday, e-mail address or mailing address.</p>

            <h3>Collection of Information</h3>
            <p>In connection with your use of our site, we ask for and may collect a variety of information from and about you in different ways. For example, when you register to use our site, we ask for and store your and/or your business' name, address, email address and other identifying contact data. CNPL collects information from its users when they register to gain access to our services or at other specific instances when they are requested to provide us with their personal & business information. During registration, we ask the users for their name, email address, business information and other personal details. Once a user registers, he/she is no longer anonymous to CNPL and it is deemed that the user has given CNPL the right to publish the desired business & there Employee's details throughout its marketplaces.</p>
            <p>When you are on our network and are asked for Company Information or Employee's information, you are sharing the information with CNPL alone, unless it is specifically stated otherwise. We also gather usage statistics such as pages viewed, number of unique visitors, browser software, screen resolution etc. for analysis that helps us provide you improved user experience and services.</p>

            <h3>Use of Business Information & Employee's Information</h3>
            <p>CNPL primary goal in collecting your On Bench employee's details and to provide the user with a customized experience on our network of sites. This includes Client's ongoing resource needs and Partner's need to place their employees at client location. Client/Partner information is used to display the user's listing of Jobs/Candidates across our network to fetch maximum opportunity for both Client and Partner.</p>

            <h3>Sharing of Information</h3>
            <p>As a general rule, CNPL will not disclose or share any of the user's personally identifiable information except when the Partner or Client shows an Interest for Candidate or Job respectively or under special circumstances, such as when CNPL believes in good faith that the law requires it or as permitted in terms of this policy. CNPL does not display user email addresses on marketplace to avoid extraction by email extractors.</p>
            <p>CNPL may also disclose account information in special cases when CNPL has reasons to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be violating CNPL's Terms of Services or may be causing injury to or interference with (either intentionally or unintentionally) CNPL's rights or property, other CNPL network website users, or if CNPL deems it necessary to maintain, service, and improve its products and services. Personal information collected may be transferred and shared in the event of a sale. We may provide third parties with collective profiles of our user groups and their activities and preferences. Such collective profiles may also be disclosed in describing our site and services to prospective partners, advertisers and other third parties, and for other lawful purposes. We may also disclose information to fulfil certain legal and regulatory requirements or if we believe, in good faith, that such disclosure is legally required or necessary to protect others' rights or to prevent harm.</p>
            
            <h3>Distribution of Information</h3>
            <p>CNPL may, from time to time, send its users emails regarding its products and services. CNPL constantly tries and improves the marketplace tools for better efficiency, more relevancy, innovative business matchmaking and better personal preferences. CNPL may have many match making tools on the marketplace to be used by its users for making relevant business contacts with each other. CNPL may allow direct mails using its own scripts (without disclosing the email address) with respect/pertaining to the products and services of third parties that it feels may be of interest to the user or if the user has shown interest in your company for business requirement by way of our paid Client-Partner contact services. All users shall be able to control the information sharing through their administration panel available at http://OnBenchMark.com. Only CNPL, its registered members or its agents working under confidentiality agreements will send these direct mailers. Relevancy of the contacts made between users would depend upon the information sought & given by various users.</p>
        
           <h3>Updation of Information</h3>
            <p>A user may edit his/her account information and profile by logging onto our website and/or by sending a request to  admin&#64;onbenchmark.com. The requested changes may take upto 1 day to appear online because of verification process and server cache policies.</p>
            <p>A user account can be deleted or deactivated, but doing so will result in the user not being able to access any members-only area of the network. Residual information will remain within our archive records.</p>
         
            <h3>Links to other Websites</h3>
            <p>This website may contain Hyperlinks to websites that are not operated by us. These Hyperlinks are provided for your reference and convenience only and do not imply any endorsement of the activities of these third-party websites or any association with their operators. We do not control these websites and are not responsible for their data or privacy practices. We urge you to review any privacy policy posted on any site you visit before using the site or providing any Personal Data about yourself.</p>
        
            <h3>Protection of information</h3>
            <p>As with all information, CNPL do not sell any data in bulk to third party that can be misused for telemarketing or bulk mailing or any other wrong purposes. In addition, CNPL does not sell any user's personal information or business information until and unless it is permitted by the user. Any user statistics that we may provide to prospective advertisers or partners regarding product or service usage on CNPL network is provided in an aggregate form only and does not include any personally identifiable information about any individual user.</p>
            <p>We request our users to sign out of their CNPL/OnBenchMark account and close their browser window when they have finished their work. This is to ensure that others cannot access their personal or business information and correspondence, if the user shares the computer with someone else or is using a computer in a public place.</p>
            <p>CNPL takes necessary steps, within its limits of commercial viability and necessity, to ensure that the user's information is treated securely.</p>
            <p>Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while CNPL strives to protect the user's personal & business information, it cannot ensure the security of any information transmitted to CNPL and you do so at your own risk. Once CNPL receives your transmission, it makes best efforts to ensure its security in its systems. Please keep in mind that whenever you post personal & business information online, that is accessible to the public, you may receive unsolicited messages from other parties.</p>
        
            <h3>Changes in Privacy Policy</h3>
            <p>The policy may change from time to time so please check periodically.</p>
        
        </div>
    </div>
</div>
<!-- End Rules -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->