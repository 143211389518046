<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>My Tickets</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50" >
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="job-list-right">
          <div class="job">
            <!-- <h3>Find A Job</h3> -->

            <form>
              <!-- <label><i class="flaticon-send"></i></label> -->
              <!-- <input type="email" class="form-control" placeholder="Email address" /> -->
              <!-- <button routerLink="/support" type="submit" class="btn">Create Ticket</button> -->

              <button *ngIf="liststt" type="button" class="btn btn-blue" (click)="changestt()">Raise a Ticket</button>
              <button *ngIf="liststt == false" type="button" class="btn btn-blue" (click)="changestt()">List Ticket</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="employer-item">
          <!-- <div class="row">
                <div class="col-sm-12 text-right">
                  <button *ngIf="liststt" type="button" class="btn btn-blue" (click)="changestt()"> Create Ticket</button>
                  <button *ngIf="liststt==false" type="button" class="btn btn-blue" (click)="changestt()">List Ticket</button>
                  <br> <br>
                </div>
            </div> -->

          <div class="panel panel-default onpanel">
            <div class="panel-body">
              <div *ngIf="liststt">
                <table *ngIf="ticketData?.length > 0" class="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Subject</th>
                      <th>Comment</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of ticketData; let i = index">
                      <td>{{ 5 * (cPage?.currentPage - 1) + (i + 1) }}</td>
                      <td>{{ list?.subject }}</td>
                      <td>{{ list?.description }}</td>

                      <td>{{ list?.createdAt | date: "mediumDate" }}</td>
                      <td>
                        <span *ngIf="list?.status == 1" class="badge bg-warning">Pending</span>
                        <span *ngIf="list?.status == 2" class="badge bg-primary">Under Process</span>
                        <span *ngIf="list?.status == 3" class="badge bg-success">Resolved</span>
                      </td>
                      <td>
                        <span class="badge badge-info" *ngIf="list?.status == 3" (click)="reopenticket(list?._id)">Reopen</span>&nbsp;
                        <a *ngIf="list?.imageurl" href="{{ list?.imageurl }}" class="btn btn-default btn-sm" target="_blank">
                          <i class="fa fa-paperclip"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="cpagedata?.pages?.length > 0" class="paginateContainer paginateCase">
                  <ul class="paginateList">
                    <li [ngClass]="{ disabled: cpagedata?.currentPage === 1 }" class="paginatePrevious paginateDisabled">
                      <a class="paginateAnchor" (click)="setPage(1)">«</a>
                    </li>
                    <li *ngFor="let list of cpagedata.pages" class="paginatePage paginateActive" style="display: inline-block">
                      <a class="paginateAnchor" [ngClass]="cpagedata?.currentPage == list ? 'activepage' : ''" (click)="setPage(list)">{{ list }}</a>
                    </li>
                    <li [ngClass]="{ disabled: cpagedata?.currentPage === cpagedata?.totalPages }" class="paginateNext">
                      <a class="paginateAnchor" (click)="setPage(cpagedata.totalPages)">»</a>
                    </li>
                  </ul>
                  <ul class="paginateShowAllList">
                    <li class="paginateShowAll"><a class="paginateAnchor">⇓</a></li>
                  </ul>
                </div>
                <div *ngIf="shimmer">
                  <div class="shimmer">
                    <div class="shine"></div>
                    <div class="shine"></div>
                    <div class="shine"></div>
                  </div>
                </div>
                <div *ngIf="ticketData?.length == 0 && shimmer == false" class="text-center">
                  <h5>Not Found Ticket</h5>
                </div>
              </div>
              <div *ngIf="liststt == false">
                <form [formGroup]="ticketForm" class="box-body">
                  <div class="form-group row mb-3">
                    <div class="col-lg-6">
                      <label class="control-label">Subject* </label>
                      <input type="text" class="form-control" placeholder="Name" formControlName="subject" />
                      <small class="text-danger" *ngIf="(this.ticketForm.get('subject').invalid && this.ticketForm.get('subject').touched) || (ticketForm.get('subject').errors?.maxlength && ticketForm.get('subject').touched)"> Subject is required and not maximum 100 characters.</small>
                    </div>
                    <div class="col-lg-6">
                      <label class="control-label">Image </label>
                      <input type="file" class="form-control" placeholder="File" (change)="onFileSelect($event)" />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <div class="col-lg-12">
                      <label class="control-label">Comment* </label>
                      <textarea class="form-control" formControlName="description" placeholder="Write something here..."> </textarea>
                      <small class="text-danger" *ngIf="(this.ticketForm.get('description').invalid && this.ticketForm.get('description').touched) || (ticketForm.get('description').errors?.maxlength && ticketForm.get('description').touched)"> Comment is required and not maximum 100 characters.</small>
                    </div>
                    <div *ngIf="ticketForm?.value?.imageurl" class="col-md-6">
                      <img src="{{ ticketForm?.value?.imageurl }}" style="width: 150px" />
                    </div>
                  </div>
                  <div class="form-group row my-3">
                    <div class="col-lg-12 text-center">
                      <button type="button" class="btn btn-info" [disabled]="ticketForm.invalid" (click)="ticketFormSubmit()">{{ btn }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="pagination-area">
          <ul>
            <li><a routerLink="/support">Prev</a></li>
            <li><a routerLink="/support">1</a></li>
            <li><a routerLink="/support">2</a></li>
            <li><a routerLink="/support">3</a></li>
            <li><a routerLink="/support">Next</a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
