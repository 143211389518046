<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Banner -->

<!-- Video background -->

<section class="video-area">
  <!-- Video background -->
  <video class="video-background" [autoplay]="autoplay" [muted]="autoplay" [loop]="autoplay" playsinline>
    <source src="https://ik.imagekit.io/kxfkzv0rg/video-05.mp4?updatedAt=1727764224302" type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  <!-- Content over the video -->
  <div>
    <div class="banner-area three pt-100">
      <div class="container-fluid">
        <div class="banner-content two three">
          <div class="d-table">
            <div class="d-table-cell">
              <h1>Quick Talent<span> Marketplace</span></h1>

              <h2 class="subtitle-main" style="font-size: 35px; font-weight: 600">Hire Developers on Contract in just ONE Day</h2>

              <h3 class="subtitle-main" style="font-size: 24px; font-weight: 600">Directly connect with over <span>8000 IT Companies </span> to Deploy Bench</h3>

              <!-- SearchingBar -->
              <div style="background: transparent; padding: 20px 10px" class="mt-2">
                <!-- <div class="row ps-0">
              <div class="job-list-right">
                <div class="job" style="text-align: left">
                  <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': hireTalentStatus}"
                    (click)="hireTalentSearch()"><i class="fa-solid fa-circle me-1"></i> Hire Talent
                  </button>
                  <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': findProjectsStatus}"
                  (click)="findProjectsSearch()"><i class="fa-solid fa-circle me-1"></i> Find Projects</button>
                </div>
              </div>
            </div> -->
                <div class="row d-flex justify-content-start">
                  <div class="col-lg-10 pt-3">
                    <div class="row form-group mb-0">
                      <!-- <div class="col-lg-12 pb-0">
                      <div class="form-check form-check-inline">
                        <label class="form-check-label" >
                          <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': hireTalentStatus}"
                          (click)="hireTalentSearch()">
                          <input class="form-check-input" type="radio" name="searchOptions" value="option1" checked>Hire Talent
                          </button>
                        </label>
                      </div>
                      <div class="form-check form-check-inline ">
                        <label class="form-check-label" >
                          <button type="submit" class="btn topToggleBtN" [ngClass]="{'active-btn': findProjectsStatus}"
                            (click)="findProjectsSearch()"> <input class="form-check-input" type="radio" name="searchOptions" value="option2">Find Projects
                          </button>
                        </label>
                      </div>
                    </div> -->
                      <div class="col-lg-12 ps-3">
                        <span class="switchRadioBTN me-2" [ngClass]="{ activeRadioBtn: hireTalentStatus }">
                          <div class="form-check form-check-inline ms-4">
                            <input (click)="hireTalentSearch()" class="form-check-input" type="radio" name="searchOptions" value="option1" checked />
                            <label class="form-check-label"> Hire Talent </label>
                          </div>
                        </span>
                        <span class="switchRadioBTN" [ngClass]="{ activeRadioBtn: findProjectsStatus }">
                          <div class="form-check form-check-inline ms-4">
                            <input (click)="findProjectsSearch()" class="form-check-input" type="radio" name="searchOptions" value="option2" />
                            <!-- <label class="form-check-label"> Find Projects </label>-->
                            <label class="form-check-label"> Find Jobs </label>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="row searchBarBG pt-3 mt-0">
                      <div class="col-lg-5 firstCol">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>

                            <ng-select [multiple]="true" [(ngModel)]="search.skills" placeholder="Select Skill" style="width: calc(100% - 50px); border: none">
                              <ng-option *ngFor="let skill of skillListdata" [value]="skill.name">{{ skill.name }}</ng-option>
                            </ng-select>

                            <!-- <input type="text" [(ngModel)]="search.job" name="job" class="form-control" placeholder="Search Your Job" /> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="form-group two">
                          <div class="input-group mb-3">
                            <span class="input-group-text"><i class="fa-solid fa-location-dot"></i></span>

                            <ng-select [multiple]="false" [(ngModel)]="search.location" (input)="get_city($event)" placeholder="Select City" style="width: calc(100% - 50px); border: none">
                              <ng-option *ngFor="let city of citylist" [value]="city.city_name">{{ city.city_name }}</ng-option>
                            </ng-select>

                            <!-- <input type="text" [(ngModel)]="search.location" name="location" class="form-control" placeholder="Location" /> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2" style="margin-top: -6px">
                        <button (click)="homeSearch()" type="submit" class="btn searchBTN">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end of SearchingBar -->
              <div class="register-area">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4">
                      <div class="register-item">
                        <h3><span class="odometer" data-count="7448">00</span></h3>
                        <p>Jobs</p>
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <div class="register-item">
                        <h3><span class="odometer" data-count="10871">00</span></h3>
                        <p>Bench Resources</p>
                      </div>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <div class="register-item">
                        <h3><span class="odometer" data-count="6471">00</span></h3>
                        <p>IT Companies</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="banner-shape-three">
        <!-- <img src="assets/img/banner/banner-img.png" class="bnner-img" alt="Shape" /> -->
      </div>

      <!-- Partner -->
      <div class="partner-area two">
        <div class="container">
          <div class="partner-slider owl-theme owl-carousel">
            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo9.jpg?updatedAt=1727764499233" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo1.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo8.jpg?updatedAt=1727764496949" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo2.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo5.jpg?updatedAt=1727764496925" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo3.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo21.jpeg?updatedAt=1727764496893" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo4.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo4.jpg?updatedAt=1727764496572" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo5.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo6.jpg?updatedAt=1727764496523" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo6.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo7.png?updatedAt=1727764496527" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo7.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo20.jpeg?updatedAt=1727764496452" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo8.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo3.jpg?updatedAt=1727764496504" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo9.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo2.jpg?updatedAt=1727764496366" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo10.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo19.jpeg?updatedAt=1727764496256" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo11.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo18.png?updatedAt=1727764493992" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo12.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo12.jpg?updatedAt=1727764493996" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo13.jpeg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo15.png?updatedAt=1727764493980" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo14.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo14.png?updatedAt=1727764493975" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo15.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo17.png?updatedAt=1727764493984" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo16.jpg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo13.jpeg?updatedAt=1727764493970" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo17.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo16.jpg?updatedAt=1727764493916" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo18.png" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo11.png?updatedAt=1727764493916" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo19.jpeg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo10.png?updatedAt=1727764493735" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo20.jpeg" alt="Partner" /> -->
            </div>

            <div class="partner-item">
              <img src="https://ik.imagekit.io/kxfkzv0rg/clients-logo/logo10.png?updatedAt=1727764493735" alt="Partner" />
              <!-- <img src="assets/img/clients-logo/logo21.jpeg" alt="Partner" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Video background -->

<!-- End Banner -->

<!-- Find & Hire Contract/Part-Time Talent -->

<section class="work-area ptb-50">
  <div class="container">
    <div class="section-title text-center">
      <h2>Find & Hire Contract/Part-Time Talent</h2>
      <span class="sub-title">Hire bench employees on contract from the network community of over 5000 IT Companies</span>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-6 col-lg-6">
        <div class="work-item-img">
          <img src="https://ik.imagekit.io/kxfkzv0rg/img-banner-a.png?updatedAt=1727765814486" class="work-img" />
        </div>
      </div>

      <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <!-- <i class="flaticon-comment"></i>
              <span>01</span> -->
              <h3>Join Community of IT Companies</h3>
              <p class="box-p">Join networked community of companies to deploy & bill your bench on contract or get projects.</p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <!-- <i class="flaticon-comment"></i>
              <span>02</span> -->
              <h3>Hire on Contract /Part-Time</h3>
              <p class="box-p">Get immediate joiner on contract/ part-time from the biggest pool of bench. <br /><br /></p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <!-- <i class="flaticon-comment"></i>
              <span>03</span> -->
              <h3>Top talent on demand</h3>
              <p class="box-p">Hire the top rated Contract /part-time talent in a day</p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <!-- <i class="flaticon-comment"></i>
              <span>04</span> -->
              <h3>Find talent your way</h3>
              <p class="box-p">Connect directly with bench employers or freelancers at zero commission.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Find & Hire Contract/Part-Time Talent -->

<!-- Find Clients & Deploy Bench Employees or Get IT Projects -->

<section class="work-area ptb-50">
  <div class="container">
    <div class="section-title text-center">
      <h2>
        Find Clients & Deploy Bench Employees or <br />
        Get IT Projects
      </h2>
      <span class="sub-title">Join the Biggest Talent Marketplace. Connect with Global Clients to Sell Bench or Get Projects. Expand your Business & Reduce Sales Cost</span>
    </div>

    <div class="row">
      <div class="col-sm-8 col-lg-6">
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <div class="work-item work-item-box bg-light">
              <!-- <i class="flaticon-comment"></i>
              <span>01</span> -->
              <h3>Sell Bench or Get Projects</h3>
              <p class="box-p">Connect directly with global clients to deploy & bill your bench on contract or get projects.</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="work-item work-item-box bg-light">
              <!-- <i class="flaticon-comment"></i>
              <span>02</span> -->
              <h3>Reduce Sales Cost</h3>
              <p class="box-p">Expand your business & reduce your sales cost. Make relevant clients to sell your bench employees or get project</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="work-item work-item-box bg-light">
              <!-- <i class="flaticon-comment"></i>
              <span>03</span> -->
              <h3>Reduce Bench Resource Loss</h3>
              <p class="box-p">Reduce bench resource loss and get your bench employees & sell you bench to global clients.</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="work-item work-item-box bg-light">
              <!-- <i class="flaticon-comment"></i>
              <span>04</span> -->
              <h3>Join Community of IT Companies</h3>
              <p class="box-p">Join Networked community of IT Companies to work in collaboration & create Business Opportunities.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-lg-6">
        <div class="work-item-img">
          <img src="https://ik.imagekit.io/kxfkzv0rg/img-banner-b.png?updatedAt=1727765852902" class="work-img" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Find Clients & Deploy Bench Employees or Get IT Projects -->

<!-- Find Experts -->

<section class="work-area ptb-50 bg-light">
  <div class="container">
    <div class="section-title text-center">
      <h2>Find Experts</h2>
      <span class="sub-title">Connect directly with clients & get project work at zero commission from global clients. Connect with fellow freelancers & create your virtual team to bid for bigger projects</span>
    </div>

    <div class="row">
      <!-- <div class="col-sm-4 col-lg-6">
                <div class="work-item work-item-img">
                    <img src="assets/img/banner/img-c.svg" class="work-img">
                </div>
            </div> -->

      <div class="col-sm-12 col-lg-12">
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <div class="find-expert-item">
              <i class="flaticon-comment"></i>
              <!-- <i><img src="assets/img/icon/customer-satisfaction.png"></i> -->
              <!-- <span>01</span>
              <h3>Freedom as Freelancer</h3> -->
              <p class="txt-p">Get freedom and autonomy as freelancer to work directly with clients.</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="find-expert-item">
              <i class="flaticon-comment"></i>
              <!-- <i><img src="assets/img/icon/customer-satisfaction.png"></i> -->
              <!-- <span>02</span>
              <h3>Connect with Fellow Freelancers</h3> -->
              <p class="txt-p">Create your virtual team and connect with fellow freelancers to execute bigger project.</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="find-expert-item">
              <i class="flaticon-comment"></i>
              <!-- <i><img src="assets/img/icon/customer-satisfaction.png"></i> -->
              <!-- <span>03</span>
              <h3>Find Work at Zero Commission</h3> -->
              <p class="txt-p">Stop paying commission to freelancing sites for your hard work.</p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-6">
            <div class="find-expert-item">
              <i class="flaticon-comment"></i>
              <!-- <i><img src="assets/img/icon/customer-satisfaction.png"></i> -->
              <!-- <span>04</span>
              <h3>Connect Directly with Clients</h3> -->
              <p class="txt-p">Connect directly with global clients without any restrictions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Find Experts  -->

<!--========= Find Experts - New  ======-->

<!-- <section class="work-area pt-100 pb-50">
  <div class="container">
    <div class="section-title text-center">
      <span class="sub-title">Connect directly with clients & get project work at zero commission from global clients. Connect with fellow freelancers & create your virtual team to bid for bigger projects</span>
      <h2>Find Experts</h2>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-6 col-lg-6">
        <div class="work-item work-item-img">
          <img src="assets/img/banner/1.png" class="work-img" />
        </div>
      </div>

      <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <i class="flaticon-comment"></i>
              <span>01</span>
              <h3>Freedom as Freelancer</h3>
              <p class="box-p">Get freedom and autonomy as freelancer to work directly with clients.</p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <i class="flaticon-comment"></i>
              <span>03</span>
              <h3>Connect with Fellow Freelancers</h3>
              <p class="box-p">Create your virtual team and connect with fellow freelancers to execute bigger project.<br><br></p>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <i class="flaticon-comment"></i>
              <span>02</span>
              <h3>Find Work at Zero Commission</h3>
              <p class="box-p">Stop paying commission to freelancing sites for your hard work.</p>
            </div>
          </div>



          <div class="col-xs-12 col-sm-6 col-lg-6">
            <div class="work-item work-item-box">
              <i class="flaticon-comment"></i>
              <span>04</span>
              <h3>Connect Directly with Clients</h3>
              <p class="box-p">Connect directly with global clients without any restrictions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- Find Experts  -->

<!-- System -->
<section class="system-area ptb-50">
  <div class="system-shape">
    <img src="https://ik.imagekit.io/kxfkzv0rg/home-three/system1.png?updatedAt=1727765950820" alt="Shape" />
    <img src="https://ik.imagekit.io/kxfkzv0rg/home-three/system2.png?updatedAt=1727765950989" alt="Shape" />
  </div>

  <div class="container">
    <div class="system-item">
      <div class="section-title three">
        <div class="sub-title-wrap">
          <span class="sub-title">Quick Talent Marketplace</span>
        </div>
        <h2>You’ll Be Able To Recruit Qualified Candidate With OBM</h2>
      </div>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p> -->
      <ul class="system-list">
        <li>
          <span></span>
          Reduce Hiring TAT by 80%
        </li>
        <li>
          <span></span>
          Reduce Hiring Cost by 50%
        </li>
        <li>
          <span></span>
          Reduce Sales Cost by 50%
        </li>
        <li>
          <span></span>
          Increase Resource Utilization by 30%
        </li>
      </ul>
      <ul class="system-video">
        <li class="left-btn">
          <span>Watch Company Profile</span>
          <!-- <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=Rp7KIVKZOv0"><i class="bx bx-play"></i></a> -->
          <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=4nb5LA75_0Q"><i class="bx bx-play"></i></a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- End System -->

<!-- Work -->
<section class="work-area two three ptb-50 bg-light">
  <div class="container">
    <div class="section-title three">
      <div class="sub-title-wrap">
        <!-- <img src="assets/img/obm-favicon.png" class="favicon-img" alt="Icon"> -->
        <span class="sub-title">Working Process</span>
      </div>
      <h2>How It Works</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="work-item two three">
          <i class="flaticon-accounting"></i>
          <h3>Register An Account</h3>
          <p>Sign Up for free If you have a hiring need for Immediate Joiner or have bench employees / looking for clients.</p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="work-item two three">
          <i class="flaticon-file"></i>
          <h3>Upload Bench /Post Job</h3>
          <p>Post a job if you need immediate joiner on contract/ part-time. Upload profile of employees to deploy to clients</p>
        </div>
      </div>

      <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="work-item two three work-border">
          <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
          <h3>Connect Directly</h3>
          <p>Connect directly with clients or bench employer and hire/deploy resources.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Work -->

<!-- Feature -->
<section class="feature-area ptb-50">
  <div class="container-fluid">
    <div class="section-title three">
      <div class="sub-title-wrap">
        <span class="sub-title">Employers Offering Job</span>
      </div>
      <h2>Here's Features Job</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="feature-item">
          <!-- <a routerLink="/job-details"><img src="assets/img/portfolio2.jpg" alt="Feature" /></a> -->
          <div class="txt-bnner feature-bg1">
            <h6 class="text-light">Frontend Developer</h6>
          </div>

          <div class="bottom">
            <span>Angular.JS</span>
            <span>React.JS</span>
            <span>Next.JS</span>
            <span>MEAN</span>
            <!-- <span>MERN</span>
            <span>Vue.JS</span> -->
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="feature-item">
          <div class="txt-bnner feature-bg2">
            <h6 class="text-light">Backend Developer</h6>
          </div>

          <div class="bottom">
            <!-- <a routerLink="/job-details"><img src="assets/img/feature-2.png" alt="Feature" /></a> -->
            <span>PHP</span>
            <span>Python</span>
            <span>Laravel</span>
            <span>.Net</span>
            <!-- <span>NodeJs</span>
                      <span>Javav</span> -->
            <!-- <span>Django</span> -->

            <!-- <a routerLink="/job-details"><img src="assets/img/feature2.png" alt="Feature" /></a>
          <div class="bottom">
            <h3><a routerLink="/job-details">Digital Marketing</a></h3>
            <h3><a routerLink="/job-details">Digital Marketing</a></h3>
            <h3><a routerLink="/job-details">Digital Marketing</a></h3> -->
            <!-- <h3><a routerLink="/job-details">Digital Marketing</a></h3>
            <h3><a routerLink="/job-details">Digital Marketing</a></h3> -->
            <!-- <span>2 Jobs</span> -->
            <!-- <i class="flaticon-verify"></i> -->
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="feature-item">
          <!-- <h6>Mobile App Development</h6>
          <div class="bottom">
            <span>Android</span>
            <span>iOS</span>
            <span>iOS</span>
            <span>Swift</span>
            <span>Flutter</span>
            <span>Xamarin</span> -->

          <div class="txt-bnner feature-bg3">
            <h6 class="text-light">App Development</h6>
          </div>
          <!-- <h6 class="text-dark">App Development</h6> -->
          <div class="bottom">
            <!-- <a routerLink="/job-details"><img src="assets/img/feature-3.png" alt="Feature" /></a> -->
            <span>Android</span>
            <span>iOS</span>
            <span>Swift</span>
            <span>Flutter</span>
            <!-- <span>Xamarin</span>
            <span>Flutter</span> -->
            <!-- <span>Xamarin</span> -->

            <!-- <a routerLink="/job-details"><img src="assets/img/feature3.png" alt="Feature" /></a>
          <div class="bottom">
            <h3><a routerLink="/job-details">Customer Service</a></h3>
            <h3><a routerLink="/job-details">Customer Service</a></h3>
            <h3><a routerLink="/job-details">Customer Service</a></h3> -->
            <!-- <h3><a routerLink="/job-details">Customer Service</a></h3>
            <h3><a routerLink="/job-details">Customer Service</a></h3> -->
            <!-- <span>4 Jobs</span> -->
            <!-- <i class="flaticon-verify"></i> -->
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="feature-item">
          <div class="txt-bnner feature-bg4">
            <h6 class="text-light">Artificial Intelligence</h6>
          </div>

          <!-- <h6 class="text-dark">Artificial Intelligence</h6> -->
          <div class="bottom">
            <!-- <a routerLink="/job-details"><img src="assets/img/feature-4.png" alt="Feature" /></a> -->
            <span>Machine Learning</span>
            <span>Artificial Intelligence</span>
            <span>Deep Learning</span>
            <span>Data Analytics</span>
            <!-- <span>Data Analytics</span>

            <span>MEAN</span> -->

            <!-- <a routerLink="/job-details"><img src="assets/img/feature4.png" alt="Feature" /></a>
          <div class="bottom">
            <h3><a routerLink="/job-details">Broadcasting</a></h3>
            <h3><a routerLink="/job-details">Broadcasting</a></h3>
            <h3><a routerLink="/job-details">Broadcasting</a></h3> -->
            <!-- <h3><a routerLink="/job-details">Broadcasting</a></h3>
            <h3><a routerLink="/job-details">Broadcasting</a></h3> -->
            <!-- <span>1 Job</span> -->
            <!-- <i class="flaticon-verify"></i> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="job-browse">
      <p>Jobs are waiting for you <a routerLink="/jobs">Browse all jobs</a></p>
    </div> -->
  </div>
</section>
<!-- End Feature -->

<!-- Company -->
<!-- <section class="company-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="section-title three">
                    <div class="sub-title-wrap">
                        <img src="assets/img/obm-favicon.png" class="favicon-img" alt="Icon">
                        <span class="sub-title">Employers Offering Job</span>
                    </div>
                    <h2>Company Offering Job</h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="sorting-menu">
                    <ul>
                       <li class="filter" data-filter="all">All</li>
                       <li class="filter" data-filter=".m">Featured</li>
                       <li class="filter" data-filter=".n">Most Viewed</li>
                       <li class="filter" data-filter=".o">Newest</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="container" class="row">
            <div class="col-sm-6 col-lg-3 mix n">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Most Viewed</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company1.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Pi Agency</a></h3>
                        <span>Part Time Job</span>
                        <p><i class="flaticon-appointment"></i> 10 min ago / Austria, Vienna</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Pay Relocation Free</li>
                            <li>Remote Work</li>
                            <li>Duration: 5 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix n">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Most Viewed</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company2.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Kn It</a></h3>
                        <span>Permanent Job</span>
                        <p><i class="flaticon-appointment"></i> 9 min ago / Tirana, Albania</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Graphic Designer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>56K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix m">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company3.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Orbit Inc.</a></h3>
                        <span>Part Time Job</span>
                        <p><i class="flaticon-appointment"></i> 8 min ago / Doha, Qatar</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Product Manager</li>
                            <li>Remote Work</li>
                            <li>Duration: 5 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>70K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix o">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Newest</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company4.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Dev Roside</a></h3>
                        <span>Full Time Job</span>
                        <p><i class="flaticon-appointment"></i> 15 min ago / UK, England</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Design & Developer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>89K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix o">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Newest</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company5.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Roshu.co</a></h3>
                        <span>Part Time Job</span>
                        <p><i class="flaticon-appointment"></i> 10 min ago / Cardiff, England</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Internet Operator</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>66K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix m">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Featured</span>
                    </div>
                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company6.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Omti. Med</a></h3>
                        <span>Part Time Job</span>
                        <p><i class="flaticon-appointment"></i> 40 min ago / Tokyo, Japan</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Caring Officer</li>
                            <li>Remote Work</li>
                            <li>Duration: 2 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix o">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Newest</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company7.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Rahbar</a></h3>
                        <span>Full Time Job</span>
                        <p><i class="flaticon-appointment"></i> 7 min ago / Washington, US</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Media Connector</li>
                            <li>Remote Work</li>
                            <li>Duration: 3 Years</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>87K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix n">
                <div class="company-item">
                    <div class="feature-top-right">
                        <span>Most Viewed</span>
                    </div>

                    <div class="top">
                        <a routerLink="/employer-details"><img src="assets/img/home-one/company8.png" alt="Brand"></a>
                        <h3><a routerLink="/employer-details">Doblin. Fo</a></h3>
                        <span>Part Time Job</span>
                        <p><i class="flaticon-appointment"></i> 12 min ago / California, US</p>
                    </div>

                    <div class="bottom">
                        <ul>
                            <li>Private Officer</li>
                            <li>Remote Work</li>
                            <li>Duration: 1 Year</li>
                        </ul>
                        <span>Annual Salary</span>
                        <h4>50K</h4>
                        <a routerLink="/employer-details"><i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Company -->

<!-- Pricing -->

<!-- <section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title three">
            <div class="sub-title-wrap">
                <img src="assets/img/obm-favicon.png" class="favicon-img" alt="Icon">
                <span class="sub-title">Pricing Package</span>
            </div>
            <h2>Affordable Pricing Plan</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Premium</h3>
                        <span>For enormous Company</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 560/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>Unlimited Job Categories</li>
                            <li>Unlimited Job Posting</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li>Individually written job ads</li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Advanced</h3>
                        <span>For companies under 400</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 399/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>400 Job Posting</li>
                            <li>90 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li>Individually written job ads</li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Basic</h3>
                        <span>For companies under 80</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 150/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>50 Job Posting</li>
                            <li>20 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li>Resume database access</li>
                            <li><del>Individually written job ads</del></li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="pricing-item">
                    <div class="top">
                        <h3>Free</h3>
                        <span>For companies under 10</span>
                    </div>
                    <div class="middle">
                        <h4><span class="span-left">$</span> 100/ <span class="span-right">Month</span></h4>
                    </div>
                    <div class="end">
                        <ul>
                            <li>10 Job Posting</li>
                            <li>15 Job Categories</li>
                            <li>Unlimited proposals</li>
                            <li><del>Resume database access</del></li>
                            <li><del>Individually written job ads</del></li>
                        </ul>
                        <a class="cmn-btn" routerLink="/">Get Started <i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Pricing -->

<!-- Client -->
<!-- <section class="client-area pt-100 bg-light">
  <div class="client-img">
    <img src="assets/img/testimonials/client1.webp" alt="Client" />
    <img src="assets/img/testimonials/client2.webp" alt="Client" />
    <img src="assets/img/testimonials/client3.webp" alt="Client" />
    <img src="assets/img/testimonials/client4.png" alt="Client" />
    <img src="assets/img/testimonials/client5.webp" alt="Client" />
    <img src="assets/img/testimonials/client6.jpeg" alt="Client" />
  </div>

  <div class="container">
    <div class="section-title three">
      <div class="sub-title-wrap">

        <span class="sub-title">Testimonials</span>
      </div>
      <h2>Our Trusted Clients</h2>
    </div>

    <div class="client-slider owl-theme owl-carousel">
      <div class="client-item">
        <img src="assets/img/testimonials/client-1.jpg" class="client-img">
        <p>We were looking for Java Architects and found 2 good resources through OnBenchMark in a week’s time</p>
        <h3>Mahesh Menon</h3>
        <span>Managing Director at MBO Partners (Bengaluru)</span>
      </div>

      <div class="client-item">
        <img src="assets/img/testimonials/client-2.jpg" class="client-img">
        <p>“OnBenchMark is a great aggregator platform which brings consultant partners, freelancers and hiring companies together to support resource fulfillment in a short time. Affine has got some good closures recently and look forward to more in the future.”</p>
        <h3>Navin Patel</h3>
        <span>Recruitment Director at Affine (Bengaluru)</span>
      </div>


      <div class="client-item">
        <img src="assets/img/testimonials/client-4.webp" class="client-img">
        <p>“We are using OnBenchMark platform and hired top quality talent on niche technologies. The platform is a great initiative considering our business where we help global IT Companies to build their remote tech teams.”</p>
        <h3>Vyom Bhardwaj</h3>
        <span>Director Technology at Muoro</span>
      </div>

      <div class="client-item">
        <img src="assets/img/testimonials/client-5.png" class="client-img">
        <p>“Our experience with OnBenchMark has been nothing short of impressive. It's a valuable tool that simplifies resource deployment and enhances our bench management.”</p>
        <h3>Jay Kumar Patel</h3>
        <span>Founder & Director at Namah Softech (Vadodra)</span>
      </div>



      <div class="client-item">
        <img src="assets/img/testimonials/client-7.webp" class="client-img">
        <p>“OnBenchMark is a complete solution for all our temporary hiring needs. We have got an excellent quality of candidates at competitive cost with a simplified and unrestricted process of connecting with candidates.”</p>
        <h3>Jyant Kohli</h3>
        <span>Founder & COO of Lawrbit Global Compliance</span>
      </div>

      <div class="client-item">
        <img src="assets/img/testimonials/client-8.webp" class="client-img">
        <p>“"I am happy to be associated with OnBenchMark as it provides me much needed freedom as a freelancer. I got some very good projects through them without worrying about paying high commission unlike other top freelancing website. I highly recommend OnBenchMark for its trusted services at no extra cost”</p>
        <h3>Ankit Kumar Singh</h3>
        <span>Freelancer-Graphic Designer</span>
      </div>
    </div>
  </div>
</section> -->
<!-- End Client -->

<!-- Blog -->
<!-- <section class="blog-area three pt-50 pb-100">
  <div class="container">
    <div class="section-title three">
      <div class="sub-title-wrap">

        <span class="sub-title">News & Blog</span>
      </div>
      <h2>Latest Blog Posts</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="blog-item">
          <div class="top">
            <a routerLink="/blog-details"><img src="assets/img/home-one/blog1.jpg" alt="Blog" /></a>
          </div>
          <span>Job skills. 12-09-2024</span>
          <h3><a routerLink="/blog-details">The Internet Is A Job Seeker Most Crucial Success </a></h3>
          <div class="cmn-link">
            <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="blog-item">
          <div class="top">
            <a routerLink="/blog-details"><img src="assets/img/home-one/blog2.jpg" alt="Blog" /></a>
          </div>
          <span>Career advice. 11-09-2024</span>
          <h3><a routerLink="/blog-details">Today From Connecting With Potential Employers</a></h3>
          <div class="cmn-link">
            <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="blog-item">
          <div class="top">
            <a routerLink="/blog-details"><img src="assets/img/home-one/blog3.jpg" alt="Blog" /></a>
          </div>
          <span>Future plan. 10-09-2024</span>
          <h3><a routerLink="/blog-details">We’ve Weeded Through Hundreds Of Job Hunting</a></h3>
          <div class="cmn-link">
            <a routerLink="/blog-details"><i class="flaticon-right-arrow one"></i> Learn More <i class="flaticon-right-arrow two"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Blog -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
