<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container mt-50" >
        <div class="row align-items-end">
          <div class="col-lg-8 col-md-12">
            <div class="left">
              <h2>{{ data.name }}</h2>
              <ul>
                <li><i class="bx bx-box"></i> UX/UI Designer</li>
                <li><i class="fa-solid fa-location-dot"></i> {{ data.currentlocation }}</li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-12">
            <div class="right">
              <ul>
                <li>
                  <a>{{ candidate.jobtype }}</a>
                </li>
              </ul>
            </div>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Candidate Details -->
<div class="person-details-area pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="details-item">
          <div class="work bottom-item">
            <h3 class="mb-0">Skills</h3>
            <span *ngFor="let s of data.skill" class="span-one " style="margin: 2px 2px">{{ s }}</span>
          </div>

          <div class="work bottom-item">
            <h3 class="mb-0">Description</h3>

            <div [innerHTML]="data.description"></div>
          </div>

          <div class="portfolio mb-4">
            <h3 class="mb-0">Resume</h3>

            <div style="padding: 20px; border: 1px solid #ccc; border-radius: 10px; background: #ececec !important" [innerHTML]="data.dochtml[0]"></div>
          </div>
        </div>
      </div>

    
    </div>
  </div>
</div>
<!-- End Candidate Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
