import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobApplicantsService {

  apiUrl: any = "https://www.onbenchmark.com/";
  jsonheader: any;
  public globalprize = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { 
    this.jsonheader = new HttpHeaders({ "Content-Type": "application/json" });
  } 

  changeprizeglobal(prize:any) {
    this.globalprize.next(prize); 
  }

  initialize() {
    this.jsonheader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`, 
    });
  }



  async FindbyId(data: any, tbl: any): Promise<any> {
    return this.http.get(`${this.apiUrl}api/${tbl}/${data}`).toPromise().catch((error) => {
      console.error("Error occurred while finding data by id:", error);
      throw error;
    });
  }

  // async jobAppUpdateStatus(data: any, tbl: any): Promise<any> {
  //   return this.http.post(`${this.apiUrl}custom/jobappupdateStatus/${tbl}`, data, { headers: this.authService.jsonheader })
  //   .toPromise().catch((error) => {
  //       console.error("Error occurred while updating job status:", error);
  //       throw error;
  //     });
  // }

  async jobAppUpdateStatus(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/jobappupdateStatus/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise(); 
      return response;
    } catch (error) {
      console.error("Error occurred while updating job status:", error);
      throw error; 
    }
  }

  async getAppication(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/jobapplication/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while fetching job applicants data:", error);
      throw error; 
    }
  }

  async getTotalApplication(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/gettotalapplication/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while fetching total job applicants data:", error);
      throw error; 
    }
  }

  async ratePost(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/ratepost/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while getting rate data:", error);
      throw error; 
    }
  }

  async exportExcel(data: any, tbl: any): Promise<any> {
    try {
      const response = await this.http.post(`${this.apiUrl}custom/${tbl}`, data, { headers: this.authService.jsonheader })
      .toPromise();
      return response;
    } catch (error) {
      console.error("Error occurred while getting excel:", error);
      throw error; 
    }
  }





}
