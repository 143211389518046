import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router"
import { ToastrService } from "ngx-toastr";
import { FormBuilder, Validators } from '@angular/forms';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ProfileService } from 'src/app/services/profile.service';

import { MustMatch } from "../must-match"; 

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {

  profileForm: any;

  receivedToken = "";
  resetPasswordForm: any;
  submitted: boolean = false;
  resetformstt = false;
  alreadyResetmsg: any = null;
  errormsg: any = null;

  profileData: any = {};
  loginId:any = "";
  companyId:any = "";

  constructor(
    public fb: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingScreenService,
    private toastrService: ToastrService
  ) {
    this.createResetPasswordForm();

    // this.activatedRoute.queryParams.subscribe((params: any) => {
    //   this.receivedToken = params["token"];
    //   if (this.receivedToken) {
    //     this.checkToken();
    //   }
    // });

  }

  ngOnInit(): void {
    // this.getProfile();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      password: ["", Validators.compose([ Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
      confirmpwd: ['', Validators.required]
    },
      {
        validators: MustMatch('password', 'confirmpwd') 
      }
    );
  }

  get formResetPassword() {
    return this.resetPasswordForm.controls;
  }


  onSubmit(){
    
  }

  // onSubmit() {
  //   this.submitted = true;

  //   if (this.resetPasswordForm.invalid) {
  //     this.loader.stopLoading();
  //     return;
  //   }

  //   this.loader.startLoading();

  //   let data = { password: this.resetPasswordForm.value.password };

  //   this.profileService.update(this.loginId, data, 'user').then((res: any) => {
  //     const actiobj = {
  //       companyLoginId: this.loginId,
  //       desc: "updated password"
  //     };

  //     this.profileService.create(actiobj, 'activity').then((res: any) => {
  //       this.loader.stopLoading();
  //       this.createResetPasswordForm();
  //       this.toastrService.success('Password reset successfully');
  //     }, err => {
  //       this.loader.stopLoading();
  //       console.error('Error creating activity:', err);
  //       this.toastrService.error('Please try again later');
  //     });

  //   }, err => {
  //     this.loader.stopLoading();
  //     console.error('Error updating password:', err);
  //     this.toastrService.error('Please try again later');
  //   });
  // }





}
