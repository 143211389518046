import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { ExcelService } from "src/app/services/excel.service";
import { JobApplicantsService } from "src/app/services/job-applicants.service";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-job-applicants",
  templateUrl: "./job-applicants.component.html",
  styleUrl: "./job-applicants.component.scss",
})
export class JobApplicantsComponent implements OnInit {
  @ViewChild("autoShownModal") autoShownModal!: ModalDirective;

  minDate = new Date();

  isModalShown = false;
  isModalShownpackage = false;
  tab: any = [true, false, false, false];
  jobdata: any = {};
  jobapp: any = [];
  jobid: any = "";
  rate = 0;
  ratearr: any = [false, false, false, false, false];
  rateobj: any = { ratingFor: "", rate: 0, name: "", inx: 0 };
  ratestt = false;
  description: any = "";
  myId: any = "";
  total: any = {};
  tabstt = 1;
  querydata: any = {
    query: { $and: [] },
    total: 0,
    limit: 25,
    page: 1,
    short: { updatedAt: -1 },
    jobId: "",
    totalpage: 0,
  };
  totaljob = 0;
  searchForm: any;
  openstt = 1;
  shimmer = false;
  utype: any = "";
  authdata: any = {};
  currencytype: any = "";

  constructor(
    private formBuild: FormBuilder,
    private jobApplicantsSevice: JobApplicantsService,
    private excelService: ExcelService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private loader: LoadingScreenService,
    private auth: AuthService,
    public datepipe: DatePipe,
    private router: Router,
  ) {
    this.loader.stopLoading();
    this.searchFormCreate();
    this.route.queryParams.subscribe((params) => {
      this.jobid = params["jobid"];
      this.tabstt = params["tab"];
      if (this.jobid) {
        this.getJob();
      }
      if (this.tabstt == 1) {
        this.getList(1, 0);
      } else if (this.tabstt == 2) {
        this.getList(2, 1);
      } else if (this.tabstt == 3) {
        this.getList(3, 2);
      } else {
        this.getList(4, 3);
      }
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem("currency")) {
      this.currencytype = localStorage.getItem("currency");
    }
    this.jobApplicantsSevice.globalprize.subscribe((res: any) => {
      this.currencytype = res.ctype;
    });
    if (this.auth.getloginstt()) {
      this.authdata = this.auth.getloginData();
    }
    this.auth.profiledata.subscribe((res: any) => {
      this.authdata = res;
    });
    this.gettotalapplication();
  }

  showModal(val: any): void {
    if (val == "comment") {
      this.isModalShown = true;
    }
    if (val == "upgrade") {
      this.isModalShownpackage = true;
    }
  }

  parseRate(list: any) {
    if (list.candidateId.ratetype == "Hourly") {
      let newRate = parseInt((list.candidateId.rate * 168 * 83.5).toString());
      let roundedNumber = Math.round(newRate / 1000) * 1000;
      return parseInt(roundedNumber.toString()).toLocaleString();
    }
  }
  // ?.candidateId?.rate
  hideModal(val: any): void {
    if (val == "upgrade") {
      this.isModalShownpackage = false;
    }
    if (val == "comment") {
      this.isModalShown = false;
    }
  }
  onHidden(val: any): void {
    if (val == "upgrade") {
      this.isModalShownpackage = false;
    }
    if (val == "comment") {
      this.isModalShown = false;
    }
  }

  searchFormCreate() {
    this.searchForm = this.formBuild.group({
      namesearch: new FormControl("", Validators.required),
      rangedate: new FormControl("", Validators.required),
    });
  }

  getList(stt: any, inx: any) {
    this.tab = [false, false, false, false];
    this.tab[inx] = true;
    this.openstt = stt;
    this.querydata.page = 1;
    this.querydata.openstt = stt;
    this.searchFormCreate();
    this.filterjob();
  }

  getJob() {
    this.loader.startLoading();
    this.jobApplicantsSevice.FindbyId(this.jobid, "job").then(
      (res: any) => {
        this.jobdata = res.data;
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  updateStatus(ids: any, val: any, inx: any, utype: any): any {
    this.utype = utype;
    this.loader.startLoading();
    this.jobApplicantsSevice
      .jobAppUpdateStatus(
        { status: val, appId: ids, utype: utype },
        "jobapplication",
      )
      .then(
        (res: any) => {
          this.loader.stopLoading();
          if (res.data == "upgrade") {
            this.showModal("upgrade");
            return false;
          }
          if (val == 2) {
            this.toaster.success("Shortlisted Successfully");
            this.tab = [false, true, false, false];
            this.openstt = 2;
            this.querydata.openstt = 2;
            this.filterjob();
          } else if (val == 3) {
            this.openstt = 3;
            this.tab = [false, false, true, false];
            this.querydata.openstt = 3;
            this.filterjob();

            this.toaster.success("Hired Successfully");
          } else if (val == 4) {
            this.toaster.success("Rejected Successfully");

            this.filterjob();
          }
          return true;
        },
        (err: any) => {
          this.loader.stopLoading();
        },
      );
  }

  filterjob() {
    this.querydata.query = { $and: [] };
    let fdata = this.searchForm.value;
    let orarr: any = { $or: [] };
    if (fdata.namesearch) {
      orarr.$or.push({ jId: { $regex: fdata.namesearch, $options: "i" } });
      orarr.$or.push({ jname: { $regex: fdata.namesearch, $options: "i" } });
      orarr.$or.push({ benchId: { $regex: fdata.namesearch, $options: "i" } });
      orarr.$or.push({
        benchname: { $regex: fdata.namesearch, $options: "i" },
      });
      orarr.$or.push({ freename: { $regex: fdata.namesearch, $options: "i" } });
      orarr.$or.push({
        freephone: { $regex: fdata.namesearch, $options: "i" },
      });
      orarr.$or.push({
        freeemail: { $regex: fdata.namesearch, $options: "i" },
      });
      this.querydata.query.$and.push(orarr);
    }
    if (fdata.rangedate) {
      var todate = this.datepipe.transform(fdata.rangedate[0], "yyyy-MM-dd");
      var fromdate = this.datepipe.transform(fdata.rangedate[1], "yyyy-MM-dd");
      let dq = {
        createdAt: { $gt: todate + " 00:00:00", $lt: fromdate + " 23:59:00" },
      };
      this.querydata.query.$and.push(dq);
    }
    this.jobapp = [];
    if (this.openstt == 1) {
      this.querydata.query.$and.push({ status: 1 });
      this.getappliedlist();
    } else if (this.openstt == 2) {
      this.querydata.query.$and.push({ status: 2 });
      this.getshortlist();
    } else if (this.openstt == 3) {
      this.querydata.query.$and.push({ status: 3 });
      this.gethiredtlist();
    } else {
      this.querydata.query.$and.push({ status: 4 });
      this.getrejecttlist();
    }
  }

  exportexcel(): any {
    let fdata = this.searchForm.value;
    if (!fdata.rangedate) {
      this.toaster.error("Please select date range");
      return false;
    }
    const todate = this.datepipe.transform(fdata.rangedate[0], "yyyy-MM-dd");
    const fromdate = this.datepipe.transform(fdata.rangedate[1], "yyyy-MM-dd");
    const filter: any = {
      query: {
        $and: [
          {
            createdAt: {
              $gt: todate + " 00:00:00",
              $lt: fromdate + " 23:59:00",
            },
          },
        ],
      },
      openstt: this.openstt,
    };
    if (this.openstt == 2) {
      filter.query.$and.push({ status: 2 });
    }
    if (this.openstt == 3) {
      filter.query.$and.push({ status: 3 });
    }
    this.loader.startLoading();
    this.jobApplicantsSevice.exportExcel(filter, "exportexcel").then(
      (res: any) => {
        if (res.confirmation == "success") {
          this.excelService.exportAsExcelFile(res.data, "job_application");
        }
        this.loader.stopLoading();
      },
      (err) => {
        this.loader.stopLoading();
      },
    );
  }

  getlistbypage(val: any) {
    if (val == "next") {
      if (this.querydata.page < this.querydata.totalpage) {
        this.querydata.page = this.querydata.page + 1;
        this.filterjob();
      }
      if (this.querydata.page == this.querydata.totalpage) {
        this.filterjob();
      }
    }
    if (val == "prev") {
      if (this.querydata.page > 1) {
        this.querydata.page = this.querydata.page - 1;
        this.filterjob();
      }
    }
  }

  rateUs(inx: any, rtype: any, rate: any) {
    this.rate = rate;
    for (let i = 0; i < this.ratearr.length; i++) {
      if (inx >= i) {
        this.ratearr[i] = true;
      } else {
        this.ratearr[i] = false;
      }
    }
    this.ratestt = true;
  }

  resetrate() {
    this.ratestt = false;
    this.ratearr = [false, false, false, false, false];
    this.description = "";
  }

  rateOpen(ids: any, rtype: any, name: any, inx: any) {
    this.rateobj.ratingFor = ids;
    this.rateobj.name = name;
    this.rateobj.inx = inx;
    this.showModal("comment");
  }

  getValue(val: any) {
    return val.replace("_", " ");
  }

  addRate() {
    let data = {
      candidateId: this.rateobj.ratingFor,
      rate: this.rate,
      description: this.description,
    };
    this.jobApplicantsSevice.ratePost(data, "candidate").then(
      (res: any) => {
        this.toaster.success("Apply rate successfully!!");
        this.hideModal("comment");
        this.resetrate();
      },
      (err) => {},
    );
  }

  ratechacking(ratearr: any) {
    const myId = this.myId;
    let arrlength = ratearr.filter(function (el: any) {
      return el.rateby == myId;
    });
    if (arrlength.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  //=================================================

  getappliedlist() {
    //this.querydata.query.$and.push({ status: 1 });
    if (this.jobid) {
      this.querydata.jobId = this.jobid;
    }
    this.shimmer = true;
    this.jobApplicantsSevice
      .getAppication(this.querydata, "jobapplication")
      .then(
        (res: any) => {
          this.jobapp = res.data.data;
          this.querydata.total = res.data.total;
          this.querydata.totalpage = Math.ceil(
            this.querydata.total / this.querydata.limit,
          );
          this.shimmer = false;
          this.gettotalapplication();
        },
        (err) => {
          this.shimmer = false;
        },
      );
  }

  getshortlist() {
    //this.querydata.query.$and.push({ status: 2 });
    if (this.jobid) {
      this.querydata.jobId = this.jobid;
    }
    this.shimmer = true;
    this.jobapp = [];
    this.jobApplicantsSevice
      .getAppication(this.querydata, "jobapplication")
      .then(
        (res: any) => {
          this.jobapp = res.data.data;
          this.querydata.total = res.data.total;
          this.querydata.totalpage = Math.ceil(
            this.querydata.total / this.querydata.limit,
          );
          this.shimmer = false;
          this.gettotalapplication();
        },
        (err) => {
          this.shimmer = false;
        },
      );
  }

  gethiredtlist() {
    //this.querydata.query.$and.push({ status: 3 });
    if (this.jobid) {
      this.querydata.jobId = this.jobid;
    }
    this.shimmer = true;
    this.jobApplicantsSevice
      .getAppication(this.querydata, "jobapplication")
      .then(
        (res: any) => {
          this.jobapp = res.data.data;
          this.querydata.total = res.data.total;
          this.querydata.totalpage = Math.ceil(
            this.querydata.total / this.querydata.limit,
          );
          this.shimmer = false;
          this.gettotalapplication();
        },
        (err) => {
          this.shimmer = false;
        },
      );
  }

  getrejecttlist() {
    //this.querydata.query.$and.push({ status: 4 });
    if (this.jobid) {
      this.querydata.jobId = this.jobid;
    }
    this.shimmer = true;
    this.jobApplicantsSevice
      .getAppication(this.querydata, "jobapplication")
      .then(
        (res: any) => {
          this.jobapp = res.data.data;
          this.querydata.total = res.data.total;
          this.querydata.totalpage = Math.ceil(
            this.querydata.total / this.querydata.limit,
          );
          this.shimmer = false;
          this.gettotalapplication();
        },
        (err) => {
          this.shimmer = false;
        },
      );
  }

  gettotalapplication() {
    let data = {};
    if (this.jobid) {
      data = { jobId: this.jobid };
    } else {
      data = {};
    }
    this.jobApplicantsSevice.getTotalApplication(data, "jobapplication").then(
      (res: any) => {
        this.total = res.data;
      },
      (err) => {},
    );
  }

  openJobDetails(job) {
    this.router.navigateByUrl("/my-job-details?jobId=" + job._id);
  }





}
