<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Hire Contract Resources at ZERO Commission</h2>
          
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area ptb-50">
  <div class="container">
    <!-- <div class="title-item text-center pb-5">
      <h2>Hire Contract Resources at ZERO Commission</h2>
      
    </div> -->
    
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="new-img">
          <img src="assets/img/immediate-joiner.png" alt="New" />
          <!-- <img src="assets/img/about-shape.png" alt="New" /> -->
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <div class="section-title">
            <span class="sub-title">Hire Immediate Joiners on Contract</span>
            <h2>Hire Immediate Joiners on Contract</h2>
          </div>
          <p>
            Hire Developers on Contract from the networked community of about 5000 IT companies with about 7000 real time active Bench candidate available to join immediately.
          </p>

          <!-- <h6>Let's talk +91 97189 28125    <span><a class="cmn-btn" routerLink="/contact"> Contact Us</a></span></h6> -->

          <div class="buttons">
            <ul>
              <li><a routerLink="/register">SignUp - Free forever</a></li>
              <li><a routerLink="/pricing">Pricing</a></li>
            </ul>
          </div>

          <!-- <a class="cmn-btn" routerLink="/contact">Pricing<i class='bx bx-plus'></i></a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End New -->

<!-- Counter -->
<div class="counter-area two ptb-50 ">
  <div class="counter-shape">
    <img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" />
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-hiring"></i>
          <p>CANDIDATES</p>
          <h3><span class="odometer" data-count="87360">00</span></h3>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-candidate"></i>
          <p>TOTAL JOBS</p>
          <h3><span class="odometer" data-count="45257">00</span></h3>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-working-at-home"></i>
          <p>EMPLOYERS</p>
          <h3>
            <span class="odometer" data-count="9650">00</span>
          </h3>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="flaticon-choice"></i>
          <p>JOB APPLICATIONS</p>
          <h3><span class="odometer" data-count="70299">00</span></h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Counter -->

<!-- Work -->
<section class="work-area pb-50">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="work-item">
         
          <h3 class="text-center">Reduce your Hiring Cost</h3>
          <p class="text-center">
            No more TA team is required to hire contract resources. Our Team
            will assist you to hire resources on Contract. Get dedicated
            recruiter
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="work-item ">
          <!-- <i class="flaticon-file"></i>
          <span>02</span> -->
          <h3 class="text-center">PAN India Contract Resources</h3>
          <p class="text-center">
            Get resources on Contract locally and hire onsite or offsite
            candidates at lowest rates. Get partners to outsource your project
            or job
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="work-item">
          <!-- <i class="flaticon-comment"></i>
          <span>03</span> -->
          <h3 class="text-center">No Hiring Commission</h3>
          <p class="text-center">
            You don’t need to pay any Hiring commission to on-board any resource
            on contract. Just connect & hire directly from IT Companies
          </p>
        </div>
      </div>

      <!-- <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>Knowledge</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>The Passion</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>06</span>
                    <h3>Innovation</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div> -->
    </div>
  </div>
</section>
<!-- End Work -->

<!-- Explore -->
<!-- <section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Be Found Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p>
            <ul>
                <li>
                    <a class="left-btn" routerLink="/">Upload Your Resume <i class="flaticon-upload"></i></a>
                </li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End Explore -->

<!-- Candidate -->
<!-- <section class="candidate-area two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>Leadership Team</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                    <span>Web Developer</span>
                    <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate1.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div>
        </div>
    </div>
</section> -->
<!-- End Candidate -->

<!-- Info -->
<div class="contact-info-area ptb-50 bg-light">
  <div class="container">
    <h2 class="text-center pb-4">How To Hire Contract Developers in 2 Days</h2>
    <div class="row">
      <div class="col-sm-4 col-lg-4">
        <div class="info-item">
          <!-- <i class="bx bx-world"></i> -->
          <i class="img-icon bench-icon"><img src="assets/img/icon/post-job.png"></i>
          <h3>Post your Job</h3>
          <!-- <p>54 Hegmann Uninuo Apt. 890, New York, NY 10018, United States.</p> -->
        </div>
      </div>

      <div class="col-sm-4 col-lg-4">
        <div class="info-item">
          <!-- <i class="bx bx-world"></i> -->
          <i class="img-icon bench-icon"><img src="assets/img/icon/available-bench.png"></i>
          <h3>Connect with Available Bench</h3>
          <!-- <p>
              BSI Business Park, H-161, G10, Sector 63, Noida, Uttar Pradesh
              201301
            </p> -->
        </div>
      </div>

      <div class="col-sm-4 col-lg-4">
        <div class="info-item">
          <!-- <i class="bx bx-world"></i> -->
          <i class="img-icon bench-icon"><img src="assets/img/icon/on-board.png"></i>
          <h3>On-Board Contractor</h3>
          <!-- <p>142 West newton, New York 19088 United States</p> -->
        </div>
      </div>
    </div>
  </div>
</div >
<!-- End Info -->

<!-- Jobs -->
<div class="job-area-list pt-50">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8">
        <div class="employer-item">
            <!-- <img src="assets/img/home-one/job1.png" alt="Employer" /> -->
            <h1>
              Choose a right resource from over 5000 bench resources available
              on contract
            </h1>

            <p>
              Connect directly with thousands of Bench employers or take a help
              from our dedicated recruiter to shortlist contract employees from
              thousands of bench to increase your Temporary staff
            </p>
         
        </div>
      </div>

      <div class="col-lg-4 col-md-4">
        <div class="employer-item">
          <h4>Vetted Bench Employers</h4>

          <p>
            Hire contract resources from vetted bench employers from IT
            companies
          </p>

          <h4>Vetted Resources on Contract</h4>

          <p>
            Stringent process of on-Boarding only the best of the resources
            available on Contract
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
