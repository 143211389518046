import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router"
import { ToastrService } from "ngx-toastr";
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss' 
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: any;
  submitted: boolean = false;

  apiUrl: any = "https://www.onbenchmark.com/";
  localUrl: any = "http://localhost:4500/";

  email: any;
  ifMsgSent: boolean = false;



  // custom validators
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";


  constructor(
    public fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.createForgotPasswordForm();
  }

  ngOnInit(): void {}

  createForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      "email": ["", Validators.compose([Validators.required, Validators.email, Validators.pattern(this.emailPattern)])]
    });    
  }

  get formForgotPassword() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit() {
    let clientEmail: any = this.forgotPasswordForm.get('email').value;

    this.authService.forgotPassword({ email: clientEmail }).then((res) => {    
        if (res.confirmation === "success") {
            // console.log("Sudatt you are in function!!");
            console.log("response: ", res);
            this.ifMsgSent = true;
            // this.router.navigate(['/dashboard']);          
        } else if (res.confirmation === "already") {
            this.toastrService.error(res.message);
            console.log("response message: ", res.message);
        } else if (res.confirmation === "invalid") {
            this.toastrService.error(res.message);
            console.log("response message: ", res.message);
        } else if (res.confirmation === "error") {
          this.toastrService.error(res.message);
          console.log("response message: ", res.message);
        } else {
            console.error("Reset Password failed", res);
        }
    },
    (error) => {
        console.error("Forgot Password Failed", error);
    });
  }

}
