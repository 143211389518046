<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Add Bench Employee</h2>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Add_Bench -->
<div class="add_Bench ptb-50">
  <div class="container">
    <!-- [formGroup]="addBench" -->
    <form [formGroup]="addBenchForm">
      <div class="bench_item">
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-12">
            <label>Name<span class="text-danger">*</span></label>
          </div>
          <div class="col-lg-6 col-md-12">
            <input type="text" class="form-control" placeholder="Enter First Name*" formControlName="fname" [ngClass]="{ 'is-invalid': formCreateBench.fname.invalid && (formCreateBench.fname.touched || submitted), 'is-valid': formCreateBench.fname.valid }" />
            <div *ngIf="formCreateBench.fname.invalid && (formCreateBench.fname.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.fname.errors?.required"> First Name is <b>required.</b> </small>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <input type="text" class="form-control" placeholder="Enter Last Name*" formControlName="lname" [ngClass]="{ 'is-invalid': formCreateBench.lname.invalid && (formCreateBench.lname.touched || submitted), 'is-valid': formCreateBench.lname.valid }" />
            <div *ngIf="formCreateBench.lname.invalid && (formCreateBench.lname.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.lname.errors?.required"> Last Name is <b>required.</b> </small>
            </div>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-6 col-md-12">
            <label>Gender<span class="text-danger">*</span></label>
            <!-- <select formControlName="gender" class="form-control" style="width: 100%;" >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>  -->
            <ng-select [searchable]="false" [multiple]="false" formControlName="gender" placeholder="Select Gender" [ngClass]="{ 'is-invalid': formCreateBench.gender.invalid && (formCreateBench.gender.touched || submitted), 'is-valid': formCreateBench.gender.valid }">
              <ng-option value="" [disabled]="true"> Select Gender </ng-option>
              <ng-option value="Male">Male</ng-option>
              <ng-option value="Female">Female</ng-option>
              <ng-option value="Other">Other</ng-option>
            </ng-select>
            <div *ngIf="formCreateBench.gender.invalid && (formCreateBench.gender.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.gender.errors?.required"> Gender is <b>required.</b> </small>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <label>Designation<span class="text-danger">*</span> </label>
            <input type="text" class="form-control" placeholder="Enter Designation" formControlName="designation" maxlength="70" [ngClass]="{ 'is-invalid': formCreateBench.designation.invalid && (formCreateBench.designation.touched || submitted), 'is-valid': formCreateBench.designation.valid }" />
            <div *ngIf="formCreateBench.designation.invalid && (formCreateBench.designation.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.designation.errors?.required">Designation is <b>required. </b></small>
              <small class="text-danger" *ngIf="formCreateBench.designation.errors?.maxlength">Designation could be of maximum <b>70 characters</b>.</small>
            </div>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div class="col-lg-4 col-md-12">
            <label>Total Experience (Years)<span class="text-danger">*</span> </label>
            <input type="number" class="form-control" id="expid" placeholder="Experience" min="0" formControlName="experience" />
            <div *ngIf="formCreateBench.experience.invalid && (formCreateBench.experience.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.experience.errors?.required">Experience is <b>required.</b></small>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <ng-template #popTemplate>
              <div [innerHtml]="info['workfrom']"></div>
            </ng-template>
            <!-- [popover]="popTemplate" [outsideClick]="true" -->
            <label
              >Deployment Type
              <i popovertitle="Deployment Type" class="bx bxs-info-circle pointer mb-0 pb-0"></i>
              <span class="text-danger">*</span>
            </label>
            <!-- <select class="form-control" formControlName="onsiteAvailability"  style="width: 100%;">
                            <option value="">Select</option>
                            <option value="Offsite">Offsite</option>
                            <option value="Onsite">Onsite</option>
                            <option value="Any">Anywhere</option>
                        </select>  -->

            <ng-select [searchable]="false" [multiple]="false" formControlName="onsiteAvailability" placeholder="Select" [ngClass]="{ 'is-invalid': formCreateBench.onsiteAvailability.invalid && (formCreateBench.onsiteAvailability.touched || submitted), 'is-valid': formCreateBench.onsiteAvailability.valid }">
              <ng-option value="" [disabled]="true"> Select </ng-option>
              <ng-option value="Offsite">Remote</ng-option>
              <ng-option value="Onsite">Onsite</ng-option> 
              <ng-option value="Any">Anywhere</ng-option>
            </ng-select>

            <div *ngIf="formCreateBench.onsiteAvailability.invalid && (formCreateBench.onsiteAvailability.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.onsiteAvailability.errors?.required"> Deployment Type is <b>required.</b> </small>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <label>Availability<span class="text-danger">*</span> </label>
            <!-- <select class="form-control" formControlName="Availability">
                          <option value="">Select Availability </option>
                          <option value="Immediate">Immediate</option>
                          <option value="1_Week">1 Week</option>
                          <option value="2_Week">2 Week</option>
                          <option value="3_Week">3 Week</option>
                          <option value="1_Month">1 Month</option>
                          <option value="More_1_Month">More than 1 Month</option>
                        </select>   -->

            <ng-select [searchable]="false" [multiple]="false" formControlName="Availability" placeholder="Select" [ngClass]="{ 'is-invalid': formCreateBench.Availability.invalid && (formCreateBench.Availability.touched || submitted), 'is-valid': formCreateBench.Availability.valid }">
              <ng-option value="" [disabled]="true"> Select Availability </ng-option>
              <ng-option value="Immediate">Immediate</ng-option>
              <ng-option value="1_Week">1 Week</ng-option>
              <ng-option value="2_Week">2 Week</ng-option>
              <ng-option value="3_Week">3 Week</ng-option>
              <ng-option value="1_Month">1 Month</ng-option>
              <ng-option value="More_1_Month">More 1 Month</ng-option>
            </ng-select>
            <div *ngIf="formCreateBench.Availability.invalid && (formCreateBench.Availability.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.Availability.errors?.required"> Availability is <b>required.</b> </small>
            </div>
          </div>
        </div>
        <!-- ========================================== -->
        <div class="row form-group">
          <div *ngIf="othercity == false" class="col-lg-12">
            <label>Candidate Location<span class="text-danger">*</span> </label>
            <!-- (change)="locationclear()" -->
            <input type="text" class="form-control" placeholder="Choose Candidate Location" (keyup)="get_city($any($event.target).value)" id="citycountry" />
            <span *ngIf="loderstt" class="city_loader">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            <div id="myDropdown" class="dropdownContent_city {{ cls }}">
              <a *ngFor="let item of citylist" (click)="setvalue(item.city_name, item?.country_id?.country_name, item._id)"> {{ item?.city_name }} ,{{ item?.state_id?.state_name }} ({{ item?.country_id?.country_name }}) </a>
              <a (click)="othercityslt()"> Other City </a>
            </div>
          </div>

          <div *ngIf="othercity" class="col-sm-3">
            <label>City Name<span class="text-danger">*</span> </label>
            <input type="text" id="othercity" class="form-control inputFieldBorder" (change)="otherslt($any($event.target).value, 1)" placeholder="Enter City Name" />
          </div>
          <div *ngIf="othercity" class="col-sm-3">
            <label>Country Name<span class="text-danger">*</span> </label>
            <a href="javascript:void(0)" class="ps-3">
              <span class="badge bg-secondary" (click)="othercityslt()">Clear</span>
            </a>
            <select class="form-control inputFieldBorder" id="othercountry" (change)="otherslt($any($event.target).value, 2)">
              <option value="">Select Country *</option>
              <option *ngFor="let list of countrylist" value="{{ list?.country_name }}">{{ list?.country_name }}</option>
            </select>
          </div>
          <!-- ------------------------------------------------- -->
          <!-- <div class="col-lg-6 col-md-12">
            <label>Category<span class="text-danger">*</span></label>

            <ng-select [multiple]="false" formControlName="jobtype" placeholder="Select Job">
              <ng-option value="" [disabled]="true"> Select Job </ng-option>
              <ng-option *ngFor="let list of jobType" [value]="list?.name">
                {{ list?.name }}
              </ng-option>
            </ng-select>
            <div *ngIf="(formCreateBench.jobtype.invalid && formCreateBench.jobtype.touched) || (formCreateBench.jobtype.invalid && submitted)">
              <small class="text-danger">Job Type is <b>required.</b></small>
            </div>
          </div> -->
        </div>
        <!-- ===================================== -->
        <div class="form-group row">
          <div class="col-sm-12">
            <label> Skills<span class="text-danger">*</span> </label>
            <div>
              <span class="my-slt-li" *ngFor="let list of slist; let i = index">
                <span class="slt-name">{{ list }} </span>
                <!-- <span class="cls-close" (click)="removeItem(i);">X </span> -->
                <span class="cls-close text-light" (click)="removeItem(i)">
                  <i class="bx bx-x-circle bxCloseIcon mb-0 pb-0"></i>
                </span>
              </span>
            </div>
            <div *ngIf="slist?.length < 20">
              <input #menu type="text" class="form-control" placeholder="Enter comma separated one by one or select skills" formControlName="skillsearch" (keyup)="getsltSkills($any($event.target).value)" maxlength="30" />
              <span *ngIf="loderstt2" class="city_loader2">
                <i class="fa fa-circle-o-notch fa-spin"></i>
              </span>
              <div #menudrop id="myDropdown" class="dropdownContent_skills {{ cls1 }}">
                <a *ngFor="let item of templist" (click)="setskillvalue(item?.name)" class="salectchanged system">
                  {{ item.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- ====================================== -->
        <div class="row">
          <div class="col-lg-12 text-start">
            <!-- (click)="checkexp()" -->
            <span>
              <h4 class="rupees">
                Rate Recommendation
                <!-- <span class="dropRecom">
                  <i class="bx bxs-show"></i>
                </span> -->
              </h4>
            </span>
          </div>
          <hr />
        </div>
        <!-- ====================================== -->
        <div class="row form-group">
          <div class="col-lg-4 col-md-12">
            <label> Currency </label>
            <ng-select [searchable]="false" id="currency" formControlName="ratecurrency">
              <ng-option value="">Select</ng-option>
              <ng-option value="in">₹</ng-option>
              <ng-option value="doller">$</ng-option>
            </ng-select>

            <div *ngIf="formCreateBench.ratecurrency.invalid && (formCreateBench.ratecurrency.touched || submitted)">
              <small class="text-danger" *ngIf="formCreateBench.ratecurrency.errors?.required"> Currency is <b>required.</b> </small>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <label>Rate Type<span class="text-danger">*</span></label>
            <ng-select [searchable]="false" class="form-control" id="rate" formControlName="sltratetype">
              <ng-option value="Hourly">Hourly</ng-option>
              <ng-option value="Monthly">Monthly</ng-option>
            </ng-select>
          </div>
          <!-- *ngIf="currency=='in'" -->
          <div class="col-lg-4 col-md-12" *ngIf="currency == 'in'">
            <label
              >Rate <small class="color-theme">({{ addBenchForm.value.ratecurrency == "in" ? "INR" : "USD" }}/{{ addBenchForm.value.sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="text" class="form-control" placeholder="Rate" min="0" id="rateinmonth" (change)="shortingchange($any($event.target).value)" formControlName="rate" (keypress)="numberOnly($event)" />
              <!-- <div class="input-group-prepend">
                <div class="input-group-text form-control b_radiusL_zero">
                  <span (click)="checkexp()" class="drop-recom">
                    <i class="bx bxs-show bxIconSize"></i>
                  </span>
                  <small *ngIf="showtest" class="text-info">
                    {{ showtest }}
                  </small>
                </div>
              </div> -->
            </div>
          </div>

          <!-- *ngIf="currency=='doller'" -->

          <div class="col-lg-4 col-md-12" *ngIf="currency == 'doller'">
            <label
              >Rate <small class="color-theme">(USD/{{ sltratetype }})</small><span class="text-danger">*</span>
            </label>
            <div class="input-group mb-2 mr-sm-2">
              <input type="text" class="form-control" placeholder="Rate" min="0" formControlName="rate" (keypress)="numberOnly($event)" (change)="shortingchange($any($event.target).value)" />
              <div class="input-group-prepend">
                <div class="input-group-text form-control b_radiusL_zero">
                  <span (click)="checkexp()" class="drop-recom">
                    <i class="bx bxs-show bxIconSize"></i>
                  </span>
                  <small *ngIf="showtest" class="text-info">
                    {{ showtest }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ====================================== -->
        <div class="row form-group">
          <div class="col-sm-6">
            <label>
              Upload Resume<span class="color-theme"><span class="text-danger">* </span>(Without Name and contact details) </span></label
            >
            <input #myInput type="file" class="form-control uploadFil_Link" (change)="onFileSelect($event)" />
            <div *ngIf="addBenchForm?.value?.Resumename" class="text-link">
              <a href="{{ addBenchForm?.value?.Resumeurl }}" class="resume-link" target="_blank"> View Resume </a>
            </div>
          </div>
          <div *ngIf="showstt" class="col-sm-6">
            <label>{{ Uploadstt }} {{ progress }} % </label>
            <div *ngIf="loaderimg" class="loader-img">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="progress">
              <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">{{ progress }} %</div>
            </div>
          </div>
        </div>
        <!-- ====================================== -->
        <hr />
        <div class="form-group row">
          <div class="col-md-12">
            <label>Summary<span class="text-danger">*</span> </label>
            <ckeditor [editor]="Editor" [config]="configck" class="ck_height" (change)="getcountsummary($event)" formControlName="description"> </ckeditor>

            <div *ngIf="minerror">
              <small class="text-danger"> Characters is not less than 50. </small>
            </div>
            <div>
              <small class="text-warning"> Note: Characters not more than 5000 and not less than 50. </small>
              &nbsp;<span> <b>Left : </b> {{ cntchr }} </span>
            </div>
          </div>
        </div>
        <hr />

        <div class="project-div">
          <div class="project-title">
            <span> Project Details</span>
          </div>
          <div formArrayName="projectList">
            <div *ngFor="let item of sellingPrice.controls; let priceIndex = index; let last = last" [formGroupName]="priceIndex">
              <div class="form-group row">
                <div class="col-md-6">
                  <label>Project Title </label>
                  <input type="text" class="form-control" formControlName="title" maxlength="100" />
                </div>
                <div class="col-md-6">
                  <label>Duration in month </label>
                  <input type="number" class="form-control" formControlName="duration" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label>Role and Responsibilities </label>
                  <ckeditor [editor]="Editor" [config]="configck" formControlName="role" class="ck_height"> </ckeditor>

                  <small class="text-danger">Note: Characters not more than 4000 </small>
                </div>
                <div class="col-sm-6">
                  <label>Project Description </label>
                  <ckeditor [editor]="Editor" [config]="configck" formControlName="description" class="ck_height"> </ckeditor>
                  <small class="text-danger"> Note : Characters not more than 4000 </small>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-end">
                  <button class="deleteProjectBTN btn-sm btn-danger" type="button" (click)="deleteProject(priceIndex)">Delete</button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12 text-end">
              <button type="button" class="addProjectBTN add-more" (click)="addmoreproject()">Add</button>
            </div>
          </div>
        </div>
        <!-- ====================================== -->

        <div class="submit-btn">
          <div class="col-md-12">
            <!-- [disabled]="formCreateBench.invalid" -->
            <button type="button" class="btn job-post-btn" (click)="candidateFormSubmit()" [disabled]="formCreateBench.invalid">{{ btn }} {{ page }}</button>
            <!-- {{ id ? "Update" : "Submit" }} -->
          </div>
        </div>

        <!-- <button (click)="onSubmit()" type="button" class="btn">Post A Job</button> -->
      </div>
    </form>
  </div>
</div>
<!-- End of Add_Bench -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->

<!-- modal_for_ratesMin -->
<div *ngIf="isModalShown" #staticModal="bs-modal" [config]="{ backdrop: 'static', show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade mt-5" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Content for Rate Recommendation ( Bench Upload)</h5>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <row>
          <div class="col-lg-12">
            <!--  -->
            <ul class="drop-inner listStyle_none" *ngIf="currency === 'in'">
              <li *ngFor="let p of per; let i = index" class="borderBottom">
                <div class="inner-li" *ngIf="sltratetype == 'Hourly'" (click)="setrate()">Hourly: {{ p?.min * exp * doller }} - {{ p?.max * exp * doller }} - {{ p?.per }}% chance of shortlisting</div>
                <div class="inner-li" *ngIf="sltratetype == 'Monthly'" (click)="setrate()">Monthly: {{ p?.min * exp * totalhour * doller }} - {{ p?.max * exp * totalhour * doller }} - {{ p?.per }}% chance of shortlisting</div>
              </li>
            </ul>

            <ul class="drop-inner listStyle_none" *ngIf="currency === 'doller'">
              <li *ngFor="let p of per; let i = index" class="borderBottom">
                <div class="inner-li" *ngIf="sltratetype == 'Hourly'" (click)="setrate()">Hourly: {{ p?.min * exp }} - {{ p?.max * exp }} - {{ p?.per }}% chance of shortlisting</div>
                <div class="inner-li" *ngIf="sltratetype == 'Monthly'" (click)="setrate()">Monthly: {{ p?.min * exp * totalhour }} - {{ p?.max * exp * totalhour }} - {{ p?.per }}% chance of shortlisting</div>
              </li>
            </ul>
          </div>
        </row>
      </div>
    </div>
  </div>
</div>
