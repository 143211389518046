import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  apiUrl: any = "https://www.onbenchmark.com/";
  localUrl: any = "http://localhost:4500/";

  email: any;
  password: any;

  isModalShown = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {}

  showModal(val: any): void {
    if (val == "resetLoginModal") {
      this.isModalShown = true;
    }
  }
  hideModal(val: any): void {
    if (val == "resetLoginModal") {
      this.isModalShown = false;
    }
  }
  onHidden(val: any): void {
    if (val == "resetLoginModal") {
      this.isModalShown = false;
    }
  }

  login() {
    console.log("email ", this.email);
    console.log("password ", this.password);

    this.authService
      .login({ email: this.email, password: this.password })
      .subscribe(
        (resp) => {
          if (resp.confirmation === "success") { 
            console.log("Login successful!");

            this.toastrService.success("Welcome to the OnbenchMark!");

            console.log("res:==", resp.data);

            localStorage.setItem("email", this.email);
            localStorage.setItem("password", this.password);

            // this.router.navigate(['/dashboard']);
            this.router.navigate(["/dashboard"]);

            //   .then(() => {
            //   this.router.navigate(['/dashboard']);
            // });
          } else if (resp.confirmation === "already") {
            localStorage.setItem("email", this.email);
            localStorage.setItem("password", this.password);
            this.showModal("resetLoginModal");
          } else if (resp.confirmation === "invalid") {
            this.toastrService.error(resp.message);
            console.error("Login failed: ", resp.message);
          }
        },
        (error) => {
          console.error("Login failed", error);
        },
      );
  }

  resetLogin() {
    console.log("I am in resetLogin");

    const userEmail = localStorage.getItem("email");
    const userPassword = localStorage.getItem("password");

    console.log("userEmail:==", userEmail);
    console.log("userPassword:==", userPassword);

    if (userEmail && userPassword) {
      this.authService
        .logoutFromAll({ email: userEmail, password: userPassword })
        .subscribe(
          (res) => {
            if (res.confirmation === "success") {
              this.hideModal("resetLoginModal");
              this.login();
            }
          },
          (error) => {
            localStorage.clear();
          },
        );
    }
  }



  
}
